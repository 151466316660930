import { Fragment } from 'react';

import Texts from 'core/components/Texts';
import Feed from 'core/components/Feed';

import { Indent } from 'site/components/Wrappers';

import { Card1Type4 } from 'site/cards/Card1';


export default function CarNews() {
  return (
    <Fragment>
      <Texts type='title3'>Автомобильные новости</Texts>
      <Indent bottom={5} />
      <Feed
        card={Card1Type4}
        limit={4}
        interitemSpacing={0}
      />
    </Fragment>
  );
}
