import PropTypes from 'prop-types';
import ButtonWithIcon from 'core/components/ButtonWithIcon';
import IconFilter from 'site/icons/Filter';

import bindProps from 'core/components/bindProps';
import withTheme from 'core/components/theme';

import { FULL_FILTER_HASH } from 'site/constants';

import styles from './index.styl';

const FiltersCount = withTheme(props => {
  const {
    theme,
    count,
  } = props;
  return (
    <div className={styles.countWrapper}>
      <style jsx>{`
        .${styles.count}
          background-color ${theme.controls.button.primary.idle.color}
          color ${theme.controls.button.primary.idle.background}
      `}</style>
      <div className={styles.count}>{count}</div>
    </div>
  );
});

FiltersCount.propTypes = {
  count: PropTypes.number,
};

export default function FullFilterToggler(props) {
  const {
    location,
    toggleFullForm,
    fullFiltersCount,
    ...otherProps
  } = props;

  const isFullFilterOpened = location.hash === FULL_FILTER_HASH;

  if (isFullFilterOpened) return null;

  let buttonType = 'secondary';
  let Icon = bindProps({ width: 12, height: 12 })(IconFilter);

  if (fullFiltersCount) {
    buttonType = 'primary';
    Icon = bindProps({ count: fullFiltersCount })(FiltersCount);
  }
  return (
    <ButtonWithIcon
      radius='18px'
      type={buttonType}
      size='small'
      iconPosition='right'
      icon={Icon}
      minWidth={0}
      onClick={toggleFullForm}
      {...otherProps}
    >
      Все фильтры
    </ButtonWithIcon>
  );
}

FullFilterToggler.propTypes = {
  fullFiltersCount: PropTypes.number,
  location: PropTypes.object,
  toggleFullForm: PropTypes.func,
};
