import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';
import QutoBrandIcon from 'core/components/QutoBrandIcon';

import Scroller from 'core/components/Scroller';
import Link from 'core/components/Link';
import EmptyWrapper from 'core/components/EmptyWrapper';

import styles from './logos.styl';

/**
 * В десктоп версии логотипы показываем по 9 штук, выбирая рандомом из набора популярных брендов.
 * Для этого используем state, чтобы не было расхождений между клиент/сервер.
 */

class Logos extends PureComponent {
  state = {
    logosBrands: [],
  };

  componentDidMount() {
    const { brands, isMobile } = this.props;

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      logosBrands: isMobile
        ? [...brands]
        : [...brands]
          .sort(() => 0.5 - Math.random())
          .slice(0, 9),
    });
  }

  render() {
    const {
      isMobile,
      theme,
      children,
      isMoto,
    } = this.props;

    const scoped = resolveScopedStyles((
      <scope>
        <style jsx>{`
          .${styles.logo}
            border 1px solid ${theme.colors.primary100}
            transition border ${theme.animations.hover}

            &:hover
              border 1px solid ${theme.colors.primary250}
        `}</style>
      </scope>
    ));

    const logos = isMobile ? this.props.brands : this.state.logosBrands;

    const Wrapper = isMobile ? Scroller : EmptyWrapper;

    return (
      <div className={styles.container} data-qa='brand-cards' >
        <Wrapper>
          <div className={styles.logos}>
            {logos.map(brand => {
              const {
                attributes: {
                  slug,
                },
              } = brand;

              return (
                <Link
                  type='secondary'
                  to={`${isMoto ? '/moto' : ''}/${slug}`}
                  className={styles.logoLink}
                  key={slug}
                  title={brand.attributes.name}
                  data-qa='brand-name-cards-link'
                >
                  <QutoBrandIcon
                    brand={brand}
                    size={45}
                    className={scoped.wrapClassNames(styles.logo)}
                    data-qa='brand-name-cards'
                  />
                </Link>
              );
            }
            )}
            {children}
          </div>
        </Wrapper>
        <scoped.styles />
      </div>
    );
  }
}

Logos.propTypes = {
  isMobile: PropTypes.bool,
  brands: PropTypes.array,
  theme: PropTypes.object,
  isMoto: PropTypes.bool,
};

export default compose(
  withBreakpoint,
  withTheme,
)(Logos);
