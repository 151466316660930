import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';
import { NegativeMobile } from 'site/components/Wrappers';
import GalleryWithAds from 'site/components/GalleryWithAds';

import GalleryWrapper from './GalleryWrapper';

class TopicHeaderGallery extends PureComponent {
  state = {
    isMediaVisible: false,
  };

  componentDidMount() {
    const {
      content: {
        attributes: {
          announce,
        },
      },
      location: {
        hash,
      },
    } = this.props;

    if (hash || !announce) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ isMediaVisible: true });
    }
  }

  mediaHandler = () => {
    this.setState({ isMediaVisible: true });
  };

  render() {
    const { content } = this.props;
    const { isMediaVisible } = this.state;

    const {
      relationships: {
        photo_gallery: {
          data: photoGallery,
          data: { attributes: { image_count: imageCount } },
        },
      },
    } = content;

    if (!photoGallery) return null;

    const galleryProps = {
      content,
      isMediaVisible,
      mediaHandler: this.mediaHandler,
      imageCount,
    };

    const themeName = isMediaVisible ? 'light' : 'dark';

    return (
      <NegativeMobile>
        <ThemeSwitchWithColor tone={themeName}>
          <GalleryWrapper {...galleryProps}>
            <GalleryWithAds
              photoGallery={photoGallery}
              firstImageFetchPriority='high'
              instantOnFirstImage
              hashNavigation
            />
          </GalleryWrapper>
        </ThemeSwitchWithColor>
      </NegativeMobile>
    );
  }
}

TopicHeaderGallery.propTypes = {
  content: PropTypes.object,
  location: PropTypes.object.isRequired,
};

export default withRouter(TopicHeaderGallery);
