import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import { servicesApi } from 'site/components/servicesApi/ServicesApiClient';
import { denormalizeData } from 'core/utils/api';

import H3 from 'core/components/H3';
import Feed from 'core/components/Feed';
import Close from 'core/components/Icon/Close';
import SearchInput from 'core/components/SearchInput/index';
import bindProps from 'core/components/bindProps';
import { SimpleLoader } from 'core/components/Loader';

import Modal from 'site/components/Modal';
import { Indent } from 'site/components/Wrappers';

import CardServiceBrand from 'site/cardsService/CardServiceBrand';
import ArrowStickLeft from 'site/icons/ArrowStickLeft';

import groupBrandsByFirstLetter from '../utils';
import LetteredList from '../LetteredList';

import styles from './index.styl';


function ModalBrands(props) {
  const {
    isMoto,
    isOpen,
    onClose,
    isMobile,
  } = props;

  const [value, setValue] = useState('');
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let ignore = false;

    if (isOpen && brands.length === 0) {
      const getBrands = isMoto ? servicesApi.getMotoBrands() : servicesApi.getBrands();

      getBrands
        .then(denormalizeData)
        .then(data => {
          if (!ignore) {
            setBrands(data);
            setLoading(false);
          }
        }).catch(e => {
          if (!ignore) {
            console.error(e);
            setLoading(false);
          }
        });
    }

    return () => {
      ignore = true;
    };
  }, [isOpen, isMoto, brands.length]);

  const groupedBrands = useMemo(() => groupBrandsByFirstLetter(brands), [brands]);

  const filteredBrands = useMemo(() => {
    if (!value) return [];

    const firstLetterUpperInValue = value[0].toLocaleUpperCase();

    return groupedBrands[firstLetterUpperInValue]
      ? groupedBrands[firstLetterUpperInValue].filter(brand => {
        return brand.attributes.name
          .toLocaleUpperCase()
          .indexOf(value.toLocaleUpperCase()) !== -1;
      }) : [];
  }, [value, groupedBrands]);
  const ItemCard = bindProps({ type: isMobile ? 1 : 0 })(CardServiceBrand);

  const FeedCard = value
    ? ItemCard
    // eslint-disable-next-line react/prop-types
    : ({ content }) => <LetteredList {...content} />;

  const feedContent = value
    ? filteredBrands
    : Object.keys(groupedBrands).map(letter => ({
      id: letter,
      letter,
      items: groupedBrands[letter],
      itemCard: ItemCard,
    }));


  function searchBrand(evt) {
    setValue(evt.target.value);
  }


  const searchInputProps = {
    placeholder: 'Поиск по названию',
    size: 'small',
    onInput: searchBrand,
    autoFocus: !isMobile,
  };

  return (
    <Modal
      isOpen={isOpen}
      style={{ content: { flex: '0 1 800px' } }}
      onRequestClose={onClose}
    >
      <div className={styles.brands} data-qa='all-brands-popup'>
        <Desktop>
          <div className={styles.header}>
            <div className={styles.close} onClick={onClose}>
              <Close />
            </div>
            <H3 className={styles.title}>Все марки</H3>
          </div>
          <Indent bottom={40} />
          <SearchInput {...searchInputProps} />
        </Desktop>
        <Mobile>
          <div className={styles.header}>
            <ArrowStickLeft onClick={onClose} className={styles.arrowLeft} />
            <div className={styles.title}>Марка</div>
            <SearchInput {...searchInputProps} />
          </div>
        </Mobile>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            {loading ?
              <SimpleLoader />
              :
              <Feed
                card={FeedCard}
                content={feedContent}
                columns={isMobile ? 1 : 4}
                interitemSpacing={isMobile ? 10 : 20}
              />
            }
            {/* добавляем отступ для firefox */}
            <div style={{ height: '20px' }} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalBrands.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isMoto: PropTypes.bool,
};

export default withBreakpoint(ModalBrands);
