import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import mapTypes from './types';
import styles from './index.styl';

function Label({ theme, type, className }) {
  if (!type) return null;

  const {
    text,
    bgColor,
    color,
  } = mapTypes[type] || {};

  return (
    <div className={cx(styles.label, className)}>
      <style jsx>{`
        .${styles.label}
          background ${bgColor}
          color ${theme.colors[color]}
          font 900 10px/12px ${theme.fonts.text}
      `}</style>
      {text}
    </div>
  );
}

Label.propTypes = {
  /** Тип лейбла */
  type: PropTypes.oneOf([1, 2, 3]),
  /** @ignore */
  className: PropTypes.string,
  /** @ignore */
  theme: PropTypes.object,
};

const LabelWithHOCs = withTheme(Label);
LabelWithHOCs.displayName = 'Label';

export default LabelWithHOCs;
export { Label as StorybookComponent };
