/* eslint-disable react/prop-types */

import styles from './index.styl';

export default function SelectContainer(props) {
  const {
    children,
    innerProps,
    ...otherProps
  } = props;

  return (
    <div
      {...innerProps}
      {...otherProps.selectProps.dataQa && { 'data-qa': otherProps.selectProps.dataQa }}
      className={styles.selectContainer}
    >
      {children}
    </div>
  );
}
