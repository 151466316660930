import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import themePropTypes from 'core/utils/prop-types/theme';

import renameThemeProp from 'site/components/renameThemeProp';

import styles from './index.styl';


function Control(props) {
  const {
    /* eslint-disable react/prop-types */
    children,
    innerProps,
    innerRef,
    hasValue,
    selectProps,
    selectProps: {
      label,
      isFocused,
      isDisabled,
    },
    /* eslint-enable react/prop-types */
    theme: {
      controls: {
        inputDropdown: inputDropdownAtoms,
      },
    },
  } = props;

  return (
    <div
      {...innerProps}
      ref={innerRef}
      className={cx(
        'control',
        styles.control,
        isFocused && ['_hasFocus', styles._hasFocus],
        isDisabled && '_disabled',
        hasValue && ['_hasValue', styles._hasValue],
      )}
    >
      <style jsx>{`
        .control
          background-color ${inputDropdownAtoms.idle.backgroundColor}
          border-bottom ${selectProps.hideBorder ? 'none' : inputDropdownAtoms.idle.borderBottom}
          color ${inputDropdownAtoms.idle.color}
          border-radius ${selectProps.roundingPosition === 'top' ? '4px 4px 0 0' : '0 0 4px 4px'}
          :global([id*="$"])
            fill ${inputDropdownAtoms.idle.indicatorColor}
          &:hover
            background-color ${inputDropdownAtoms.hover.backgroundColor}
            border-bottom ${selectProps.hideBorder ? 'none' : inputDropdownAtoms.hover.borderBottom}
            color ${inputDropdownAtoms.hover.color}
            :global([id*="$"])
              fill ${inputDropdownAtoms.hover.indicatorColor}
          &._hasValue
            background-color ${inputDropdownAtoms.hasValue.backgroundColor}
            border-bottom ${selectProps.hideBorder ? 'none' : inputDropdownAtoms.hasValue.borderBottom}
            color ${inputDropdownAtoms.hasValue.color}
            :global([id*="$"])
              fill ${inputDropdownAtoms.hasValue.indicatorColor}
          &._hasFocus
            background-color ${inputDropdownAtoms.active.backgroundColor}
            border-bottom ${selectProps.hideBorder ? 'none' : inputDropdownAtoms.active.borderBottom}
            color ${inputDropdownAtoms.active.color}
            :global([id*="$"])
              fill ${inputDropdownAtoms.active.indicatorColor}
          &._disabled
            background-color ${inputDropdownAtoms.disabled.backgroundColor}
            border-bottom ${selectProps.hideBorder ? 'none' : inputDropdownAtoms.disabled.borderBottom}
            color ${inputDropdownAtoms.disabled.color}
            :global([id*="$"])
              fill ${inputDropdownAtoms.disabled.indicatorColor}

        .label
          color ${inputDropdownAtoms.idle.color}
          .control:hover &
            color ${inputDropdownAtoms.hover.labelColor}
          .control._hasValue &
            color ${inputDropdownAtoms.hasValue.labelColor}
          .control._hasFocus &
            color ${inputDropdownAtoms.active.labelColor}
          .control._disabled &
            color ${inputDropdownAtoms.disabled.labelColor}
      `}</style>
      {children}
      <div className={cx('label', styles.label)}>{label}</div>
    </div>
  );
}

const inputDropdownContract = `{
  backgroundColor,
  color,
  labelColor,
  borderBottom,
  indicatorColor,
}`;

Control.propTypes = {
  theme: themePropTypes(`{
    controls: {
      inputDropdown: {
        idle: ${inputDropdownContract},
        hover: ${inputDropdownContract},
        active: ${inputDropdownContract},
        hasValue: ${inputDropdownContract},
        disabled: ${inputDropdownContract},
      }
    }
  }`),
  selectProps: PropTypes.object,
};

export default renameThemeProp(withTheme(Control));
