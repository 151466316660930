import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';
import { capitalize } from 'core/utils/string-helper';

import Safety from 'site/icons/Safety';
import Comfort from 'site/icons/Comfort';
import Audio from 'site/icons/Audio';
import Manufacturability from 'site/icons/Manufacturability';

import { abilities } from 'site/constants';

import styles from './index.styl';

function Abilities({ theme, battery }) {
  if (!battery) return null;

  const icons = {
    Safety,
    Comfort,
    Audio,
    Manufacturability,
  };

  return (
    <div className='abilities'>
      <style jsx>{`
        .${styles.complectationName}
          color ${theme.colors.primary400}
      `}</style>
      <div className={styles.header}>
        {abilities.map(({ name, slug }, index) => {
          if (slug === 'total') return null;

          const Icon = icons[capitalize(slug)];

          return (
            <div className={styles.ability} key={index}>
              <Icon className={styles.abilityIcon} />
              <div className={styles.value}>{battery[slug]}</div>
              <div className={styles.complectationName}>{name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

Abilities.propTypes = {
  battery: PropTypes.object,
  theme: themePropTypes(`{
    colors: {
      primary400
    }
  }`),
};

export default withTheme(Abilities);
