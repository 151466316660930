import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';
import themePropTypes from 'core/utils/prop-types/theme';

const relationships = resolveRelationships(
  ['image'],
  {},
  { image: { versions: {} } },
);

function CaptionsGallery(props) {
  const {
    content,
    theme,
    isMobile,
  } = props;

  const {
    image: {
      caption,
      credits,
    },
  } = relationships(content);

  if (!caption && !credits) return null;

  return (
    <Fragment>
      <style jsx>{`
        .caption
        .credits
          font 13px/1.2 ${theme.fonts.text}
          color ${theme.colors.primary500}

        .credits
          margin-top ${isMobile ? '10px' : 0}
      `}
      </style>
      <div className='caption'>{caption}</div>
      {credits && <div className='credits'>{credits}</div>}
    </Fragment>
  );
}

CaptionsGallery.propTypes = {
  content: PropTypes.object.isRequired,
  breakpoint: breakpointPropTypes(),
  isMobile: PropTypes.bool,
  theme: themePropTypes(`{
    colors: {
      primary500,
    },
  }`),
};

export default withTheme(withBreakpoint(CaptionsGallery));
