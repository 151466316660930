import { withRouter } from 'core/libs/router';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { buidReleasePhotoUrl } from 'site/utils';

import Image from 'core/components/Image';
import Link from 'core/components/Link';
import Swipe, { Slide } from 'core/components/Swipe';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { imageAttributes } from 'core/utils/prop-types/model';

import PhotoSimple from 'site/icons/PhotoSimple';

import styles from './index.styl';

const THUMBS_HEIGHT = 60;
const THUMBS_WIDTH = 92;

function Navigation(props) {
  const {
    theme,
    galleryImages,
    activeIndex,
    focusHandler,
    goToHandler,
    hasGallery,
    match,
  } = props;

  const {
    colors: {
      primary,
      primary250,
      active2,
      content,
    },
  } = theme;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.navigation}
          // width + offset for box-shadow
          width ${THUMBS_WIDTH + 4}px

        :global(.${styles.photoSimple} .lens)
          fill ${active2}

        .${styles.thumb}
          &:before
            border 1px solid ${primary250}
            transition opacity ${theme.animations.hover} color ${theme.animations.hover}

        .${styles.activeThumb}
          &:after
            border 1px solid ${primary}
            box-shadow 0 0 0 1px ${primary}

        .${styles.showMore}
          color ${content}
          background ${active2}
          width ${THUMBS_WIDTH}px
          height ${THUMBS_HEIGHT}px

          &:before
            border 1px solid ${active2}

          &:visited
            color ${content}
            background ${active2}
      `}</style>
    </scope>
  );

  return (
    <div className={scope.wrapClassNames(styles.navigation)}>
      <Swipe
        virtualTranslate
        onInteraction={focusHandler}
      >
        {galleryImages.map((image, i) => {
          const { id: imageId } = image;
          const active = i === activeIndex;
          const thumbnail = getThumbnail(image, '3/2');

          return (
            <Slide key={imageId}>
              <div
                className={scope.wrapClassNames(styles.thumb, active && styles.activeThumb)}
                style={{
                  width: THUMBS_WIDTH,
                  height: THUMBS_HEIGHT,
                }}
                onClick={active ? undefined : () => goToHandler(i)}
              >
                <div className={styles.image}>
                  <Image
                    placement='cover'
                    src={thumbnail}
                    height={THUMBS_HEIGHT}
                    width={THUMBS_WIDTH}
                    instant
                  />
                </div>
              </div>
            </Slide>
          );
        }).concat(
          hasGallery && (
            <Slide key={galleryImages.length + '_showMoreThumb'}>
              <Link
                to={buidReleasePhotoUrl(match.params)}
                type='blank'
                className={scope.wrapClassNames(
                  styles.showMore,
                  styles.thumb,
                  activeIndex === galleryImages.length && styles.activeThumb
                )}
              >
                Ещё
                <br />
                <PhotoSimple className={scope.wrapClassNames(styles.photoSimple)} />
              </Link>
            </Slide>
          )
        )}
      </Swipe>
      <scope.styles />
    </div>
  );
}

Navigation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
      modification: PropTypes.string,
    }),
  }),
  galleryImages: PropTypes.arrayOf(modelPropTypes(imageAttributes)),
  activeIndex: PropTypes.number,
  focusHandler: PropTypes.func,
  goToHandler: PropTypes.func,
  theme: themePropTypes(`{
    colors: {
      primary250,
    }
  }`),
  /**
   * Если false, то не показываем кнопку "ещё"
   */
  hasGallery: PropTypes.bool,
};

export default withRouter(withTheme(Navigation));
