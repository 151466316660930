import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';

import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad';
import AdBillboard from 'core/components/Ad/Billboard';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';

import card1Styles from './styles/card1';
import card3Styles from './styles/card3';

import { RIGHT_COLUMN_WIDTH } from 'site/constants';

export const Billboard = bindProps({
  name: 'Billboard',
  height: 250,
  blockId: '433009906',
  options: {
    p1: 'brzph',
    p2: 'y',
    pct: 'c',
  },
})(AdBillboard);

export const Background = bindProps({
  name: 'Background',
  blockId: '439300756',
  options: {
    p1: 'bluom',
    p2: 'enhl',
    pct: 'a',
  },
})(Ad);

export const Fullscreen = bindProps({
  name: 'Fullscreen',
  blockId: '433009904',
  options: {
    'p1': 'beema',
    'p2': 'emiu',
    'pct': 'a',
    'pli': 'b',
    'plp': 'c',
    'pop': 'a',
  },
})(Ad);

export const Shtorka = bindProps({
  name: 'Shtorka',
  blockId: '450995008',
  options: {
    p1: 'brgyd',
    p2: 'exne',
    pct: 'a',
  },
})(Ad);

export const Ad240x400 = bindProps({
  name: '240x400',
  width: RIGHT_COLUMN_WIDTH,
  height: 600,
  blockId: '433009886',
  stickyTemp: 3,
  options: {
    p1: 'beels',
    p2: 'emhk',
    pct: 'c',
  },
})(Ad);

export const Ad240x400Second = bindProps({
  name: '240x400_2nd',
  width: RIGHT_COLUMN_WIDTH,
  height: 600,
  lazy: true,
  blockId: '433009912',
  options: {
    p1: 'brzpq',
    p2: 'ewqs',
    pct: 'a',
  },
})(Ad);

export const ContextAd = bindProps({
  name: 'Context',
  height: 80,
  lazy: true,
  blockId: '433009944',
  options: {
    p1: 'bunck',
    p2: 'ewzc',
    pct: 'a',
  },
})(Ad);

export function Context({ count }) {
  return <ContextAd manualPuids={{ puid44: `context_item${count}` }} />;
}

Context.defaultProps = {
  count: 1,
};

Context.propTypes = {
  count: PropTypes.number,
};

export const Sponsored = compose(
  bindProps({
    name: 'Sponsored',
    blockId: '442926104',
    options: {
      'p1': 'bwugx',
      'p2': 'fomx',
      'pct': 'c',
    },
  }),
  sponsoredStyles,
)(Ad);

export const SuperFooter = bindProps({
  name: '100%x240',
  height: 240,
  lazy: true,
  blockId: '433009910',
  options: {
    p1: 'brzpk',
    p2: 'fcuz',
    pct: 'a',
  },
})(Ad);

export const Parallax = bindProps({
  name: 'Parallax',
  height: 250,
  lazy: true,
  blockId: '442925430',
  options: {
    'p1': 'bwugw',
    'p2': 'fhoe',
    'pct': 'a',
  },
})(Ad);

export const AdCenter = bindProps({
  name: 'Center',
  height: 240,
  lazy: true,
  blockId: '442924770',
  options: {
    'p1': 'bwugv',
    'p2': 'fomw',
    'pct': 'a',
  },
})(Ad);

export const InRead = bindProps({
  name: 'inRead',
  height: 300,
  blockId: '439300768',
  options: {
    p1: 'brzpt',
    p2: 'fcvb',
    pct: 'a',
  },
})(Ad);

export const Native1asCard3 = compose(
  bindProps({
    name: 'Native1',
    blockId: '433009936',
    options: {
      p1: 'bunci',
      p2: 'fhzr',
      pct: 'a',
    },
  }),
  card3Styles
)(Ad);

export const Native2asCard3 = compose(
  bindProps({
    name: 'Native2',
    blockId: '433009942',
    options: {
      p1: 'buncj',
      p2: 'fhzs',
      pct: 'a',
    },
  }),
  card3Styles
)(Ad);

export const Native3asCard1 = compose(
  bindProps({
    name: 'Native3',
    width: RIGHT_COLUMN_WIDTH,
    blockId: '462307654',
    lazy: true,
    options: {
      'p1': 'bxynr',
      'p2': 'fjgk',
      'pct': 'a',
    },
  }),
  card1Styles
)(Ad);

export const NativeFooterAsCard3 = compose(
  bindProps({
    name: 'NativeFooter',
    lazy: true,
    blockId: '441551896',
    options: {
      p1: 'buncl',
      p2: 'fhzt',
      pct: 'a',
    },
  }),
  card3Styles
)(Ad);

export const InGallery = bindProps({
  name: 'InGallery',
  blockId: '579291705',
  disableAutoInit: true,
})(Ad);
