/**
 * Возвращает отклонённый промис с кодом 404, если данные falsy
 * или пустой массив, и разрешённый в противном случае.
 *
 * TODO:
 * По хорошему, в API должны быть ручки для получения одной
 * конкретной сущности, типа /topics/{topic_link} в Bebop API.
 * Когда такие ручки будут готовы, эту утилиту и все её вызовы
 * на страницах надо будет удалить вместе с `catch(renderError)`,
 * т.к. при неправильных параметрах запроса эти ручки будут
 * отдавать 404, который будет обрабатываться уже в LB.
 *
 * @param {*} result - любые данные.
 * @returns {Promise} - отклонённый промис с кодом 404.
 */
function rejectEmptyResult(result) {
  if (!result || Array.isArray(result) && !result.length) {
    return Promise.reject({ statusCode: 404 });
  }

  return Promise.resolve(result);
}

export default rejectEmptyResult;
