import PropTypes from 'prop-types';
import { Fragment, useContext, useState } from 'react';

import { withRouter } from 'core/libs/router';
import withTheme from 'core/components/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { withNonPureBreakpoint } from 'core/components/breakpoint';

import Link from 'core/components/Link';
import Dropdown from 'core/components/Dropdown';
import Modal from 'site/components/Modal';

import ButtonWithIcon from 'core/components/ButtonWithIcon';
import { CompareContext } from 'site/components/CompareProvider';
import ButtonTextWrap from 'site/components/Wrappers/ButtonTextWrap';

import { createCompareLink } from 'site/utils/compare';
import { isMotoPath } from 'site/utils';

import AutoCompare from 'site/icons/AutoCompare';
import MotoCompare from 'site/icons/MotoCompare';
import Triangle from 'site/icons/Triangle';

import CompareListItem from './ListItem';
import styles from './index.styl';

function CompareInfo({ isDesktop, theme, location: { pathname } }) {
  const [isOpen, setIsOpen] = useState(false);
  const { carUuids, motoUuids } = useContext(CompareContext);
  const hasAutoUuid = carUuids.length > 0;
  const hasMotoUuid = motoUuids.length > 0;

  if (!hasAutoUuid && !hasMotoUuid) return null;

  const hasMultipleOptions = hasAutoUuid && hasMotoUuid;
  const isMotoUuids = !hasMultipleOptions && hasMotoUuid;
  const isMotoPage = isMotoPath(pathname);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  let icon;
  let uuids;

  if (hasMultipleOptions && isMotoPage) {
    icon = MotoCompare;
    uuids = motoUuids;
  } else if (hasMultipleOptions && !isMotoPage) {
    icon = AutoCompare;
    uuids = carUuids;
  } else {
    icon = isMotoUuids ? MotoCompare : AutoCompare;
    uuids = isMotoUuids ? motoUuids : carUuids;
  }
  const initButton = (
    <ButtonWithIcon
      size={isDesktop ? 'small' : 'extraSmall'}
      type='secondary'
      radius='20px'
      icon={icon}
      minWidth='auto'
      onClick={onOpen}
    >
      <ButtonTextWrap>
        {isDesktop ? `Сравнить (${uuids.length})` : uuids.length}
        {hasMultipleOptions && <span className={styles.triangleIcon}><Triangle /></span>}
      </ButtonTextWrap>
    </ButtonWithIcon>
  );

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          color ${theme.colors.active1}
          font 500 14px/16px ${theme.fonts.text}

          &:visited
            color ${theme.colors.active1}

          & :global([id*="!"])
            stroke ${theme.colors.active1}
      `}</style>
    </scope>
  );

  const params = [
    {
      title: 'Автомобили',
      uuids: carUuids,
      icon: AutoCompare,
    },
    {
      title: 'Мотоциклы',
      uuids: motoUuids,
      icon: MotoCompare,
    },
  ];

  const listOptions = (<ul className={styles.dropdownList}>
    {params.map((data, i) => {
      return (
        <CompareListItem
          key={i}
          motoUuids={motoUuids}
          onClose={onClose}
          scope={scope}
          {...data}
        />
      );
    })}
    <scope.styles />
  </ul>);

  return (
    <Fragment>
      {
        hasMultipleOptions && isDesktop && (
          <Dropdown
            contentPosition='right'
            onCloseDelay={200}
            closeAfterUrlChanged
            anchor={initButton}
            showIcon={false}
            contentBorderRadius='5px'
            contentPadding='0'
            contentMargin='5px 0 0 0'
            trigger='click'
            onClose={onClose}
            onOpen={onOpen}
          >
            {listOptions}
          </Dropdown>
        )
      }

      {
        hasMultipleOptions && !isDesktop && (
          <Fragment>
            {initButton}
            <Modal
              className={styles.modal}
              style={{
                content: {
                  width: 'auto',
                  height: 'auto',
                },
              }}
              isOpen={isOpen}
              onRequestClose={onClose}
            >
              {listOptions}
            </Modal>
          </Fragment>
        )
      }

      {
        !hasMultipleOptions && (
          <Link type='secondary' to={createCompareLink(uuids, isMotoUuids)}>
            {initButton}
          </Link>
        )
      }
    </Fragment>
  );
}

CompareInfo.propTypes = {
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  /** @ignore */
  isDesktop: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
};

const CompareInfoWithHOCs = withNonPureBreakpoint(withTheme(withRouter(CompareInfo)));
CompareInfoWithHOCs.displayName = 'CompareInfo';

export default CompareInfoWithHOCs;
export { CompareInfo as StorybookComponent };
