import PropTypes from 'prop-types';

import Link from 'core/components/Link';

import withTheme from 'core/components/theme';
import QutoBrandIcon from 'core/components/QutoBrandIcon';

import themePropTypes from 'core/utils/prop-types/theme';
import { carBrandAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import resolveRelationships from 'core/utils/relationships';

import withGeo from 'site/components/GeoContext/withGeo';

import modelPropTypes, {
  brandOffersStatAttributes,
} from 'site/utils/prop-types/model';
import relationshipsPropTypes from 'site/utils/prop-types/relationships';

import styles from './index.styl';

const relationships = resolveRelationships(['brand'], {}, {
  brand: {
    attributes: {},
  },
});


function CardBrandOffers(props) {
  const {
    content,
    content: {
      attributes: {
        count,
      },
    },
    fullHeight,
    geoSlug,
    defaultGeo,
    theme: {
      colors: {
        divider: colorDivider,
        primary: colorPrimary,
        hint: colorHint,
      },
    },
  } = props;

  const {
    brand: {
      name: brandName,
      slug: brandSlug,
    },
    brand,
  } = relationships(content);

  const link = `/inventory/${geoSlug ? geoSlug : defaultGeo.attributes.url}/${brandSlug}`;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.cardBrandOffers}
          border-color ${colorDivider}

        .${styles.brandName}
          color ${colorPrimary}

        .${styles.offersCount}
          color ${colorHint}
      `}</style>
    </scope>
  );

  return (
    <Link
      className={scope.wrapClassNames(
        styles.cardBrandOffers,
        fullHeight && styles._fullHeight,
      )}
      type='secondary'
      to={link}
    >
      <QutoBrandIcon brand={brand} size={40} />
      <div className={scope.wrapClassNames(styles.brandName)}>
        {brandName}
      </div>
      <div className={scope.wrapClassNames(styles.offersCount)}>
        {count}
      </div>
      <scope.styles />
    </Link>
  );
}

CardBrandOffers.propTypes = {
  /** Сущность BrandOffersStat с брендом в связях. */
  content: modelPropTypes(
    brandOffersStatAttributes,
    relationshipsPropTypes({
      brand: modelPropTypes(carBrandAttributes),
    }),
  ),

  /** Растягивает карточку на 100% высоты контейнера. */
  fullHeight: PropTypes.bool,

  /** geoSlug из гео-контекста */
  geoSlug: PropTypes.string,

  /** дефолтный geo из гео-контекста */
  defaultGeo: PropTypes.object,

  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      divider,
    }
  }`),
};

const CardWithHOCs = withTheme(withGeo(CardBrandOffers));
CardWithHOCs.displayName = 'CardBrandOffers';

export default CardWithHOCs;
export { CardBrandOffers as StorybookComponent };
