import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';
import { withRouter } from 'core/libs/router';

import { withBreakpoint } from 'core/components/breakpoint';

import Drum from 'core/components/Drum';

import Divider from 'site/components/Divider';
import FeedHeader from 'site/components/FeedHeader';
import CardServiceRelease1 from 'site/cardsService/CardServiceRelease1';
import { ShadowContentBg } from 'site/components/Wrappers';

import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

function Bodies({ isMobile, bodies, location, withShadowBg }) {
  if (!bodies || bodies.length === 0) return null;

  const { pathname } = location;
  const filteredBodies = bodies.filter(body => {
    const url = get(body, 'attributes.url');
    return !url.includes(pathname);
  });

  if (!filteredBodies || filteredBodies.length === 0) return null;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const commonProps = {
    title: 'Эта модель в других кузовах',
    headerComponent: FeedHeader,
    grid: true,
    itemWidth: '25%',
    itemWidthMobile: '50%',
    spaceBetween: 20,
    content: filteredBodies,
    card: CardServiceRelease1,
    showHeaderArrows: true,
    showArrowsOnHover: true,
    arrowsShift: -10,
  };

  if (withShadowBg) {
    return (
      <ShadowContentBg type={isMobile ? 4 : 3}>
        <Drum {...commonProps} />
      </ShadowContentBg>
    );
  }

  return (
    <Fragment>
      <Divider top={vertical} bottom={vertical} />
      <Drum {...commonProps} />
    </Fragment>
  );
}

Bodies.propTypes = {
  isMobile: PropTypes.bool,
  bodies: PropTypes.arrayOf(modelPropTypes(carReleaseAttributes)),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  withShadowBg: PropTypes.bool,
};

export default withRouter(withBreakpoint(Bodies));
