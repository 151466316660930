import PropTypes from 'prop-types';

import { setCheckedRadioRange } from 'site/utils/forms';

import RadioClouds from 'site/components/RadioClouds';

/**
 * Компонент обертка над радио-группой с поддержкой range значений.
 */
export default function RadioCloudsRange({ onChange, options, ranges, ...otherProps }) {
  return (
    <RadioClouds
      hideDefaultRadio
      onChange={(value, { name }) => {
        if (!value) {
          onChange({ min: undefined, max: undefined }, { name });
          return;
        }
        if (value.min && value.max) {
          onChange({ min: value.min, max: value.max }, { name });
          return;
        }
        if (value.min) {
          onChange({ min: value.min, max: undefined }, { name });
          return;
        }
        if (value.max) {
          onChange({ min: undefined, max: value.max }, { name });
        }
      }}
      options={setCheckedRadioRange(options, otherProps.value, ranges)}
      {...otherProps}
    />
  );
}

RadioCloudsRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  ranges: PropTypes.object.isRequired,
};
