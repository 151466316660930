
import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';
import { buildSearchRequestParams, isMotoPath } from 'site/utils';


export default resolve({
  brand: ({ servicesApi, renderError, match, location }) => {
    const isLcvPage = Boolean(match.params.lcv);

    let brandsAPI;

    const isMoto = isMotoPath(location.pathname);
    const vehiclePrefix = isMoto ? 'moto' : 'car';

    const requestParams = {
      [`attributes[${vehiclePrefix}_brand]`]: 'base,seo,image',
      'filter[brand]': match.params.brand,
      limit: 1,
      ...(isLcvPage && { 'filter[car_type]': 'lcv' }),
    };

    if (isMoto) {
      brandsAPI = servicesApi.getMotoBrands;
    } else {
      brandsAPI = servicesApi.getBrands;
    }

    return brandsAPI(requestParams)
      .then(brandsData => denormalizeData(brandsData)[0])
      .then(rejectEmptyResult)
      .catch(renderError);
  },

  brandSeo: ({ servicesApi, consoleError, match, location }) => {
    const isMoto = isMotoPath(location.pathname);

    const seoAPI = isMoto ? servicesApi.getMotoBrandSeo : servicesApi.getCarBrandSeo;

    return seoAPI({
      'filter[brand]': match.params.brand,
    })
      .then(denormalizeData)
      .catch(consoleError('brandSeo', {}));
  },

  releases: ({ servicesApi, consoleError, match, location }) => {
    let releasesAPI;

    const isLcvPage = Boolean(match.params.lcv);
    const isMoto = isMotoPath(location.pathname);
    const vehiclePrefix = isMoto ? 'moto' : 'car';
    const requestParams = {
      [`attributes[${vehiclePrefix}_release]`]: 'base,image,stats',
      [`relations[${vehiclePrefix}_release]`]: 'class',
      include: `${vehiclePrefix}_class`,
      sort: isMoto ? null : 'class',
      'filter[type_slug]': match.params.type_slug,
      'filter[brand]': match.params.brand,
      'filter[usages]': 'catalog',
    };

    if (isMoto) {
      releasesAPI = servicesApi.getMotoReleases;
    } else {
      releasesAPI = servicesApi.getReleases;
    }

    return releasesAPI({
      ...(isLcvPage && { 'filter[car_type]': 'lcv' }),
      ...requestParams,
      ...buildSearchRequestParams(location.search),
    })
      .then(denormalizeData)
      .catch(consoleError('releases', []));
  },

  archiveReleases: ({ servicesApi, consoleError, match, location }) => {
    let archiveReleasesAPI;
    let include;
    const isMoto = isMotoPath(location.pathname);
    const vehiclePrefix = isMoto ? 'moto' : 'car';

    if (isMoto) {
      archiveReleasesAPI = servicesApi.getMotoReleases;
      include = 'moto_year';
    } else {
      archiveReleasesAPI = servicesApi.getReleases;
      include = 'car_submodel,car_model,car_generation,car_body,car_release';
    }

    const apiParams = {
      [`attributes[${vehiclePrefix}_release]`]: 'base,image,stats',
      [`relations[${vehiclePrefix}_release]`]: 'model,generation,body',
      include,
      'filter[brand]': match.params.brand,
      'filter[usages]': 'archive',
    };

    return archiveReleasesAPI({
      ...apiParams,
      with_filtered_count: 1,
    })
      .then(firstArchiveReleases => {
        const filteredCount = firstArchiveReleases.meta.filtered_count;
        const iterations = Math.ceil(filteredCount / 100);
        if (iterations > 1) {
          const payloads = [];

          for (let i = 1; i < iterations; i++) {
            payloads.push(
              archiveReleasesAPI({
                ...apiParams,
                offset: i * 100,
              })
            );

            return Promise.all(payloads)
              .then(data => {
                return data.reduce((stack, item) => {
                  return {
                    data: stack.data.concat(item.data),
                    included: stack.included.concat(item.included),
                  };
                }, firstArchiveReleases);
              });
          }
        }
        return firstArchiveReleases;
      })
      .then(denormalizeData)
      .catch(consoleError('archiveReleases', []));
  },

  archiveGenerations({ servicesApi, consoleError, match, location }) {
    if (isMotoPath(location.pathname)) return [];

    return servicesApi
      .getGenerations({
        'filter[brand]': match.params.brand,
        'filter[usages]': 'archive',
        'relations[car_generation]': 'model',
      })
      .then(denormalizeData)
      .catch(consoleError('archiveGenerations', []));
  },
});
