import accounting from 'accounting';

import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import CardServiceModification2 from '../../';

import styles from './index.styl';


export default function CardModification2WithPrice(props) {
  const {
    content: {
      attributes: {
        min_price: minPrice,
        max_price: maxPrice,
      },
    },
  } = props;

  return (
    <CardServiceModification2 {...props}>
      {Boolean(minPrice || maxPrice) && (
        <div className={styles.price}>
          {accounting.formatNumber(minPrice)} — {accounting.formatMoney(maxPrice)}
        </div>
      )}
    </CardServiceModification2>
  );
}

CardModification2WithPrice.propTypes = {
  content: modelPropTypes(carReleaseAttributes),
};
