import cx from 'classnames';
import PropTypes from 'prop-types';

import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';

import Dislike from 'site/icons/Dislike';
import Like from 'site/icons/Like';

import styles from './index.styl';

function LikeAndDislike({ theme, like, dislike }) {
  return (
    <div className={cx('likeAndDislike', styles.likeAndDislike)}>
      <style jsx>{`
        .item
          color ${theme.colors.primary400}
      `}</style>
      <span className={cx('item', styles.item, styles.like)}>
        <Like />
        <span className={styles.count}>{like || 0}</span>
      </span>
      <span className={cx('item', styles.item, styles.dislike)}>
        <Dislike />
        <span className={styles.count}>{dislike || 0}</span>
      </span>
    </div>
  );
}

LikeAndDislike.propTypes = {
  /** Количество лайков */
  like: PropTypes.number,

  /** Количество дизлайков */
  dislike: PropTypes.number,

  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      primary400,
    }
  }`),
};

const LikeAndDislikeWithHOCs = withTheme(LikeAndDislike);
LikeAndDislikeWithHOCs.displayName = 'LikeAndDislike';

export default LikeAndDislikeWithHOCs;
export { LikeAndDislike as StorybookComponent };
