export default {
  s: {
    headlineSize: '500 16px/18px',
    headlineFontFamily: 'text',
    width: 275,
    height: 155,
    padding: '10px 14px 15px',
    iconSize: 40,
  },
  m: {
    headlineSize: '500 18px/20px',
    headlineFontFamily: 'text',
    width: 320,
    height: 214,
    padding: '10px 14px',
    iconSize: 40,
  },
  l: {
    headlineSize: '800 20px/28px',
    headlineFontFamily: 'display',
    width: 540,
    height: 372,
    padding: '25px 20px',
    iconSize: 60,
  },
};
