import { Fragment } from 'react';
import PropTypes from 'prop-types';

import H3 from 'core/components/H3';

import modelPropTypes, {
  carModelAttributes,
} from 'site/utils/prop-types/model';

import BrandReleases from 'site/components/BrandReleases';
import { Indent } from 'site/components/Wrappers';

import CardServiceModification2 from 'site/cardsService/CardServiceModification2';
import CardServiceModification1 from 'site/cardsService/CardServiceModification1';


function Imported({ releases }) {
  return (
    <Fragment>
      <H3>Официально не поставляются в Россию</H3>
      <Indent bottom={30} />
      <BrandReleases
        releases={releases}
        mobileCard={CardServiceModification2}
        desktopCard={CardServiceModification1}
      />
    </Fragment>
  );
}

Imported.propTypes = {
  releases: PropTypes.arrayOf(modelPropTypes(carModelAttributes)).isRequired,
};

export default Imported;
