import GalleryWithAds from 'site/components/GalleryWithAds';

import modelPropTypes, { photoGalleryAttributes } from 'core/utils/prop-types/model';

export default function PhotoGallery({ block }) {
  return (
    <GalleryWithAds
      photoGallery={block}
      infoTheme='lightBlue'
    />
  );
}

PhotoGallery.propTypes = {
  /** Данные виджета из API */
  block: modelPropTypes(photoGalleryAttributes).isRequired,
};
