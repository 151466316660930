import { components } from 'react-select';

import { compose } from 'core/libs/recompose';
import withTheme from 'core/components/theme';

import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';
import renameThemeProp from 'site/components/renameThemeProp';

import styles from './index.styl';


/* eslint-disable react/prop-types */
function MenuList(props) {
  const {
    children,
    theme: {
      colors: {
        dividerLight: colorDividerLight,
        text: colorText,
      },
    },
    externalTheme,
    selectProps: {
      placeholder,
      hideTitleFromMenu,
    },
    ...otherProps
  } = props;

  return (
    <ThemeSwitchWithColor tone='light'>
      <components.MenuList {...otherProps} theme={externalTheme}>
        <div className='menuListContent' data-qa='dropdown-menu-list-content'>
          <style jsx>{`
            .menuListContent
              color ${colorText}

            .${styles.title}
              border-bottom 1px solid ${colorDividerLight}
          `}</style>
          {!hideTitleFromMenu && <div className={styles.title}>{placeholder}</div>}
          {children}
        </div>
      </components.MenuList>
    </ThemeSwitchWithColor>
  );
}

export default compose(
  renameThemeProp,
  withTheme
)(MenuList);
