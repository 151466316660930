import PropTypes from 'prop-types';
import cx from 'classnames';
import accounting from 'accounting';

import withTheme from 'core/components/theme';

import mapTypes from './types';

import styles from './index.styl';

function Price({ theme, value, isPriceUndeclared, className, type }) {
  const {
    fontSize,
    lineHeight,
    borderWidth,
  } = mapTypes[type] || {};

  return (
    <div className={cx('price', styles.price, className)}>
      <style jsx>{`
        .price
          color ${theme.colors.primary}
          border ${borderWidth} solid ${theme.colors.primary}
          font bold ${fontSize}/${lineHeight} ${theme.fonts.display}
      `}</style>
      {isPriceUndeclared ? 'Цена не объявлена' : accounting.formatMoney(value)}
    </div>
  );
}

Price.defaultProps = {
  type: 1,
};

Price.propTypes = {
  /** @ignore */
  className: PropTypes.string,
  /** Значение цены */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Вместо цены отображает текст "Цена не объявлена" */
  isPriceUndeclared: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
  /** Тип, опеределяющий размер элемента */
  type: PropTypes.oneOf([1, 2]),
};

const PriceWithHOCs = withTheme(Price);
PriceWithHOCs.displayName = 'Price';

export default PriceWithHOCs;
export { Price as StorybookComponent };
