import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import modelPropTypes, { readMoreAttributes } from 'core/utils/prop-types/model';

import readMore from 'core/components/TopicContent/blocks/readMore';

import { Card2Type1 } from 'site/cards/Card2';
import CardReadMore from 'site/cards/CardReadMore';

import { LimeGradientBg } from 'site/components/Wrappers';


function ReadMore(props) {
  const {
    block,
    parentProps: {
      isMobile,
    },
  } = props;

  const contentArr = block && block.attributes ? block.attributes.topic_ids : [];

  if (!contentArr.length) return null;

  return (
    <LimeGradientBg>
      {readMore(
        block,
        {
          title: 'Материалы по теме',
          singleCard: CardReadMore,
          feedCard: CardReadMore,
          drumCard: isMobile ? CardReadMore : Card2Type1,
        }
      )}
    </LimeGradientBg>
  );
}

ReadMore.propTypes = {
  /** @ignore */
  parentProps: PropTypes.object,
  /** Данные виджета из API */
  block: modelPropTypes(readMoreAttributes),
};

export default compose(ReadMore);
