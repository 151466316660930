import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import H3 from 'core/components/H3';
import Feed from 'core/components/Feed';

import bindProps from 'core/components/bindProps';
import { Mobile, withBreakpoint } from 'core/components/breakpoint';

import { Indent, LimeGradientBg } from 'site/components/Wrappers';
import Divider from 'site/components/Divider';

import { SIDE_INDENT_MOBILE } from 'site/constants';

import { Card1Type4 } from 'site/cards/Card1';
import { Card2Type6 } from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card5 from 'site/cards/Card5';


function Popular({ topics, isMobile }) {
  const horizontal = isMobile ? SIDE_INDENT_MOBILE : 20;

  const first = topics.slice(0, 3);
  const second = topics[3];

  const dataQaSmallCard = 'small-card-popular-feed';
  const dataQaMainCard = 'main-card-popular-feed';

  const CardForFeed = bindProps({ dataQa: dataQaSmallCard })(isMobile ? Card1Type4 : Card3);
  const HeroCard = bindProps({ content: second, dataQa: dataQaMainCard })(isMobile ? Card2Type6 : Card5);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .gradient
          padding 20px ${horizontal}px
          :global(.mobile) &
            padding 10px ${horizontal}px
      `}</style>
    </scope>
  );

  return (
    <div data-qa='popular-feed'>
      <Indent left={-horizontal} right={-horizontal}>
        <LimeGradientBg className={scope.wrapClassNames('gradient')}>
          <H3>Самое читаемое</H3>
          {first && (
            <Indent top={isMobile ? 0 : 10}>
              <Feed
                card={CardForFeed}
                interitemSpacing={isMobile ? 0 : 20}
                content={first}
                dataQa='popular-feed-first'
              />
              <Mobile>
                <Divider />
              </Mobile>
            </Indent>
          )}
          {second && (
            <Indent top={20}>
              <HeroCard />
            </Indent>
          )}
        </LimeGradientBg>
        <scope.styles />
      </Indent>
    </div>
  );
}

Popular.propTypes = {
  isMobile: PropTypes.bool,
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)).isRequired,
};

export default withBreakpoint(Popular);
