import PropTypes from 'prop-types';
import cx from 'classnames';

import Feed from 'core/components/Feed';
import withTheme from 'core/components/theme';
import { Desktop } from 'core/components/breakpoint';
import Link from 'core/components/Link';

import themePropTypes from 'core/utils/prop-types/theme';

import CardServiceCompletion from 'site/cardsService/CardServiceCompletion';

import modelPropTypes, { carModificationAttributes } from 'site/utils/prop-types/model.js';
import styles from './index.styl';


const empty = {};

function getRangeValue(value) {
  const { min, max } = value;
  if (max === min) {
    return min;
  }
  return `${min}-${max}`;
}

function Modifications({ theme, modifications }) {
  if (!modifications || modifications.length === 0) return null;

  const modificationsGroups = modifications.reduce((groups, modification) => {
    const {
      name,
      power,
    } = modification.attributes;

    const groupKey = `${name} ${power}`;

    if (groups[groupKey]) {
      groups[groupKey].push(modification);
    } else {
      groups[groupKey] = [modification];
    }
    return groups;
  }, []);

  return (
    <div className='modifications'>
      <style jsx>{`
        .${styles.wrapper}
        .modification
          :global(.mobile) &
            border 1px solid ${theme.colors.primary100}

        .${styles.header}
          background ${theme.colors.content}
          border-bottom 1px solid ${theme.colors.dividerLight}

        .${styles.name}
          font-family ${theme.fonts.display}

        .key
          color ${theme.colors.primary500}
          font 11px/13px ${theme.fonts.text}

        .${styles.value}
          color ${theme.colors.primary}
          font 14px/18px ${theme.fonts.text}

        .completions
          background ${theme.colors.content}
      `}</style>
      {Object.keys(modificationsGroups).map(groupName => {
        const {
          id: modificationId,
          attributes: {
            name,
            power,
            specifications_primary: {
              fuel_consumption_mixed: fuelConsumptionMixed,
              fuel,
              drive,
              electric_range: electricRange,
            },
          },
          relationships: {
            release: {
              data: {
                attributes: {
                  generation_offers_url: offersUrl,
                },
              },
            },
          },
        } = modificationsGroups[groupName][0];

        const offersCount = modificationsGroups[groupName].reduce((acc, curr) => {
          return acc + parseInt(curr.attributes.offers_count, 10);
        }, 0);

        const { value: fuelConsumptionMixedValue } = fuelConsumptionMixed || empty;
        const { value: electricRangeValue } = electricRange || empty;
        const { value_normalized: fuelType } = fuel || empty;
        const { value: driveValue } = drive || empty;

        return (
          <div className={styles.wrapper} key={modificationId}>
            <div className={styles.header}>
              <div className={styles.name}>{name}</div>
              <div className={styles.headerList}>
                <Desktop>
                  <div className={styles.headerItem}>
                    <div className='key'>В продаже</div>
                    <div className={styles.value}>
                      {offersCount ?
                        <Link
                          to={offersUrl}
                          type='secondary'
                          className={styles.link}
                        >
                          {offersCount}
                        </Link> : '-'}
                    </div>
                  </div>
                </Desktop>
                <div className={styles.headerItem}>
                  <div className='key'>Привод</div>
                  <div className={styles.value}>{driveValue}</div>
                </div>
                <div className={styles.headerItem}>
                  <div className='key'>Мощность</div>
                  <div className={styles.value}>{`${power} л.с.`}</div>
                </div>
                <div className={styles.headerItem}>
                  <div className='key'>Топливо</div>
                  <div className={cx(styles.value, styles.fuel)}>{fuelType ? fuelType : 'н. д.'}</div>
                </div>
                <div className={styles.headerItem}>
                  <div className='key'>
                    {electricRange ? 'Запас хода' : 'Расход'}
                  </div>
                  <div className={styles.value}>
                    {!electricRange && !fuelConsumptionMixedValue && 'н.д'}
                    {electricRange && !fuelConsumptionMixedValue && `${getRangeValue(electricRangeValue)} км`}
                    {!electricRange && fuelConsumptionMixedValue && `${fuelConsumptionMixedValue} л/100 км`}
                  </div>
                </div>
              </div>
            </div>

            <div className={cx('completions', styles.completions)}>
              <Feed
                card={CardServiceCompletion}
                content={modificationsGroups[groupName]}
                interitemSpacing={0}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

Modifications.propTypes = {
  modifications: PropTypes.arrayOf(modelPropTypes(carModificationAttributes)),
  theme: themePropTypes(`{
    colors: {
      primary100,
      primary500,
      divider,
    },
    fonts: {
      display,
      text,
    },
  }`),
};

export default withTheme(Modifications);
