import { PDD_EXAM_MAX_MISTAKES, PDD_TICKET_QUESTIONS_COUNT } from 'site/constants';

export function isCorrectAnswer(userAnswer) {
  return userAnswer && userAnswer.isCorrect;
}

export function isGetAnswer(userAnswer) {
  const {
    answer,
  } = userAnswer || {};

  const answerType = typeof answer;

  return answerType === 'number' || answerType === 'string';
}

/**
 * Преобразует числовой массив с ответами в массив объектов
 * @param {Object[]} questions - массив с вопросами
 * @param {Object[]} answersArray - числовой массив с ответами
 */
export function transformAnswers(questions, answersArray) {
  return questions.map((question, i) => {
    const answer = answersArray[i];
    if (!isGetAnswer({ answer })) return null;

    return {
      answer,
      isCorrect: question.attributes.correct_answer === answer,
    };
  });
}

export function getRandomTicket() {
  return Math.floor(Math.random() * 40) + 1;
}

export function getPrevQuestionIndex(answers, index) {
  const leftIndex = answers.lastIndexOf(null, index - 1);
  if (leftIndex !== -1) return leftIndex;
  return answers.lastIndexOf(null);
}

export function getNextQuestionIndex(answers, index) {
  const rightIndex = answers.indexOf(null, index + 1);
  if (rightIndex !== -1) return rightIndex;

  return answers.indexOf(null);
}

/**
 * Возвращает результат экзамена, если он завершен и false если нет
 */
export function checkExamResult(answers, mistakes, seconds, mode) {
  if (seconds < 1) {
    return {
      status: 'error',
      message: 'Время вышло',
    };
  }

  if (mode === 'exam' && mistakes.length > PDD_EXAM_MAX_MISTAKES) {
    return {
      status: 'error',
      message: 'У вас более 2-х ошибок',
    };
  }

  if (mode === 'exam' && mistakes.find(m => m.questionIndex >= PDD_TICKET_QUESTIONS_COUNT)) {
    return {
      status: 'error',
      message: 'Ошибка в дополнительном вопросе',
    };
  }

  const hasUnanswered = answers.some(answer => {
    return !isGetAnswer(answer);
  });

  if (!hasUnanswered) {
    return {
      status: 'success',
    };
  }

  return false;
}
