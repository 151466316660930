import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';

import Drum from 'core/components/Drum';

import Divider from 'site/components/Divider';
import { Indent } from 'site/components/Wrappers';

import CardServiceGeneration1 from 'site/cardsService/CardServiceGeneration1';

export default function withTabs(props) {
  const { tabs } = props;
  const tabsContent = tabs.map(tab => tab.content);

  return function(Component) {
    class WithTabs extends React.PureComponent {
      static propTypes = {
        tabs: PropTypes.arrayOf(PropTypes.object),
      };

      state = {
        active: 0,
      };

      render() {
        const { active } = this.state;
        const { requestParams: activeTabRequestParams } = tabs[active];

        const Card = bindProps({
          setActive: position => active === position,
          onClick: position => this.setState({ active: position }),
        })(CardServiceGeneration1);

        if (tabs.length > 0) {
          return (
            <Fragment>
              {/* поколения */}
              <Drum
                content={tabsContent}
                card={Card}
                itemWidth='25%'
                itemWidthMobile='60%'
                spaceBetween={0}
                noCounter
              />
              <Indent left={-10} right={-10}>
                <Divider />
              </Indent>
              {/* передаем параметры для загрузки релиза по активному табу поколения */}
              <Component tabRequestParams={activeTabRequestParams} />
            </Fragment>
          );
        }

        return null;
      }
    }

    WithTabs.WrappedComponent = Component;

    return WithTabs;
  };
}
