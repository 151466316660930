import { Fragment } from 'react';
import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import Feed from 'core/components/Feed';
import { Desktop, Mobile } from 'core/components/breakpoint';

import Divider from 'site/components/Divider';

import CardRelease1WithYears from 'site/cardsService/CardServiceRelease1/cards/CardRelease1WithYears';
import CardModification2WithYears from 'site/cardsService/CardServiceModification2/cards/CardModification2WithYears';

import styles from './index.styl';


function Releases({ releases, isMoto }) {
  if (!releases || releases.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <Mobile>
        <Feed
          content={releases}
          card={bindProps({ isMoto: isMoto })(CardModification2WithYears)}
          interitemSpacing= {15}
          grid
        />
      </Mobile>
      <Desktop>
        <div className={styles.wrapper}>
          {releases.map((release, i) => {
            const isDivider = ((i !== 0) && ((i + 1) % 4 === 0)) || (i === releases.length - 1);

            return (
              <Fragment key={release.id}>
                <div className={styles.release}>
                  <CardRelease1WithYears content={release} isMoto={isMoto} />
                </div>
                {isDivider &&
                  <div className={styles.divider}>
                    <Divider />
                  </div>
                }
              </Fragment>
            );
          })}
        </div>
      </Desktop>
    </Fragment>
  );
}

Releases.propTypes = {
  releases: PropTypes.array,
  isMoto: PropTypes.bool,
};

export default Releases;
