const lightBlueColors = {
  text: '#000',
  primary: '#000',
  greyText: '#9E9E9E',
  greyText2: '#AAAAAA',
  grey3: '#F7F7F7',
  grey4: '#DBDBDB',
  error: '#ff3e56',
  content: '#fff',
  contentList: '#fafafa',
  layout: '#fff',
  lime1: '#CEFF00',
  lime2: '#E0FF30',
  lime3: '#EDFF00',
  divider: 'rgba(0, 0, 0, 0.2)',
  dividerLight: 'rgba(207, 207, 207, 0.5)',
  blue1: '#2F59ED',
  blue2: '#305EFC',
  hint: '#9E9E9E',
  green: '#2AB56E',
  darkGreen: '#20ab64',
  active1: '#7524D7',
  active2: '#311B92',
  active3: '#6427E5',
  white: '#fff',
};

const darkBlueColors = {
  ...lightBlueColors,
  primary: '#fff',
  text: '#fff',
  content: '#311B92',
};

const lightRedColors = {
  text: '#000',
  primary: '#000',
  greyText: '#9E9E9E',
  greyText2: '#AAAAAA',
  grey4: '#DBDBDB',
  error: '#ff3e56',
  content: '#fff',
  contentList: '#fafafa',
  layout: '#fff',
  lime1: '#CEFF00',
  lime2: '#E0FF30',
  lime3: '#EDFF00',
  divider: 'rgba(0, 0, 0, 0.2)',
  dividerLight: 'rgba(207, 207, 207, 0.5)',
  blue1: '#2F59ED',
  blue2: '#305EFC',
  hint: '#9E9E9E',
  green: '#2AB56E',
  darkGreen: '#20ab64',
  active1: '#D71A47',
  active2: '#800C2F',
  white: '#fff',
};


const darkRedColors = {
  ...lightRedColors,
  primary: '#fff',
  text: '#fff',
  content: '#800C2F',
};

const darkColors = {
  greyText: '#9E9E9E',
  greyText2: '#AAAAAA',
  error: '#ff3e56',
  lime1: '#CEFF00',
  lime2: '#E0FF30',
  lime3: '#EDFF00',
  divider: 'rgba(0, 0, 0, 0.2)',
  dividerLight: 'rgba(207, 207, 207, 0.5)',
  blue1: '#2F59ED',
  blue2: '#305EFC',
  hint: '#9E9E9E',
  green: '#2AB56E',
  darkGreen: '#20ab64',
  active3: '#6427E5',
  text: '#fff',
  active2: '#1c1c1c',
  content: '#fff',
  active1: '#ADFF00',
  primary: '#fff',
  grey: '#424242',
  contentList: '#1c1c1c',
  white: '#fff',
};


export {
  lightBlueColors,
  darkBlueColors,
  lightRedColors,
  darkRedColors,
  darkColors,
};
