import PropTypes from 'prop-types';

export default function TableVerticalIndent(props) {
  const {
    indent,
    colspan,
  } = props;

  return (
    <tr>
      <td
        colSpan={colspan}
        style={{
          ...indent && { paddingTop: `${indent}px` },
        }}
      />
    </tr>
  );
}

TableVerticalIndent.propTypes = {
  colspan: PropTypes.number,
  indent: PropTypes.number,
};
