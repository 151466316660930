import { useEffect } from 'react';
import ReactSlider from 'react-slider';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import themePropTypes from 'core/utils/prop-types/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import skip from 'core/resolver/skip';

import { updateRangeValue } from 'site/utils/forms';

import Input from 'site/components/InputRangeInput';
import { Indent } from 'site/components/Wrappers';

import mapOfFormatValue from './types';

import styles from './index.styl';

function InputRange(props) {
  const {
    title,
    name,
    value = {},
    onChange,
    unit,
    meta, // eslint-disable-line
    theme: {
      controls: {
        inputRange: inputRangeAtoms,
      },
    },
    formatNumberType,
    isDisabled,
    prepositionMin,
    prepositionMax,
    customStartMark,
    customEndMark,
    isMobile,
    isDesktop, // eslint-disable-line
    breakpoint, // eslint-disable-line
    hiddenInputValue,
    replaceValue,
    resetValue,
    arrangeJustify,
    ...rangeProps
  } = props;

  const {
    min: rangeMin,
    max: rangeMax,
  } = rangeProps;

  const {
    min: valueMin = rangeMin,
    max: valueMax = rangeMax,
  } = value;

  useEffect(() => {
    if  (resetValue) {
      onChange({ min: rangeMin, max: rangeMax }, { name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValue]);

  const minOutOfRange = valueMin < rangeMin || valueMin > rangeMax;
  const maxOutOfRange = valueMax < rangeMin || valueMax > rangeMax;

  const trackPercentValue = (rangeMax - rangeMin) / 100;

  rangeProps.value = [
    +(minOutOfRange ? rangeMin : valueMin),
    +(maxOutOfRange ? rangeMax : valueMax),
  ];

  const updateRangeParams = { value, range: rangeProps, onChange, name };

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.title}
          color ${inputRangeAtoms.title.color}
        .unit
          color ${inputRangeAtoms.unit.color}
        .track
          background-color ${inputRangeAtoms.track.between.background}
          &:nth-child(2n + 1)
            background-color ${inputRangeAtoms.track.outside.background}
        .thumb
          background-color ${inputRangeAtoms.thumb.background}
        .thumbRing
          background-color ${inputRangeAtoms.thumb.background}
        .marks
          background-color ${inputRangeAtoms.thumb.background}
        .markValue
          color ${inputRangeAtoms.mark.color}
      `}</style>
    </scope>
  );

  const Thumb = (thumbProps, { value: thumbValue, index }) => {
    const percentagePosition = (thumbValue[index] - rangeMin) / trackPercentValue;
    const formattedNumber = mapOfFormatValue[formatNumberType](thumbValue[index]);
    const mustBeReplaced = replaceValue && replaceValue[0] === thumbValue[index];

    return (
      <div {...thumbProps}>
        <div
          className={styles.thumbHint}
          style={{ transform: `translateX(-${percentagePosition}%)`, left: `${percentagePosition}%` }}
        >
          {mustBeReplaced ? replaceValue[1] : formattedNumber}
        </div>
        <div className={scope.wrapClassNames('thumbRing', styles.thumbRing)} />
      </div>
    );
  };

  const Mark = markProps => {
    const isCustomMinMark = markProps.key === rangeMin && customStartMark;
    const isCustomMaxMark = markProps.key === rangeMax && customEndMark;
    const mustBeReplaced = replaceValue && replaceValue[0] === markProps.key;

    if (mustBeReplaced) {
      return (
        <span {...markProps}>
          <span className={scope.wrapClassNames('markValue', styles.markValue)}>
            {replaceValue[1]}
          </span>
        </span>
      );
    }

    return (
      <span {...markProps}>
        <span className={scope.wrapClassNames('markValue', styles.markValue)}>
          {isCustomMinMark && customStartMark}
          {isCustomMaxMark && customEndMark}
          {!(isCustomMinMark || isCustomMaxMark) && markProps.key}
        </span>
      </span>
    );
  };


  return (
    <div
      {...props.dataQa && { 'data-qa': props.dataQa }}
      className={scope.wrapClassNames(
        styles.inputRange,
        isDisabled && styles._disabled,
        rangeProps.marks && styles._withMarks,
        arrangeJustify && styles._arrangeJustify
      )}
    >
      <div className={styles.info}>
        {!!title && (
          <div className={scope.wrapClassNames(styles.title)}>
            {title}
          </div>
        )}
        {!hiddenInputValue && (
          <div className={styles.values}>
            <Input
              name={name + '_min'}
              label={prepositionMin}
              value={valueMin}
              min={rangeMin}
              max={valueMax}
              blurHandler={onBlurValue => updateRangeValue({ min: onBlurValue, ...updateRangeParams })}
              formatNumberType={formatNumberType}
              disabled={isDisabled}
            />
            <Indent right={isMobile ? 10 : 20} />
            <Input
              name={name + '_max'}
              label={prepositionMax}
              value={valueMax}
              min={valueMin}
              max={rangeMax}
              blurHandler={onBlurValue => updateRangeValue({ max: onBlurValue, ...updateRangeParams })}
              formatNumberType={formatNumberType}
              disabled={isDisabled}
            />
            {!!unit && (
              <div className={scope.wrapClassNames('unit', styles.unit)}>
                {unit}
              </div>
            )}
          </div>
        )}
      </div>
      <ReactSlider
        {...rangeProps}
        className={styles.slider}
        renderThumb={Thumb}
        markClassName={scope.wrapClassNames('marks', styles.marks)}
        trackClassName={scope.wrapClassNames('track', styles.track)}
        thumbClassName={scope.wrapClassNames('thumb', styles.thumb)}
        thumbActiveClassName={styles._active}
        onAfterChange={([min, max]) => updateRangeValue({ min, max, ...updateRangeParams })}
        renderMark={Mark}
        /**
         * TODO: написать обработчик minDistance.
         * minDistance в связке с текстовыми инпутами и возможностью
         * изменения значений в урле может приводить к неправильной работе
         * компонента, поэтому на всякий случай перекрываем этот параметр нулём.
         */
        minDistance={0}
        disabled={isDisabled}
      />
      <scope.styles />
    </div>
  );
}

InputRange.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  unit: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number.isRequired,
  prepositionMin: PropTypes.string,
  prepositionMax: PropTypes.string,
  formatNumberType: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,

  /** @ignore */
  theme: themePropTypes(`{
    controls: {
      inputRange: {
        track: {
          outside: { background },
          between: { background },
        },
        thumb: {
          background,
        },
        unit: {
          color
        },
        mark: {
          color
        },
      },
    }`
  ),

  /**
   * Свойство позволяет указать кастомную start засечку mark на треке
   */
  customStartMark: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  /**
   * Свойство позволяет указать кастомную end засечку mark на треке
   */
  customEndMark: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  /** @ignore */
  isMobile: PropTypes.bool,

  /**
   * Отключает рендер для inputs
   */
  hiddenInputValue: PropTypes.bool,

  /**
 * Можно передать массив из двух значений.
 * Для замены одного на другое.
 * Используется для фильтра "Количество цилиндров"
 */
  replaceValue: PropTypes.array,

  /**
  * Cбрасывает значения формы, на min/max
  */
  resetValue: PropTypes.bool,

  /**
   * Распределяет заголовок и инпуты по краям.
   * Используется в <InputRange name='price'/>
   */
  arrangeJustify: PropTypes.bool,

  /** @ignore */
  dataQa: PropTypes.string,
};

InputRange.defaultProps = {
  min: 0,
  formatNumberType: 1,
  prepositionMin: 'от',
  prepositionMax: 'до',
  hiddenInputValue: false,
};

const InputRangeWithHOCs = skip(withTheme(withBreakpoint(InputRange)));
InputRangeWithHOCs.displayName = 'InputRange';

export default InputRangeWithHOCs;
export { InputRange as StorybookComponent };
