import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import renameThemeProp from 'site/components/renameThemeProp';

import styles from './index.styl';


function Placeholder(props) {
  const {
    children,
    theme, // eslint-disable-line
    selectProps: {
      isFocused,
    },
  } = props;

  if (!isFocused) return null;

  return (
    <div className={cx('placeholder', styles.placeholder)}>
      <style jsx>{`
        .placeholder
          color ${theme.controls.formInput.const.placeholderColor}
      `}</style>
      {children}
    </div>
  );
}

Placeholder.propTypes = {
  theme: PropTypes.object,
  selectProps: PropTypes.shape({
    isFocused: PropTypes.bool,
  }),
};


export default renameThemeProp(withTheme(Placeholder));
