import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactHintFactory from 'react-hint';
import cx from 'classnames';

import skip from 'core/resolver/skip';

import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';

import { Indent } from 'site/components/Wrappers';
import H4 from 'core/components/H4';

import styles from './index.styl';

const ReactHint = new ReactHintFactory(React);

function onRenderContent(target) {
  const {
    dataset: {
      title,
      description,
    },
  } = target;

  return (
    <div className={styles.hintContent}>
      {title && (
        <Indent bottom={8}>
          <H4>{target.dataset.title}</H4>
        </Indent>
      )}
      {description && <div>{target.dataset.description}</div>}
    </div>
  );
}

function Hint(props) {
  const {
    title,
    description,
    tag,
    className,
    theme,
    children,
  } = props;

  const notChildren = !children;
  const notHintContent = !(title || description);

  if (notHintContent && notChildren) return null;

  const Tag = tag;

  return (
    <Fragment>
      <style jsx>{`
        .question
          color ${theme.colors.content}
          &:before
            background ${theme.colors.blue1}
      `}</style>
      <Tag
        data-rh={!notHintContent}
        data-title={title}
        data-description={description}
        className={cx(
          className,
          notChildren && { [styles.question]: true, question: true }
        )}
      >
        {children}
      </Tag>
    </Fragment>
  );
}

Hint.defaultProps = {
  tag: 'div',
};

Hint.propTypes = {
  /** Заголовок подсказки */
  title: PropTypes.string,
  /** Подробное описание */
  description: PropTypes.string,
  /** Тег элемента для отображении подсказки */
  tag: PropTypes.string,
  /** @ignore */
  className: PropTypes.string,
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      blue1,
    }
  }`),
};

const HintWithHOCs = skip(withTheme(Hint));
HintWithHOCs.displayName = 'Hint';

export default HintWithHOCs;
export { Hint as StorybookComponent };

export const InitHints = skip(() => {
  return (
    <ReactHint
      events
      persist
      autoPosition
      className={styles.hint}
      onRenderContent={onRenderContent}
    />
  );
});
