import PropTypes from 'prop-types';

import H3 from 'core/components/H3';
import Link from 'core/components/Link';
import withTheme from 'core/components/theme';

import { Indent } from 'site/components/Wrappers';

import styles from './index.styl';

const tickets = [];

for (let i = 1; i <= 40; i++) {
  tickets.push(i);
}

function Tickets({ theme }) {
  return (
    <div className={styles.tickets}>
      <style jsx>{`
        .${styles.btn}
          &:hover
            border 2px solid ${theme.colors.active1}
      `}</style>
      <H3 is='div'>
        <span className={styles.title}>Билеты</span>
      </H3>
      <Indent bottom={10} />
      <p className={styles.text}>
        Выберите для тренировки один из официальных билетов
      </p>
      <Indent bottom={17} />
      <div className={styles.ticketsLinks}>
        {tickets.map((item) => (
          <Link
            key={item}
            to={`/pdd/tren/${item}`}
            className={styles.links}
          >
            <button className={styles.btn}>{item}</button>
          </Link>
        ))}
      </div>
    </div>
  );
}

Tickets.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(Tickets);
