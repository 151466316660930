import PropTypes from 'prop-types';

import { useRef, useEffect, useState } from 'react';
import { SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

import { withNonPureBreakpoint } from 'core/components/breakpoint';

import styles from './index.styl';

function Menu(props) {
  const {
    children,
    isMobile,
    innerProps, // eslint-disable-line
    innerRef, // eslint-disable-line
    ...otherProps
  } = props;

  const menuRef = useRef(null);
  const [positionStyles, setPositionStyles] = useState({ opacity: 0 });

  useEffect(() => {
    const {
      left,
      right,
    } = menuRef.current.getBoundingClientRect();

    const horizontalIndent = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

    // рассчитываем выступает ли дропдаун за экран (включая боковые отступы страницы)
    const neededSpace = right + horizontalIndent - window.innerWidth;
    setPositionStyles({
      transform: neededSpace > 0 ? `translateX(${-Math.min(neededSpace, left)}px)` : 0,
      opacity: 1,
    });
  }, [isMobile]);

  return (
    <div ref={innerRef}>
      <div
        {...innerProps}
        ref={menuRef}
        className={styles.menu}
        style={positionStyles}
        data-qa='dropdown-menu'
      >
        <style jsx>{`
          .${styles.menu}
            width ${otherProps.selectProps.width}px
        `}</style>
        {children}
      </div>
    </div>
  );
}

Menu.propTypes = {
  isMobile: PropTypes.bool,
};

export default withNonPureBreakpoint(Menu);
