import cx from 'classnames';

import styles from './index.styl';

/* eslint-disable react/prop-types */
export default function SingleValue(props) {
  const {
    selectProps: {
      overflowSelectValue,
    },
  } = props;

  return (
    <div className={cx(styles.singleValue, overflowSelectValue && styles._overflowSelectValue)}>
      <style jsx>{`
        .${styles._overflowSelectValue}
          max-width ${overflowSelectValue ? overflowSelectValue + 'px' : '100%'}
      `}</style>
      {props.children}
    </div>
  );
}
/* eslint-enable react/prop-types */
