import PropTypes from 'prop-types';

import Link from 'core/components/Link';

import { createCompareLink } from 'site/utils/compare';
import ArrowRightSm from 'site/icons/ArrowRightSm';

import styles from './index.styl';

function CompareListItem(props) {
  const {
    uuids,
    title,
    icon: Icon,
    motoUuids,
    onClose,
    scope,
  } = props;

  return (
    <li className={styles.listItem}>
      <div className={styles.listItemInner}>
        <span className={styles.iconWrapper}>
          <Icon />
        </span>
        <span className={styles.listItemVal}>{title}</span>
      </div>
      <Link
        onClick={onClose}
        className={scope.wrapClassNames('link', styles.listItemLink)}
        to={createCompareLink(uuids, uuids === motoUuids)}
      >
        Сравнить ({uuids.length}) <ArrowRightSm className={styles.arrowRight} />
      </Link>
    </li>
  );
}

CompareListItem.propTypes = {
  uuids: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  motoUuids: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  scope: PropTypes.object.isRequired,
  icon: PropTypes.func.isRequired,
};

export default CompareListItem;
