import styles from './index.styl';


function ValueContainer(props) {
  const {
    // eslint-disable-next-line no-unused-vars
    children,
  } = props;

  const [firstChild, ...otherChildren] = Array.isArray(children) ? children : [];
  const isMulti = Array.isArray(firstChild);
  const selectValue = isMulti
    ? firstChild[0]
    : firstChild;
  const count = isMulti && firstChild.length > 1
    ? ` +${firstChild.length - 1}`
    : null;


  return (
    <div className={styles.valueContainer}>
      {selectValue}
      {count && <div className={styles.count}>{count}</div>}
      {otherChildren}
    </div>
  );
}

export default ValueContainer;
