import accounting from 'accounting';

export default {
  1: {
    inputProps: {
      step: 1,
    },
    formatter: value => accounting.formatNumber(value),
  },
  2: {
    inputProps: {
      step: 1,
    },
    formatter: value => value,
  },
  3: {
    inputProps: {
      step: 0.1,
    },
    formatter: value => Number.parseFloat(value).toFixed(1),
  },
};
