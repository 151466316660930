import { Fragment } from 'react';
import PropTypes from 'prop-types';

import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import bindProps from 'core/components/bindProps';

import FooterAds from 'site/components/FooterAds';

import { ListContentBg, ViewportHeightWrap } from 'site/components/Wrappers';

import ParallaxWrapper from 'site/components/Ads/ParallaxWrapper';
import { InnerSideColumnPart1 } from 'site/components/InnerSideColumn';

const ListContentBgCollapse = bindProps({ marginCollapse: true })(ListContentBg);

export default function PageLayoutService({ children, bgContentList, fullWidthContent }) {
  const Wrapper = bgContentList ? ListContentBgCollapse : Fragment;

  return (
    <Wrapper>
      <ViewportHeightWrap>
        {fullWidthContent && <Fragment>{children}</Fragment>}
        <PageIndent>
          {!fullWidthContent && (
            <ColumnLayout rightColumn={<InnerSideColumnPart1 />}>
              {children}
            </ColumnLayout>
          )}
          <ParallaxWrapper />
        </PageIndent>
        <FooterAds contextCount={2} />
      </ViewportHeightWrap>
    </Wrapper>
  );
}

PageLayoutService.propTypes = {
  bgContentList: PropTypes.bool,
  fullWidthContent: PropTypes.bool,
};
