import { Fragment } from 'react';

import { Route, Switch } from 'core/libs/router';

import SecondaryMenu from 'site/components/SecondaryMenu';
import PageLayoutService from 'site/components/PageLayoutService';

import Brand from 'site/pages/brand';
import Model from 'site/pages/model';
import Generation from 'site/pages/generation';
import Release from 'site/pages/release';
import Catalog from 'site/pages/catalog';

import CatalogForm from 'site/forms/Catalog';


const catalogRoutes = [
  {
    exact: true,
    path: '/catalog',
    component: Catalog,
  }, {
    exact: true,
    path: '/:lcv(lcv)',
    component: Catalog,
  }, {
    exact: true,
    path: '/:lcv(lcv)/:brand/:model/:generation/:body',
    component: Release,
  }, {
    exact: true,
    path: '/:lcv(lcv)/:brand/:model/:generation',
    component: Generation,
  }, {
    exact: true,
    path: '/:lcv(lcv)/:brand/:model',
    component: Model,
  }, {
    exact: true,
    path: '/:lcv(lcv)/:brand',
    component: Brand,
  }, {
    exact: true,
    path: '/:brand/:model/:generation/:body',
    component: Release,
  }, {
    exact: true,
    path: '/:brand/:model/:generation',
    component: Generation,
  }, {
    exact: true,
    path: '/:brand/:model',
    component: Model,
  }, {
    exact: true,
    path: '/:brand',
    component: Brand,
  },
];

export default function CatalogLayout() {
  return (
    <Fragment>
      <SecondaryMenu section='catalog' />
      <CatalogForm />
      <PageLayoutService>
        <Switch>
          {catalogRoutes.map((routeParams, i) => {
            return (
              <Route key={i} {...routeParams} />
            );
          })}
        </Switch>
      </PageLayoutService>
    </Fragment>
  );
}
