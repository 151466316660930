import PropTypes from 'prop-types';

import themePropTypes from 'core/utils/prop-types/theme';

import H3 from 'core/components/H3';
import AdWrapper from 'core/components/Ad/AdWrapper';
import SecondAdvPlace from 'site/components/Ads/SecondAdvPlace';
import FirstAdvPlace from 'site/components/Ads/FirstAdvPlace';
import SpecificationValue from 'site/components/SpecificationValue';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import styles from './index.styl';

import { Indent } from 'site/components/Wrappers';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

function SpecificationList({
  isMobile,
  theme,
  specifications,
  specificationGroups,
}) {
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const {
    colors: {
      primary500,
      divider,
    },
  } = theme;

  if (!specificationGroups || specificationGroups.length === 0) return null;

  return (
    <AdWrapper top={vertical}>
      <style jsx>{`
        .${styles.name}
          :global(.mobile) &
            color ${primary500}

        .${styles.row}
        .${styles.title}
        .${styles.subtitle}
          border-bottom 1px solid ${divider}
      `}</style>
      <H3>Технические характеристики</H3>
      <Indent bottom={vertical} />
      {specificationGroups.map(({ name: groupName, items, subgroups }, index) => {
        const showFirstAdvPlace = index === 3;
        const showSecondAdvPlace = index !== 0 && index % 8 === 0;

        return (
          <div className={styles.specification} key={index}>
            <div className={styles.title}>{groupName}</div>
            {items.map(({ dictionary_id: dictionaryId }) => {
              const currentSpecification = specifications
                .find(specification => specification.dictionary_id === dictionaryId);
              const { name, value, unit } = currentSpecification;

              return (
                <div className={styles.row} key={dictionaryId}>
                  <div className={styles.name}>{name}</div>
                  <div className={styles.value}>
                    <SpecificationValue value={value} unit={unit} />
                  </div>
                </div>
              );
            })}

            {subgroups.map(subgroup => (
              <div className='subgroup' key={subgroup.name}>
                <div className={styles.subtitle}>{subgroup.name}</div>
                <div className={styles.subgroupItems}>
                  {subgroup.items.map(({ dictionary_id: dictionaryId }) => {
                    const currentSpecification = specifications
                      .find(specification => specification.dictionary_id === dictionaryId);
                    const { name, value, unit } = currentSpecification;

                    return (
                      <div className={styles.row} key={dictionaryId}>
                        <div className={styles.name}>{name}</div>
                        <div className={styles.value}>
                          <SpecificationValue value={value} unit={unit} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}

            {showFirstAdvPlace && <FirstAdvPlace />}
            {showSecondAdvPlace && <SecondAdvPlace />}
          </div>
        );
      })}
    </AdWrapper>
  );
}

SpecificationList.propTypes = {
  isMobile: PropTypes.bool,
  specifications: PropTypes.array,
  specificationGroups: PropTypes.array,
  theme: themePropTypes(`{
    colors: {
      divider,
    },
  }`),
};

export default withTheme(withBreakpoint(SpecificationList));
