export const PATHNAME_FIELDS = ['brand', 'model'];

export const DEPENDENCIES = {
  brand: {
    model: () => null,
  },
};

const currentYear = new Date().getFullYear();

export const RANGES = {
  year: {
    min: 1890,
    max: currentYear,
  },
};
