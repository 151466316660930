import PropTypes from 'prop-types';

import { Desktop } from 'core/components/breakpoint';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import Quote from 'site/icons/Quote';
import Authors from 'site/components/Authors';

import { LimeGradientBg } from 'site/components/Wrappers';

import modelPropTypes, { opinionAttributes } from 'core/utils/prop-types/model';

import styles from './index.styl';

export default function Opinion(props) {
  const {
    block,
    parentProps: {
      theme,
    },
  } = props;

  const {
    attributes: {
      author,
      body,
    },
  } = block;

  return (
    <div className={styles.opinion}>
      <style jsx>{`
        .${styles.body}
          font-family ${theme.fonts.text}
          color ${theme.colors.primary}

          &:after
            border-left 8px solid ${theme.colors.lime1}
            border-top 8px solid ${theme.colors.lime1}
      `}</style>
      <LimeGradientBg className={styles.gradient}>
        <div className={styles.body}>
          <Desktop>
            <Quote className={styles.quote} />
          </Desktop>
          <MarkdownWrapper>{body}</MarkdownWrapper>
        </div>
      </LimeGradientBg>
      {(author && author.attributes.name) && <div className={styles.author}>
        <Authors withoutLink authors={[].concat(author)} />
      </div>}
    </div>
  );
}

Opinion.propTypes = {
  /** Данные виджета из API */
  block: modelPropTypes(opinionAttributes).isRequired,
  /** @ignore */
  parentProps: PropTypes.object,
};
