import { Component } from 'react';
import PropTypes from 'prop-types';

import InputCompact from 'site/components/InputCompact';

import mapOfFormatValue from './types';

import styles from './index.styl';

/**
 * Используем этот объект для реализации обновления
 * значений инпутов при перемещении thumbs react-range'а.
 */
const prevValues = {};

export default class InputRangeInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,

    /** Label текстового поля */
    label: PropTypes.string,

    /** Минимальное значение, которое можно задать в поле */
    min: PropTypes.number,

    /** Максимальное значение, которое можно задать в поле */
    max: PropTypes.number,

    /** Неотформатированное числовое значение, как есть */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

    /** Обработчик потери фокуса с input */
    blurHandler: PropTypes.func.isRequired,

    /** Тип форматирования чисел */
    formatNumberType: PropTypes.number.isRequired,

    /** Делает input неактивным */
    disabled: PropTypes.bool,

    /** Можно передать заголовок перед инпутом */
    title: PropTypes.string,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.value !== prevValues[props.name]) {
      prevValues[props.name] = props.value;
      return {
        ...state,
        value: props.value,
      };
    }
    return null;
  }

  state = {
    value: this.props.value,
    invalid: false,
  };

  handleChange = e => {
    const value = e.target.value;

    this.setState({
      value,
      invalid: value < this.props.min || value > this.props.max,
    });
  };

  handleBlur = () => {
    const {
      min,
      max,
      blurHandler,
    } = this.props;

    const {
      value,
    } = this.state;

    if (value < min) {
      blurHandler(min);
      this.setState({
        value: min,
        invalid: false,
      });
      return;
    }

    if (value > max) {
      blurHandler(max);
      this.setState({
        value: max,
        invalid: false,
      });
      return;
    }

    blurHandler(value);
  };


  render() {
    const {
      label,
      name,
      formatNumberType,
      disabled,
      title,
    } = this.props;

    const {
      value,
      invalid,
    } = this.state;

    const valueFormatted = mapOfFormatValue[formatNumberType].formatter(value);
    const inputTypeProps = mapOfFormatValue[formatNumberType].inputProps;

    return (
      <div className={styles.inputRangeInput}>
        {title && <div className={styles.title}>{title}</div>}
        <InputCompact
          label={label}
          name={name}
          value={value}
          valueFormatted={valueFormatted}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          invalid={invalid}
          disabled={disabled}
          {...inputTypeProps}
        />
      </div>
    );
  }
}
