import Page from 'core/components/Page';
import H1 from 'core/components/H1';
import Link from 'core/components/Link';
import Text from 'core/components/Text';

import { PageIndent } from 'site/components/Wrappers';

export default function Firsttime() {
  return (
    <Page title='Автосайт Quto и лучшие автомобильные сайты России — выбор и покупка авто, рейтинг автомобилей по надежности'>
      <PageIndent>
        <H1>Возможности сервиса Quto по подбору нового автомобиля</H1>
        <Text>
          <p>Добро пожаловать на автомобильный сайт России — Quto.ru!</p>

          <p>Как и многие популярные автомобильные сайты, мы рады предоставить целый комплекс сервисов и информационно-аналитических материалов для опытных водителей, новичков и тех, кто только-только планирует сесть за руль.</p>

          <p>Однако наш автосайт выделяется рядом уникальных сервисов, которыми не смогут похвастаться иные автосайты России.</p>

          <p>Процедура покупки автомобиля станет для Вас более понятной. Используя сервисы Quto в комплексе, вы сможете почувствовать себя всецело подготовленным к покупке автомобиля и его эксплуатации — на нашем автосайте Вы сами задаете параметры. Выбрав все модели автомобилей, отвечающие необходимым вам требованиям — ценовой диапазон, страна происхождения, объем двигателя, тип привода и коробки передач, и другие — <Link to='/catalog'>отберите наиболее интересные</Link>, используя <Link to='/compare'>сравнение авто</Link> по параметрам комфорта, безопасности и оснащенности. Таким образом Вы увидите своеобразный рейтинг автомобилей по надежности и другим параметрам, включая сравнение цен на авто.</p>

          <p>Затем определитесь с конкретной моделью, выберите комплектацию, добавьте к ней недостающие, на ваш взгляд, опции — оцените, как изменилась цена машины. Здесь же, на странице модели/комплектации, выберите в верхнем меню над фотографией функцию «Стоимость владения» и получите информацию, во сколько обойдется эксплуатация и обслуживание после покупки автомобиля. Кроме того, с каждым годом ваш когда-то новый автомобиль будет терять в цене — на той же странице узнайте, как будет меняться его стоимость, чтобы продать машину до того, как расходы на ее содержание начнут превышать ее цену на рынке.</p>

          <p>Перед покупкой автомобиля на странице комплектации в окошке «Ценник» узнайте о доступных кредитных программах и наиболее выгодных условиях, сопоставьте со своими возможностями, принимая решение. В случае необходимости выберите наиболее удобный кредит на покупку автомобиля в Вашем регионе.</p>

          <p>Во вкладке «<Link to='/dealers'>Дилеры</Link>» вы сможете найти адрес и телефон ближайшего к вам дилера выбранной марки.</p>

          <p>Перед тем, как принять окончательное решение, ознакомьтесь с мнениями экспертов в разделе «<Link to='/journal/testdrives/'>Тест-драйвы</Link>» Журнала Quto. В журнале автосайта вы, кроме того, найдете актуальную и полезную информацию для автомобилистов — какие документы при покупке автомобиля могут потребоваться, какие меры подразумевает диагностика автомобиля перед покупкой, чем выгодна покупка автомобиля в автосалоне и чем может закончиться покупка автомобиля с рук. Штатные эксперты подробно анализируют процедуру приобретения авто и дают советы.</p>

          <p>Раздел «<Link to='/journal/articles/'>Водителю на заметку</Link>» расскажет вам о тонкостях общения с ГИБДД, проинструктирует, как себя вести в случае попадания в ДТП, подскажет, на что обратить внимание при взаимодействии с представителями страховых компаний и автосервисов, и даст много других <Link to='/journal/label/sovety-avtomobilistu'>полезных советов</Link>.</p>.
        </Text>
      </PageIndent>
    </Page>
  );
}
