import PropTypes from 'prop-types';
import accounting from 'accounting';

import withTheme from 'core/components/theme';

import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import CardServiceRelease1 from '../../';

import styles from './index.styl';


function CardRelease1WithPrice(props) {
  const {
    content: {
      attributes: {
        min_price: minPrice,
      },
    },
    theme,
  } = props;

  return (
    <CardServiceRelease1 {...props}>
      <style jsx>{`
        .${styles.price}
          color ${theme.colors.primary500}
      `}</style>
      {Boolean(minPrice) && (
        <div className={styles.price}>от {accounting.formatMoney(minPrice)}</div>
      )}
    </CardServiceRelease1>
  );
}

CardRelease1WithPrice.propTypes = {
  content: modelPropTypes(carReleaseAttributes),
  theme: PropTypes.object,
};

export default withTheme(CardRelease1WithPrice);

