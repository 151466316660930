import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';

import SocializatorSkeleton from 'core/components/Socializator/Skeleton';

import { LimeGradientBg } from 'site/components/Wrappers';

import { CONTENT_AREA, LAYOUT_MAX_WIDTH_MOBILE } from 'site/constants';

import styles from './index.styl';

function Common({ isMobile }) {
  return (
    <Fragment>
      <style jsx>{`
        .${styles.cover}
          max-width ${isMobile ? LAYOUT_MAX_WIDTH_MOBILE : CONTENT_AREA}px
      `}</style>
      <Desktop>
        <Indent
          bottom={15}
          left={-10}
          right={-10}
        >
          <LimeGradientBg style={{ padding: '14px 10px 10px' }}>
            <Indent
              className={styles.socializator}
              top={isMobile ? 0 : 5}
              bottom={15}
            >
              <div className={cx('lb-skeleton', styles.button)} />
              <SocializatorSkeleton />
            </Indent>
            <div className={cx('lb-skeleton', styles.cover)} />
          </LimeGradientBg>
        </Indent>
      </Desktop>

      <Mobile>
        <div className={cx('lb-skeleton', styles.cover)} />
        <Indent top={isMobile ? 10 : 15} />
        <div className={cx('lb-skeleton', styles.caption)} />
        <Indent bottom={20} />
        <div className={cx('lb-skeleton', styles.button)} />
        <Indent bottom={24} />
      </Mobile>
    </Fragment>
  );
}

Common.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Common);
