import PropTypes from 'prop-types';
import cx from 'classnames';

import pluralize from 'core/utils/pluralize';
import themePropTypes from 'core/utils/prop-types/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import ButtonWithIcon from 'core/components/ButtonWithIcon';

import ArrowRightSm from 'site/icons/ArrowRightSm';

import cardServiceHOC from 'site/components/cardService';
import ButtonTextWrap from 'site/components/Wrappers/ButtonTextWrap';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import styles from './index.styl';

function CardServiceRelease2(props) {
  if (!props.content) return null;

  const {
    theme,
    content: {
      attributes: {
        url,
        image,
        release_offers_new_count: offersNewCount,
        release_offers_used_count: offersUsedCount,
        generation_offers_url: offersUrl,
      },
    },
  } = props;

  const cover = getThumbnail(image);

  const countNew = `${offersNewCount} ${pluralize(offersNewCount, ['новая', 'новые', 'новых'])}`;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .control
          &:hover
          &:focus
            text-decoration underline
      `}</style>
      <style jsx>{`
        .control
          font-family ${theme.fonts.text}
          color ${theme.colors.blue1}

          &:visited
          &:hover
          &:focus
            color ${theme.colors.blue1}

          :global([id*="!"])
            stroke ${theme.colors.blue1}
      `}</style>
    </scope>
  );
  return (
    <div className={cx(styles.cardServiceRelease2, 'cardServiceRelease2')}>
      <style jsx>{`
        .price
          color ${theme.colors.primary500}
          font 14px/16px ${theme.fonts.text}

        .cardServiceRelease1
          &:hover
            .name
              color ${theme.colors.active1}
      `}</style>
      <div className={styles.image}>
        {cover && <Image
          src={cover}
          maxWidth={530}
        />}
      </div>
      <Link
        type='secondary'
        to={url}
        className={styles.more}
      >
        <ButtonWithIcon
          icon={ArrowRightSm}
          iconPosition='right'
          size='small'
        >
          <ButtonTextWrap>Перейти к модели</ButtonTextWrap>
        </ButtonWithIcon>
      </Link>
      <div className={styles.controls}>
        {Boolean(offersNewCount) &&
          <Link
            type='secondary'
            to={`${offersUrl}?condition=new`}
            className={cx(styles.control, scope.wrapClassNames('control'))}
          >
            {`В продаже ${countNew}`}
            <ArrowRightSm className={styles.arrow} />
          </Link>
        }
        {Boolean(offersUsedCount) &&
          <Link
            type='secondary'
            to={`${offersUrl}?condition=trade_in`}
            className={cx(styles.control, scope.wrapClassNames('control'))}
          >
            {`${offersUsedCount} с пробегом`}
            <ArrowRightSm className={styles.arrow} />
          </Link>
        }
      </div>
      <scope.styles />
    </div>
  );
}

CardServiceRelease2.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: themePropTypes(`{
    color: {
      blue1,
      primary500,
    },
  }`),
  /** Данные для карточки, соответствующие модели `carReleaseAttributes` */
  content: modelPropTypes(carReleaseAttributes),
};

const CardWithHOCs = cardServiceHOC(CardServiceRelease2);
CardWithHOCs.displayName = 'CardServiceRelease2';

export default CardWithHOCs;
export { CardServiceRelease2 as StorybookComponent };
