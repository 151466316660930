import cx from 'classnames';
import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import relationshipsPropTypes from 'site/utils/prop-types/relationships';
import modelPropTypes, {
  carNoveltyAttributes,
  carReleaseAttributes,
} from 'site/utils/prop-types/model';
import getThumbnail from 'site/utils/get-thumbnail';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import Label from 'site/components/Label';

import cardServiceHOC from 'site/components/cardService';

import styles from './index.styl';

const requiredPayloadImports = ['release'];

const relationships = resolveRelationships(requiredPayloadImports, {}, {});

function CardServiceNewRelease1(props) {
  const {
    theme,
    content: {
      attributes: {
        name,
      },
    },
    content,
    showNew,
  } = props;

  if (!content) return null;

  const {
    release: {
      url,
      short_name: shortName,
      image,
      is_new: isNew,
    },
  } = relationships(content);

  const cover = getThumbnail(image);

  return (
    <div className='cardServiceNewRelease1' data-qa='small-card-new-releases'>
      <style jsx>{`
        .name
          color ${theme.colors.primary}
          font 500 16px/20px ${theme.fonts.text}
          transition color ${theme.animations.hover}

        .description
          color ${theme.colors.primary500}
          font 14px/16px ${theme.fonts.text}

        .cardServiceNewRelease1
          &:hover
            .name
              color ${theme.colors.active1}
      `}</style>
      <Link
        to={url}
        type='secondary'
      >
        {cover && <Image
          src={cover}
          maxWidth={180}
        />}
        <div className={cx(styles.name, 'name')} data-qa='small-card-title-new-releases'>
          {(showNew && isNew) && <Label className={styles.label} type={1} />}
          {shortName}
        </div>
        <div className={cx(styles.description, 'description')}>
          <MarkdownWrapper children={name} inline />
        </div>
      </Link>
    </div>
  );
}

CardServiceNewRelease1.defaultProps = {
  showNew: true,
};

CardServiceNewRelease1.propTypes = {
  /** Модель данных для карточки */
  content: modelPropTypes(
    carNoveltyAttributes,
    relationshipsPropTypes({
      release: modelPropTypes(carReleaseAttributes),
    })
  ),
  /** @ignore */
  theme: PropTypes.object,
  /** Флаг, включающий отображение плашки New */
  showNew: PropTypes.bool,
};

const CardWithHOCs = cardServiceHOC(CardServiceNewRelease1);
CardWithHOCs.displayName = 'CardServiceNewRelease1';

export default CardWithHOCs;
export { CardServiceNewRelease1 as StorybookComponent };
