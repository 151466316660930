import PropTypes from 'prop-types';

import TopicCoverImage from './TopicCoverImage';
import TopicCoverGallery from './TopicCoverGallery';
import TopicCoverVideo from './TopicCoverVideo';

const topicTypeMap = {
  news: TopicCoverImage,
  article: TopicCoverImage,
  gallery: TopicCoverGallery,
  video: TopicCoverVideo,
};

export default function TopicCoverMedia({ content }) {
  const {
    attributes: {
      topic_type: topicType,
    },
  } = content;

  const Cover = topicTypeMap[topicType] || TopicCoverImage;

  return <Cover content={content} />;
}

TopicCoverMedia.propTypes = {
  content: PropTypes.object.isRequired,
};
