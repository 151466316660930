import { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  tagFetcher,
  topicsFetcher,
} from 'core/fetchers/tagTopics';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';
import TagTopics from 'core/components/TagTopics';

import SelectionList from 'site/components/SelectionList';
import PageLayout from 'site/components/PageLayout';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';


function TagPage({ tag, topics: tagTopics }) {
  return (
    <PageLayout>
      <TagTopics
        tag={tag}
        topics={tagTopics}
        limit={RUBRICS_PAGE_LIMIT}
      >
        {({ topics }) => {
          return (
            <Fragment>
              <SelectionList topics={topics} />
            </Fragment>
          );
        }}
      </TagTopics>
    </PageLayout>
  );
}

TagPage.propTypes = {
  tag: PropTypes.object,
  topics: PropTypes.object,
};

const dataProvider = resolve({
  tag: tagFetcher(),
  topics: topicsFetcher({
    limit: RUBRICS_PAGE_LIMIT,
    include: SelectionList.requiredPayloadImports,
    fields: SelectionList.requiredPayloadFields,
  }),
});

export default withPageHocs(dataProvider)(TagPage);
