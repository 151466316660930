import { useCallback } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';

import { get } from 'core/libs/lodash';
import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import Radio from 'core/components/Radio';
import Image from 'core/components/Image';

import Hint from 'site/components/Hint';
import OptionDependenciesSimple from 'site/components/OptionDependenciesSimple';

import getThumbnail from 'site/utils/get-thumbnail';

import styles from './index.styl';


function OptionRadio(props) {
  const {
    option,
    theme,
    isMobile,
    onChange,
    isImportant,
    modificationId,
    ...restParams
  } = props;

  const onRadioChange = useCallback(() => {
    onChange(option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!option) return null;

  const {
    name,
    name_full: fullName,
    price,
    image,
  } = option;

  const termName = get(option, 'term.name');
  const termExplanation = get(option, 'term.explanation');
  const preview = getThumbnail(image);

  return (
    <div className={styles.row}>
      <style jsx>{`
        .${styles.row}
          border-bottom-color ${theme.colors.divider}
      `}</style>
      <div className={styles.body}>
        <div className={styles.label}>
          <Radio onChange={onRadioChange} {...restParams}>
            <div className={styles.nameColumn}>
              <div className={styles.name}>{fullName || name}</div>
            </div>
            <Hint title={termName} description={termExplanation} />
          </Radio>
        </div>
        {preview && (
          <div className={styles.preview}>
            <Image
              width={isMobile ? 70 : 100}
              placement='contain'
              aspectRatio={1}
              src={preview}
            />
          </div>
        )}
        <div className={styles.price}>
          {accounting.formatMoney(price, '', 0, ' ')} ₽
          {isImportant && <sup className={styles.importantMark}>*</sup>}
        </div>
      </div>
      <OptionDependenciesSimple
        type={1}
        modificationId={modificationId}
        option={option}
      />
    </div>
  );
}

OptionRadio.propTypes = {
  option: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      divider,
    },
  }`),
  isMobile: PropTypes.bool,
  /**
   * Выделяет опцию звездочкой.
   * Может использоваться для обозначения дополнительной информации в сноске.
   * Например "Опция имеет зависимости".
   */
  isImportant: PropTypes.bool,
  modificationId: PropTypes.string,
};

const OptionRadioWithHOCs = withTheme(withBreakpoint(OptionRadio));
OptionRadioWithHOCs.displayName = 'OptionRadio';

export default OptionRadioWithHOCs;
export { OptionRadio as StorybookComponent };
