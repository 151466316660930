import { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { denormalizeData } from 'core/utils/api';

import H3 from 'core/components/H3';
import { withBreakpoint } from 'core/components/breakpoint';
import { SimpleLoader } from 'core/components/Loader';
import { withRouter } from 'core/libs/router';

import { NegativeMobile, Indent } from 'site/components/Wrappers';
import DealersMap from 'site/components/DealersMap';
import GeoSelect from 'site/components/GeoSelect';
import withGeo from 'site/components/GeoContext/withGeo';

import { isMotoPath } from 'site/utils';
import { getDealersWithCoordinats } from 'site/utils/dealers';

import styles from './index.styl';

function WhereToBuy(props, { servicesApi }) {
  const {
    isMobile,
    geoSlug,
    brandName,
    location,
    match: {
      params: {
        brand,
      },
    },
  } = props;

  const [innerGeoSlug, setInnerGeoSlug] = useState(geoSlug);
  const [content, setContent] = useState({
    dealers: [],
    geos: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let ignore = false;

    const vehiclePrefix = isMotoPath(location.pathname) ? 'moto' : 'car';

    Promise.allSettled([
      servicesApi
        .getDealersGeos({
          [`filter[${vehiclePrefix}_brand]`]: brand,
        })
        .then(denormalizeData),

      servicesApi
        .getDealers({
          'filter[status]': 'official',
          'filter[geo]': geoSlug,
          [`filter[${vehiclePrefix}_brand]`]: brand,
        })
        .then(denormalizeData)
        .then(getDealersWithCoordinats),
    ])
      .then(([geos, dealers]) => ({
        dealers: dealers.value || [],
        geos: geos.value || [],
      }))
      .then(data => {
        if (!ignore) {
          setContent(data);
          setLoading(false);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setLoading(false);
        }
      });

    return () => {
      ignore = true;
    };
  }, [brand, geoSlug, location.pathname, servicesApi]);

  if (loading) return <SimpleLoader />;

  const {
    dealers,
    geos,
  } = content;

  const HEIGHT = isMobile ? 340 : 500;

  if (!geos.length || !geoSlug) return null;

  return (
    <div className={cx('whereToBuy', isMobile && styles.mobile)}>
      <div className={styles.controls}>
        <H3>Где купить {brandName}</H3>
        <div className={styles.select}>
          <GeoSelect
            geoSlug={innerGeoSlug}
            geos={geos}
            onChange={({ value }) => setInnerGeoSlug(value)}
          />
        </div>
      </div>
      <Indent bottom={20} />
      <NegativeMobile>
        <DealersMap
          dealers={dealers}
          geoSlug={innerGeoSlug}
          height={HEIGHT}
        />
      </NegativeMobile>
    </div>
  );
}

WhereToBuy.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  match: PropTypes.object,
  /** @ignore */
  location: PropTypes.object,
  /** Слаг региона */
  geoSlug: PropTypes.string,
  /** Название бренда */
  brandName: PropTypes.string,
};

WhereToBuy.contextTypes = {
  /** @ignore */
  servicesApi: PropTypes.object.isRequired,
};

const WhereToBuyWithHOCs = withBreakpoint(withGeo(withRouter(WhereToBuy)));

WhereToBuyWithHOCs.displayName = 'WhereToBuy';

export default WhereToBuyWithHOCs;
export { WhereToBuy as StorybookComponent };
