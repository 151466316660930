import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import withPageHocs from 'core/components/withPageHocs';

import withGeo from 'site/components/GeoContext/withGeo';

import { denormalizeData } from 'core/utils/api';

import servicesApiPropTypes from 'site/utils/prop-types/api';

import resolve from 'core/resolver/resolve';

import {
  generateSelectOptions,
} from 'site/utils/forms';
import { isMotoPath } from 'site/utils';

import Form from './Form';
import BaseForm from 'site/components/BaseForm';

import { DEALERS_URL } from 'site/constants';

import { PATHNAME_FIELDS } from './constants';


class DealersForm extends BaseForm {
  constructor(props) {
    super(props);

    const {
      dealersGeos,
      dealerNetworks,
      brands,
    } = this.props;

    this.formOptions = {
      brandsDealersOptions: [{
        label: 'Марки',
        options: generateSelectOptions(brands, { value: 'attributes.slug' }),
      }, {
        label: 'Автосалоны',
        options: generateSelectOptions(dealerNetworks),
      }],
      geoOptions: generateSelectOptions(dealersGeos, { value: 'attributes.url' }),
    };

    this.state = this.getFormStateFromLocation();
  }

  dependencies = {};

  get basePath() {
    const { location } = this.props;

    return `${isMotoPath(location.pathname) ? '/moto' : ''}${DEALERS_URL}`;
  }

  get pathnameFields() {
    const { search } = this.state;

    if (!search || search && (search.type === 'car_brand' || search.type === 'moto_brand')) {
      return PATHNAME_FIELDS;
    }

    return ['geo'];
  }

  componentDidUpdate(prevProps) {
    const { match: { params: prevParams } } = prevProps;
    const { match: { params } } = this.props;

    if (params !== prevParams) {
      this.setState({
        ...this.getFormStateFromLocation(),
      });
    }
  }

  getFormStateFromLocation = () => {
    const {
      match: {
        params: {
          brand: brandSlug,
          geo: geoSlug,
        },
      },
      dealersGeos,
      geo: defaultGeo,
      location,
    } = this.props;

    const filteredGeos = this.getGeoOptions(dealersGeos);
    const filteredDefaultGeo = this.getGeoOptions([defaultGeo])[0];

    const { search: dealerNetworkId } = queryString.parse(location.search);

    const findSearchValue = ({ value }) => value === (brandSlug || dealerNetworkId);

    /**
     * Если значение не найдено, то устанавливаем его в null вместо
     * undefined, потому что undefined при сбросе фильтров не воспринимается
     * для некоторых контролов и там остается предыдущее установленное
     * значение.
     */
    const filteredBrands = this.formOptions.brandsDealersOptions;
    const search = filteredBrands.reduce((acc, { options }) => {
      return acc || options.find(findSearchValue);
    }, null);

    const geo = filteredGeos.find(item => (item.attributes.url === geoSlug)) || filteredDefaultGeo;

    return {
      search,
      geo,
    };
  };

  getGeoOptions = memoize(
    (geos = []) => generateSelectOptions(geos, { value: 'attributes.url' })
  );

  getBrandOptions = memoize(
    (brands = []) => generateSelectOptions(brands)
  );

  render() {
    return (
      <Form
        formState={this.state}
        formOptions={this.formOptions}
        onChange={this.handleControlChange}
      />
    );
  }
}

DealersForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      geo: PropTypes.string,
    }),
  }),
  location: PropTypes.object,
};

DealersForm.contextTypes = {
  servicesApi: servicesApiPropTypes(),
};


const dataProvider = resolve({
  dealersGeos({ servicesApi, consoleError }) {
    return servicesApi
      .getDealersGeos()
      .then(denormalizeData)
      .catch(consoleError('dealersGeos', []));
  },
  brands({ match, servicesApi, consoleError, location }) {
    const handle = isMotoPath(location.pathname)
      ? servicesApi.getMotoBrands
      : servicesApi.getBrands;

    return handle({
      'filter[with_offers_from_geo]': match.params.geo,
    })
      .then(denormalizeData)
      .catch(consoleError('brands', []));
  },
  dealerNetworks({ match, servicesApi, consoleError, location }) {
    return servicesApi
      .getDealersNetworks({
        'filter[geo]': match.params.geo,
        'filter[type]': isMotoPath(location.pathname) ? 'moto' : 'car,lcv',
      })
      .then(denormalizeData)
      .catch(consoleError('dealersNetworks', []));
  },
});

export default withGeo(withRouter(withPageHocs(dataProvider)(DealersForm)));
