import { Fragment } from 'react';
import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';
import GlobalNativeStyles from 'site/components/Ads/styles/global';
import './index.styl';

const NativeStyles = ({ theme }) => (
  <Fragment>
    <style jsx global>{`
      .native-ad.listing-spec
        border-bottom 1px solid ${theme.colors.primary100}

        [data-render-state='rendered']
          margin-bottom 0

        .ad_label__text
          color ${theme.colors.hint}
          font 13px/1.2 ${theme.fonts.nuance}
    `}</style>
  </Fragment>
);

NativeStyles.propTypes = {
  theme: themePropTypes(`
    {
      colors: {
        primary100,
      },
    }
  `),
};

export default function ListingSpec(Component) {
  return withTheme(props => (
    <div className='native-ad listing-spec'>
      <GlobalNativeStyles />
      <NativeStyles {...props} />
      <Component {...props} />
    </div>
  ));
}
