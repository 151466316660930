import PropTypes from 'prop-types';

import Page from 'core/components/Page';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import ReleasePromo from 'site/components/ReleasePromo';
import ReleaseBoroda from 'site/components/ReleaseBoroda';
import { Indent, NegativeMobile } from 'site/components/Wrappers';
import { Listing1 } from 'site/components/Ads/mobile';

import modelPropTypes, {
  carModificationAttributes,
  carReleaseAttributes,
  seoAttributes,
} from 'site/utils/prop-types/model';

import Modifications from './blocks/Modifications';
import Bodies from './blocks/Bodies';
import Generations from './blocks/Generations';

import Classmates from 'site/components/Classmates';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import dataProvider from './releaseDataProvider';


function Release(props) {
  const {
    seo,
    release,
    bodies,
    modifications,
    isMobile,
    isTopicsByBrandExist,
  } = props;

  const mainGenerationId = release?.relationships?.generation?.data?.id;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Page title={seo.title} description={seo.description}>
      <AdWrapper bottom={vertical}>
        <NegativeMobile>
          <ReleasePromo release={release} isTopicsByBrandExist={isTopicsByBrandExist} />
        </NegativeMobile>
        <Mobile>
          <Indent bottom={vertical} />
        </Mobile>
        <Modifications modifications={modifications} />
        <Indent bottom={vertical} />
        <Bodies bodies={bodies} withShadowBg={isMobile} />
        <Indent bottom={vertical} />
        <Mobile>
          <Listing1 />
        </Mobile>
        <Generations mainGenerationId={mainGenerationId} />
        <Indent bottom={vertical} />
        <Classmates mainReleaseId={release?.id} withShadowBg={isMobile} />
        <ReleaseBoroda release={release} />
      </AdWrapper>
    </Page>
  );
}

Release.propTypes = {
  release: modelPropTypes(carReleaseAttributes),
  modifications: PropTypes.arrayOf(modelPropTypes(carModificationAttributes)),
  bodies: PropTypes.arrayOf(modelPropTypes(carReleaseAttributes)),
  isTopicsByBrandExist: PropTypes.bool,
  seo: seoAttributes,
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
    }),
  }).isRequired,
  isMobile: PropTypes.bool,
};

export default withPageHocs(dataProvider)(withBreakpoint(Release));
