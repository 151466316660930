import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import GridFeed from 'core/components/GridFeed';
import Recommender from 'core/components/Recommender';

import { Card2Type7 } from 'site/cards/Card2';


import styles from './index.styl';

function SiteRecommender({ isMobile }) {
  return (
    <Recommender
      blockId={isMobile ? '4dccbdd35bfe4d6aa8d02f5089843ed3' : 'ab006db9d176433fab609bfbcef745e3'}
      title='Популярное'
      card={Card2Type7}
      renderer={GridFeed}
      gap={isMobile ? '10px' : '15px'}
      limit={isMobile ? 3 : 6}
      itemClassName={styles.item}
      {...!isMobile && {
        gridTemplateColumns: '1fr 1fr 1fr',
      }}
    />
  );
}

SiteRecommender.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SiteRecommender);
