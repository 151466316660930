import PropTypes from 'prop-types';
import cx from 'classnames';
import { get } from 'core/libs/lodash';

import withTheme from 'core/components/theme';

import modelPropTypes, { pddTicketAttributes } from 'site/utils/prop-types/model';

import SmartImage from 'core/components/SmartImage';

import styles from './index.styl';

function PddImage({ question, theme, pddWidget }) {
  const imageSrc = get(question, 'attributes.image.attributes.versions.original');
  return (
    <div className={styles.wrapper}>
      <style jsx>{`
        .imagePlacholderText
          color ${theme.colors.primary400}
          font 500 18px/23px ${theme.fonts.text}

        `}</style>
      {imageSrc ? (
        <SmartImage src={imageSrc ? imageSrc : null} />
      ) : (
        <div className={cx(styles.imagePlacholder, pddWidget && styles.imagePlacholder_pddWidget)}>
          <span className={'imagePlacholderText'}>Вопрос без картинки</span>
        </div>
      )}
    </div>
  );
}

PddImage.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** Вопрос ПДД */
  question: modelPropTypes(pddTicketAttributes),
  /** Используется ли компонент в правой колонке или нет */
  pddWidget: PropTypes.bool,
};

const PddImageWithHOCs = withTheme(PddImage);
PddImageWithHOCs.displayName = 'PddImage';

export default PddImageWithHOCs;
export { PddImage as StorybookComponent };
