import PropTypes from 'prop-types';
import { Helmet } from 'core/libs/helmet';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import H2 from 'core/components/H2';
import H3 from 'core/components/H3';

import { Indent } from 'site/components/Wrappers';

import Switcher from '../Switcher';

import { SIDE_INDENT, SIDE_INDENT_MOBILE, VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';


function Header({ isMobile, theme, isMoto, showSwitcher }) {
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const horisontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  const Title = isMobile ? H3 : H2;

  return (
    <div className={styles.header}>
      <Helmet>
        <title>{isMoto
          ? 'Сравнение мотоциклов, скутеров и квадроциклов по параметрам'
          : 'Сравнение автомобилей по параметрам - наиболее подборный сервис, позволяющий сравнить авто: цены, оснащенность, безопасность, расход на авто.'
        }</title>
        {isMoto && <meta name='description' content='Помощь в выборе и покупке мотоциклов, скутеров и квадроциклов - Quto.ru' />}
      </Helmet>
      <style jsx>{`
        .${styles.header}
          background ${theme.colors.content}
          padding ${vertical}px 0
      `}</style>
      <Indent left={horisontal} right={horisontal}>
        <div className={styles.content}>
          <Title className={styles.title} is='h1'>{`Сравнение ${isMoto ? 'мототехники' : 'автомобилей'}`}</Title>
          {showSwitcher && <Desktop><Switcher /></Desktop>}
        </div>
        <Mobile><Switcher /></Mobile>
      </Indent>
    </div>
  );
}

Header.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
  isMoto: PropTypes.bool,
  /**
   * Флаг показывающий тумблер "Все параметры/Только различающиеся"
   */
  showSwitcher: PropTypes.bool,
};

export default withBreakpoint(withTheme(Header));
