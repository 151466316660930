import PropTypes from 'prop-types';
import cx from 'classnames';

import pluralize from 'core/utils/pluralize';

import withTheme from 'core/components/theme';

import SortFilter from 'site/components/SortFilter/index';

import styles from './index.styl';
import mapTypes from './types';

function ListSortHeader(props) {
  const {
    theme,
    type,
    filteredCount,
    ...otherProps
  } = props;

  const { countText } = mapTypes[type] || {};

  const countTextFormatted = `${filteredCount} ${pluralize(filteredCount, countText)}`;

  return (
    <div className={cx(styles.header, 'header')}>
      <style jsx>{`
        .header
          color ${theme.colors.greyText}
          border-bottom 1px solid ${theme.colors.primary100}
      `}</style>
      <div className='counter'>{countTextFormatted}</div>
      <SortFilter {...otherProps} />
    </div>
  );
}

ListSortHeader.propTypes = {
  /** Тип заголовка */
  type: PropTypes.oneOf([1, 2, 3, 4]),
  /** Общее количество отфильтрованных значений */
  filteredCount: PropTypes.number,
  /** @ignore */
  theme: PropTypes.object,
  /* Набор параметров необходимый для SortFilter */
  location: PropTypes.object.isRequired,
  /** Набор фильтров */
  filters: PropTypes.array.isRequired,
};

ListSortHeader.defaultProps = {
  type: 1,
};

const ListSortHeaderWithHOCs = withTheme(ListSortHeader);
ListSortHeaderWithHOCs.displayName = 'ListSortHeader';

export default ListSortHeaderWithHOCs;
export { ListSortHeader as StorybookComponent };

