import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import pluralize from 'core/utils/pluralize';

import { withBreakpoint } from 'core/components/breakpoint';
import H1 from 'core/components/H1';
import H3 from 'core/components/H3';

import { Indent } from 'site/components/Wrappers';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


function TitleWithReleasesCount({ releasesCount, isMoto, isMobile, match }) {
  const {
    params: {
      lcv: isLcv,
    },
  } = match;

  let declination = ['автомобиль', 'автомобиля', 'автомобилей'];
  if (isMoto) {
    declination = ['мотоцикл', 'мотоцикла', 'мотоциклов'];
  }

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const pluralizeText = pluralize(Number(releasesCount), declination);

  let title;
  if (isLcv) {
    title = 'Новые коммерческие автомобили';
  } else if (isMoto) {
    title = 'Мотоциклы, квадроциклы, скутеры - каталог с ценами и характеристиками';
  } else {
    title = 'Каталог новых легковых автомобилей';
  }

  return (
    <Fragment>
      <H1 dataQa='page-title'>{title}</H1>
      <Indent bottom={vertical} />
      <H3 is='div'>{releasesCount
        ? `${releasesCount} ${pluralizeText} в каталоге`
        : 'Ничего не нашлось'
      }</H3>
    </Fragment>
  );
}

TitleWithReleasesCount.propTypes = {
  isMoto: PropTypes.bool,
  isMobile: PropTypes.bool,
  releasesCount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(withBreakpoint(TitleWithReleasesCount));
