import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import ListPage from 'core/components/ListPage';

import { withBreakpoint } from 'core/components/breakpoint';

import ListSortHeader from 'site/components/ListSortHeader';
import { Indent } from 'site/components/Wrappers';
import NothingFound from 'site/components/NothingFound';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';


function ListSortPage(props) {
  const {
    title,
    description,
    entitiesData,
    limit,
    nothingFoundTitle,
    nothingFoundText,
    sortHeader: sortHeaderProps,
    children,
    isMobile,
    location,
  } = props;

  const verticalIndent = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  if (!entitiesData?.data?.length) {
    return (
      <NothingFound
        text={nothingFoundText}
        title={nothingFoundTitle}
        seoTitle={title}
        seoDescription={description}
      />
    );
  }

  const {
    filtered_count: itemsCount,
  } = entitiesData.meta || {};

  return (
    <Fragment>
      <ListSortHeader
        filteredCount={itemsCount}
        location={location}
        {...sortHeaderProps}
      />
      <Indent bottom={verticalIndent} />
      <ListPage
        title={title}
        description={description}
        rawData={entitiesData}
        limit={limit}
        children={children}
      />
    </Fragment>
  );
}

ListSortPage.propTypes = {
  /** Заголовок страницы. */
  title: PropTypes.string,
  description: PropTypes.string,

  /** НЕ денормализованные данные, включающие в себя filtered_count. */
  entitiesData: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    meta: PropTypes.shape({
      filtered_count: PropTypes.number,
    }),
  }).isRequired,

  /** Параметры SortHeader. */
  sortHeader: PropTypes.exact({
    type: PropTypes.number,
    filters: PropTypes.array,
    defaultOrder: PropTypes.string,
  }),

  /** Текст и заголовок, который будет показан, если результат запроса пустой. */
  nothingFoundText: PropTypes.string,
  nothingFoundTitle: PropTypes.string,

  /** Количество элементов на странице. */
  limit: PropTypes.number,

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  location: PropTypes.object,
};

const ListSortPageWithHOCs = withRouter(withBreakpoint(ListSortPage));
ListSortPageWithHOCs.displayName = 'ListSortPage';

export default ListSortPageWithHOCs;
export { ListSortPage as StorybookComponent };
