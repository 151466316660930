import PropTypes from 'prop-types';
import ReactSwitch from 'react-switch';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import styles from './index.styl';

/**
 * В основе компонента лежит input[type="checkbox"]
 *
 * https://github.com/markusenglund/react-switch
 */
function Switch(props) {
  const {
    labelAfter,
    labelBefore,
    input: {
      checked,
      onChange,
      ...otherInputProps
    },
    theme: {
      controls: {
        switcher: {
          colors: {
            off: offColor,
            on: onColor,
            boxShadow: boxShadowColor,
            text: textColor,
          },
        },
      },
    },
    highlightText,
  } = props;

  return (
    <label className={cx(styles.switch, highlightText && styles.highlightText)}>
      {labelBefore && <span className={cx(styles.text, styles.textBefore)}>{labelBefore}</span>}
      <style jsx>{`
        .${styles.text}
          color ${textColor}
      `}</style>
      <ReactSwitch
        uncheckedIcon={false}
        checkedIcon={false}
        offColor={offColor}
        onColor={onColor}
        offHandleColor={onColor}
        onHandleColor={offColor}
        activeBoxShadow={`0 0 1px 2px ${boxShadowColor}`}
        height={14}
        width={34}
        handleDiameter={20}
        checked={checked}
        {...otherInputProps}
        onChange={currentChecked => onChange(Boolean(currentChecked))}
      />
      {labelAfter && <span className={cx(styles.text, styles.textAfter)}>{labelAfter}</span>}
    </label>
  );
}

Switch.defaultProps = {
  input: {
    onChange: () => null,
    checked: false,
  },
};

Switch.propTypes = {
  /** Свойства, передающиеся непосредственно в `ReactSwitch` */
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    checked: PropTypes.bool.isRequired,
  }),
  /** label до контрола */
  labelBefore: PropTypes.string,
  /** label после контрола */
  labelAfter: PropTypes.string,
  /** @ignore */
  theme: themePropTypes(`{
    controls: {
      switcher: {
        colors: {
          off,
          on,
          boxShadow,
          text,
        }
      }
    }
  }`),
  /**
   * Выделяет текст как важный
   */
  highlightText: PropTypes.bool,
};

const SwitchWithHOCs = withTheme(Switch);
SwitchWithHOCs.displayName = 'Switch';

export default SwitchWithHOCs;
export { Switch as StorybookComponent };
