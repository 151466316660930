import PropTypes from 'prop-types';

import { Section, Block } from 'core/components/Grid';
import { withNonPureBreakpoint } from 'core/components/breakpoint';

import { Indent } from 'site/components/Wrappers';
import InputDropdown from 'site/components/InputDropdown';
import InputRange from 'site/components/InputRange';
import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';
import FormContainer from 'site/components/FormContainer';

import { RANGES } from '../constants';

function Form(props) {
  const {
    formState,
    formOptions,
    isMobile,
    onChange,
  } = props;

  const {
    filteredBrands,
    filteredModels,
  } = formOptions;

  const {
    brand,
    model,
    year,
  } = formState;

  return (
    <ThemeSwitchWithColor tone='dark'>
      <FormContainer>
        <Section>
          <Block width={12} desktop={4}>
            <Indent right={!isMobile && 20}>
              <InputDropdown
                name='brand'
                label='Марка'
                placeholder='Введите название марки'
                options={filteredBrands}
                value={brand}
                onChange={onChange}
                isMulti={false}
                isClearable
                {...isMobile && { hideBorder: true }}
              />
            </Indent>
          </Block>
          <Block width={12} desktop={4}>
            <Indent right={!isMobile && 35} top={isMobile && 1}>
              <InputDropdown
                name='model'
                label='Модельный ряд'
                placeholder='Введите название модели'
                options={filteredModels}
                value={model}
                onChange={onChange}
                isDisabled={filteredModels.length < 1}
                isMulti={false}
                isClearable
                {...isMobile && {
                  roundingPosition: 'bottom',
                  hideBorder: true,
                }}
              />
            </Indent>
          </Block>
          <Block width={12} desktop={4}>
            <Indent top={isMobile && 20}>
              <InputRange
                name='year'
                title='Год выпуска'
                value={year}
                onChange={onChange}
                min={RANGES.year.min}
                max={RANGES.year.max}
                step={1}
                formatNumberType={2}
              />
            </Indent>
          </Block>
        </Section>
      </FormContainer>
    </ThemeSwitchWithColor>
  );
}

Form.propTypes = {
  isMobile: PropTypes.bool,
  formState: PropTypes.object,
  formOptions: PropTypes.object,
  onChange: PropTypes.func,
};

export default withNonPureBreakpoint(Form);
