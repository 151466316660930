import cover3x2 from '/site/icons/covers/cover_3x2.png';
import coverEditorialChoice from '/site/icons/covers/cover_editorial_choice.png';

export default {
  '3/2': {
    options: {
      rel_url: '/' + cover3x2,
      width: 300,
      height: 200,
    },
  },
  'editorialChoiceCover': {
    options: {
      rel_url: '/' + coverEditorialChoice,
      width: 560,
      height: 300,
    },
  },
};
