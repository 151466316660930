import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';

import H1 from 'core/components/H1';
import QutoBrandIcon from 'core/components/QutoBrandIcon';

import styles from './index.styl';

const ruVowels = ['а', 'е', 'ё', 'и', 'о', 'у', 'ы', 'э', 'ю', 'я'];

const BRAND_ICON_SIZE = 50;

export default function Title(props) {
  const {
    offersGeo,
    brand,
    model,
    generation,
    isMoto,
  } = props;

  const brandName = get(brand, 'attributes.name');
  const modelName = get(model, 'attributes.name', '');
  const generationPart = generation ? ` ${generation}` : '';

  const geoAttributes = (offersGeo && offersGeo.attributes) || {};

  const geoNamePrepositional = geoAttributes.name_prepositional;
  const geoPreposition =
    geoNamePrepositional
    && geoNamePrepositional.charAt(0) === 'В'
    && !ruVowels.includes(geoNamePrepositional.charAt(1))
      ? 'во'
      : 'в';
  const geoTitlePart = offersGeo ? ` ${geoPreposition} ${geoNamePrepositional}` : '';

  const text = `
    Купить ${brandName || (isMoto ? 'мотоцикл' : 'автомобиль')} 
    ${modelName}${generationPart}${geoTitlePart}
  `;

  return (
    <div className={styles.title}>
      <style jsx>{`
        .${styles.title}
          // задаем высоту, чтобы не "скакала" страница с/без иконки
          min-height ${BRAND_ICON_SIZE}px
      `}</style>
      <QutoBrandIcon
        brand={brand}
        size={BRAND_ICON_SIZE}
        className={styles.logo}
      />
      <H1 className={styles.text} dataQa='page-title'>{text}</H1>
    </div>
  );
}

Title.propTypes = {
  offersGeo: PropTypes.object,
  brand: PropTypes.object,
  model: PropTypes.object,
  generation: PropTypes.string,
  isMoto: PropTypes.bool,
};
