import PropTypes from 'prop-types';
import { indentPropTypes } from 'site/utils/prop-types/common';

function Indent(props) {
  const {
    children,
    top,
    bottom,
    left,
    right,
    dataQa,
  } = props;

  return (
    <div
      style={{
        ...top && { marginTop: `${top}px` },
        ...bottom && { marginBottom: `${bottom}px` },
        ...left && { marginLeft: `${left}px` },
        ...right && { marginRight: `${right}px` },
      }}
      {...dataQa && { 'data-qa': dataQa }}
    >
      {children}
    </div>
  );
}

Indent.propTypes = {
  top: indentPropTypes,
  bottom: indentPropTypes,
  left: indentPropTypes,
  right: indentPropTypes,
  dataQa: PropTypes.string,
};

export default Indent;
