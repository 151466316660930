import PropTypes from 'prop-types';

import { Desktop } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';
import AdWrapper from 'core/components/Ad/AdWrapper';
import TopicFooter from 'core/components/TopicFooter';

import { SuperFooter } from 'site/components/Ads/desktop';

import { buildTagUrl } from 'site/utils';

import { TopicContentMaxWidth, Indent } from 'core/components/Wrappers';

function TopicFooterWrap(props) {
  const {
    content,
    hideSuperFooter,
    theme: {
      controls: {
        topicFooter: {
          block: {
            spacing,
          },
        },
      },
    },
  } = props;

  return (
    <TopicContentMaxWidth>
      <Indent bottom={spacing}>
        <TopicFooter
          content={content}
          hrefBuilder={buildTagUrl}
        />
      </Indent>
      {!hideSuperFooter && (
        <Desktop>
          <AdWrapper bottom={spacing}>
            <SuperFooter />
          </AdWrapper>
        </Desktop>
      )}
    </TopicContentMaxWidth>
  );
}

TopicFooterWrap.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.object,
  hideSuperFooter: PropTypes.bool,
};

export default withTheme(TopicFooterWrap);
