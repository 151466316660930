import { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  rawRubricFetcher,
  rawTopicsFetcher,
} from 'core/fetchers/rubric';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Rubric from 'core/components/Rubric';

import RubricSelector from 'site/components/RubricSelector';
import SelectionList from 'site/components/SelectionList';
import PageLayout from 'site/components/PageLayout';

import { Indent } from 'site/components/Wrappers';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE, RUBRICS_PAGE_LIMIT } from 'site/constants';


function RubricPage({ rawRubric, rawTopics, isMobile }) {
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <PageLayout>
      <Rubric
        rawRubric={rawRubric}
        rawTopics={rawTopics}
        limit={RUBRICS_PAGE_LIMIT}
        headerSpacing={0}
      >
        {({ topics }) => (
          <Fragment>
            <Indent bottom={isMobile ? VERTICAL_INDENT_MOBILE : 24} />
            <RubricSelector />
            <Indent bottom={vertical} />
            <SelectionList topics={topics} />
          </Fragment>
        )}
      </Rubric>
    </PageLayout>
  );
}

RubricPage.propTypes = {
  rawRubric: PropTypes.object,
  rawTopics: PropTypes.object,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  rawRubric: rawRubricFetcher(),
  rawTopics: rawTopicsFetcher({
    limit: RUBRICS_PAGE_LIMIT,
    include: SelectionList.requiredPayloadImports,
    fields: SelectionList.requiredPayloadFields,
  }),
});

export default withPageHocs(dataProvider)(withBreakpoint(RubricPage));
