/**
 * Название хранилища в localStorage
 */
export const COMPARISON_STORAGE_NAMES = {
  car_modification: 'comparison_car_modification',
  moto_release: 'comparison_moto_release',
};

/**
 * Ширины колонки в таблице
 */
export const COLUMN_WIDTH = 200;
export const COLUMN_WIDTH_MOBILE = 150;
