import cx from 'classnames';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';
import Button from 'core/components/Button';


import { dealerPhoneAttributes } from 'site/utils/prop-types/model';
import themePropTypes from 'core/utils/prop-types/theme';

import styles from './index.styl';


function CardDealerInfoPhone(props) {
  if (!props.content) return null;

  const {
    content: {
      phone: number,
      extension,
      time,
    },
    isHidden,
    handleButtonClick,
    theme: {
      icons: {
        Phone: PhoneIcon,
      },
      colors: {
        contentList: colorContentList,
      },
    },
  } = props;

  const extensionText = extension ? `, доб. ${extension}` : '';
  const phoneText = isHidden ? number.substring(0, 12) : number + extensionText;
  const timeText = time ? `Время работы: ${time}` : '';

  return (
    <div className={cx(styles.phoneCard, !isHidden && styles._visible)}>
      <style jsx>{`
        .buttonWrapper:before
          background linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, ${colorContentList} 100%)
      `}</style>
      <div className={styles.phone}>
        <PhoneIcon
          width={16}
          height={16}
          className={styles.icon}
        />
        {/* TODO: хорошо бы сделать телефон ссылкой: <Link to={'tel:' + ... */}
        <div className={styles.phoneText}>{phoneText}</div>
      </div>
      {!isHidden && timeText &&
      <div className={styles.time}>{timeText}</div>
      }
      <div className={cx('buttonWrapper', styles.buttonWrapper)}>
        <YandexMetrikaGoalSender
          show={'show_phone_show'}
          click={'show_phone_click'}
          skipTargetCheck
        >
          <Button
            onClick={handleButtonClick}
            radius='5px'
            type='green'
            size='small'
          >
            <span className={styles.buttonText}>
              Показать телефон
            </span>
          </Button>
        </YandexMetrikaGoalSender>
      </div>
    </div>
  );
}

CardDealerInfoPhone.propTypes = {
  /** Контент, соответствующий модели `dealerPhoneAttributes` */
  content: dealerPhoneAttributes,
  /** Скрывает текст под кнопку `Показать телефон` */
  isHidden: PropTypes.bool,
  /** Обработчик клика по кнопке */
  handleButtonClick: PropTypes.func,
  /** @ignore */
  theme: themePropTypes(`{
    icons: {
      Phone
    },
    colors: {
      contentList
    }
  }`),
};

const CardWithHOCs = withTheme(CardDealerInfoPhone);
CardWithHOCs.displayName = 'CardDealerInfoPhone';

export default CardWithHOCs;
export { CardDealerInfoPhone as StorybookComponent };
