import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'core/libs/router';

import color from 'core/libs/color';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';

import cardHOC from 'core/components/card';
import bindProps from 'core/components/bindProps';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import AdvTooltip from 'core/components/AdvTooltip';

import mapTypes from './types';
import mapSizes from './sizes';

import styles from './index.styl';

import { LAYOUT_MAX_WIDTH_MOBILE, JOURNAL_URL } from 'site/constants';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'announce',
  'topic_type',
  'ad_label',
  'link_with_announce_erid',
  'advertiser_name',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card2(props) {
  const {
    content,
    isMobile,
    theme,
    type,
    dataQa,
    location,
    canShowAvdTooltip,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    announce,
    topic_type: topicType,
    ad_label: adLabel,
    link_with_announce_erid: linkWithAnnounceErid,
    advertiser_name: advertiserName,
  } = content.attributes;

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    size,
    withAnnounce,
    withoutBg,
    withShadow,
    originalSize,
  } = mapTypes[type] || {};

  const {
    headlineSize,
    headlineFontFamily,
    width: imageWidth,
    height: imageHeight,
    padding: contentPadding,
    iconSize,
  } = mapSizes[size] || {};

  const {
    icons: {
      Video,
      Photo,
    },
  } = theme;

  const mapIconByType = {
    video: Video,
    gallery: Photo,
  };

  const Icon = mapIconByType[topicType];

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const isJournalPage = location.pathname === JOURNAL_URL;
  const shouldShowAdvTooltip = !!(isJournalPage && canShowAvdTooltip && linkWithAnnounceErid && advertiserName && adLabel);
  const topicLink = shouldShowAdvTooltip ? linkWithAnnounceErid : link;

  return (
    <div
      className={cx(
        'card2',
        styles.card2,
        withShadow && styles._withShadow,
        originalSize && styles._originalSize,
      )}
      data-qa={dataQa}
    >
      <style jsx>{`
        .${styles.card2}
          background ${withoutBg ? 'transparent' : theme.colors.content}
          box-shadow ${isMobile && !withShadow ? 'none' : `0px 1px 5px ${color(theme.colors.primary).alpha(0.2)},
            0px 3px 4px ${color(theme.colors.primary).alpha(0.12)},
            0px 2px 4px ${color(theme.colors.primary).alpha(0.15)}`}

          &:hover
            .headline
              color ${theme.colors.active1}

        .headline
          color ${theme.colors.primary}
          font ${headlineSize} ${theme.fonts[headlineFontFamily]}
          transition color ${theme.animations.hover}

        .${styles.announce}
          color ${theme.colors.primary}
          font 14px/16px ${theme.fonts.text}

        .${styles.image}
          &:after
            background ${theme.colors.primary}
            transition opacity ${theme.animations.hover}

        .${styles.content}
          padding ${contentPadding}
      `}
      </style>
      <Link
        to={topicLink}
        type='secondary'
        className={styles.link}
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
      >
        <div className={styles.image}>
          <Image
            {...originalCover && { src: originalCover }}
            {...originalCover && { previewSrc: previewCover }}
            {...remoteImage && { url: remoteImage }}
            aspectRatio={+(imageWidth / imageHeight).toFixed(2)}
            maxWidth={isMobile ? LAYOUT_MAX_WIDTH_MOBILE : imageWidth}
          />
          {Icon && (
            <span className={styles.icon}>
              <Icon width={iconSize} height={iconSize} />
            </span>
          )}
        </div>
        <div className={styles.content}>
          <div className='headline' data-qa='card-headline'>{listHeadline || headline}</div>
          {withAnnounce && <div className={styles.announce}>{announce}</div>}
        </div>
      </Link>
      {shouldShowAdvTooltip && (
        <AdvTooltip text={advertiserName} adLabel={adLabel} />
      )}
    </div>
  );
}

Card2.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7]),
  /** Данные для карточки */
  content: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  breakpoint: breakpointPropTypes(),
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  dataQa: PropTypes.string,
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  canShowAvdTooltip: PropTypes.bool,
};

Card2.defaultProps = {
  type: 1,
};

const Card = cardHOC(withRouter(Card2));
export const Card2Type1 = bindProps({ type: 1 })(Card);
export const Card2Type2 = bindProps({ type: 2 })(Card);
export const Card2Type3 = bindProps({ type: 3 })(Card);
export const Card2Type4 = bindProps({ type: 4 })(Card);
export const Card2Type5 = bindProps({ type: 5 })(Card);
export const Card2Type6 = bindProps({ type: 6 })(Card);
export const Card2Type7 = bindProps({ type: 7 })(Card);

Card.displayName = 'Card2';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card2 as StorybookComponent };
