import { createContext, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import { isMotoPath } from 'site/utils';
import { getStorageUuids, updateStorageUuids, choiceOfTransportStorage } from 'site/utils/compare';

export const CompareContext = createContext({
  carUuids: [],
  motoUuids: [],
  updateUuids: () => null,
});

/**
 * Uuids исползуются:
 * - для построения ссылки на страницу сравнения;
 * - счетчика о количестве добавленных сравнений;
 * - значения checked для контролов "добавить в сравнение";
 * - логики рендера site/pages/compare/sections/Content (В сравнении ничго нет, В сравнении N-модификаций, Сравнения)
 */
class CompareProvider extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      carUuids: [],
      motoUuids: [],
      updateUuids: this.updateUuids,
    };
  }

  componentDidMount() {
    this.updateUuidsFromLS();
    /**
     * Для синхронизации вкладок браузера добавлен обрабочик:
     * Обработчик window.addEventListener('storage') срабатывает только на изменения в соседних вкладках.
     * В рамках сессии одной вкладки используются данные из контекста приложения.
     */
    window.addEventListener('storage', this.handleStorage);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.handleStorage);
  }

  handleStorage = event => {
    const pathname = this.props.location.pathname;
    const isMoto = isMotoPath(pathname);
    const typeVehicle = isMoto ? 'moto_release' : 'car_modification';

    if (event.key !== choiceOfTransportStorage(typeVehicle)) return;
    this.updateUuidsFromLS();
  };

  updateUuidsFromLS = () => {
    this.setState(() => {
      return { motoUuids: getStorageUuids('moto_release'), carUuids: getStorageUuids('car_modification') };
    });
  };

  updateUuids = id => {
    const pathname = this.props.location.pathname;
    const isMoto = isMotoPath(pathname);
    const typeVehicle = isMoto ? 'motoUuids' : 'carUuids';
    const typeEntity = isMoto ? 'moto_release' : 'car_modification';

    this.setState(currentState => {
      const uuids = currentState[typeVehicle];
      const hasId = uuids && uuids.some(uuid => uuid === id);
      const newIds = hasId
        ? uuids.filter(localId => localId !== id)
        : [].concat(uuids || [], id);

      return { [typeVehicle]: newIds };
    }, () => updateStorageUuids(this.state[typeVehicle], typeEntity));
  };

  render() {
    const { children } = this.props;

    return (
      <CompareContext.Provider value={this.state}>
        {children}
      </CompareContext.Provider>
    );
  }
}

CompareProvider.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default withRouter(CompareProvider);
