import PropTypes from 'prop-types';
import accounting from 'accounting';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import H3 from 'core/components/H3';
import Button from 'core/components/Button';

import Modal from 'site/components/Modal';

import Close from 'site/icons/Close';
import Back from 'site/icons/Back';

import styles from './index.styl';

function OptionModal(props) {
  const {
    theme,
    children,
    isOpen,
    toggleModal,
    handleBackward,
    handleAccept,
    handleClose,
    diffPrice,
    totalPrice,
    acceptText,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      style={{ content: { flex: '0 1 640px' } }}
      onRequestClose={handleClose}
      ariaHideApp={false}
    >
      <style jsx>{`
        .${styles.body}
          background ${theme.colors.content}
      `}</style>
      <div className={styles.optionModal}>
        <div className={styles.body}>
          <div className={styles.header}>
            {handleBackward && <Back className={styles.back} onClick={handleBackward} />}
            <H3>Требуются уточнения</H3>
            <Close className={styles.close} onClick={() => toggleModal(false)} />
          </div>

          <div className={styles.contentWrap}>
            <div className={styles.content}>
              {children}
            </div>
          </div>

          <div className={styles.footer}>
            <div className={styles.counts}>
              <div className={styles.price}>
                Цена изменится на {accounting.formatMoney(diffPrice)}
              </div>
              <div className={styles.summary}>
                Итого: {accounting.formatMoney(totalPrice)}
              </div>
            </div>

            <div className={styles.accept}>
              <Button
                minWidth='100%'
                onClick={handleAccept}
              >{acceptText}</Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

OptionModal.defaultProps = {
  handleAccept: () => null,
  handleClose: () => null,
  acceptText: 'Принять',
};

OptionModal.propTypes = {
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      primary100,
    }
  }`),
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  handleAccept: PropTypes.func,
  handleClose: PropTypes.func,
  handleBackward: PropTypes.func,
  totalPrice: PropTypes.number,
  diffPrice: PropTypes.number,
  acceptText: PropTypes.string,
};

const OptionModalWithHOCs = withTheme(OptionModal);
OptionModalWithHOCs.displayName = 'OptionModal';

export default OptionModalWithHOCs;
export { OptionModal as StorybookComponent };
