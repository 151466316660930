import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { withRouter } from 'core/libs/router';
import ThemeSwitch from 'core/components/ThemeSwitch';
import { isMotoPath, isGameCompare } from 'site/utils';

import DarkWrapper from '../DarkWrapper';


const ThemeSwitchWithColor = (props) => {
  const {
    tone,
    location,
    children,
  } = props;

  let currentTheme = 'Blue';
  const isGameComparePage = isGameCompare(location.pathname);
  const Wrapper = isGameComparePage ? DarkWrapper : Fragment;

  if (isMotoPath(location.pathname)) {
    currentTheme = 'Red';
  } else if (isGameComparePage) {
    currentTheme = '';
  }

  return (
    <ThemeSwitch name={tone +  currentTheme}>
      <Wrapper>
        {children}
      </Wrapper>
    </ThemeSwitch>
  );
};

ThemeSwitchWithColor.propTypes = {
  /** Тон темы на которую должны переключиться вложенные компоненты - dark или light.*/
  tone: PropTypes.string.isRequired,
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  /** @ignore */
  theme: PropTypes.object,
};

const ThemeSwitchWithColorWithHOCs = withRouter(ThemeSwitchWithColor);
ThemeSwitchWithColorWithHOCs.displayName = 'ThemeSwitchWithColor';

export default ThemeSwitchWithColorWithHOCs;
export { ThemeSwitchWithColor as StorybookComponent };
