import PropTypes from 'prop-types';

import themePropTypes from 'core/utils/prop-types/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import mapTypes from './types';

import { Indent, NegativeMobile } from 'site/components/Wrappers';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';

function ShadowContentBg(props) {
  const {
    theme,
    isMobile,
    isDesktop, // eslint-disable-line
    children,
    className,
    type,
    ...otherProps
  } = props;

  const {
    borderRadius,
    padding,
    withIndents,
  } = mapTypes[type] || {};

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .box
          background ${theme.colors.content}
          box-shadow 0 2px 5px ${theme.colors.primary100}
          border-radius ${borderRadius}
          padding ${padding}
      `}</style>
    </scope>
  );

  if (withIndents) {
    return (
      <NegativeMobile>
        <div className={scope.wrapClassNames('box', styles.box, className)} {...otherProps}>
          <Indent
            top={vertical}
            bottom={vertical}
            left={15}
            right={15}
          >
            {children}
          </Indent>
        </div>
        <scope.styles />
      </NegativeMobile>
    );
  }

  return (
    <div className={scope.wrapClassNames('box', styles.box, className)} {...otherProps}>
      <style jsx>{`
        .box
          background ${theme.colors.content}
          box-shadow 0 2px 5px ${theme.colors.primary100}
          border-radius ${borderRadius}
          padding ${padding}
      `}</style>
      {children}
      <scope.styles />
    </div>
  );
}

ShadowContentBg.defaultProps = {
  type: 1,
};

ShadowContentBg.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.oneOf([1, 2, 3, 4]),
  isMobile: PropTypes.bool,
  theme: themePropTypes(`{
    colors: {
      primary100,
    }
  }`),
};

export default withBreakpoint(withTheme(ShadowContentBg));
