import cx from 'classnames';
import accounting from 'accounting';
import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';
import { withRouter } from 'core/libs/router';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import { capitalize } from 'core/utils/string-helper';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import resolveRelationships from 'core/utils/relationships';

import H6 from 'core/components/H6';
import Link from 'core/components/Link';

import ArrowRightSm from 'site/icons/ArrowRightSm';

import { Indent } from 'site/components/Wrappers';

import { isMotoPath } from 'site/utils';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';

const relationships = resolveRelationships(['release', 'modification', 'body', 'class', 'dealer', 'class'], {},
  {
    release: {
      name: '',
      url: '',
    },
    modification: {
      specifications_primary: {
        engine: '',
        transmission: '',
        drive: '',
      },
      url: '',
      power: '',
      volume: '',
    },
    body: {
      name: '',
    },
    class: {
      name: '',
    },
  });

function Properties({ offer, theme, location, isMobile }) {
  const {
    attributes: {
      power: offerPower,
      year,
      run,
      volume: offerVolume,
      color,
      condition_title: conditionTitle,
    },
  } = offer;

  const {
    release: {
      url: releaseUrl,
    },
    release,
    modification: {
      specifications_primary: {
        engine,
        transmission,
        drive,
      },
      url: modificationUrl,
      power: modificationPower,
      volume: modificationVolume,
    },
    body: {
      name: bodyName,
    },
    class: {
      name: className,
    },
  } = relationships(offer);

  const isMoto = isMotoPath(location.pathname);
  const cylindersValue = get(release, 'specifications_primary.cylinders.value');
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const power = offerPower || modificationPower;
  const volume = offerVolume || modificationVolume;

  const { value: transmissionValue } = transmission || {};
  const { value: driveValue } = drive || {};
  const { value: engineValue } = engine || {};

  const properties = [
    { title: 'Год выпуска', value: year },
    { title: 'Пробег', value: accounting.formatMoney(run, 'км') },
    className ? { title: 'Класс', value: className } : {},
    { title: 'Состояние', value: capitalize(conditionTitle) },
    bodyName ? { title: 'Кузов', value: bodyName } : {},
    { title: 'Объем двигателя', value: `${volume} ${isMoto ? 'см³' : 'л'}` },
    { title: 'Мощность', value: accounting.formatMoney(power, 'л.c.') },
    cylindersValue ? { title: 'Количество цилиндров', value: cylindersValue } : {},
    engineValue ? { title: 'Тип двигателя', value: engineValue } : {},
    transmissionValue ? { title: 'КПП', value: transmissionValue } : {},
    driveValue ? { title: 'Привод', value: driveValue } : {},
    { title: 'Цвет', value: color },
  ];

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          color ${theme.colors.active1}
          display flex
          align-items center
          & :global([id*="!"])
            stroke ${theme.colors.active1}

          &:visited :global([id*="!"])
            stroke ${theme.colors.blue1}

          &:hover :global([id*="!"])
            stroke ${theme.colors.primary}
      `}</style>
    </scope>
  );

  return (
    <div className={cx(styles.section, 'section')}>
      <style jsx>{`
        .row
          &:before
            border-bottom 1px dashed ${theme.colors.divider}

        .name
        .value
          background ${theme.colors.contentList}

        .name
          color ${theme.colors.primary250}

        .section
          border-bottom 1px solid ${theme.colors.primary250}
      `}</style>
      <H6>Технические характеристики</H6>
      <Indent bottom={vertical / 2} />
      {properties.map(({ title, value }, i) => {
        if (!value) return null;

        return (
          <div key={i} className={cx(styles.row, 'row')}>
            <div className={cx(styles.name, 'name')}>{title}</div>
            <div className={cx(styles.value, 'value')}>{value}</div>
          </div>
        );
      })}
      <Indent bottom={vertical / 2} top={vertical / 2}>
        <Link className={scope.wrapClassNames('link')} to={modificationUrl || releaseUrl}>
          Все технические характеристики в каталоге<ArrowRightSm className={cx('arrowRight', styles.arrowRight)} />
        </Link>
      </Indent>
      <scope.styles />
    </div>
  );
}

Properties.propTypes = {
  offer: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withRouter(withTheme(withBreakpoint(Properties)));
