import resolveRelationships from 'core/utils/relationships';

const bodies = ['sedan', 'hatchback', 'wagon', 'suv', 'coupe', 'cabriolet', 'coupe-cabriolet', 'roadster', 'minivan', 'pickup', 'furgon', 'minubus', 'chassis', 'platform'];
const requiredPayloadImports = ['model', 'body'];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  body: {},
  model: {},
});


function getLatestCarGeneration(archiveReleases, archiveGenerations) {
  const latestGenerations = archiveReleases.filter(archiveRelease => {
    return archiveGenerations.find(archiveGeneration => {
      return archiveRelease.relationships.model.data.id === archiveGeneration.relationships.model.data.id;
    });
  });

  const releasesGenerations = latestGenerations.reduce((groups, release) => {
    const {
      model: { name },
    } = relationships(release);

    if (groups[name]) {
      groups[name].push(release);
    } else {
      groups[name] = [release];
    }

    return groups;
  }, {});

  const filteredByBody = Object.values(releasesGenerations).reduce((result, models) => {
    if (models.length > 1) {
      models.filter(model => {
        const {
          body: { slug },
        } = relationships(model);

        return bodies.find(body => {
          return slug.includes(body);
        });
      });
    }
    result.push(models[0]);

    return result;
  }, []);

  return filteredByBody;
}

export default getLatestCarGeneration;
