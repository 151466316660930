import { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import modelPropTypes, { numberAttributes } from 'core/utils/prop-types/model';

import MarkdownWrapper from 'core/components/MarkdownWrapper';

import Text from 'core/components/Text';

import { LimeGradientBg } from 'site/components/Wrappers';

import styles from './index.styl';

export default function Number(props) {
  const {
    block: {
      attributes: {
        body,
        value,
        unit,
      },
    },
    parentProps: {
      theme,
    },
  } = props;

  return (
    <Fragment>
      <style jsx>{`
        .valueBox
          color ${theme.colors.primary}
          font 80px/1 ${theme.fonts.display}

        .unit
          color ${theme.texts.lead.color}
          font ${theme.texts.lead.font}
      `}</style>
      <LimeGradientBg className={styles.gradient} />
      <div className={styles.number}>
        <div className={styles.value}>
          <span className={cx(styles.valueBox, 'valueBox')}>{value}</span>
          {unit && <div className={cx(styles.unit, 'unit')}>{unit}</div>}
        </div>
        <div className={styles.body}>
          <Text>
            <MarkdownWrapper>
              {body}
            </MarkdownWrapper>
          </Text>
        </div>
      </div>
      <LimeGradientBg className={styles.gradient} />
    </Fragment>
  );
}

Number.propTypes = {
  /** @ignore */
  parentProps: PropTypes.object,
  /** Данные виджета из API */
  block: modelPropTypes(numberAttributes).isRequired,
};
