import PropTypes from 'prop-types';
import cx from 'classnames';

import { withRouter } from 'core/libs/router';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import { withBreakpoint } from 'core/components/breakpoint';

import User from 'site/components/User/index';

import resolveRelationships from 'core/utils/relationships';

import styles from './index.styl';

import getThumbnail from 'site/utils/get-thumbnail/index';

const relationships = resolveRelationships(['release', 'user'], {}, {});

function Info({ isMobile, match, review }) {
  const {
    release: {
      image,
      name: releaseName,
    },
    user,
  } = relationships(review);

  const {
    params: {
      brand,
      model,
      generation,
      body,
    },
  } = match;

  const cover = getThumbnail(image);

  return (
    <div className={cx(styles.info, isMobile && styles.mobile)}>
      <div className={styles.section}>
        <User type={1} user={user} />
      </div>
      <div className={styles.section}>
        <Link
          to={`/${brand}/${model}/${generation}/${body}/`}
          className={styles.link}
          type='secondary'
        >
          <div className={styles.image}>
            <Image src={cover} width={100} />
          </div>
          <div className={styles.releaseName}>{releaseName}</div>
        </Link>
      </div>
    </div>
  );
}

Info.propTypes = {
  isMobile: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
      generation: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }),
  }).isRequired,
  review: PropTypes.object,
};

export default withRouter(withBreakpoint(Info));
