function getLatestMotoGeneration(archiveReleases) {
  const generateModels = archiveReleases.reduce((result, model) => {
    const {
      short_name: shortName,
      finish_date: finishDate,
    } = model.attributes;

    if (result[shortName]) {
      const existFinishDate = result[shortName].attributes.finish_date;
      if (new Date(existFinishDate) < new Date(finishDate)) {
        result[shortName] = model;
      }
    } else {
      result[shortName] = model;
    }
    return result;
  }, {});

  const archiveContent = Object.values(generateModels);
  return archiveContent;
}

export default getLatestMotoGeneration;
