export default {
  0: {
    showExtendedInfo: true,
    imageSize: 80,
  },
  1: {
    showRating: true,
    imageSize: 60,
  },
};
