import PropTypes from 'prop-types';
import cx from 'classnames';

import Scroller from 'core/components/Scroller';
import { withBreakpoint } from 'core/components/breakpoint';
import EmptyWrapper from 'core/components/EmptyWrapper';

import { NegativeMobile } from 'site/components/Wrappers';

import {
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import RadioCloud from './RadioCloud';

import styles from './index.styl';


function ScrollWrapper({ children }) {
  return (
    <NegativeMobile>
      <Scroller>
        {children}
      </Scroller>
    </NegativeMobile>
  );
}


function RadioClouds(props) {
  const {
    options,
    title,
    name,
    onChange,
    isDisabled,
    value,
    isMobile,
    hideDefaultRadio,
    customDefaultOption,
  } = props;

  const Wrapper = isMobile ? ScrollWrapper : EmptyWrapper;

  const defaultOption = customDefaultOption || { title: 'любой', value: '', label: 'любой' };
  const newOptions = [!hideDefaultRadio && defaultOption, ...options].filter(Boolean);

  return (
    <div className='radioClouds'>
      <style jsx>{`
        .${styles.cloud}
          :global(.mobile) &
            &:first-child
              margin-left ${SIDE_INDENT_MOBILE}px
            &:last-child
              margin-right ${SIDE_INDENT_MOBILE}px
      `}</style>

      {title && <div className={cx(styles.title, isDisabled && styles._disabled)}>{title}</div>}
      <Wrapper>
        <div className={styles.list}>
          {newOptions.map((item, index) => (
            <div className={styles.cloud} key={index}>
              <RadioCloud
                name={name}
                onChange={() => onChange(item.value, { name })}
                disabled={isDisabled}
                checked={value ? value === item.value : item.value === ''}
                {...item}
              />
            </div>
          ))}
        </div>
      </Wrapper>
    </div>
  );
}

RadioClouds.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  /**
   * Заголовок блока
   */
  title: PropTypes.string,
  /**
   * Имя радио-группы
   */
  name: PropTypes.string.isRequired,
  /** Коллбек для события onChange */
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Свойство, позволяющее сделать контрол неактивным */
  isDisabled: PropTypes.bool,
  /**
   * Флаг скрывает радио "Любой" устанавливаемый по умолчанию
   */
  hideDefaultRadio: PropTypes.bool,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
  customDefaultOption: PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
  }),
};


const RadioCloudsWithHOCs = withBreakpoint(RadioClouds);
RadioCloudsWithHOCs.displayName = 'RadioClouds';

export default RadioCloudsWithHOCs;
export { RadioClouds as StorybookComponent };
