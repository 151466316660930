import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import { denormalizeData } from 'core/utils/api';
import { isMotoPath } from 'site/utils';

export const ComparePageContext = createContext();

function catalogComparisonRequest(servicesApi, location) {
  const { uuid: searchUuids } = queryString.parse(location.search);

  const isMoto = isMotoPath(location.pathname);
  const vehiclePrefix = isMoto ? 'moto' : 'car';
  const requestParams = {
    modifications: [].concat(searchUuids).join(),
    releases: [].concat(searchUuids).join(),
    'attributes[car_modification]': 'base,options,specifications,ownership',
    'relations[car_modification]': 'release,completion',
    'relations[moto_release]': 'release',
    [`attributes[${vehiclePrefix}_release]`]: 'base,image,stats,media',
    include: `${vehiclePrefix}_release,${vehiclePrefix}_modification_completion`,
  };

  const compareAPI = isMoto
    ? servicesApi.getMotoCompareData
    : servicesApi.getCompareData;


  return compareAPI({ ...requestParams })
    .then(({ data, included }) => {
      const content = isMoto ? 'releases' : 'modifications';

      return {
        merged: data.merged,
        [content]: denormalizeData({ data: data[content], included }),
      };
    })
    .catch(err => console.error(err.message));
}

/**
 * Провайдер предоставляет данные и метод для работы со сравнениями в рамках страницы.
 * updateComparison - колбек делающий запрос на ручку `catalog/modifications`
 * modifications - данные для построения таблицы
 */
function ComparePageProvider({ location, children }, { servicesApi }) {
  const isMoto = isMotoPath(location.pathname);
  const [differentState, toggleDifferent] = useState(false);
  const [comparisons, setComparisons] = useState(null);

  const updateComparison = () => {
    if (!location.search) {
      setComparisons(null);
      return;
    }

    /**
     * Сбрасываем флаг "Только различающиеся" если в сравнении остается одна модификация.
     * При добавлении модификаций, переключатель оставляем со своим состоянием.
     */
    const { uuid: searchUuids } = queryString.parse(location.search);
    if (typeof searchUuids === 'string') {
      toggleDifferent(false);
    }

    catalogComparisonRequest(servicesApi, location)
      .then(setComparisons);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateComparison, [location.search]);

  return (
    <ComparePageContext.Provider
      value={{
        comparisons,
        differentState,
        isMoto,
        toggleDifferent,
      }}
    >
      {children}
    </ComparePageContext.Provider>
  );
}

ComparePageProvider.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }),
};

ComparePageProvider.contextTypes = {
  servicesApi: PropTypes.object,
};

export default withRouter(ComparePageProvider);
