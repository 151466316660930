import queryString from 'core/libs/query-string';

import resolve from 'core/resolver/resolve';

import { getOffset } from 'core/utils/url-helper';
import { denormalizeData } from 'core/utils/api';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';
import { isMotoPath } from 'site/utils';

import {
  OFFERS_LIMIT,
  OFFERS_LIMIT_MOBILE,
} from 'site/constants';


export default resolve({
  dealer: props => {
    const {
      servicesApi,
      renderError,
      match: {
        params: {
          dealer_slug: dealerSlug,
        },
      },
      location: {
        pathname,
      },
    } = props;

    const vehiclePrefix = isMotoPath(pathname) ? 'moto' : 'car,lcv';

    return servicesApi
      .getDealers({
        'filter[dealer_slug]': dealerSlug,
        'filter[type]': vehiclePrefix,
        'attributes[dealer]': 'base,text,stat',
        'relations[dealer]': `dealer_network,${vehiclePrefix}_brands,geo,geo_parent`,
        include: `dealer_network,${vehiclePrefix}_brand,geo`,
        limit: 1,
      })
      .then(dealerData => denormalizeData(dealerData)[0])
      .then(rejectEmptyResult)
      .catch(renderError);
  },

  dealerSeo: ({ servicesApi, consoleError, match, location }) => {
    const { geo, brand, dealer_slug: dealerSlug } = match.params;

    const isMoto = isMotoPath(location.pathname);

    const method = `get${isMoto ? 'Moto' : 'Car'}DealersDealer${brand ? 'OfBrand' : ''}Seo`;
    const seoAPI = servicesApi[method];

    return seoAPI({
      'filter[geo]': geo,
      'filter[dealer]': dealerSlug,
      'filter[brand]': brand,
    })
      .then(denormalizeData)
      .catch(consoleError('dealerSeo', {}));
  },

  offersData: props => {
    const {
      servicesApi,
      consoleError,
      location: {
        search,
      },
      match: {
        params: {
          geo,
          brand: brandSlug,
          offer_type: offerType,
          dealer_slug: dealerSlug,
        },
      },
      isDesktop,
    } = props;

    const limit = isDesktop ? OFFERS_LIMIT : OFFERS_LIMIT_MOBILE;
    const queryFilters = queryString.parse(search);
    const sort = queryFilters.sort || 'price';

    return servicesApi
      .getOffers({
        [offerType === 'used' && 'filter[is_trade_in]']: true,
        'filter[dealer_slug]': dealerSlug,
        'filter[brand]': brandSlug,
        'filter[geo]': geo,
        'attributes[dealer_offer]': `base,image,${isDesktop ? 'text' : ''}`,
        'relations[dealer_offer]': 'brand,model,modification,dealer,dealer_network',
        'relations[dealer]': 'network',
        include: 'car_brand,car_model,car_modification,dealer,dealer_network',
        offset: getOffset(search, limit),
        sort,
        limit,
        with_filtered_count: true,
      })
      .catch(consoleError('offersData', {}));
  },
});
