import PropTypes from 'prop-types';

import Link from 'core/components/Link';

import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';

import { JOURNAL_URL } from 'site/constants';

import Author from './Author';
import styles from './index.styl';

export default function Authors({ authors, withoutLink }) {
  if (!authors || authors.length === 0) return null;

  return (
    <div className={styles.authors}>
      {authors.map(author => {
        const {
          id: authorId,
          attributes: {
            slug,
          },
        } = author;

        if (withoutLink) {
          return (
            <Author
              withoutLink
              author={author}
              key={authorId}
            />
          );
        }

        return (
          <Link
            to={`${JOURNAL_URL}/authors/` + slug}
            className={styles.link}
            type='secondary'
            key={authorId}
          >
            <Author author={author} />
          </Link>
        );
      })}
    </div>
  );
}

Authors.propTypes = {
  /**
   * Флаг, отключающий оборачивание имя и фамилию автора
   * в ссылку на его страницу
   */
  withoutLink: PropTypes.bool,
  /** Список авторов, соответствующих модели `personAttributes` */
  authors: PropTypes.arrayOf(modelPropTypes(personAttributes)).isRequired,
};
