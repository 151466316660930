import { SideIndent } from 'core/components/Wrappers';
import StoryTopics from 'core/components/stories/StoryTopics';

export default function Stories() {
  return (
    <SideIndent>
      <StoryTopics top={20} bottom={20} />
    </SideIndent>
  );
}
