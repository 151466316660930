import logoSm from 'site/icons/LogoSm.png';
import logoMd from 'site/icons/LogoMd.png';
import logoLg from 'site/icons/LogoLg.png';
import logoQuarantine from 'site/icons/logo_quarantine.png';
import logoLime from 'site/icons/LogoLime.png';

// TODO: Размеры надо брать из стилевых атомов
export default {
  1: {
    src: logoMd,
    width: 118,
    height: 35,
  },
  2: {
    src: logoMd,
    width: 150,
    height: 44,
  },
  3: {
    src: logoLg,
    width: 190,
    height: 44,
  },
  4: {
    src: logoQuarantine,
    width: 299,
    height: 37,
  },
  5: {
    src: logoSm,
    width: 72,
    height: 32,
  },
  6: {
    src: logoLime,
    width: 207,
    height: 44,
  },
};
