import PropTypes from 'prop-types';
import cx from 'classnames';

import color from 'core/libs/color';
import resolveRelationships from 'core/utils/relationships';

import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import { CONTENT_AREA } from 'site/constants';

import mapTypes from './types';
import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'topic_type',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card5(props) {
  const {
    content,
    theme,
    type,
    dataQa,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    topic_type: topicType,
  } = content.attributes;

  const {
    width: imageWidth,
    height: imageHeight,
    iconSize,
  } = mapTypes[type] || {};

  const {
    icons: {
      Video,
      Photo,
    },
  } = theme;

  const mapIconByType = {
    video: Video,
    gallery: Photo,
  };

  const Icon = mapIconByType[topicType];

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  return (
    <div
      className={cx('card5', styles.card5)}
      data-qa={dataQa}
    >
      <style jsx>{`
        .headline
          color ${theme.colors.content}
          font 500 30px/34px ${theme.fonts.text}

        .card5
          background ${theme.colors.content}

        .image
          &:after
            background linear-gradient(180deg,
                ${color(theme.colors.primary).alpha(0)} 60%,
                ${color(theme.colors.primary).alpha(1)} 100%
              )
            transition opacity ${theme.animations.hover}
      `}
      </style>
      <Link
        to={link}
        type='secondary'
        className={styles.link}
      >
        <div className={cx(styles.image, 'image')}>
          <Image
            src={originalCover}
            previewSrc={previewCover}
            aspectRatio={+(imageWidth / imageHeight).toFixed(2)}
            maxWidth={CONTENT_AREA}
          />
          <div className={cx(styles.content, 'content')}>
            <div className={cx(styles.header, 'header')}>
              <div className={cx(styles.headline, 'headline')} data-qa='card-headline'>{listHeadline || headline}</div>
            </div>
            {Icon && (
              <span className={styles.icon}>
                <Icon width={iconSize} height={iconSize} />
              </span>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}

Card5.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([1]),
  /** Данные для карточки */
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  dataQa: PropTypes.string,
};

Card5.defaultProps = {
  type: 1,
};

const Card = cardHOC(Card5);

Card.displayName = 'Card5';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card5 as StorybookComponent };
