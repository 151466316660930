import queryString from 'core/libs/query-string';

import resolve from 'core/resolver/resolve';

import { getOffset } from 'core/utils/url-helper';
import { denormalizeData } from 'core/utils/api';

import { buildSearchRequestParams, isMotoPath } from 'site/utils';

import { OFFERS_PAGE_LIMIT } from 'site/constants';

export default resolve({
  offers(props) {
    const {
      servicesApi,
      match: {
        params: {
          geo,
          brand,
          model,
          generation,
        },
      },
      location: { search },
      location,
      isDesktop,
      renderError,
    } = props;

    const isMoto = isMotoPath(location.pathname);
    const vehiclePrefix = isMoto ? 'moto' : 'car';

    const offersAPI = isMoto ? 'getMotoOffers' : 'getOffers';

    const queryFilters = queryString.parse(search);
    const sortFilter = queryFilters.sort || '-date';
    const requestParams = buildSearchRequestParams(search);

    const {
      condition,
      ...otherRequestParams
    } = requestParams;

    return servicesApi[offersAPI]({
      ...otherRequestParams,
      'filter[geo]': geo,
      'filter[brand]': brand,
      'filter[model]': model,
      'filter[generation]': generation,
      [condition === 'new' && 'filter[is_new]']: true,
      [condition === 'trade_in' && 'filter[is_trade_in]']: true,
      [`attributes[dealer_offer${isMoto ? '_moto' : ''}]`]: `base,image,${isDesktop ? 'text' : ''}`,
      [`attributes[${vehiclePrefix}_brand]`]: ['base', 'image'],
      [`relations[dealer_offer${isMoto ? '_moto' : ''}]`]: 'brand,model,modification,dealer,dealer_network',
      'relations[dealer]': 'dealer_network',
      include: `${vehiclePrefix}_brand,${vehiclePrefix}_model,${vehiclePrefix}_modification,dealer,dealer_network`,
      offset: getOffset(search, OFFERS_PAGE_LIMIT),
      limit: OFFERS_PAGE_LIMIT,
      sort: sortFilter,
      with_filtered_count: true,
    })
      .catch(renderError);
  },

  generationSeo: ({ servicesApi, consoleError, match }) => {
    const generationSlug = match.params.generation;
    if (!generationSlug) return null;

    return servicesApi
      .getGenerations({
        'filter[brand]': match.params.brand,
        'filter[model]': match.params.model,
        'filter[generation]': generationSlug,
        limit: 1,
      })
      .then(generationsData => denormalizeData(generationsData)[0])
      .catch(consoleError('generationSeo', {}));
  },

  geos({ servicesApi, consoleError, match, location }) {
    const isMoto = isMotoPath(location.pathname);

    const geosAPI = isMoto ? 'getMotoOffersGeos' : 'getOffersGeos';

    return servicesApi[geosAPI]({
      'filter[brand]': match.params.brand,
    })
      .then(denormalizeData)
      .catch(consoleError('geos', []));
  },

  offersSeo({ servicesApi, consoleError, match, location }) {
    const { brand, geo, model, generation } = match.params;
    const isMoto = isMotoPath(location.pathname);
    const vehicleType = isMoto ? 'Moto' : 'Car';
    const variationType = generation && !isMoto && 'Generation' || model && 'Model' || brand && 'Brand' || geo && 'Geo';

    const method = `get${vehicleType}Offers${variationType}Seo`;
    const seoAPI = servicesApi[method];

    return seoAPI({
      'filter[geo]': geo,
      'filter[brand]': brand,
      'filter[model]': model,
      'filter[generation]': generation,
    })
      .then(denormalizeData)
      .then(pageSeo => {
        if (!model || pageSeo.text) return pageSeo;

        const apiEndpoint = isMoto
          ? servicesApi.getMotoOffersBrandSeo
          : servicesApi.getCarOffersBrandSeo;

        return apiEndpoint({
          'filter[geo]': geo,
          'filter[brand]': brand,
        })
          .then(denormalizeData)
          .then(brandSeo => ({
            ...pageSeo,
            text: brandSeo.text,
            brandSeoTitle: brandSeo.title,
          }));
      })
      .catch(consoleError('offersSeo', {}));
  },

  brands: ({ servicesApi, consoleError, match, location }) => {
    const isLcvPage = Boolean(match.params.lcv);
    const isMoto = isMotoPath(location.pathname);
    const vehiclePrefix = isMoto ? 'moto' : 'car';

    const requestParams = {
      [`attributes[${vehiclePrefix}_brand]`]: 'base',
      'filter[brand]': match.params.brand,
      limit: 1,
      ...(isLcvPage && { 'filter[car_type]': 'lcv' }),
    };

    const brandsAPI = isMoto
      ? servicesApi.getMotoBrands
      : servicesApi.getBrands;

    return brandsAPI(requestParams)
      .then(brandsData => denormalizeData(brandsData)[0])
      .catch(consoleError('brands', []));
  },

  models({ servicesApi, consoleError, match, location }) {
    if (!match.params.brand) return undefined;

    const isMoto = isMotoPath(location.pathname);

    const modelsAPI = isMoto ? servicesApi.getMotoModels : servicesApi.getModels;

    return modelsAPI({
      'filter[brand]': match.params.brand,
      'filter[with_offers_from_geo]': match.params.geo,
    })
      .then(denormalizeData)
      .catch(consoleError('models', []));
  },
});
