import PropTypes from 'prop-types';

import Back from 'site/icons/Back';

import styles from './index.styl';

function TopPanel(props) {
  const {
    handleBackClick,
  } = props;

  return (
    <div className={styles.topPanel}>
      <div className={styles.back} onClick={handleBackClick}>
        <Back height={40} width={40} />
        <div className={styles.backTitle}>Дилеры</div>
      </div>
    </div>
  );
}

TopPanel.propTypes = {
  handleBackClick: PropTypes.func,
};

TopPanel.defaultProps = {
  handleBackClick: () => null,
};

export default TopPanel;
