import Triangle from 'site/icons/Triangle';

import styles from './index.styl';

/* eslint-disable react/prop-types */
export default function DropdownIndicator(props) {
  if (props.hasValue && props.selectProps.isClearable) return null;

  return (
    <div className={styles.dropdownIndicatorContainer}>
      <Triangle width={10} height={5} />
    </div>
  );
}
