import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import withTheme from 'core/components/theme';
import Image from 'core/components/Image';
import Link from 'core/components/Link';
import ButtonWithIcon from 'core/components/ButtonWithIcon';
import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';
import ButtonTextWrap from 'site/components/Wrappers/ButtonTextWrap';

import { withBreakpoint } from 'core/components/breakpoint';

import modelPropTypes, { photoGalleryAttributes } from 'core/utils/prop-types/model';
import getThumbnail from 'site/utils/get-thumbnail';
import { buidReleasePhotoUrl } from 'site/utils';

import Reload from 'core/components/Icon/Reload';
import ArrowRightSm from 'site/icons/ArrowRightSm';

import {
  LAYOUT_MAX_WIDTH_MOBILE,
  SLIDE_WIDTH,
  SLIDE_HEIGHT,
  SLIDE_HEIGHT_MOBILE,
} from 'site/constants';

import styles from './index.styl';

function ShowMore(props) {
  const {
    goToHandler,
    photogallery,
    match,
    theme,
    isMobile,
  } = props;

  const {
    attributes: {
      images: photogalleryImages,
    },
  } = photogallery;

  const images = photogalleryImages.slice(0, 9);

  const handleReload = () => goToHandler(0);

  const imageHeight = Math.ceil((isMobile ? SLIDE_HEIGHT_MOBILE : SLIDE_HEIGHT) / 3);
  const imageWidth = Math.ceil((isMobile ? LAYOUT_MAX_WIDTH_MOBILE : SLIDE_WIDTH) / 3);
  const link = buidReleasePhotoUrl(match.params);

  return (
    <div className={styles.showMore}>
      <style jsx>{`
        .${styles.showMore}
          &:after
            transition background ${theme.animations.hover}
            background ${theme.colors.primary250}

          &:hover
            &:after
              background ${theme.colors.primary100}
      `}</style>
      {images.map(image => {
        const { id: imageId } = image;
        const thumbnail = getThumbnail(image);

        return (
          <div className={styles.image} key={imageId}>
            <Image
              src={thumbnail}
              key={imageId}
              maxWidth={imageWidth}
              maxHeight={imageHeight}
            />
          </div>
        );
      })}
      <ThemeSwitchWithColor tone='dark'>
        <div className={styles.reload}>
          <ButtonWithIcon
            size='medium'
            onClick={handleReload}
            round
            icon={() => (
              <div className={styles.reloadIcon}>
                <Reload width={22} height={22} />
              </div>
            )}
          />
        </div>
      </ThemeSwitchWithColor>
      <Link
        type='secondary'
        to={link}
        className={styles.link}
      >
        <ThemeSwitchWithColor tone='dark'>
          <ButtonWithIcon
            icon={ArrowRightSm}
            iconPosition='right'
            size='medium'
            radius='20px'
          >
            <ButtonTextWrap>Все фото и видео</ButtonTextWrap>
          </ButtonWithIcon>
        </ThemeSwitchWithColor>
      </Link>
    </div>
  );
}

ShowMore.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
      modification: PropTypes.string,
    }),
  }),
  photogallery: modelPropTypes(photoGalleryAttributes),
  theme: PropTypes.object,
  goToHandler: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default withRouter(withBreakpoint(withTheme(ShowMore)));
