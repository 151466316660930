import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';
import Link from 'core/components/Link';
import Button from 'core/components/Button';

import Scroller from 'core/components/Scroller';

import { NegativeMobile } from 'site/components/Wrappers';

import { rubrics, JOURNAL_URL } from 'site/constants';

import styles from './index.styl';

function RubricSelector(props) {
  const {
    match: {
      params: {
        level_1: level1,
      },
    },
  } = props;

  return (
    <NegativeMobile>
      <Scroller
        data-qa='rubric-selector'
        className={styles.rubricSelector}
      >
        {rubrics.map(({ link, content }, index) => {
          const reg = new RegExp(`(${level1})$`);
          const isCurrent = reg.test(link) || (!level1 && link === JOURNAL_URL);

          return (
            <Link
              key={index}
              to={link}
              className={styles.link}
              dataQa='rubric-scroller-link'
            >
              <Button
                minWidth={0}
                size='small'
                type={isCurrent ? 'primary' : 'secondary'}
              >
                {content}
              </Button>
            </Link>
          );
        })}
      </Scroller>
    </NegativeMobile>
  );
}

RubricSelector.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      level_1: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(RubricSelector);
