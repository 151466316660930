import PropTypes from 'prop-types';

import pluralize from 'core/utils/pluralize';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import Label from 'site/components/Label';
import Divider from 'site/components/Divider';
import cardServiceHOC from 'site/components/cardService';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import styles from './index.styl';


function CardServiceModification2(props) {
  const {
    theme,
    content: {
      attributes: {
        url,
        image,
        short_name: shortName,
        name,
        is_new: isNew,
        modifications_count_catalog: modificationsCountCatalog,
        modifications_count: modificationsCount,
        usage,
      },
    },
    showAllModification,
    content,
    children,
  } = props;

  if (!content) return null;

  const cover = getThumbnail(image);
  const count = showAllModification ? modificationsCount : modificationsCountCatalog;
  const modificationsText = `${count} ${pluralize(count, ['модификация', 'модификации', 'модификаций'])}`;
  const isArchive = usage === 'archive';

  return (
    <div className='cardServiceModification2'>
      <style jsx>{`
        .${styles.title}
          color ${theme.colors.primary}
          font 700 17px/24px ${theme.fonts.text}
          transition color ${theme.animations.hover}

        .${styles.count}
          color ${theme.colors.blue2}
          font 14px/16px ${theme.fonts.text}
          transition color ${theme.animations.hover}

        .cardServiceModification2
          &:hover
            .title
              color ${theme.colors.active1}


        .${styles.image}
          opacity ${isArchive ? 0.5 : 1}
      `}</style>
      <Link
        type='secondary'
        to={url}
        className={styles.link}
      >
        <div>
          <div className={styles.title}>
            {shortName || name}
            {isNew && <Label className={styles.label} type={1} />}
            {isArchive && <><br /><Label type={3} /></>}
          </div>
          {children}
          {count > 0 && (
            <div className={styles.count}>
              {modificationsText}
            </div>
          )}
        </div>
        <div className={styles.image}>
          {cover && (
            <Image
              src={cover}
              maxWidth={110}
            />
          )}
        </div>
      </Link>
      <Divider />
    </div>
  );
}

CardServiceModification2.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
  /** Данные для карточки, соответствующие модели `carReleaseAttributes` */
  content: modelPropTypes(carReleaseAttributes),
  /** Флаг, переключающий отображение модификаций */
  showAllModification: PropTypes.bool,
};

const CardWithHOCs = cardServiceHOC(CardServiceModification2);
CardWithHOCs.displayName = 'CardServiceModification2';

export default CardWithHOCs;
export { CardServiceModification2 as StorybookComponent };
