import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import Lightning from 'site/icons/Lightning';

import styles from './index.styl';

import mapTypes from './types';

const mapColors = [
  '#FF4B24',
  '#F87B34',
  '#F6A200',
  '#FFD913',
  '#92E412',
  '#2AD20B',
  '#0CB463',
];

function Battery(props) {
  const {
    theme,
    value,
    type,
    ...otherProps
  } = props;

  if (!value) return null;

  const {
    width,
    height,
    capHeight,
    borderRadius,
    withHover,
    valueFontSize,
    innerPosition,
  } = mapTypes[type];

  const currentColor = mapColors[Math.round(value * (mapColors.length - 1) / 100)];

  return (
    <div
      className={cx(
        'battery',
        styles.battery,
        withHover && styles.withHover,
        withHover && 'withHover',
        innerPosition && styles.innerPosition,
      )}
      {...otherProps}
    >
      <style jsx>{`
        .withHover
          &:hover
            :global([id*="$"])
              fill ${theme.colors.blue2}

        .value
          color ${theme.colors.primary}
          font 500 ${valueFontSize} ${theme.fonts.text}

        .box
          width ${width}px
          height ${height}px
          background ${theme.colors.layout}
          border 2px solid ${currentColor}
          border-radius ${borderRadius}px

          &:after
            background ${currentColor}
            width ${value + '%'}

          .cap
            height ${capHeight}px
            margin-top -${capHeight / 2}px
            background ${currentColor}
            border-top-right-radius ${borderRadius}px
            border-bottom-right-radius ${borderRadius}px
      `}</style>
      <div className={styles.valueBox}>
        <div className={cx(styles.value, 'value')}>{value}</div>
        <Lightning />
      </div>
      <div className={cx(styles.box, 'box')}>
        <i className={cx(styles.cap, 'cap')} />
      </div>
    </div>
  );
}

Battery.defaultProps = {
  type: 1,
  value: 0,
};

Battery.propTypes = {
  /** Тип отображения */
  type: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  /** @ignore */
  theme: PropTypes.object,
  /** Значение "заряженности" */
  value: PropTypes.number,
};

const BatteryWithHOCs = withTheme(Battery);
BatteryWithHOCs.displayName = 'Battery';

export default BatteryWithHOCs;

export { Battery as StorybookComponent };
