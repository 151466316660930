import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { setCookie } from 'core/utils/cookie-helper';
import modelPropTypes, { geoAttributes } from 'site/utils/prop-types/model';
import requestHeadersPropTypes from 'core/utils/prop-types/request-headers';

import { GEO_ID_COOKIE } from 'core/site/constants';

import GeoContext, { DEFAULT_GEO } from '.';

/**
 * TODO: Рассмотреть возможность использовать uuid вместо quto_id
 */
function getUserGeoByQutoId(qutoGeoId, servicesApi) {
  const geoId = Number(qutoGeoId);

  if (geoId === DEFAULT_GEO.attributes.quto_id) {
    return Promise.resolve(DEFAULT_GEO);
  }

  return servicesApi.getUserGeos()
    .then(({ data }) => {
      /**
       * TODO: Фильтрацию надо сделать на беке.
       */
      return (data || []).find(item => item.attributes.quto_id === geoId)
        || DEFAULT_GEO;
    })
    .catch(() => DEFAULT_GEO);
}

class GeoProvider extends PureComponent {
  static propTypes = {
    geo: modelPropTypes(geoAttributes),
  };

  static contextTypes = {
    requestHeaders: requestHeadersPropTypes,
    servicesApi: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      geo: null,
      geoSlug: null,
    };
  }

  async componentDidMount() {
    const {
      requestHeaders: {
        'x-geo-id': X_GEO_ID,
        HTTP_X_GEO_COUNTRY,
        HTTP_X_GEO_REGION,
        HTTP_X_GEO_CITY,
      },
      servicesApi,
    } = this.context;

    const geo = X_GEO_ID
      ? await getUserGeoByQutoId(X_GEO_ID, servicesApi)
      : await servicesApi.resolveGeo({
        country: HTTP_X_GEO_COUNTRY,
        region: HTTP_X_GEO_REGION,
        city: HTTP_X_GEO_CITY,
      })
        .then(({ data }) => data)
        .catch(() => DEFAULT_GEO);
    this.setGeo(geo);
  }

  setGeo = (geo) => {
    this.setState({
      geo: geo,
      geoSlug: geo.attributes.url,
    });
  };

  updateGeo = (newQutoGeoId) => {
    getUserGeoByQutoId(newQutoGeoId, this.context.servicesApi)
      .then(newGeo => {
        this.setState({
          geo: newGeo,
          geoSlug: newGeo.attributes.url,
        });
        setCookie(GEO_ID_COOKIE, newQutoGeoId);
      });
  };

  /**
   * Возвращает атрибуты geo. Оставлено для обратной совместимости.
   * Лучше использовать напрямую объект geo
   */
  getGeoAttributes = () => this.state.geo && this.state.geo.attributes || {};

  render() {
    return (
      <GeoContext.Provider
        value={{
          geo: this.state.geo || DEFAULT_GEO,
          geoSlug: this.state.geoSlug || DEFAULT_GEO.attributes.url,
          getGeoAttributes: this.getGeoAttributes,
          defaultGeo: DEFAULT_GEO,
          updateGeo: this.updateGeo,
        }}
      >
        {this.props.children}
      </GeoContext.Provider>
    );
  }
}

export default GeoProvider;
