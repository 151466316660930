import cx from 'classnames';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import Button from 'core/components/Button';
import Link from 'core/components/Link';
import H3 from 'core/components/H3';

import styles from './index.styl';

function Exam({ theme }) {
  return (
    <div className={styles.img}>
      <style jsx>{`
        .info
          color ${theme.colors.content}
      `}</style>
      <div className={styles.text}>
        <H3 is='div'>
          <span className={styles.examText}>Экзамен</span>
        </H3>
        <p className={cx('info', styles.info)}>
          У вас будет 20 минут, чтобы ответить на 20 вопросов одного из
          официальных билетов. Все как на настоящем экзамене в ГИБДД
        </p>
        <Link to='/pdd/exam'>
          <Button className={styles.btn}>СДАТЬ ЭКЗАМЕН</Button>
        </Link>
      </div>
    </div>
  );
}

Exam.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(Exam);
