import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import EmptyWrapper from 'core/components/EmptyWrapper';
import Link from 'core/components/Link';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';
import themePropTypes from 'core/utils/prop-types/theme';

const relationships = resolveRelationships(
  ['image'],
  {},
  { image: { versions: {} } },
);

function CaptionsImage(props) {
  const {
    content,
    theme,
    isMobile,
  } = props;

  const {
    image: {
      caption,
      credits,
      credits_url: creditsUrl,
    },
  } = relationships(content);

  if (!caption && !credits) return null;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
      .creditsUrl
        text-decoration none
        color inherit
        &:visited
        &:hover
        &:active
          text-decoration none
      `}</style>
    </scope>
  );

  return (
    <EmptyWrapper>
      <style jsx>{`
        .caption
        .credits
          font 13px/1.2 ${theme.fonts.text}
          color ${theme.colors.primary500}

        .credits
          margin-top ${isMobile ? '10px' : 0}
      `}
      </style>
      <div className='caption'>{caption}</div>
      {credits && <div className='credits'>
        {creditsUrl ? (
          <Link
            className={scoped.wrapClassNames('creditsUrl')}
            to={creditsUrl}
            type='blank'
          >
            {credits}
          </Link>
        ) : credits }
      </div>}
      <scoped.styles />
    </EmptyWrapper>
  );
}

CaptionsImage.propTypes = {
  content: PropTypes.object.isRequired,
  breakpoint: breakpointPropTypes(),
  isMobile: PropTypes.bool,
  theme: themePropTypes(`{
    colors: {
      primary500,
    },
  }`),
};

export default withTheme(withBreakpoint(CaptionsImage));
