import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';

import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';
import { capitalize } from 'core/utils/string-helper';
import resolveRelationships from 'core/utils/relationships';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import AdWrapper from 'core/components/Ad/AdWrapper';
import Page from 'core/components/Page';

import HtmlWithTheme from 'site/components/HtmlWithTheme';
import TitleCatalog from 'site/components/TitleCatalog';
import WhereToBuy from 'site/components/WhereToBuy';
import { Indent } from 'site/components/Wrappers';

import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing1, ListingSpec } from 'site/components/Ads/mobile';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import Releases from './Releases';

import {
  buildSearchRequestParams,
} from 'site/utils';
import modelPropTypes, {
  carReleaseAttributes,
  carGenerationAttributes,
  seoFullDataAttributes,
} from 'site/utils/prop-types/model';

const relationships = resolveRelationships(['brand', 'model'], {}, {});

function GenerationPage(props) {
  const {
    generation,
    generationSeo,
    releases,
    isMobile,
  } = props;

  const {
    brand: {
      name: brandName,
    },
    model: {
      name: modelName,
    },
  } = relationships(generation);

  const brand = get(generation, 'relationships.brand.data');

  const {
    name: generationName,
    seo_text: seoText,
  } = generation.attributes;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const title = `${capitalize(brandName)} ${modelName}, ${generationName}`;

  const { title: seoTitle, description: seoDescription } = generationSeo;

  return (
    <Page title={seoTitle} description={seoDescription}>
      <AdWrapper bottom={vertical}>
        <TitleCatalog brand={brand} title={title} />
        <Indent bottom={vertical} />

        <Releases releases={releases} />
        <Indent bottom={vertical} />

        <Desktop><SuperFooter /></Desktop>
        <Mobile><Listing1 /></Mobile>

        <WhereToBuy brandName={brandName} />
        <Indent bottom={vertical} />

        <HtmlWithTheme html={seoText} />
        <Indent bottom={vertical} />

        <Mobile><ListingSpec /></Mobile>
      </AdWrapper>
    </Page>
  );
}

const dataProvider = resolve({
  generation: ({ servicesApi, renderError, match }) => {
    return servicesApi
      .getGenerations({
        'filter[brand]': match.params.brand,
        'filter[model]': match.params.model,
        'filter[generation]': match.params.generation,
        'attributes[car_generation]': 'base,seo',
        include: 'car_brand,car_model',
        'relations[car_generation]': 'brand,model',
        'attributes[car_brand]': 'base,image',
        limit: 1,
      })
      .then(generationsData => denormalizeData(generationsData)[0])
      .then(rejectEmptyResult)
      .catch(renderError);
  },

  generationSeo: ({ servicesApi, consoleError, match }) => {
    const { brand, model, generation } = match.params;

    return servicesApi
      .getCarGenerationSeo({
        'filter[brand]': brand,
        'filter[model]': model,
        'filter[generation]': generation,
      })
      .then(denormalizeData)
      .catch(consoleError('generationSeo', {}));
  },

  releases: ({ servicesApi, consoleError, match, location }) => {
    const { brand, model, generation } = match.params;

    return servicesApi
      .getReleases({
        ...buildSearchRequestParams(location.search),
        'attributes[car_release]': 'base,image,stats',
        'relations[car_release]': 'body',
        'include': 'car_submodel',
        'filter[brand]': brand,
        'filter[model]': model,
        'filter[generation]': generation,
      })
      .then(denormalizeData)
      .catch(consoleError('releases', []));
  },
});

GenerationPage.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
      generation: PropTypes.string.isRequired,
    }),
  }),
  generation: modelPropTypes(carGenerationAttributes).isRequired,
  generationSeo: seoFullDataAttributes.isRequired,
  releases: PropTypes.arrayOf(modelPropTypes(carReleaseAttributes)).isRequired,
};

export default withPageHocs(dataProvider)(withBreakpoint(GenerationPage));
