import stylesAtomsFactory from 'core/themes/styles-atoms';
import stylesAtomsMobileFactory from 'core/themes/styles-atoms-mobile.js';
import deepmerge from 'core/libs/deepmerge';

import baseTheme from './base';
import baseMobileTheme from './mobile';
import darkBaseTheme from './darkBase';
import darkBaseMobile from './darkBaseMobile';

import {
  lightBlueColors,
  darkBlueColors,
  lightRedColors,
  darkRedColors,
  darkColors,
} from './colors';

const base = atoms => stylesAtomsFactory(baseTheme(atoms));
const darkBase = (atoms) => stylesAtomsFactory(baseTheme(atoms));

export const lightBlue = base({ colors: lightBlueColors });
export const darkBlue = darkBase({ colors: darkBlueColors, ...darkBaseTheme(darkBlueColors) });
export const lightRed = base({ colors: lightRedColors });
export const darkRed = darkBase({ colors: darkRedColors, ...darkBaseTheme(darkRedColors) });
export const dark = darkBase({ colors: darkColors, ...darkBaseTheme(darkColors) });

export const lightBlueMobile = stylesAtomsMobileFactory(lightBlue, baseMobileTheme({ colors: lightBlueColors }));
export const darkBlueMobile = stylesAtomsMobileFactory(darkBlue, baseMobileTheme({ colors: darkBlueColors }));
export const lightRedMobile = stylesAtomsMobileFactory(lightRed, baseMobileTheme({ colors: lightRedColors }));
export const darkRedMobile = stylesAtomsMobileFactory(darkRed, baseMobileTheme({ colors: darkRedColors }));
export const darkMobile = stylesAtomsMobileFactory(
  dark,
  deepmerge(
    baseMobileTheme({ colors: darkColors }),
    darkBaseMobile({ colors: darkColors })
  )
);
