import PropTypes from 'prop-types';

import { get } from 'core/libs/lodash';

import { capitalize } from 'core/utils/string-helper';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import AdWrapper from 'core/components/Ad/AdWrapper';
import Page from 'core/components/Page';

import TitleCatalog from 'site/components/TitleCatalog';
import HtmlWithTheme from 'site/components/HtmlWithTheme';
import WhereToBuy from 'site/components/WhereToBuy';
import { Indent } from 'site/components/Wrappers';

import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing1, ListingSpec } from 'site/components/Ads/mobile';

import { isMotoPath } from 'site/utils';

import modelPropTypes, {
  carModelAttributes,
  carReleaseAttributes,
  carGenerationAttributes,
  motoYearsAttributes,
  seoFullDataAttributes,
} from 'site/utils/prop-types/model';

import Releases from './Releases';

import dataProvider from './modelDataProvider';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


function ModelPage(props) {
  const {
    model,
    modelSeo,
    releases,
    generations,
    isMobile,
    years,
    location: {
      pathname,
    },
  } = props;

  const { name: modelName } = model.attributes;

  const {
    title: seoTitle,
    description: seoDescription,
  } = modelSeo;

  const brand = get(model, 'relationships.brand.data');

  const {
    name: brandName = '',
  } = brand.attributes || {};

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Page title={seoTitle} description={seoDescription}>
      <AdWrapper bottom={vertical}>
        <TitleCatalog brand={brand} title={`${capitalize(brandName)} ${modelName}`} />
        <Indent bottom={vertical} />

        <Releases
          releases={releases}
          generations={generations}
          years={years}
          isMoto={isMotoPath(pathname)}
        />
        <Indent bottom={vertical} />

        <Desktop><SuperFooter /></Desktop>
        <Mobile><Listing1 /></Mobile>

        <WhereToBuy brandName={brandName} />
        <Indent bottom={vertical} />

        <HtmlWithTheme html={seoDescription} />
        <Indent bottom={vertical} />

        <Mobile><ListingSpec /></Mobile>
      </AdWrapper>
    </Page>
  );
}

ModelPage.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  location: PropTypes.object,

  /** @ignore */
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
      brand: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
    }),
  }),
  model: modelPropTypes(carModelAttributes).isRequired,
  modelSeo: seoFullDataAttributes.isRequired,
  releases: PropTypes.arrayOf(modelPropTypes(carReleaseAttributes)).isRequired,
  generations: PropTypes.arrayOf(modelPropTypes(carGenerationAttributes)).isRequired,
  years: PropTypes.arrayOf(modelPropTypes(motoYearsAttributes)).isRequired,
};

export default withPageHocs(dataProvider)(withBreakpoint(ModelPage));
