import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';


export const releaseCommonProviders = {
  isTopicsByBrandExist: ({ bebopApi, match }) => {
    const {
      params: {
        brand,
      },
    } = match;

    return bebopApi
      .getTag({
        tag_slug: brand,
      })
      .then(() => true)
      .catch(() => false);
  },
};

export default resolve({
  release: ({ servicesApi, renderError, match }) => {
    const {
      params: {
        brand,
        model,
        generation,
        body,
      },
    } = match;

    return (
      servicesApi
        .getReleases({
          'filter[brand]': brand,
          'filter[model]': model,
          'filter[generation]': generation,
          'filter[body]': body,
          include: 'car_brand,car_feature',
          limit: 1,
          'relations[car_release]': 'brand,features',
          'attributes[car_release]': 'base,image,media,seo,extended,stats',
          'attributes[car_brand]': 'base,image',
        })
        .then(releases => denormalizeData(releases)[0])
        .then(rejectEmptyResult)
        .catch(renderError)
    );
  },

  seo: ({ servicesApi, consoleError, match: { params } }) => {
    return (
      servicesApi
        .getReleasesSeo({
          'filter[brand]': params.brand,
          'filter[model]': params.model,
          'filter[generation]': params.generation,
          'filter[submodel]': params.body,
        })
        .then(denormalizeData)
        .catch(consoleError('seo', {}))
    );
  },

  modifications: ({ servicesApi, consoleError, match }) => {
    const {
      params: {
        brand,
        model,
        generation,
        body,
      },
    } = match;

    return (
      servicesApi
        .getModifications({
          'filter[brand]': brand,
          'filter[model]': model,
          'filter[generation]': generation,
          'filter[body]': body,
          include: 'car_modification_completion,car_release',
          'attributes[car_modification]': 'base,extended,stats,prices',
          'attributes[car_release]': 'stats',
          'relations[car_modification]': 'completion,release',
          'relations[car_release]': 'stats',
          sort: '-price',
        })
        .then(denormalizeData)
        .catch(consoleError('modifications', []))
    );
  },

  bodies: ({ servicesApi, consoleError, match }) => {
    const {
      params: {
        brand,
        model,
        generation,
      },
    } = match;

    return (
      servicesApi.getReleases({
        'filter[brand]': brand,
        'filter[model]': model,
        'filter[generation]': generation,
        'attributes[car_release]': 'base,image',
      })
        .then(denormalizeData)
        .catch(consoleError('bodies', []))
    );
  },
  ...releaseCommonProviders,
});
