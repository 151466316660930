import { Fragment, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Divider from 'site/components/Divider';
import H3 from 'core/components/H3';
import Link from 'core/components/Link';
import bindProps from 'core/components/bindProps';

import resolveRelationships from 'core/utils/relationships';
import { carBrandAttributes } from 'core/utils/prop-types/model';

import cardServiceHOC from 'site/components/cardService';
import { Indent, NegativeMobile } from 'site/components/Wrappers';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import ArrowRightSm from 'site/icons/ArrowRightSm';
import IconMap from 'site/icons/Map';
import IconPointer from 'site/icons/Pointer';
import DealerInfoSberAuto from  './DealerInfoSberAuto';

import CardDealerInfoPhone from 'site/cardsService/CardDealerInfoPhone';

import modelPropTypes, {
  dealerAttributes,
  geoAttributes,
  dealerNetworkAttributes,
} from 'site/utils/prop-types/model';
import relationshipsPropTypes from 'site/utils/prop-types/relationships';

import styles from './index.styl';

const relationships = resolveRelationships(['dealer_network', 'car_brands', 'geo', 'geo_parent'], null, {
  dealer_network: {},
  car_brands: [],
  geo: {},
  geo_parent: {},
});

const DividerType5 = bindProps({ type: 5 })(Divider);


function DealerInfo(props) {
  const [isPhoneHidden, setPhoneHidden] = useState(true);

  if (!props.content) return null;

  const {
    content: {
      attributes: {
        phones,
        address,
        is_official: isOfficial,
        name: dealerName,
        is_online: isOnline,
      },
    },
    showHeader,
    isMobile,
    theme,
    isMoto,
    externalUrl,
  } = props;

  const networkId =
    props.content.relationships
    && props.content.relationships.dealer_network
    && props.content.relationships.dealer_network.data.id;


  const {
    dealer_network: {
      name: networkName,
    },
    car_brands: carBrands,
    geo: {
      url: geoSlug,
    },
    geo_parent: {
      name: city,
    },
  } = relationships(props.content);

  const visiblePhones = isPhoneHidden && phones.length > 1
    ? phones.slice(0, 1)
    : phones;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const baseUrl = '/' + [isMoto && 'moto', 'dealers', geoSlug].filter(Boolean).join('/');
  const addressText = (city ? city + '. ' : '') + address;

  return (
    <NegativeMobile>
      <style jsx>{`
        .${styles.mapRow}
          .${styles.address}
            color ${theme.colors.primary}
        .addressSmall
          font: ${theme.texts.bodySmall.font}
      `}</style>

      <div className={styles.dealerInfoWrapper}>
        <div className={cx(styles.topRowsWrapper, isOnline && styles._isOnline)}>
          {isOnline ? (
            <DealerInfoSberAuto externalUrl={externalUrl} />
          ) : (
            <Fragment>
              <div className={styles.topRows}>
                {showHeader && (
                  <Fragment>
                    <H3>{dealerName}</H3>
                    <Indent bottom={vertical} />
                  </Fragment>
                )}
              </div>
              {visiblePhones.map((phone, i) => (
                <Fragment key={i}>
                  <CardDealerInfoPhone
                    content={phone}
                    isHidden={isPhoneHidden && i === 0}
                    handleButtonClick={() => setPhoneHidden(false)}
                  />
                  <Indent bottom={vertical / 2} />
                </Fragment>
              ))}
              {phones.length > 0 && <DividerType5 className={styles.divider} />}
              {!!(networkName && networkId) && (
                <Fragment>
                  <div className={styles.row}>
                    {networkName}
                    {geoSlug && (
                      <Link
                        to={`${baseUrl}?search=${networkId}`}
                        className={styles.link}
                        type='secondary'
                      >
                        Салоны сети
                        <ArrowRightSm className={styles.linkArrow} />
                      </Link>
                    )}
                  </div>
                  <DividerType5 className={styles.divider} />
                </Fragment>
              )}
              {carBrands.map((item, i) => {
                const {
                  attributes: {
                    name: brandName,
                    slug: brandSlug,
                  },
                } = item;
                const notLastItem = i < carBrands.length - 1;

                return (
                  <Fragment key={brandName}>
                    <div className={styles.row}>
                      {isOfficial ? `Официальный дилер ${brandName}` : `Дилер ${brandName}`}
                      {geoSlug && (
                        <Link
                          to={`${baseUrl}/${brandSlug}`}
                          className={styles.link}
                          type='secondary'
                        >
                          Другие дилеры
                          <ArrowRightSm className={styles.linkArrow} />
                        </Link>
                      )}
                    </div>
                    {notLastItem && <DividerType5 className={styles.divider} />}
                  </Fragment>
                );
              })}
            </Fragment>
          )}
        </div>
        <DividerType5 />
        {!isOnline && (
          <Link to={'https://yandex.ru/maps/?text=' + encodeURI(addressText)}>
            <div className={styles.mapRow}>
              <IconMap
                className={styles.mapIcon}
                height='100%'
                preserveAspectRatio='none'
              />
              <IconPointer
                className={styles.pointer}
                height='40'
                width='25'
              />
              <span className={cx(
                styles.address,
                (isMobile && addressText.length > 70) && 'addressSmall')}
              >
                {addressText}
              </span>
            </div>
          </Link>
        )}
      </div>
    </NegativeMobile>
  );
}

DealerInfo.propTypes = {
  /**
   * Дилер со связями.
   * Элементы блока отображаются в зависимости от наличия связей.
   */
  content: modelPropTypes(
    dealerAttributes,
    relationshipsPropTypes({
      geo: modelPropTypes(geoAttributes),
      geo_parent: modelPropTypes(geoAttributes),
      car_brands: modelPropTypes(carBrandAttributes),
      dealer_network: modelPropTypes(dealerNetworkAttributes),
    })),

  /** Показывать ли заголовок с названием дилера. */
  showHeader: PropTypes.bool,

  /** @ignore */
  isMobile: PropTypes.bool.isRequired,

  externalUrl: PropTypes.string,

  /** @ignore */
  theme: PropTypes.object,

  isMoto: PropTypes.bool,
};

const DealerInfoWithHOCs = cardServiceHOC(DealerInfo);
DealerInfoWithHOCs.displayName = 'DealerInfo';

export default DealerInfoWithHOCs;
export { DealerInfo as StorybookComponent };
