import cx from 'classnames';

import themePropTypes from 'core/utils/prop-types/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import cardServiceHOC from 'site/components/cardService';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { reviewAttributes } from 'site/utils/prop-types/model';

import styles from './index.styl';

const WIDTH = 102;
const HEIGHT = 63;

function CardServiceReview3(props) {
  if (!props.content) return null;

  const {
    theme,
    content: {
      attributes: {
        name,
        image,
        url = '/',
      },
    },
  } = props;

  const cover = getThumbnail(image, '3/2');

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          color ${theme.colors.primary}

          &:hover
          &:focus
            color ${theme.colors.active1}
      `}</style>
    </scope>
  );

  return (
    <div className={cx('cardServiceReview3', styles.cardServiceReview3)}>
      <style jsx>{`
        .cardServiceReview3
          background ${theme.colors.content}
          border 1px solid ${theme.colors.primary100}
          box-shadow 0px 2px 6px ${theme.colors.primary100}
      `}</style>
      <Link
        to={url}
        type='secondary'
        className={cx(scope.wrapClassNames('link'), styles.link)}
      >
        <div className={styles.title}>{name}</div>
        <div className={styles.image}>
          <Image
            src={cover}
            width={WIDTH}
            aspectRatio={WIDTH / HEIGHT}
          />
        </div>
      </Link>
      <scope.styles />
    </div>
  );
}

CardServiceReview3.propTypes = {
  /** Данные для карточки, соответствующие модели `reviewAttributes` */
  content: modelPropTypes(reviewAttributes),
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      primary100,
    },
  }`),
};

const CardWithHOCs = cardServiceHOC(CardServiceReview3);
CardWithHOCs.displayName = 'CardServiceReview3';

export default CardWithHOCs;
export { CardServiceReview3 as StorybookComponent };
