import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';

import Sponsored from 'site/components/Ads/Sponsored';
import { Indent } from 'site/components/Wrappers';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import CaptionsImage from './CaptionsImage';
import CaptionsGallery from './CaptionsGallery';
import CaptionsVideo from './CaptionsVideo';

const topicTypeMap = {
  news: CaptionsImage,
  article: CaptionsImage,
  gallery: CaptionsGallery,
  video: CaptionsVideo,
};

function CaptionsMedia({ content, isMobile }) {
  const topicType = content.attributes.topic_type;
  const Caption = topicTypeMap[topicType] || CaptionsImage;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <AdWrapper bottom={vertical}>
      <Caption content={content} />
      <Indent top={isMobile ? 4 : 24} />
      <Sponsored />
    </AdWrapper>
  );
}

CaptionsMedia.propTypes = {
  isMobile: PropTypes.bool,
  content: PropTypes.object.isRequired,
};

export default withBreakpoint(CaptionsMedia);
