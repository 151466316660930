import { Fragment } from 'react';
import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import Feed from 'core/components/Feed';

import { Indent } from 'site/components/Wrappers';
import Divider from 'site/components/Divider';
import TitleUpperCase from 'site/components/TitleUpperCase';
import NothingFound from 'site/components/NothingFound';

import CardModification1WithPrice from 'site/cardsService/CardServiceModification1/cards/CardModification1WithPrice';
import CardModification2WithPrice from 'site/cardsService/CardServiceModification2/cards/CardModification2WithPrice';

import modelPropTypes, {
  carGenerationAttributes,
  carReleaseAttributes,
  motoYearsAttributes,
} from 'site/utils/prop-types/model';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


const requiredPayloadImports = ['generation', 'year'];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  generation: {},
  year: {},
});


function Releases(props) {
  const {
    releases,
    generations,
    isMobile,
    years,
    isMoto,
  } = props;

  if (releases.length === 0 || (years.length === 0 && generations.length === 0)) return <NothingFound />;

  const releaseRelationName = isMoto ? 'year' : 'generation';

  const releasesGroups = releases.reduce((groups, release) => {
    const {
      [`${releaseRelationName}`]: { slug },
    } = relationships(release);

    if (groups[slug]) {
      groups[slug].push(release);
    } else {
      groups[slug] = [release];
    }

    return groups;
  }, {});

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const groupEntities = isMoto ? years : generations;

  return (
    <Fragment>
      <Mobile>
        <Divider />
      </Mobile>
      {groupEntities.map(entity => {
        const {
          attributes: {
            slug,
            name,
          },
        } = entity;

        /**
         * Делаем проверку, т.к. не для всех поколений могут быть данные о релизе.
         * Например: Audi RS6 C6
         */
        if (!releasesGroups[slug]) return null;

        return (
          <Indent bottom={vertical} key={slug}>
            <TitleUpperCase title={name} />
            <Feed
              content={releasesGroups[slug]}
              card={isMobile ? CardModification2WithPrice : CardModification1WithPrice}
            />
          </Indent>
        );
      })}
    </Fragment>
  );
}

Releases.propTypes = {
  isMobile: PropTypes.bool,
  releases: PropTypes.arrayOf(modelPropTypes(carReleaseAttributes)).isRequired,
  generations: PropTypes.arrayOf(modelPropTypes(carGenerationAttributes)).isRequired,
  years: PropTypes.arrayOf(modelPropTypes(motoYearsAttributes)).isRequired,
  isMoto: PropTypes.bool,
};

export default withBreakpoint(Releases);
