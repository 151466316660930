import { Fragment } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import { compose } from 'core/libs/recompose';
import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';
import { denormalizeData } from 'core/utils/api';

import {
  withNonPureBreakpoint,
} from 'core/components/breakpoint';

import {
  generateSelectOptions,
  initSelectValue,
  initRangeValue,
  initCheckboxValue,
  initRadioValue,
} from 'site/utils/forms';

import BaseForm from 'site/components/BaseForm';
import FormModal from 'site/components/FormModal';
import withGeo from 'site/components/GeoContext/withGeo';

import Form from './Form';

import {
  RANGES,
  FULL_FILTER_FIELDS,
  PATHNAME_FIELDS,
  EXCLUDED_STATE_FIELDS,
  DEPENDENCIES,
  BASE_PATH,
} from './constants';


class MotoOffersForm extends BaseForm {
  constructor(props) {
    super(props);

    this.formOptions = this.getFormOptions({}, []);

    this.state = {
      ...this.getFormStateFromLocation({}),
      models: [],
      geos: [],
    };
  }

  dependencies = DEPENDENCIES;

  ranges = RANGES;

  pathnameFields = PATHNAME_FIELDS;
  excludedFields = EXCLUDED_STATE_FIELDS;

  fullFilterFields = FULL_FILTER_FIELDS;

  basePath = BASE_PATH;


  get emptyPath() {
    return BASE_PATH + '/' + this.props.geoSlug;
  }

  componentDidMount() {
    const { servicesApi } = this.context;

    const {
      geoSlug,
      match: {
        params: {
          brand,
        },
      },
    } = this.props;

    Promise.allSettled([
      servicesApi
        .getMotoBrands({
          'filter[with_offers_from_geo]': geoSlug,
        })
        .then(denormalizeData),

      !brand ? [] : servicesApi
        .getMotoModels({
          'filter[with_offers_from_geo]': geoSlug,
          'filter[brand]': brand,
        })
        .then(denormalizeData),

      servicesApi.getMotoOffersDictionaries(),

      servicesApi
        .getMotoOffersGeos({
          'filter[brand]': brand,
        })
        .then(denormalizeData),
    ])
      .then(results => results.map(i => i.value))
      .then(([brands, models, dictionaries, geos]) => ({
        brands: brands || [],
        models: models || [],
        dictionaries: dictionaries || {},
        geos: geos || [],
      }))
      .then(data => {
        const { brands, models, dictionaries, geos } = data;

        this.ranges = {
          ...dictionaries.ranges,
          ...RANGES,
          year: dictionaries.ranges.year,
          price: dictionaries.ranges.price,
        };

        /**
         * TODO
         * Переименовать generateSelectOptions так как используется для всех типов контролов и поправить во всех формах
         * */
        // Формирует неизменяемы опции для контролов
        this.formOptions = this.getFormOptions(dictionaries, brands);

        this.setState({
          ...this.getFormStateFromLocation({ models, geos }),
          models,
          geos,
        });
      })
      .catch(e => console.error(e));
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      geoSlug,
    } = this.props;

    const {
      brand,
    } = this.state;

    const {
      servicesApi,
    } = this.context;

    const {
      geoSlug: prevGeo,
    } = prevProps;

    const {
      brand: prevBrand,
    } = prevState;

    if (prevBrand?.value === brand?.value && prevGeo === geoSlug) return;

    Promise.allSettled([
      brand
        ? (prevBrand?.value !== brand?.value || prevGeo !== geoSlug) && servicesApi
          .getMotoModels({
            'filter[with_offers_from_geo]': geoSlug,
            'filter[brand]': brand?.value,
          })
          .then(denormalizeData)
        : [],

      (prevBrand?.value !== brand?.value) && servicesApi
        .getMotoOffersGeos({
          'filter[brand]': brand?.value,
        })
        .then(denormalizeData),
    ])
      .then(results => results.map(i => i.value))
      .then(([models, geos]) => {
        this.setState({
          ...models.length && { models },
          ...geos && { geos },
        });
      })
      .catch(e => console.error(e));
  }

  getFormStateFromLocation = ({ models = [], geos = [] }) => {
    const {
      match: {
        params,
      },
      location,
      geo: defaultGeo,
    } = this.props;

    /**
     * Если значение не найдено, то устанавливаем его в null вместо
     * undefined, потому что undefined при сбросе фильтров не воспринимается
     * для некоторых контролов и там остается предыдущее установленное
     * значение.
     */
    const filteredBrands = this.formOptions?.filteredBrands;
    const brand = filteredBrands?.find(item => (
      item.attributes.slug === params.brand
    )) || null;

    const filteredModels = this.getModelOptions(models, brand);
    const model = brand && filteredModels?.find(item => (
      item.attributes.slug === params.model
    )) || null;

    const formValuesFromUrl = queryString.parse(location.search);

    const filteredGeos = this.getGeoOptions(geos);
    const filteredDefaultGeo = this.getDefaultGeoOption([defaultGeo])[0];

    const geo = filteredGeos?.find(item => (
      item.attributes.url === params.geo
    )) || filteredDefaultGeo;

    return {
      brand,
      model,
      geo,
      price: initRangeValue(
        'price',
        formValuesFromUrl,
        this.ranges.price
      ),
      type: initSelectValue(
        'type',
        formValuesFromUrl,
        this.formOptions.filteredTypes,
      ),
      class: initSelectValue(
        'class',
        formValuesFromUrl,
        this.formOptions.filteredClasses,
      ),
      volume: initRangeValue(
        'volume',
        formValuesFromUrl,
        this.ranges.volume
      ),
      power: initRangeValue(
        'power',
        formValuesFromUrl,
        this.ranges.power
      ),
      configurate: initRadioValue(
        'configurate',
        formValuesFromUrl,
      ),
      transmission: initRadioValue(
        'transmission',
        formValuesFromUrl,
      ),
      transfer: initRadioValue(
        'transfer',
        formValuesFromUrl,
      ),
      cooling: initRadioValue(
        'cooling',
        formValuesFromUrl,
      ),
      cylinders: initRangeValue(
        'cylinders',
        formValuesFromUrl,
        this.ranges.cylinders
      ),
      abs: initCheckboxValue(
        'abs',
        formValuesFromUrl,
      ),
      condition: initCheckboxValue(
        'condition',
        formValuesFromUrl,
      ),
      control: initRadioValue(
        'control',
        formValuesFromUrl
      ),
      year: initRangeValue(
        'year',
        formValuesFromUrl,
        this.ranges.year
      ),
    };
  };

  getFormOptions = (dictionaries, brands) => ({
    filteredBrands: generateSelectOptions(brands, { value: 'attributes.slug' }),
    filteredTypes: generateSelectOptions(dictionaries.type, { value: 'attributes.slug' }),
    filteredClasses: generateSelectOptions(dictionaries.class, { value: 'attributes.slug' }),
    filteredConfigurate: generateSelectOptions(dictionaries.configurate, { label: 'title', value: 'value' }),
    filteredTransmission: generateSelectOptions(dictionaries.transmission, { label: 'title', value: 'value' }),
    filteredTransfer: generateSelectOptions(dictionaries.transfer, { label: 'title', value: 'value' }),
    filtiredCooling: generateSelectOptions(dictionaries.cooling, { label: 'title', value: 'value' }),
    filteredAbs: generateSelectOptions(dictionaries.abs, { label: 'title', value: 'value' }),
    filteredCondition: generateSelectOptions(dictionaries.condition, { label: 'title', value: 'value' }),
    filteredControl: generateSelectOptions(dictionaries.control, { label: 'title', value: 'value' }),
  });

  getDefaultGeoOption = memoize(
    (geos = []) => generateSelectOptions(geos, { value: 'attributes.url' })
  );

  getGeoOptions = memoize(
    (geos = []) => generateSelectOptions(geos, { value: 'attributes.url' })
  );

  getModelOptions = memoize(
    (models = []) => generateSelectOptions(models, { value: 'attributes.slug' })
  );

  render() {
    const {
      isMobile,
      location,
      match: {
        params: {
          release,
        },
      },
      geo: defaultGeo,
    } = this.props;

    const {
      brand,
      models,
      geos,
    } = this.state;

    const filteredDefaultGeo = this.getDefaultGeoOption([defaultGeo])[0];
    const filteredGeos = this.getGeoOptions(geos);

    // Зависимые опции
    const filteredModels = this.getModelOptions(models, brand);

    const FormWrapper = this.isFullFormOpened ? FormModal : Fragment;

    return (
      <FormWrapper>
        <Form
          formState={{ ...this.state }}
          formOptions={{
            filteredGeos,
            filteredDefaultGeo,
            filteredModels,
            ...this.formOptions,
          }}
          isMobile={isMobile}
          onChange={this.handleControlChange}
          location={location}
          navigateToNewUrl={this.navigateToNewUrl}
          toggleFullForm={this.toggleFullForm}
          resetAllForm={this.resetAllForm}
          allFiltersCount={this.allFiltersCount}
          fullFiltersCount={this.fullFiltersCount}
          isFiltersDisabled={!!release && !this.isFullFormOpened}
          ranges={this.ranges}
        />
      </FormWrapper>
    );
  }
}

MotoOffersForm.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

MotoOffersForm.contextTypes = {
  servicesApi: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withGeo,
  withNonPureBreakpoint,
)(MotoOffersForm);
