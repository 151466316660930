import { useContext } from 'react';
import PropTypes from 'prop-types';

import { get } from 'core/libs/lodash';
import { compose } from 'core/libs/recompose';
import { withRouter } from 'core/libs/router';

import resolveRelationships from 'core/utils/relationships';

import modelPropTypes, { carModificationAttributes } from 'site/utils/prop-types/model';

import withTheme from 'core/components/theme';
import Dropdown from 'core/components/Dropdown';

import Battery from 'site/components/Battery';
import ReleaseNavigation from 'site/components/ReleaseNavigation';
import ButtonCompare from 'site/components/ButtonCompare';

import styles from './index.styl';

import Complectation from '../Complectation';
import Prices from '../Prices';

import { CARINFOBAR_DESKTOP_HEIGHT } from 'site/constants';

import { ConfiguratorContext } from 'site/components/ConfiguratorProvider';

const relationships = resolveRelationships(['release', 'brand'], {}, {});

function DesktopView(props) {
  const configurations = useContext(ConfiguratorContext);

  const {
    match,
    theme: {
      colors: {
        content,
        primary500,
      },
    },
    modification,
  } = props;

  const {
    params: {
      brand: brandSlug,
      model: modelSlug,
      generation: generationSlug,
      body: bodySlug,
      modification: modificationSlug,
    },
  } = match;

  if (!modification) return null;

  const {
    release,
    brand: {
      name: brandName,
    },
  } = relationships(modification);

  const {
    id: modificationId,
    attributes: {
      name: modificationName,
      battery: baseBattery,
      price: basePrice,
      have_options: haveOptions,
    },
    type,
  } = modification;

  const isPropertyPage = Boolean(match.params.property);

  const { configurationReady } = configurations;
  const battery = get(
    configurations,
    [modificationId, 'configuration', 'battery'],
    baseBattery
  );

  const releaseLink = `/${brandSlug}/${modelSlug}/${generationSlug}/${bodySlug}`;

  return (
    <div className={styles.carInfoBar}>
      <style jsx>{`
        .${styles.carInfoBar}
          background ${content}
          height ${CARINFOBAR_DESKTOP_HEIGHT}px

        .${styles.modificationTitle}
          color ${primary500}
      `}</style>
      <ReleaseNavigation
        release={release}
        brandName={brandName}
        backLink={(isPropertyPage && haveOptions) ? releaseLink + `/${modificationSlug}` : releaseLink}
      />
      <div>
        <div className={styles.modificationTitle}>Модификация</div>
        <div>{modificationName}</div>
      </div>
      <Battery type={3} value={battery.total} />
      {configurationReady && (
        <Dropdown
          showIcon={false}
          contentPosition='right'
          anchor={<Prices modificationId={modificationId} basePrice={basePrice} />}
          className={styles.indent}
        >
          <div className={styles.complectation}>
            <Complectation modification={modification} />
          </div>
        </Dropdown>
      )}
      <ButtonCompare
        modelId={modificationId}
        transportType={type}
        customTextButton={['убрать', 'сравнить']}
      />
    </div>
  );
}

DesktopView.propTypes = {
  isMobile: PropTypes.bool,
  modification: modelPropTypes(carModificationAttributes),
  theme: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
      modification: PropTypes.string,
      property: PropTypes.string,
    }),
  }),
};

export default compose(
  withRouter,
  withTheme,
)(DesktopView);
