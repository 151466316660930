import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import { capitalize } from 'core/utils/string-helper';

import renameThemeProp from 'site/components/renameThemeProp';

import styles from './index.styl';


function Control(props) {
  const {
    /* eslint-disable react/prop-types */
    children,
    innerProps,
    innerRef,
    isDisabled,
    isFocused,
    hasValue,
    getValue,
    /* eslint-enable react/prop-types */
    theme: {
      controls: {
        chip: chipAtoms,
      },
    },
  } = props;

  /**
   * Устанавливаем нативный title при наведении на элемент.
   */
  const title = getValue()
    .map(({ label }) => capitalize(label))
    .join(', ');

  return (
    <div
      title={title}
      ref={innerRef}
      {...innerProps}
      className={cx(
        'control',
        styles.control,
        hasValue && '_hasValue',
        isFocused && '_active',
        isDisabled && styles.disabled
      )}
    >
      <style jsx>{`
        .control
          color ${chipAtoms.idle.color}
          background-color ${chipAtoms.idle.backgroundColor}
          border-color ${chipAtoms.idle.borderColor}
          &:hover
            color ${chipAtoms.hover.color}
            background-color ${chipAtoms.hover.backgroundColor}
            border-color ${chipAtoms.hover.borderColor}
          &._active
            color ${chipAtoms.active.color}
            background-color ${chipAtoms.active.backgroundColor}
            border-color ${chipAtoms.active.borderColor}
          &._hasValue
            color ${chipAtoms.hasValue.color}
            background-color ${chipAtoms.hasValue.backgroundColor}
            border-color ${chipAtoms.hasValue.borderColor}
          &._hasValue:hover
            color ${chipAtoms.hasValueHover.color}
            background-color ${chipAtoms.hasValueHover.backgroundColor}
            border-color ${chipAtoms.hasValueHover.borderColor}
          &.${styles.disabled}
            color ${chipAtoms.disabled.color}
            background-color ${chipAtoms.disabled.backgroundColor}
            border-color ${chipAtoms.disabled.borderColor}
      `}</style>
      {children}
    </div>
  );
}

Control.propTypes = {
  theme: PropTypes.object,
};

export default renameThemeProp(withTheme(Control));

