import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { denormalizeData } from 'core/utils/api';

import { SimpleLoader } from 'core/components/Loader';
import { withNonPureBreakpoint } from 'core/components/breakpoint';

import Drum from 'core/components/Drum';
import { Indent } from 'site/components/Wrappers';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import CardServiceRelease2 from 'site/cardsService/CardServiceRelease2';


function Releases({ isMobile, tabRequestParams }, { servicesApi }) {
  const [releases, setReleases] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let ignore = false;
    servicesApi
      .getReleases({ ...tabRequestParams })
      .then(denormalizeData)
      .then(data => {
        if (!ignore) {
          setReleases(data);
          setLoading(false);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setLoading(false);
        }
      });
    return () => {
      ignore = true;
    };
  }, [tabRequestParams, servicesApi]);

  if (loading) return <SimpleLoader />;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const horizontal = isMobile ? 15 : 0;

  const justOneRelease = releases.length === 1;

  if (justOneRelease) {
    return (
      <Indent
        top={vertical}
        bottom={vertical}
        left={horizontal}
        right={horizontal}
      >
        <CardServiceRelease2 content={releases[0]} />
      </Indent>
    );
  }

  return (
    <Indent
      top={vertical}
      bottom={vertical}
      left={horizontal}
      right={horizontal}
    >
      {justOneRelease
        ? <CardServiceRelease2 content={releases[0]} />
        : (
          <Drum
            grid
            itemWidth='100%'
            itemWidthMobile='100%'
            spaceBetween={20}
            content={releases}
            card={CardServiceRelease2}
          />
        )
      }
    </Indent>
  );
}

Releases.propTypes = {
  tabRequestParams: PropTypes.object,
  isMobile: PropTypes.bool,
};

Releases.contextTypes = {
  servicesApi: PropTypes.object.isRequired,
};

export default withNonPureBreakpoint(Releases);
