import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { denormalizeData } from 'core/utils/api';

import { withBreakpoint } from 'core/components/breakpoint';

import H3 from 'core/components/H3';
import Drum from 'core/components/Drum';
import { SimpleLoader } from 'core/components/Loader';

import { Indent } from 'site/components/Wrappers';
import Divider from 'site/components/Divider';

import CardServiceReview1 from 'site/cardsService/CardServiceReview1';

import modelPropTypes, {
  reviewAttributes,
} from 'site/utils/prop-types/model';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


function Reviews({ isMobile, match }, { servicesApi }) {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let ignore = false;

    const {
      brand,
      model,
      generation,
    } = match.params;

    servicesApi
      .getReviews({
        'filter[brand]': brand,
        'filter[model]': model,
        'filter[generation]': generation,
        'relations[review]': 'release,modification,user',
        'include': 'car_release,car_modification,user',
        'limit': 9,
        'attributes[review]': 'base,image,text',
        'with_filtered_count': true,
      })
      .then(denormalizeData)
      .then(data => {
        if (!ignore) {
          setReviews(data);
          setLoading(false);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setLoading(false);
        }
      });

    return () => {
      ignore = true;
    };
  }, [match.params, servicesApi]);

  if (loading) return <SimpleLoader />;

  if (reviews.length === 0) return null;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Indent bottom={vertical}>
      <Divider top={30} bottom={20} />
      <Drum
        grid
        showHeaderArrows
        showArrowsOnHover
        arrowsShift={-10}
        title='Отзывы владельцев'
        headerComponent={H3}
        itemWidth='40%'
        itemWidthMobile='100%'
        content={reviews}
        card={CardServiceReview1}
      />
    </Indent>
  );
}

Reviews.propTypes = {
  isMobile: PropTypes.bool,
  reviews: PropTypes.arrayOf(modelPropTypes(reviewAttributes)),
  match: PropTypes.object,
};

Reviews.contextTypes = {
  servicesApi: PropTypes.object.isRequired,
};

export default withBreakpoint(Reviews);
