import PropTypes from 'prop-types';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { Indent } from 'site/components/Wrappers';

import { SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

function SideIndent(props) {
  const {
    children,
    isMobile,
  } = props;

  const indent = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  return (
    <Indent left={indent} right={indent}>
      {children}
    </Indent>
  );
}

SideIndent.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SideIndent);
