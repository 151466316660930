import PropTypes from 'prop-types';
import Page from 'core/components/Page';
import ErrorPage from 'core/components/ErrorPage';


export default function Development(props) {
  return (
    <Page title={props.title}>
      <ErrorPage type='development' />
    </Page>
  );
}

Development.propTypes = {
  title: PropTypes.string,
};
