import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import resolveRelationships from 'core/utils/relationships';


const getStartYear = (date) => new Date(date).getFullYear();
const getEndYear = (date) => date ? new Date(date).getFullYear() : 'н.в';

const requiredPayloadImports = ['generation'];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  generation: {},
});


function Years({ content, theme, isMoto }) {
  if (!content) return null;

  const endDateMoto = content.attributes.finish_date;
  const startDateMoto = content.attributes.start_date;

  const {
    generation: {
      end_date: endDate,
      start_date: startDate,
    },
  } = relationships(content);

  const startYear = getStartYear(isMoto ? startDateMoto : startDate);
  const endYear = getEndYear(isMoto ? endDateMoto : endDate);

  return (
    <div className='years'>
      <style jsx>{`
        .years
          font 14px/16px ${theme.fonts.text}
          color ${theme.colors.hint}
      `}</style>
      {startYear} - {endYear}
    </div>
  );
}

Years.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.object,
  isMoto: PropTypes.bool,
};

export default withTheme(Years);
