import PropTypes from 'prop-types';
import color from 'core/libs/color';
import { withRouter } from 'core/libs/router';

import themePropTypes from 'core/utils/prop-types/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import cardServiceHOC from 'site/components/cardService';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { releaseFeatureAttributes } from 'site/utils/prop-types/model';

import styles from './index.styl';

const IMAGE_HEIGHT = 158;
const IMAGE_WIDTH_MOBILE = 90;
const IMAGE_HEIGHT_MOBILE = 60;

function CardReleaseFeature(props) {
  const {
    theme: {
      colors: {
        content: contentColor,
        primary,
        active1,
        divider,
      },
      animations: {
        hover,
      },
    },
    content,
    isMobile,
    match: {
      params: {
        brand,
        model,
        generation,
        body,
        suffix,
      },
    },
  } = props;

  if (!content) return null;

  const {
    attributes: {
      name,
      image,
      slug,
    },
  } = content;

  const cover = getThumbnail(image);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          color ${primary}
          background ${contentColor}

          &:hover
            color ${active1}

          &:before
            border 1px solid ${color(primary).alpha(0.1)}

          &:after
            transition opacity ${hover}
            box-shadow 0 1px 5px ${color(primary).alpha(0.2)},
              0 3px 4px ${color(primary).alpha(0.12)},
              0 2px 4px ${color(primary).alpha(0.14)}

          :global(.mobile) &
            background transparent
            border-bottom 1px solid ${divider}
      `}</style>
    </scope>
  );

  const imageProps = isMobile
    ? {
      width: IMAGE_WIDTH_MOBILE,
      height: IMAGE_HEIGHT_MOBILE,
    } : {
      maxHeight: IMAGE_HEIGHT,
    };

  const featureLink = '/' + [
    brand,
    model,
    generation,
    body,
    suffix,
    slug,
  ].join('/');

  return (
    <div className={scope.wrapClassNames(styles.cardReleaseFeature)}>
      <Link
        type='secondary'
        to={featureLink}
        className={scope.wrapClassNames(styles.link)}
      >
        <div className={styles.image}>
          <Image
            src={cover}
            {...imageProps}
          />
        </div>
        <div className={styles.name}>{name}</div>
      </Link>
      <scope.styles />
    </div>
  );
}

CardReleaseFeature.propTypes = {
  theme: themePropTypes(`{
    colors: {
      divider,
    }
  }`),
  content: modelPropTypes(releaseFeatureAttributes),
  isMobile: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
      suffix: PropTypes.string,
      slug: PropTypes.string,
    }),
  }),
};

const CardWrapper = withRouter(cardServiceHOC(CardReleaseFeature));

export default CardWrapper;
