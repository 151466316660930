import { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import styles from './index.styl';


function Modal(props) {
  const {
    className, // eslint-disable-line
    overlayClassName, // eslint-disable-line
    isOpen,
    ...otherProps
  } = props;

  if (!isOpen) return null;

  return (
    <Fragment>
      <style jsx global>{`
        body
          overflow hidden
      `}</style>
      <ReactModal
        isOpen={isOpen}
        portalClassName={styles.modals}
        className={{
          base: styles.content,
          afterOpen: '',
          beforeClose: '', // REF: https://github.com/reactjs/react-modal/issues/578
        }}
        overlayClassName={{
          base: styles.overlay,
          afterOpen: '',
          beforeClose: '',
        }}
        {...otherProps}
      />
    </Fragment>
  );
}

Modal.propTypes = {
  /**
   * Класс, который будет дополнительно добавлен
   * к `body` при открытии модалки.
   *
   * TODO: Кажется в react modal есть баг из-за которого класс не добавляется
   * если попап открывается сразу после открытия страницы, например если открыть
   * полную форму по ссылке quto.ru/catalog#full_filter
   */
  bodyOpenClassName: PropTypes.string,

  /**
   * Состояние по которому будет отрендерен modal.
   * Если не делать проверку при рендере, будут отрендерены все модалки на странице,
   * которых может быть много например в конфигураторе.
   */
  isOpen: PropTypes.bool,
};

export default Modal;
