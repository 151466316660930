export const PATHNAME_FIELDS = ['brand', 'model', 'release'];
export const EXCLUDED_STATE_FIELDS = ['baseEntities'];

export const RANGES = {
  price: {
    min: 50000,
    max: 5000000,
  },
  volume: {
    min: 40,
    max: 2500,
  },
  power: {
    min: 1,
    max: 310,
  },
  cylinders: {
    min: 1,
    max: 5,
  },
};

// Список полей, которые входят только в полный фильтр
export const FULL_FILTER_FIELDS = [
  'engine_type',
  'boost',
  'cylinders',
  'configurate',
  'cooling',
  'transfer',
  'transmission',
  'abs',
  'usages',
];

export const DEPENDENCIES = {
  brand: {
    model: () => null,
    release: () => null,
  },
  model: {
    release: () => null,
  },
};
