import deepmerge from 'core/libs/deepmerge';

import LogoLime from 'site/icons/LogoLime.svg';


export default (colors) => {
  colors.content = colors.active2;
  colors.primary = '#FFF';
  colors.text = '#FFF';
  colors.layout = '#fff';
  return deepmerge(
    {
      controls: {
        shapka: {
          logo: {
            Icon: LogoLime,
            width: 72,
            height: 32,
          },
        },
        scooter: {
          logo: {
            Icon: LogoLime,
            width: 100,
            height: 44,
          },
        },
      },
    }, colors);
};
