export default {
  'tiny': {
    headlineSize: '500 12px/1.2',
    width: 80,
    height: 45,
    cardPadding: 0,
  },
  'miniature': {
    headlineSize: '500 16px/20px',
    width: 124,
    height: 88,
    cardPadding: 0,
  },
  'miniatureMobile': {
    headlineSize: '500 15px/19px',
    width: 114,
    height: 80,
    cardPadding: 0,
  },
  'xs': {
    headlineSize: '500 14px/1.2',
    width: 90,
    height: 60,
    cardPadding: '15px 0',
  },
  's': {
    headlineSize: '500 16px/1.2',
    width: 90,
    height: 60,
    cardPadding: '15px 0',
  },
  'm': {
    headlineSize: '500 16px/1.2',
    width: 125,
    height: 70,
    cardPadding: '10px',
  },
};
