import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import { Indent } from 'site/components/Wrappers';
import Years from 'site/components/Years';

import CardServiceRelease1 from '../../';


export default function CardRelease1WithYears(props) {
  return (
    <CardServiceRelease1 {...props}>
      <Indent top={5} />
      <Years {...props} />
    </CardServiceRelease1>
  );
}

CardRelease1WithYears.propTypes = {
  content: modelPropTypes(carReleaseAttributes),
};
