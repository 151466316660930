import PropTypes from 'prop-types';

import { rawAuthorsFetcher } from 'core/fetchers/authorsPage';

import resolve from 'core/resolver/resolve';

import H1 from 'core/components/H1';
import AuthorsPage from 'core/components/AuthorsPage';
import { withBreakpoint } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import PageLayout from 'site/components/PageLayout';

import { Indent } from 'site/components/Wrappers';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


function Authors({ rawAuthors, isMobile }) {
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <PageLayout>
      <H1>Авторы</H1>
      <Indent bottom={vertical} />
      <AuthorsPage rawAuthors={rawAuthors} />
    </PageLayout>
  );
}

Authors.propTypes = {
  rawAuthors: PropTypes.object,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  rawAuthors: rawAuthorsFetcher(),
});

export default withPageHocs(dataProvider)(withBreakpoint(Authors));
