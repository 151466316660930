export const BASE_PATH = '/inventory';

export const RANGES = {
  price: {
    min: 300000,
    max: 30000000,
  },
  year: {
    min: 1980,
    max: new Date().getFullYear(),
  },
};

export const DEPENDENCIES = {
  brand: {
    model: () => null,
  },
  model: {
    generation: () => null,
  },
};

export const PATHNAME_FIELDS = ['brand', 'model'];
export const EXCLUDED_STATE_FIELDS = ['brands', 'models'];
