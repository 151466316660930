import PropTypes from 'prop-types';

import Image from 'core/components/Image';

import cardServiceHOC from 'site/components/cardService';

import getThumbnail from 'site/utils/get-thumbnail';

import modelPropTypes, { releaseColorAttributes } from 'site/utils/prop-types/model';

import styles from './index.styl';

function CardReleaseColor(props) {
  const {
    theme: {
      colors: {
        content: contentColor,
        primary100,
        divider,
      },
    },
    content,
    isMobile,
  } = props;

  if (!content) return null;

  const {
    attributes: {
      name,
      full_name: fullName,
      image,
    },
  } = content;

  const cover = getThumbnail(image);

  return (
    <div className={styles.cardReleaseColor}>
      <style jsx>{`
        .${styles.cardReleaseColor}
          background ${contentColor}
          :global(.desktop) &
            border 1px solid ${primary100}

          :global(.mobile) &
            border-bottom 1px solid ${divider}
      `}</style>
      <div className={styles.image}>
        <Image
          src={cover}
          maxWidth={isMobile ? 150 : 175}
        />
      </div>
      <div className={styles.name}>{fullName || name}</div>
    </div>
  );
}

CardReleaseColor.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** Контент карточки, соответсвующий модели `releaseColorAttributes` */
  content: modelPropTypes(releaseColorAttributes),
  /** @ignore */
  isMobile: PropTypes.bool,
};

const CardWithHOCs = cardServiceHOC(CardReleaseColor);
CardWithHOCs.displayName = 'CardReleaseColor';

export default CardWithHOCs;
export { CardReleaseColor as StorybookComponent };
