import PropTypes from 'prop-types';

import { Section, Block } from 'core/components/Grid';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';
import { Indent } from 'site/components/Wrappers';

import {
  SIDE_INDENT,
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import OffersForm from 'site/forms/Main';
import OffersBrands from '../OffersBrands';


function OffersSearch(props) {
  const {
    isMobile,
    isDesktop,
    theme: {
      colors: {
        active1: colorActive1,
      },
    },
    geoSlug,
  } = props;

  const verticalIndent = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const horizontalIndent = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  return (
    <ThemeSwitchWithColor tone='dark'>
      <div
        style={{
          backgroundColor: colorActive1,
          padding: `${isMobile ? 25 : 20}px ${horizontalIndent}px`,
          margin: `0 -${horizontalIndent}px`,
        }}
        data-qa='offers-search'
      >
        <Section>
          <Block desktop={4} mobile={12}>
            <OffersForm />
            {isMobile && <Indent bottom={verticalIndent} />}
          </Block>
          {isDesktop && <Block desktop={`${horizontalIndent}px`} />}
          {isDesktop && geoSlug && (
            <Block>
              <ThemeSwitchWithColor tone='light'>
                <OffersBrands geoSlug={geoSlug} />
              </ThemeSwitchWithColor>
            </Block>
          )}
        </Section>
      </div>
    </ThemeSwitchWithColor>
  );
}

OffersSearch.propTypes = {
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
  /** geoSlug из гео-контекста */
  geoSlug: PropTypes.string,
};

export default withTheme(withBreakpoint(OffersSearch));
