import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { matchPath } from 'core/libs/router';

import { denormalizeData } from 'core/utils/api';

import resolve from 'core/resolver/resolve';

import H1 from 'core/components/H1';
import Page from 'core/components/Page';
import Button from 'core/components/Button';
import Radio from 'core/components/Radio';
import withPageHocs from 'core/components/withPageHocs';

import withGeo from 'site/components/GeoContext/withGeo';
import { PageIndent } from 'site/components/Wrappers';

import styles from './index.styl';
import routes from 'site/routes';


let newGeoUrl;

function Geo(props) {
  const {
    getGeoAttributes,
    geoSlug,
    updateGeo,
    history,
    location,
    geos,
  } = props;

  const formEl = useRef(null);

  const onSubmit = useCallback(() => {
    updateGeo(formEl.current['geo-id'].value);
    const lastUrl = location?.state?.from || '/';
    const route = routes.find(routeParams => {
      return matchPath(lastUrl.pathname, routeParams);
    });

    if (!route) { // переход с главной страницы
      return history.push(lastUrl.pathname);
    }

    const geoIndex = route.path
      .split('/')
      .filter((el) => el)
      .findIndex((segment) => /:geo/.test(segment));

    if (geoIndex !== -1) {
      const destinationUrl = lastUrl.pathname.split('/').filter((el) => el);
      destinationUrl.splice(geoIndex, 1, newGeoUrl);
      return history.push(destinationUrl.join('/'));
    }
    return history.push(lastUrl.pathname);
  }, [history, location, updateGeo]);

  if (!geoSlug) return null;

  return (
    <>
      <H1 dataQa='page-title'>Ваш текущий регион: {getGeoAttributes(geoSlug).name}</H1>
      <form className={styles.content} ref={formEl}>
        <div className={styles.list}>
          {geos.map(geoItem => {
            const {
              attributes: {
                url,
                name,
                quto_id: qutoGeoId,
              },
            } = geoItem;

            return (
              <div key={url} className={styles.item}
                data-qa='geo-item'
              >
                <Radio
                  name='geo-id'
                  value={qutoGeoId}
                  defaultChecked={url === geoSlug}
                  onChange={() => {
                    newGeoUrl = url;
                  }}
                >
                  {name}
                </Radio>
              </div>
            );
          })}
        </div>
        <Button onClick={onSubmit}>Сохранить</Button>
      </form>
    </>
  );
}

const dataProvider = resolve({
  geos({ servicesApi, consoleError }) {
    return servicesApi
      .getUserGeos()
      .then(denormalizeData)
      .catch(consoleError('geos', []));
  },
});

Geo.propTypes = {
  getGeoAttributes: PropTypes.func.isRequired,
  updateGeo: PropTypes.func.isRequired,
  geoSlug: PropTypes.string,
  geos: PropTypes.array.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
};

const EnhahedGeo = withPageHocs(dataProvider)(withGeo(Geo));

export default function GeoPage() {
  return (
    <Page title='Регион'>
      <PageIndent>
        <EnhahedGeo />
      </PageIndent>
    </Page>
  );
}
