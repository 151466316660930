import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import renameThemeProp from 'site/components/renameThemeProp';
import styles from './index.styl';

function LoadingIndicator(props) {
  const {
    theme: {
      icons: {
        Spinner,
      },
    },
  } = props;

  return (
    <Spinner
      className={styles.spinner}
      width='18px'
      height='18px'
    />
  );
}

LoadingIndicator.propTypes = {
  theme: themePropTypes(`{
    icons: {
      Spinner
    }
  }`),
};

export default renameThemeProp(withTheme(LoadingIndicator));
