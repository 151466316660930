import cx from 'classnames';
import PropTypes from 'prop-types';

import Image from 'core/components/Image';

import withTheme from 'core/components/theme';

import styles from './index.styl';

import { getAvatar, cardDateFormat } from 'site/utils';
import { qutoUserAttributes } from 'site/utils/prop-types/model';

import mapTypes from './types';

function User({ theme, user, date, type }) {
  if (!user) return null;

  const {
    nickname,
    name: userName,
    surname: userSurname,
    image,
  } = user;

  const { isBold } = mapTypes[type] || {};

  const userAvatar = getAvatar(image);

  return (
    <div className={cx(styles.user, 'user', isBold && styles.isBold)}>
      <style jsx>{`
        .userAvatar
          background ${theme.colors.primary100}

        .date
          color ${theme.colors.greyText2}
      `}</style>
      <div className={cx('userAvatar', styles.userAvatar)}>
        <Image
          src={userAvatar}
          aspectRatio={1}
          width={36}
        />
      </div>
      <div className='content'>
        {nickname && <div className={styles.userName}>{nickname}</div>}
        {!nickname && (userName && userSurname) ? (
          <div className={cx('userName', styles.userName)}>{userName + ' ' + userSurname}</div>
        ) : ' '}
        {date && <div className={cx('date', styles.date)}>{cardDateFormat(date)}</div>}
      </div>
    </div>
  );
}

User.defaultProps = {
  type: 0,
};

User.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** Дата комментария */
  date: PropTypes.string,
  /** Данные пользователя, соответствующие модели `qutoUserAttributes`  */
  user: qutoUserAttributes,
  /** Тип отображения текста в компоненте */
  type: PropTypes.oneOf([0, 1]),
};

const UserWithHOCs = withTheme(User);
UserWithHOCs.displayName = 'User';

export default UserWithHOCs;
export { User as StorybookComponent };
