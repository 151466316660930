import PropTypes from 'prop-types';

import NothingFound from 'site/components/NothingFound';
import BrandReleases from 'site/components/BrandReleases';

import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';


function Releases({ releases }) {
  if (releases.length === 0) return <NothingFound title={'Сожалеем!'} text={'Ни одно транспортное средство не отвечает заданным параметрам'} />;

  return (
    <BrandReleases releases={releases} />
  );
}

Releases.propTypes = {
  releases: PropTypes.arrayOf(modelPropTypes(carReleaseAttributes)).isRequired,
};

export default Releases;
