import PropTypes from 'prop-types';
import cx from 'classnames';
import { format } from 'core/libs/date-fns';

import cardServiceHOC from 'site/components/cardService';

import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import styles from './index.styl';

function CardServiceGeneration1(props) {
  if (!props.content) return null;

  const {
    theme,
    content: {
      attributes: {
        start_date: startDate,
        end_date: endDate,
        name,
      },
    },
    setActive,
    onClick,
    position,
  } = props;

  const start = new Date(startDate);
  const end = new Date(endDate);
  const isActive = setActive(position);

  return (
    <div
      onClick={() => onClick(position)}
      className={cx(
        styles.cardServiceGeneration1,
        isActive && styles.isActive,
      )}
    >
      <style jsx>{`
        .${styles.cardServiceGeneration1}
          &:after
            background ${theme.colors.primary}

        .name
        .${styles.years}
          font 14px/20px ${theme.fonts.text}
      `}</style>
      <div className='name'>{name}</div>
      {startDate && <div className={styles.years}>
        {`${format(start, 'yyyy')} — ${endDate ? format(end, 'yyyy') : 'н.в.'}`}
      </div>}
    </div>
  );
}

CardServiceGeneration1.propTypes = {
  /** Коллбек установки активной карточки */
  setActive: PropTypes.func,
  /** Обработчик клика по карточке */
  onClick: PropTypes.func,
  /** Позиция карточки */
  position: PropTypes.number,
  /** @ignore */
  theme: PropTypes.object,
  /** Данные карточки, соответствующие модели `carReleaseAttributes` */
  content: modelPropTypes(carReleaseAttributes),
};

CardServiceGeneration1.defaultProps = {
  setActive: () => false,
  onClick: () => null,
  position: 0,
};

const CardWithHOCs = cardServiceHOC(CardServiceGeneration1);
CardWithHOCs.displayName = 'CardServiceGeneration1';

export default CardWithHOCs;
export { CardServiceGeneration1 as StorybookComponent };
