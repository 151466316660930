import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import styles from './index.styl';

/**
 * Ширина одного символа-number в input
 * @type {number}
 */
const WIDTH_OF_LETTER = 9;


function InputCompact(props) {
  const {
    label,
    invalid,
    theme: {
      controls: {
        inputCompact: inputAtoms,
      },
    },
    disabled,
    value,
    valueFormatted,
    onChange,
    onBlur,
    ...otherProps
  } = props;

  // используем в обработчике onKeyDown
  const inputRef = useRef(null);
  // используем для расчета ширины контрола, когда инпут в фокусе
  const valueRef = useRef(null);
  // используем для расчета ширины контрола, когда инпут не в фокусе
  const formattedValueRef = useRef(null);

  /**
   * Ширина контрола при инициализации.
   * По умолчанию инпут не в фокусе.
   * Поэтому устанавливается шширина, равная длине valueFormatted умноженная на ширину символа
   */
  const initialInputWidth = String(valueFormatted).length * WIDTH_OF_LETTER;

  // ширина контрола
  const [inputWidth, toggleInputWidth] = useState(initialInputWidth);
  // помечаем контрол в фокусе или нет
  const [isFocused, toggleFocus] = useState(false);

  useEffect(() => {
    const hasValue = inputRef.current && inputRef.current.value;

    if (!hasValue) return;

    const width = isFocused
      ? valueRef.current.offsetWidth + WIDTH_OF_LETTER
      : formattedValueRef.current.offsetWidth;

    toggleInputWidth(width);
  }, [isFocused, value]);

  return (
    <label
      className={cx(
        'inputCompact',
        styles.inputCompact,
        invalid && '_invalid',
        disabled && '_disabled',
        isFocused && '_focused',
        isFocused && styles._focused
      )}
    >
      <style jsx>{`
        .inputCompact
          .label
            font ${inputAtoms.label.font}
            color ${inputAtoms.label.color}

          :global(input)
            font ${inputAtoms.input.font}
            color transparent
            &:hover + .underline
              border-bottom-color ${inputAtoms.hover.borderColor}
            &:focus + .underline
              border-bottom-color ${inputAtoms.focus.borderColor}
            &:invalid + .underline
              border-bottom-color ${inputAtoms.invalid.borderColor}

          &._invalid
            :global(input) + .underline
              border-bottom-color ${inputAtoms.invalid.borderColor}

          &._disabled
            :global(input) + .underline
              border-bottom 1px solid ${inputAtoms.idle.borderColor}
              
          &._focused
            :global(input)
              color ${inputAtoms.input.color}

        .underline
          border-bottom 1px solid ${inputAtoms.idle.borderColor}
          
        .${styles.values}
          width ${inputWidth + 'px'}
          
        .${styles.formattedValue}
          font ${inputAtoms.input.font}
          color ${inputAtoms.input.color}
      `}</style>
      <span className={cx('label', styles.label)}>{label}</span>
      <div className={styles.values}>
        <span ref={valueRef} className={styles.value}>{value}</span>
        <span ref={formattedValueRef} className={styles.formattedValue}>{valueFormatted}</span>
        <input
          autoComplete='off'
          type='number'
          disabled={disabled}
          onChange={onChange}
          onBlur={event => {
            onBlur(event);
            toggleFocus(false);
          }}
          onFocus={() => toggleFocus(true)}
          onKeyDown={event => {
            // При нажатии клавиши Enter, дублируем логику обработчика onBlur.
            if (event.key === 'Enter') {
              inputRef.current.blur();
            }
          }}
          ref={inputRef}
          value={value}
          {...otherProps}
        />
        <div className={cx('underline', styles.underline)} />
      </div>
    </label>
  );
}

InputCompact.defaultProps = {
  onChange: () => null,
  onBlur: () => null,
};

const inputStateContract = '{ borderColor }';
const inputElementContract = '{ color, font }';

InputCompact.propTypes = {
  /** Label текстового поля */
  label: PropTypes.string,

  /** Неотформатированное числовое значение, как есть 30000 или 17.5 */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * Отформатированное значение.
   * Например число 30000 с пробелом 30 000
   */
  valueFormatted: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Обработчик изменения input */
  onChange: PropTypes.func,

  /** Обработчик потери фокуса с input */
  onBlur: PropTypes.func,

  /** Состояние поля при невалидном значении */
  invalid: PropTypes.bool,

  /** @ignore */
  theme: themePropTypes(`{
    controls: {
      inputCompact: {
        label: ${inputElementContract},
        input: ${inputElementContract},
        idle: ${inputStateContract},
        hover: ${inputStateContract},
        focus: ${inputStateContract},
        invalid: ${inputStateContract},
      }
    }
  }`),

  /** Делает input неактивным */
  disabled: PropTypes.bool,
};

const InputCompactWithHOCs = withTheme(InputCompact);
InputCompactWithHOCs.displayName = 'InputCompact';

export default InputCompactWithHOCs;
export { InputCompact as StorybookComponent };
