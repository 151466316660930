import AdWrapper from 'core/components/Ad/AdWrapper';
import { Desktop } from 'core/components/breakpoint';

import { Parallax } from './desktop';

import { SIDE_INDENT, VERTICAL_INDENT } from 'site/constants';

export default function ParallaxWrapper() {
  return (
    <Desktop>
      <AdWrapper
        left={-SIDE_INDENT}
        right={-SIDE_INDENT}
        bottom={VERTICAL_INDENT}
      >
        <Parallax />
      </AdWrapper>
    </Desktop>
  );
}
