import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import H2 from 'core/components/H2';
import Link from 'core/components/Link';

import BackIcon from 'site/icons/Back';

import styles from './index.styl';

function TitleBackward({ theme, title, url }) {
  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .back
          &:hover
          &:focus
            :global([id*="$"])
              fill ${theme.colors.active1}
      `}</style>
    </scope>
  );

  const titleMarkup = typeof title === 'string'
    ? <H2 is='h1'>{title}</H2>
    : <div>{title}</div>;

  return (
    <div className={styles.header}>
      <Link
        to={url}
        className={cx(scope.wrapClassNames('back'), styles.back)}
      >
        <BackIcon />
      </Link>
      {titleMarkup}
      <scope.styles />
    </div>
  );
}

TitleBackward.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  url: PropTypes.string,
  /** @ignore */
  theme: PropTypes.object,
};

const TitleBackwardWithHOCs = withTheme(TitleBackward);
TitleBackwardWithHOCs.displayName = 'TitleBackward';

export default TitleBackwardWithHOCs;
export { TitleBackward as StorybookComponent };
