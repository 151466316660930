import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';

import Link from 'core/components/Link';

function CustomLink({ to, theme, children, ...otherProps }) {
  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          :global(span)
            border-bottom 1px dashed ${theme.colors.blue1}
            transition border-color ${theme.animations.hover}

          &:hover
          &:focus
            text-decoration none
            :global(span)
              border-bottom 1px dashed transparent
      `}</style>
    </scope>
  );

  return (
    <Link
      to={to}
      className={scope.wrapClassNames('link')}
      {...otherProps}
    >
      <span>{children}</span>
      <scope.styles />
    </Link>
  );
}

CustomLink.propTypes = {
  /** Адрес URL */
  to: PropTypes.string,
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      blue1,
    },
  }`),
};

const CustomLinkWithHOCs = withTheme(CustomLink);
CustomLinkWithHOCs.displayName = 'CustomLink';

export default CustomLinkWithHOCs;
export { CustomLink as StorybookComponent };
