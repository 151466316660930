import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import { withBreakpoint, Mobile } from 'core/components/breakpoint';

import resolveRelationships from 'core/utils/relationships';

import Feed from 'core/components/Feed';
import H3 from 'core/components/H3';
import bindProps from 'core/components/bindProps';

import CardServiceNewRelease1 from 'site/cardsService/CardServiceNewRelease1';
import CardServiceNewRelease2 from 'site/cardsService/CardServiceNewRelease2';
import CardServiceNewRelease3 from 'site/cardsService/CardServiceNewRelease3';

import Divider from 'site/components/Divider';
import { Listing1, ListingSpec } from 'site/components/Ads/mobile';
import { Indent, NegativeMobile } from 'site/components/Wrappers';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';
import modelPropTypes, { carNoveltyAttributes } from 'site/utils/prop-types/model';

const requiredPayloadImports = ['release'];

const relationships = resolveRelationships(requiredPayloadImports, {}, {});

const DesktopFeedCard = bindProps({ showNew: false })(CardServiceNewRelease1);

function NewReleases(props) {
  const { isMobile, content, location: { pathname } } = props;

  if (!content?.length) return null;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const isMainPage = pathname === '/';
  const isCatalogPage = pathname === '/catalog';

  const feedProps = {
    columns: 4,
    card: isMobile ? CardServiceNewRelease3 : DesktopFeedCard,
    interitemSpacing: 15,
  };

  const filteredReleases = content.filter(release => !!relationships(release).release.image);

  const firstRelease = filteredReleases[0];
  const secondSet = filteredReleases.slice(1, 5);
  const thirdSet = filteredReleases.slice(5, 9);

  return (
    <div className='newReleases' data-qa='new-releases'>
      <H3>Новинки автопрома</H3>
      <Indent bottom={vertical} />
      {secondSet.length > 0 && <Feed
        {...feedProps}
        content={secondSet}
      />}
      <NegativeMobile>
        {firstRelease && <CardServiceNewRelease2 content={firstRelease} />}
      </NegativeMobile>
      <Divider type={isMobile ? 1 : 2} />
      <Mobile>
        <NegativeMobile>
          {isMainPage && (
            <Listing1 />
          )}
          {isCatalogPage && (
            <ListingSpec />
          )}
        </NegativeMobile>
      </Mobile>
      {thirdSet.length > 0 && <Feed
        {...feedProps}
        content={thirdSet}
      />}
    </div>
  );
}

NewReleases.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Список релизов, соответствующих модели `carNoveltyAttributes` */
  content: PropTypes.arrayOf(modelPropTypes(carNoveltyAttributes)),
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

const NewReleasesWithHOCs = withBreakpoint(withRouter(NewReleases));

NewReleasesWithHOCs.displayName = 'NewReleases';

export default NewReleasesWithHOCs;
export { NewReleases as StorybookComponent };
