import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import {
  Section,
  Block,
} from 'core/components/Grid';
import { Mobile } from 'core/components/breakpoint';

import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';
import FullFormTopline from 'site/components/FullFormTopline';
import FormContainer from 'site/components/FormContainer';
import InputDropdown from 'site/components/InputDropdown';
import Chip from 'site/components/Chip';
import ChipRange from 'site/components/ChipRange';
import InputRange from 'site/components/InputRange';
import FullFilterToggler from 'site/components/FullFilterToggler';
import { Indent } from 'site/components/Wrappers';
import Divider from 'site/components/Divider';
import RadioClouds from 'site/components/RadioClouds';
import Checkboxes from 'site/components/Checkboxes';

import {
  rangeMarksArray,
} from 'site/utils/forms';

import {
  FULL_FILTER_HASH,
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import { EXCLUDED_STATE_FIELDS } from '../constants';

import styles from './index.styl';


function Form(props) {
  const {
    formState,
    formOptions,
    isMobile,
    onChange,
    location,
    toggleFullForm,
    fullFiltersCount,
    isFiltersDisabled,
    ranges,
    navigateToNewUrl,
    resetAllForm,
    allFiltersCount,
  } = props;

  const {
    brand,
    model,
    geo,
    engine_type: engineType,
    price,
    type,
    year,
    class: motoClass,
    volume,
    condition,
    cylinders,
    abs,
    configurate,
    cooling,
    transfer,
    transmission,
    power,
    control,
  } = formState;

  const {
    filteredBrands,
    filteredModels,
    filteredTypes,
    filteredClasses,
    filteredCondition,
    filteredGeos,
    filteredDefaultGeo,
    filteredConfigurate,
    filtiredCooling,
    filteredTransmission,
    filteredTransfer,
    filteredAbs,
    filteredControl,
  } = formOptions;

  const verticalIndent = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const isBrandSelected = Boolean(Array.isArray(brand)
    ? brand[0]
    : brand
  );

  const isModelSelected = Boolean(Array.isArray(model)
    ? model[0]
    : model
  );

  const rangeDropdownProps = {
    ...isMobile && { contentPadding: (SIDE_INDENT_MOBILE - 2) + 'px' },
    contentPosition: isMobile ? 'center' : 'left',
  };

  const isFullFilterOpened = location.hash === FULL_FILTER_HASH;

  const isElectroEngineType = engineType ? engineType === 'e' : !!engineType;

  // определяем что выбранный регион, соответсвует региону пользователя
  const geoEqualContextGeo = filteredDefaultGeo.value === geo.value;

  return (
    <Fragment>
      {isFullFilterOpened && (
        <FullFormTopline
          toggleFullForm={toggleFullForm}
          resetAllForm={resetAllForm}
          navigateToNewUrl={navigateToNewUrl}
          allFiltersCount={allFiltersCount + (geoEqualContextGeo ? -1 : 0)}
          ranges={ranges}
          formType='offers'
          formState={formState}
          excludedStateFields={EXCLUDED_STATE_FIELDS}
          isMoto
        />
      )}
      <ThemeSwitchWithColor tone='dark'>
        <FormContainer>
          <Section>
            <Block width={12} desktop={4}>
              <Indent right={!isMobile && 20}>
                <InputDropdown
                  name='brand'
                  options={filteredBrands}
                  label='Марка'
                  value={brand}
                  onChange={onChange}
                  placeholder='Введите название марки'
                  isClearable
                  isMulti={false}
                  {...isMobile && { hideBorder: true }}
                />
              </Indent>
            </Block>
            <Block width={12} desktop={4}>
              <Indent right={!isMobile && 35} top={isMobile && 1}>
                <InputDropdown
                  name='model'
                  options={filteredModels}
                  label='Модельный ряд'
                  value={model}
                  onChange={onChange}
                  placeholder='Введите название модели'
                  isDisabled={!isBrandSelected}
                  isClearable
                  isMulti={false}
                  {...isMobile && {
                    roundingPosition: 'bottom',
                    hideBorder: true,
                  }}
                />
              </Indent>
            </Block>
            <Block width={12} desktop={4}>
              <Indent top={isMobile && 20}>
                <InputRange
                  name='price'
                  title='Цена'
                  min={ranges.price.min}
                  max={ranges.price.max}
                  step={10000}
                  unit='₽'
                  value={price}
                  onChange={onChange}
                  arrangeJustify
                />
              </Indent>
            </Block>
          </Section>
          <Indent top={isMobile ? 10 : 5} />
          <Section>
            <div className={styles.chipWrapper}>
              <Chip
                name='type'
                options={filteredTypes}
                placeholder='Любой тип'
                isDisabled={isFiltersDisabled && isModelSelected}
                onChange={onChange}
                value={type}
              />
            </div>
            <div className={styles.chipWrapper}>
              <ChipRange
                name='year'
                placeholder='Год выпуска'
                title='Год'
                min={ranges.year.min}
                max={ranges.year.max}
                dropdownProps={rangeDropdownProps}
                onChange={onChange}
                value={year}
              />
            </div>
            <div className={styles.chipWrapper}>
              <Chip
                name='class'
                options={filteredClasses}
                placeholder='Класс'
                isDisabled={isFiltersDisabled && isModelSelected}
                onChange={onChange}
                value={motoClass}
              />
            </div>
            <div className={styles.chipWrapper}>
              <ChipRange
                name='volume'
                placeholder='Объем двигателя'
                title='Объем'
                min={ranges.volume.min}
                max={ranges.volume.max}
                dropdownProps={rangeDropdownProps}
                onChange={onChange}
                value={volume}
                unit='см³'
                isDisabled={(isFiltersDisabled && isModelSelected) || isElectroEngineType}
              />
            </div>
            <div className={styles.chipWrapper}>
              <Chip
                name='condition'
                options={filteredCondition}
                placeholder='Любой пробег'
                onChange={onChange}
                value={condition}
              />
            </div>
            {geo && geo.attributes && (
              <div className={styles.chipWrapper}>
                <Chip
                  name='geo'
                  options={filteredGeos}
                  placeholder='Регион'
                  value={geo}
                  onChange={(value, context) => {
                    /**
                    * При сбросе фильтра устанавливаем регион,
                    * который задан в geoContext пользователя.
                    */
                    if (!value) {
                      onChange(filteredDefaultGeo, context);
                      return;
                    }
                    onChange(value, context);
                  }}
                  isMulti={false}
                  {...!(isFullFilterOpened || isMobile) && { overflowSelectValue: 150 }}
                  isClearable={!geoEqualContextGeo}
                  showDropdownWithValue={geoEqualContextGeo}
                  closeMenuOnSelect
                />
              </div>
            )}
            <div className={styles.fullFilterToggler}>
              <Indent top={15}>
                <FullFilterToggler
                  location={location}
                  toggleFullForm={toggleFullForm}
                  fullFiltersCount={fullFiltersCount}
                />
              </Indent>
            </div>
          </Section>
          {isFullFilterOpened && (
            <Fragment>
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Block width={12} desktop={6}>
                <InputRange
                  name='power'
                  title='Мощность (л.с.)'
                  menuWidth={300}
                  min={ranges.power.min}
                  max={ranges.power.max}
                  onChange={onChange}
                  value={power}
                  marks={rangeMarksArray(ranges.power.min, ranges.power.max, 25)}
                />
              </Block>

              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Section>
                <Block width={12} desktop={3}>
                  <InputRange
                    name='cylinders'
                    title='Количество цилиндров'
                    min={ranges.cylinders.min}
                    max={5}
                    step={1}
                    hiddenInputValue
                    marks={rangeMarksArray(ranges.cylinders.min, ranges.cylinders.max, 1)}
                    onChange={onChange}
                    value={cylinders}
                    isDisabled={isElectroEngineType}
                    resetValue={isElectroEngineType}
                    customEndMark={6}
                    replaceValue={[5, 6]}
                  />
                </Block>
                {isMobile && (
                  <Block width={12}>
                    <Divider
                      type={4}
                      top={verticalIndent}
                      bottom={verticalIndent}
                      {...isMobile && {
                        left: -SIDE_INDENT_MOBILE,
                        right: -SIDE_INDENT_MOBILE,
                      }}
                    />
                  </Block>
                )}
                <Block desktop={3} />
                <Block width={12} desktop={6}>
                  <RadioClouds
                    options={filteredConfigurate}
                    name='configurate'
                    onChange={onChange}
                    title='Конфиг. двигателя'
                    isDisabled={isElectroEngineType}
                    value={configurate}
                  />
                </Block>
              </Section>
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Section>
                <Block width={12} desktop={6}>
                  <RadioClouds
                    options={filtiredCooling}
                    name='cooling'
                    onChange={onChange}
                    title='Система охлаждения'
                    value={cooling}
                  />
                </Block>
                {isMobile && (
                  <Block width={12}>
                    <Divider
                      type={4}
                      top={verticalIndent}
                      bottom={verticalIndent}
                      {...isMobile && {
                        left: -SIDE_INDENT_MOBILE,
                        right: -SIDE_INDENT_MOBILE,
                      }}
                    />
                  </Block>
                )}
                <Block width={12} desktop={6}>
                  <RadioClouds
                    options={filteredControl}
                    name='control'
                    onChange={onChange}
                    title='Привод'
                    value={control}
                  />
                </Block>
              </Section>
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Section>
                <Block width={12} desktop={6}>
                  <RadioClouds
                    options={filteredTransfer}
                    name='transfer'
                    onChange={onChange}
                    title='Главная передача'
                    value={transfer}
                  />
                </Block>
                {isMobile && (
                  <Block width={12}>
                    <Divider
                      type={4}
                      top={verticalIndent}
                      bottom={verticalIndent}
                      {...isMobile && {
                        left: -SIDE_INDENT_MOBILE,
                        right: -SIDE_INDENT_MOBILE,
                      }}
                    />
                  </Block>
                )}
                <Block width={12} desktop={6}>
                  <RadioClouds
                    options={filteredTransmission}
                    name='transmission'
                    onChange={onChange}
                    title='Коробка передач'
                    value={transmission}
                  />
                </Block>
              </Section>
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Section>
                <Block width={12} desktop={6}>
                  <Checkboxes
                    title='Безопасность'
                    options={filteredAbs}
                    inputName='abs'
                    checkboxesState={abs}
                    onChange={onChange}
                  />
                </Block>
                {isMobile && (
                  <Block width={12}>
                    <Divider
                      type={4}
                      top={verticalIndent}
                      bottom={verticalIndent}
                      {...isMobile && {
                        left: -SIDE_INDENT_MOBILE,
                        right: -SIDE_INDENT_MOBILE,
                      }}
                    />
                  </Block>
                )}
              </Section>
              <Mobile><Indent bottom={50} /></Mobile>
            </Fragment>
          )}
        </FormContainer>
      </ThemeSwitchWithColor>
    </Fragment>
  );
}

Form.propTypes = {
  isMobile: PropTypes.bool,
  formState: PropTypes.object,
  formOptions: PropTypes.object,
  location: PropTypes.object,
  navigateToNewUrl: PropTypes.func,
  toggleFullForm: PropTypes.func,
  resetAllForm: PropTypes.func,
  allFiltersCount: PropTypes.number,
  ranges: PropTypes.object,
  onChange: PropTypes.func,
  isElectroEngineType: PropTypes.bool,
  isFiltersDisabled: PropTypes.bool,
  fullFiltersCount: PropTypes.number,
};

Form.contextTypes = {
  servicesApi: PropTypes.object.isRequired,
};

export default withRouter(Form);
