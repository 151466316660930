import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import H3 from 'core/components/H3';
import Feed from 'core/components/Feed';
import Image from 'core/components/Image';
import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import Headline from 'site/components/Headline';

import { Indent, NegativeMobile, TopicContentMaxWidth } from 'core/components/Wrappers';

import {
  CONTENT_AREA,
  LAYOUT_MAX_WIDTH_MOBILE,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import CardReleaseFeature from 'site/cardsService/CardReleaseFeature';

import SocialShare from 'site/components/SocialShare';
import Divider from 'site/components/Divider';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { carReleaseAttributes, releaseFeatureAttributes } from 'site/utils/prop-types/model';

import styles from './index.styl';


function Feature(props) {
  const {
    isMobile,
    currentFeature,
    filteredFeatures,
    location: {
      pathname,
    },
  } = props;

  const {
    attributes: {
      name,
      image,
      description,
    },
  } = currentFeature;

  const cover = getThumbnail(image);

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const maxWidth = isMobile ? LAYOUT_MAX_WIDTH_MOBILE : CONTENT_AREA;
  const maxHeight = isMobile ? 400 : 600;

  return (
    <Fragment>
      <Headline>{name}</Headline>
      <Desktop>
        <Indent bottom={vertical}>
          <Indent top={isMobile ? 0 : 5} bottom={15}>
            <SocialShare topicLink={pathname} />
          </Indent>
          <div className={styles.image}>
            <Image
              src={cover}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
            />
          </div>
        </Indent>
      </Desktop>

      <Mobile>
        <NegativeMobile>
          <div className={styles.image}>
            <Image
              src={cover}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
            />
          </div>
        </NegativeMobile>
        <Indent bottom={vertical} />
        <SocialShare topicLink={pathname} />
        <Indent bottom={vertical} />
      </Mobile>

      <TopicContentMaxWidth>
        {description}
        <Desktop>
          <Divider top={vertical} bottom={vertical} />
        </Desktop>
        <Mobile>
          <Indent bottom={vertical} />
        </Mobile>
      </TopicContentMaxWidth>

      <H3>Другие особенности</H3>
      <Indent bottom={vertical} />
      <Feed
        grid
        content={filteredFeatures}
        interitemSpacing={isMobile ? 0 : 20}
        columns={3}
        card={CardReleaseFeature}
      />
    </Fragment>
  );
}

Feature.propTypes = {
  currentFeature: modelPropTypes(releaseFeatureAttributes),
  filteredFeatures: PropTypes.arrayOf(modelPropTypes(releaseFeatureAttributes)),
  release: modelPropTypes(carReleaseAttributes),
  isMobile: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};


export default withBreakpoint(withRouter(Feature));
