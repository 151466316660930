import { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'uid';
import { normalize, schema } from 'normalizr';

import { compose, getContext } from 'core/libs/recompose';

import skip from 'core/resolver/skip';

import withGeo from 'site/components/GeoContext/withGeo';

import modelPropTypes, { carModificationAttributes } from 'site/utils/prop-types/model';

export const ConfiguratorContext = createContext();

const optionSheme = new schema.Entity('options');
const subgroupSheme = new schema.Entity('subgroups', { options: [optionSheme] });
const groupSheme = new schema.Entity('groups');
groupSheme.define({ options: [optionSheme], subgroups: [subgroupSheme] });
const shemeOfOptions = new schema.Array(groupSheme);

const packageSheme = new schema.Entity('packages');
packageSheme.define({ packages: [packageSheme] });
const shemeOfPackages = new schema.Array(packageSheme);

const getStorageName = id => `configurator_${id}`;

/**
 * Функция делает запрос за текущим состоянием конфигуратора.
 * Данные в Redis хранятся по uid ключу, который генерируется в рамках сессии.
 * Ключом в sessionStorage служит modificationId.
 */
function catalogConfiguratorRequest(servicesApi, id, geoSlug, optionRequestParams) {
  return (
    servicesApi.getCatalogConfigurator({
      modification_uuid: id,
      geo: geoSlug,
      key: sessionStorage.getItem(getStorageName(id)),
      ...optionRequestParams,
    })
  );
}

function generateUID(id) {
  const storageName = getStorageName(id);

  if (!sessionStorage.getItem(storageName)) {
    sessionStorage.setItem(storageName, uid());
  }
}

/**
 * Собираем опции/пакеты для каждой модификации.
 * Опции на странице сравнения приходят нормализованными
 * (из-за того что дерево строится плоским).
 * Для страницы конфигуратора нормализуем вручную
 * (из-за того что дерево имеет сложную структуру).
 */
function generateStoreOptions(modification, merged) {
  const {
    attributes: {
      options = {},
      packages = {},
    },
  } = modification;

  const isCompare = !!merged;

  if (isCompare) {
    const normalizedOptions = normalize(merged.options, shemeOfOptions);
    const { groups, subgroups } = normalizedOptions.entities;

    return {
      id: modification.id,
      options,
      packages,
      subgroups,
      groups,
    };
  }

  const normalizedOptions = normalize(options, shemeOfOptions);
  const normalizedPackages = normalize(packages, shemeOfPackages);

  return {
    id: modification.id,
    ...normalizedOptions.entities,
    ...normalizedPackages.entities,
  };
}

/**
 * Провайдер предоставляет данные и методы для работы с конфигуратором.
 * Данные о конфигурациях хранятся в хэш-таблице, с ключом по id модификации.
 *
 * Используется на страницах:
 * + конфигуратор - модификация одна;
 * + сравнение - модификаций сколько угодно.
 *
 * removeConfiguration - кодлбек удаляющий данные о модификации из контекста
 * getConfiguration - кодлбек делающий запрос на ручку `/configurator`.
 * Ответ ConfiguratorResponseOption сохраняется в соответсвующую ячейку по id модификации.
 */

class ConfiguratorProvider extends Component {
  state = {
    configurations: {},
  };

  componentDidMount() {
    if (this.props.geoSlug) this.updateConfiguration();
  }

  componentDidUpdate({ geoSlug: geoSlugPrev, modifications: modificationsPrev }) {
    const { geoSlug, modifications } = this.props;

    if (geoSlug && geoSlug !== geoSlugPrev || modifications !== modificationsPrev) {
      this.updateConfiguration();
    }
  }

  componentWillUnmount() {
    this.setState({ configurations: {} });
  }

  removeConfiguration = id => {
    this.setState(({ configurations: prevConfigurations }) => {
      const {
        [id]: configurationToDelete, // eslint-disable-line
        ...otherConfigurations
      } = prevConfigurations;
      return { ...otherConfigurations };
    });
  };

  getConfiguration = (modificationId, optionRequestParams = {}) => {
    const { geoSlug, modifications, merged, servicesApi } = this.props;

    if (!geoSlug) return {};
    return catalogConfiguratorRequest(servicesApi, modificationId, geoSlug, optionRequestParams)
      .then(configuration => {
        const currentModification = modifications.find(({ id }) => modificationId === id);
        const storeOptions = generateStoreOptions(currentModification, merged);
        this.setState(({ configurations: prevConfigurations }) => ({
          configurations: {
            ...prevConfigurations,
            [modificationId]: { configuration, ...storeOptions },
          },
        }));
      })
      .catch(e => console.error(e));
  };

  updateConfiguration = () => {
    this.props.modifications.forEach(modification => {
      generateUID(modification.id);
      this.getConfiguration(modification.id);
    });
  };

  render() {
    const { configurations } = this.state;
    const { geoSlug, modifications, children } = this.props;

    const configurationReady = !!geoSlug && modifications.every(({ id }) => configurations.hasOwnProperty(id));

    return (
      <ConfiguratorContext.Provider
        value={{
          getConfiguration: this.getConfiguration,
          removeConfiguration: this.removeConfiguration,
          configurationReady,
          ...configurations,
        }}
      >
        {children}
      </ConfiguratorContext.Provider>
    );
  }
}

ConfiguratorProvider.propTypes = {
  modifications: PropTypes.arrayOf(modelPropTypes(carModificationAttributes)),
  /**
   * Опций используемые в сравнении
   */
  merged: PropTypes.object,
  geoSlug: PropTypes.string,
  servicesApi: PropTypes.object,
};


export default compose(
  skip,
  withGeo,
  getContext({ servicesApi: PropTypes.object }),
)(ConfiguratorProvider);
