import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import { Mobile } from 'core/components/breakpoint';
import Button from 'core/components/Button';
import Link from 'core/components/Link';
import H3 from 'core/components/H3';
import Divider from 'core/components/Divider';
import PddQuestion from 'site/components/PddQuestion';
import { NegativeMobile } from 'site/components/Wrappers';

import { isCorrectAnswer } from 'site/utils/pdd';
import { pddModePropTypes, pddAnswersPropTypes } from 'site/utils/prop-types/pdd';

import ExclaimIcon from 'site/icons/ExclaimIcon.svg';
import RoundArrowIcon from 'site/icons/RoundArrowIcon.svg';

import styles from './index.styl';

function PddResult(props) {
  const {
    theme,
    questions,
    answers,
    handleReset,
    mode,
    examResult,
  } = props;

  const [showErrors, setShowErrors] = useState(false);

  const isMarafon = mode === 'marafon';
  const isTren = mode === 'tren';

  const wrongAnswers = [];
  const correctAnswers = [];
  const unanswered = [];

  answers.forEach((answer, index) => {
    if (!answer) {
      unanswered.push(answer);
      return;
    }

    if (isCorrectAnswer(answer)) {
      correctAnswers.push(answer);
      return;
    }

    wrongAnswers.push({
      ...answer,
      questionIndex: index,
    });
  });

  const isFailed = examResult && examResult.status === 'error';

  let line1 = '';
  let line2 = '';
  let line3 = '';

  if (isMarafon) {
    line1 = 'Поздравляем!';
    line2 = 'Вы прошли Марафон с результатом';
    line3 = `${correctAnswers.length} из ${answers.length}`;
  } else if (isTren) {
    line1 = 'Тренировка завершена';
    line2 = `с результатом ${correctAnswers.length} из ${answers.length}`;
  } else if (isFailed) {
    line1 = 'Экзамен не сдан.';
    line2 = examResult.message;
  } else {
    line1 = 'Вы сдали экзамен';
    line2 = `с результатом ${correctAnswers.length} из ${answers.length}`;
  }

  return (
    <div
      className={cx(
        styles.wrapper,
        isFailed && styles._isFailed,
        isMarafon && styles._marafon
      )}
    >
      <style jsx>{`
        .${styles.text}
          font ${theme.texts.lead.font}
      `}</style>

      <NegativeMobile>
        <div className={styles.body}>
          <div className={styles.result}>
            {
              <div className={styles.message}>
                {[line1, line2, line3].filter(Boolean).map((line, i) => {
                  return <span className={styles.text} key={i}>{line}</span>;
                })}
              </div>
            }
          </div>

          <div className={styles.buttonWrapper}>
            <Link className={styles.link} to='#'>
              <Button
                onClick={handleReset}
                size='large'
                type='ghost'
                className={styles.button}
              >
                <RoundArrowIcon className={styles.buttonIcon} />
                <span>Попробовать еще раз</span>
              </Button>
            </Link>
          </div>
          {wrongAnswers.length > 0 && !isMarafon && (
            <div className={styles.buttonWrapper}>
              <Divider />
              <Button
                size='large'
                type='ghost'
                className={styles.button}
                onClick={() => setShowErrors(!showErrors)}
              >
                <ExclaimIcon className={styles.buttonIcon} />
                <span>
                  {showErrors ? 'Скрыть ошибки' : 'Показать ошибки'}
                </span>
              </Button>
            </div>
          )}
          <Mobile>
            <Divider />
          </Mobile>
        </div>
      </NegativeMobile>

      {showErrors && (
        <div className={styles.errors}>
          <H3>Где у вас были ошибки:</H3>

          {wrongAnswers && wrongAnswers.map(answer => {
            const question = questions[answer.questionIndex];

            const {
              id,
              attributes: {
                ticket,
                sort,
              },
            } = question;

            return (
              <div key={id} className={styles.error}>
                <span className={styles.errorTitle}>
                  Билет {ticket}, Вопрос {sort}
                </span>
                <PddQuestion
                  question={question}
                  userAnswer={answer.answer}
                  isOver
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

PddResult.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** Список вопросов */
  questions: PropTypes.arrayOf(Object),
  /** Список ответов в виде массива объектов вида {answer, isCorrect} */
  answers: pddAnswersPropTypes,
  /** Callback для сброса параметров и для повторного прохождения режима */
  handleReset: PropTypes.func,
  /** Режимы */
  mode: pddModePropTypes,
  /** Результат экзамена в виде объекта {status, mesage} */
  examResult: PropTypes.object,
};

const PddResultWithHOCs = withTheme(PddResult);
PddResultWithHOCs.displayName = 'PddResult';

export default PddResultWithHOCs;
export { PddResult as StorybookComponent };
