import { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import { denormalizeData } from 'core/utils/api';

import { withBreakpoint, Mobile, Desktop } from 'core/components/breakpoint';
import { SimpleLoader } from 'core/components/Loader';

import FeedHeader from 'site/components/FeedHeader';
import { Indent, NegativeMobile, ShadowContentBg } from 'site/components/Wrappers';

import withTabs from './withTabs';
import Releases from './Releases';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  SIDE_INDENT_MOBILE,
} from 'site/constants';


class Generations extends Component {
  static contextTypes = {
    servicesApi: PropTypes.object.isRequired,
  };

  state = {
    generations: [],
    loading: true,
  };

  componentDidMount() {
    const {
      match: {
        params: {
          brand,
          model,
        },
      },
    } = this.props;
    this.getContent(brand, model);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: {
          brand: prevBrand,
          model: prevModel,
        },
      },
    } = prevProps;

    const {
      match: {
        params: {
          brand,
          model,
        },
      },
    } = this.props;

    if (brand !== prevBrand || model !== prevModel) {
      this.setState({ loading: true });
      this.getContent(brand, model);
    }
  }

  getContent(brand, model) {
    this.context.servicesApi
      .getGenerations({
        'filter[brand]': brand,
        'filter[model]': model,
        'relations[car_generation]': 'releases',
        'attributes[car_release]': 'base,image,stats',
        include: 'car_release',
        'sort': '-manual',
      })
      .then(denormalizeData)
      .then(data => {
        this.setState({
          generations: data,
          loading: false,
        });
      })
      .catch(e => {
        console.error(e);
        this.setState({ loading: false });
      });
  }

  render() {
    const {
      mainGenerationId,
      match: {
        params: {
          brand,
          model,
        },
      },
      isMobile,
    } = this.props;

    const {
      generations,
      loading,
    } = this.state;

    if (loading) return <SimpleLoader />;

    if (!Array.isArray(generations)) return null;

    const filteredGenerationsByRelease = generations.filter(generation => {
      return (generation?.relationships?.releases?.data || []).length &&
        generation.id !== mainGenerationId;
    });

    if (filteredGenerationsByRelease.length === 0) return null;

    const tabs = filteredGenerationsByRelease
      .filter(generation => {
        return (generation?.relationships?.releases?.data || []).length &&
        generation.id !== mainGenerationId;
      }).map(generation => ({
        requestParams: {
          'filter[brand]': brand,
          'filter[model]': model,
          'filter[generation]': generation?.attributes?.slug,
          'attributes[car_release]': 'base,image,stats',
          limit: 1,
        },
        content: generation,
      }));

    const Tabs = withTabs({ tabs })(Releases);

    const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
    const horizontal = isMobile ? SIDE_INDENT_MOBILE : 0;

    const Title = (
      <Indent
        top={vertical}
        bottom={vertical}
        left={horizontal}
        right={horizontal}
      >
        <FeedHeader>
          <Mobile>Другие поколения</Mobile>
          <Desktop>Другие поколения в каталоге</Desktop>
        </FeedHeader>
      </Indent>
    );

    return (
      <NegativeMobile>
        <Desktop>{Title}</Desktop>
        <ShadowContentBg type={isMobile ? 2 : 1}>
          <Mobile>{Title}</Mobile>
          <Indent left={isMobile ? 5 : 0} right={isMobile ? 5 : 0}>
            <Tabs />
          </Indent>
        </ShadowContentBg>
      </NegativeMobile>
    );
  }
}

Generations.propTypes = {
  isMobile: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
    }),
  }).isRequired,
  mainGenerationId: PropTypes.string,
};

export default withRouter(withBreakpoint(Generations));
