import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import GalleryWithAds from 'site/components/GalleryWithAds';
import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';

function CoverGallery({ isDesktop, photoGallery }) {
  return (
    <ThemeSwitchWithColor tone={isDesktop ? 'light' : 'dark'}>
      <GalleryWithAds
        photoGallery={photoGallery}
        firstImageFetchPriority='high'
        instantOnFirstImage
        hashNavigation
      />
    </ThemeSwitchWithColor>
  );
}

CoverGallery.propTypes = {
  photoGallery: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(CoverGallery);
