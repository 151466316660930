import GeoContext from '.';

export default function withGeo(Component) {
  return function GeoConsumer(props) {
    return (
      <GeoContext.Consumer>
        {({ ...providerProps }) => {
          return (
            <Component
              {...props}
              {...providerProps}
            />
          );
        }}
      </GeoContext.Consumer>
    );
  };
}
