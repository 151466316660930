import Years from 'site/components/Years';

import CardServiceModification2 from '../../';


export default function CardModification2WithYears(props) {
  return (
    <CardServiceModification2 {...props}>
      <Years {...props} />
    </CardServiceModification2>
  );
}
