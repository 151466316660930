import { Fragment } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import { denormalizeData } from 'core/utils/api';

import {
  generateSelectOptions,
  initSelectValue,
  initRangeValue,
  initCheckboxValue,
  initRadioValue,
  initSwitchWithSeparatorValues,
} from 'site/utils/forms';
import formatGenerations from 'site/utils';

import withGeo from 'site/components/GeoContext/withGeo';
import BaseForm from 'site/components/BaseForm';
import FormModal from 'site/components/FormModal';

import Form from './Form';

import {
  RANGES,
  FULL_FILTER_FIELDS,
  PATHNAME_FIELDS,
  EXCLUDED_STATE_FIELDS,
  BASE_PATH,
  DEPENDENCIES,
} from './constants';


class OffersForm extends BaseForm {
  constructor(props) {
    super(props);

    this.formOptions = this.getFormOptions({});

    this.state = {
      ...this.getFormStateFromLocation({}),
      brands: [],
      models: [],
      generations: [],
      geos: [],
    };
  }

  dependencies = DEPENDENCIES;

  ranges = RANGES;

  pathnameFields = PATHNAME_FIELDS;
  excludedFields = EXCLUDED_STATE_FIELDS;

  fullFilterFields = FULL_FILTER_FIELDS;

  basePath = BASE_PATH;

  get emptyPath() {
    return BASE_PATH + '/' + this.props.geoSlug;
  }

  componentDidMount() {
    const { servicesApi } = this.context;

    const {
      geoSlug,
      match: {
        params: {
          brand,
          model,
          geo,
        },
      },
    } = this.props;

    Promise.allSettled([
      servicesApi
        .getBrands({ 'filter[with_offers_from_geo]': geo || geoSlug })
        .then(denormalizeData),

      !brand ? [] : servicesApi
        .getModels({
          'filter[with_offers_from_geo]': geo || geoSlug,
          'filter[brand]': brand,
          'relations[car_model]': 'brand',
          include: 'car_brand',
        })
        .then(denormalizeData),

      !model ? [] : servicesApi
        .getGenerations({
          'filter[with_offers_from_geo]': geo || geoSlug,
          'filter[brand]': brand,
          'filter[model]': model,
          'relations[car_generation]': 'model',
          include: 'car_model',
        })
        .then(data => formatGenerations(denormalizeData(data))),

      servicesApi.getOffersDictionaries(),

      servicesApi
        .getOffersGeos({ 'filter[brand]': brand })
        .then(denormalizeData),
    ])
      .then(results => results.map(i => i.value))
      .then(([brands, models, generations, dictionaries, geos]) => ({
        brands: brands || [],
        models: models || [],
        generations: generations || [],
        geos: geos || [],
        dictionaries: dictionaries || {},
      }))
      .then(data => {
        const { brands, models, generations, dictionaries, geos } = data;

        this.ranges = {
          ...dictionaries.ranges,
          ...RANGES,
          year: dictionaries.ranges.year,
          price: dictionaries.ranges.price,
        };

        // Формирует неизменяемы опции для контролов
        this.formOptions = this.getFormOptions(dictionaries);

        this.setState({
          ...this.getFormStateFromLocation({ brands, models, generations, geos }),
          brands,
          models,
          generations,
          geos,
        });
      })
      .catch(e => console.error(e));
  }

  componentDidUpdate(prevProps, prevState) {
    const { servicesApi } = this.context;

    const {
      geoSlug,
    } = this.props;

    const {
      brand,
      model,
      geo,
    } = this.state;

    const {
      brand: prevBrand,
      model: prevModel,
      geo: prevGeo,
    } = prevState;

    if (prevBrand?.value === brand?.value && model?.value === prevModel?.value && prevGeo === geo) return;

    Promise.allSettled([
      brand
        ? (brand?.value !== prevBrand?.value || geo !== prevGeo) && servicesApi
          .getModels({
            'filter[with_offers_from_geo]': geo || geoSlug,
            'filter[brand]': brand?.value,
            'relations[car_model]': 'brand',
            include: 'car_brand',
          })
          .then(denormalizeData)
        : [],

      model
        ? (model?.value !== prevModel?.value || geo !== prevGeo) && servicesApi
          .getGenerations({
            'filter[with_offers_from_geo]': geo || geoSlug,
            'filter[brand]': brand?.value,
            'filter[model]': model?.value,
            'relations[car_generation]': 'model',
            include: 'car_model',
          })
          .then(data => formatGenerations(denormalizeData(data)))
        : [],

      (prevBrand?.value !== brand?.value) && servicesApi
        .getOffersGeos({ 'filter[brand]': brand })
        .then(denormalizeData),
    ])
      .then(results => results.map(i => i.value))
      .then(([models, generations, geos]) => {
        this.setState({
          ...models && { models },
          ...generations && { generations },
          ...geos && { geos },
        });
      })
      .catch(e => console.error(e));
  }

  getFormStateFromLocation = ({
    brands = [],
    models = [],
    generations = [],
    geos = [],
  }) => {
    const {
      location,
      match: {
        params,
      },
      geo: defaultGeo,
    } = this.props;

    const formValuesFromUrl = {
      ...queryString.parse(location.search),
    };

    const filteredBrands = this.getBrandOptions(brands);
    const filteredGeos = this.getGeoOptions(geos);
    const filteredDefaultGeo = this.getDefaultGeoOption([defaultGeo])[0];

    /**
     * Если значение не найдено, то устанавливаем его в null вместо
     * undefined, потому что undefined при сбросе фильтров не воспринимается
     * для некоторых контролов и там остается предыдущее установленное
     * значение.
     */
    const brand = filteredBrands?.find(item => (
      item.attributes.slug === params.brand
    )) || null;

    const filteredModels = this.getModelOptions(models, brand);
    const model = brand && filteredModels?.find(item => (
      item.attributes.slug === params.model
    )) || null;

    const filteredGenerations = this.getGenerationOptions(generations, model);
    const generation = model && filteredGenerations?.find(item => (
      item.attributes.slug === params.generation
    )) || null;

    const geo = filteredGeos?.find(item => (
      item.attributes.url === params.geo
    )) || filteredDefaultGeo;

    return {
      brand,
      model,
      price: initRangeValue(
        'price',
        formValuesFromUrl,
        this.ranges.price
      ),
      generation,
      body: initSelectValue(
        'body',
        formValuesFromUrl,
        this.formOptions.filteredBodies,
        'attributes.slug',
      ),
      year: initRangeValue(
        'year',
        formValuesFromUrl,
        this.ranges.year
      ),
      engine_type: initSelectValue(
        'engine_type',
        formValuesFromUrl,
        this.formOptions.filteredEngineTypes
      ),
      transmission: initSelectValue(
        'transmission',
        formValuesFromUrl,
        this.formOptions.filteredTransmissions,
      ),
      condition: initSelectValue(
        'condition',
        formValuesFromUrl,
        this.formOptions.filteredConditions,
      ),
      geo,
      dealer_type: initRadioValue(
        'dealer_type',
        formValuesFromUrl,
      ),
      country: initSelectValue(
        'country',
        formValuesFromUrl,
        this.formOptions.filteredCountries,
      ),
      class: initSelectValue(
        'class',
        formValuesFromUrl,
        this.formOptions.filteredClasses,
        'attributes.url'
      ),
      assembly: initRadioValue(
        'assembly',
        formValuesFromUrl,
      ),
      power: initRangeValue(
        'power',
        formValuesFromUrl,
        this.ranges.power
      ),
      boost: initCheckboxValue(
        'boost',
        formValuesFromUrl,
      ),
      control: initRadioValue(
        'control',
        formValuesFromUrl,
      ),
      acceleration: initRangeValue(
        'acceleration',
        formValuesFromUrl,
        this.ranges.acceleration
      ),
      trunk_volume: initRangeValue(
        'trunk_volume',
        formValuesFromUrl,
        this.ranges.trunk_volume
      ),
      clearance: initRangeValue(
        'clearance',
        formValuesFromUrl,
        this.ranges.clearance
      ),
      number_of_seats: initSwitchWithSeparatorValues(
        'number_of_seats',
        formValuesFromUrl,
        this.formOptions.filteredSeats,
      ),
    };
  };

  getFormOptions = dictionaries => ({
    filteredBodies: generateSelectOptions(dictionaries.body, { value: 'attributes.slug' }),
    filteredConditions: generateSelectOptions(dictionaries.condition, { label: 'title', value: 'value' }),
    filteredTransmissions: generateSelectOptions(dictionaries.transmission, { label: 'title', value: 'value' }),
    filteredEngineTypes: generateSelectOptions(dictionaries.engineType, { label: 'title', value: 'value' }),
    filteredDealerType: generateSelectOptions(dictionaries.dealerType, { label: 'title', value: 'value' }),
    filteredCountries: generateSelectOptions(dictionaries.country, { label: 'title', value: 'value' }),
    filteredClasses: generateSelectOptions(dictionaries.classes, { label: 'attributes.name', value: 'attributes.url' }),
    filteredAssemblies: generateSelectOptions(dictionaries.assembly, { label: 'title', value: 'value' }),
    filteredBoosts: generateSelectOptions(dictionaries.boost, { label: 'title', value: 'value' }),
    filteredControls: generateSelectOptions(dictionaries.control, { label: 'title', value: 'value' }),
    filteredSeats: dictionaries.seats,
  });

  getDefaultGeoOption = memoize(
    (geos = []) => generateSelectOptions(geos, { value: 'attributes.url' })
  );

  getGeoOptions = memoize(
    (geos = []) => generateSelectOptions(geos, { value: 'attributes.url' })
  );

  getBrandOptions = memoize(
    (brands = []) => generateSelectOptions(brands, { value: 'attributes.slug' })
  );

  getModelOptions = memoize(
    (models = []) => generateSelectOptions(models, { value: 'attributes.slug' })
  );

  getGenerationOptions = memoize(
    (generations = []) => generateSelectOptions(generations, { value: 'attributes.slug' })
  );

  render() {
    const {
      location,
      geo: defaultGeo,
    } = this.props;

    const {
      brands,
      models,
      generations,
      geos,
    } = this.state;

    const FormWrapper = this.isFullFormOpened ? FormModal : Fragment;

    const filteredDefaultGeo = this.getDefaultGeoOption([defaultGeo])[0];
    const filteredGeos = this.getGeoOptions(geos);
    // Зависимые опции
    const filteredBrands = this.getBrandOptions(brands);
    const filteredModels = this.getModelOptions(models);
    const filteredGenerations = this.getGenerationOptions(generations);

    return (
      <FormWrapper>
        <Form
          formState={{ ...this.state }}
          formOptions={{
            filteredGeos,
            filteredDefaultGeo,
            filteredBrands,
            filteredModels,
            filteredGenerations,
            ...this.formOptions,
          }}
          onChange={this.handleControlChange}
          navigateToNewUrl={this.navigateToNewUrl}
          toggleFullForm={this.toggleFullForm}
          allFiltersCount={this.allFiltersCount}
          fullFiltersCount={this.fullFiltersCount}
          resetAllForm={this.resetAllForm}
          location={location}
          ranges={this.ranges}
        />
      </FormWrapper>
    );
  }
}

OffersForm.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

OffersForm.contextTypes = {
  servicesApi: PropTypes.object.isRequired,
};

export default withRouter(withGeo(OffersForm));
