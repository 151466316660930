import { Fragment } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import {
  withNonPureBreakpoint,
} from 'core/components/breakpoint';

import { denormalizeData } from 'core/utils/api';

import {
  generateSelectOptions,
  initSelectValue,
  initRangeValue,
  initCheckboxValue,
  initRadioValue,
} from 'site/utils/forms';

import {
  filterEntitiesByRelation,
} from 'site/utils';

import BaseForm from 'site/components/BaseForm';
import FormModal from 'site/components/FormModal';
import Form from './Form';

import { MOTO_URL } from 'site/constants';

import {
  RANGES,
  FULL_FILTER_FIELDS,
  PATHNAME_FIELDS,
  EXCLUDED_STATE_FIELDS,
  DEPENDENCIES,
} from './constants';


const usages = [
  { title: 'В продаже', value: 'catalog' },
];

class MotoForm extends BaseForm {
  constructor(props) {
    super(props);

    this.formOptions = this.getFormOptions({}, {});

    this.state = {
      ...this.getFormStateFromLocation({}),
      baseEntities: {},
    };
  }

  componentDidMount() {
    const { servicesApi } = this.context;

    Promise.allSettled([
      servicesApi
        .getMotoBaseEntities()
        .then(denormalizeData),

      servicesApi.getMotoDictionaries(),
    ])
      .then(results => results.map(i => i.value))
      .then(([baseEntities, dictionaries]) => ({
        baseEntities: baseEntities || [],
        dictionaries: dictionaries || {},
      }))
      .then(data => {
        const { baseEntities, dictionaries } = data;

        /**
         * TODO
         * Переименовать generateSelectOptions так как используется для всех типов контролов и поправить во всех формах
         * */
        // Формирует неизменяемы опции для контролов
        this.formOptions = this.getFormOptions(dictionaries, baseEntities);

        this.setState({
          ...this.getFormStateFromLocation({ baseEntities }),
          baseEntities,
        });
      })
      .catch(e => console.error(e));
  }

  dependencies = DEPENDENCIES;

  ranges = RANGES;

  pathnameFields = PATHNAME_FIELDS;
  excludedFields = EXCLUDED_STATE_FIELDS;

  fullFilterFields = FULL_FILTER_FIELDS;

  basePath = MOTO_URL;

  getFormStateFromLocation = ({ baseEntities = {} }) => {
    const {
      match: {
        params,
      },
      location,
    } = this.props;

    /**
     * Если значение не найдено, то устанавливаем его в null вместо
     * undefined, потому что undefined при сбросе фильтров не воспринимается
     * для некоторых контролов и там остается предыдущее установленное
     * значение.
     */
    const filteredBrands = this.formOptions.filteredBrands;
    const brand = filteredBrands?.find(item => (
      item.attributes.slug === params.brand
    )) || null;

    const filteredModels = this.getModelOptions(baseEntities, brand);
    const model = brand && filteredModels?.find(item => (
      item.attributes.slug === params.model
    )) || null;

    const formValuesFromUrl = queryString.parse(location.search);

    return {
      brand,
      model,
      price: initRangeValue(
        'price',
        formValuesFromUrl,
        this.ranges.price
      ),
      type_uuid: initSelectValue(
        'type_uuid',
        formValuesFromUrl,
        this.formOptions.filteredTypes,
      ),
      class_uuid: initSelectValue(
        'class_uuid',
        formValuesFromUrl,
        this.formOptions.filteredClasses,
      ),
      volume: initRangeValue(
        'volume',
        formValuesFromUrl,
        this.ranges.volume
      ),
      power: initRangeValue(
        'power',
        formValuesFromUrl,
        this.ranges.power
      ),
      engine_type: initRadioValue(
        'engine_type',
        formValuesFromUrl,
      ),
      configurate: initRadioValue(
        'configurate',
        formValuesFromUrl,
      ),
      transmission: initRadioValue(
        'transmission',
        formValuesFromUrl,
      ),
      transfer: initRadioValue(
        'transfer',
        formValuesFromUrl,
      ),
      cooling: initRadioValue(
        'cooling',
        formValuesFromUrl,
      ),
      boost: initCheckboxValue(
        'boost',
        formValuesFromUrl,
      ),
      cylinders: initRangeValue(
        'cylinders',
        formValuesFromUrl,
        this.ranges.cylinders
      ),
      abs: initCheckboxValue(
        'abs',
        formValuesFromUrl,
      ),
      usages: initCheckboxValue(
        'usages',
        formValuesFromUrl,
      ),
    };
  };

  getFormOptions = (dictionaries, baseEntities) => ({
    filteredBrands: generateSelectOptions(baseEntities.brands),
    filteredTypes: generateSelectOptions(dictionaries.type),
    filteredClasses: generateSelectOptions(dictionaries.class),
    filteredEngineTypes: generateSelectOptions(dictionaries.engine_type, { label: 'title', value: 'value' }),
    filteredConfigurate: generateSelectOptions(dictionaries.configurate, { label: 'title', value: 'value' }),
    filteredTransmission: generateSelectOptions(dictionaries.transmission, { label: 'title', value: 'value' }),
    filteredTransfer: generateSelectOptions(dictionaries.transfer, { label: 'title', value: 'value' }),
    filtiredCooling: generateSelectOptions(dictionaries.cooling, { label: 'title', value: 'value' }),
    filteredBoost: generateSelectOptions(dictionaries.boost, { label: 'title', value: 'value' }),
    filteredAbs: generateSelectOptions(dictionaries.abs, { label: 'title', value: 'value' }),
    filteredUsages: generateSelectOptions(usages, { label: 'title', value: 'value' }),
  });

  getModelOptions = memoize(
    (baseEntities = {}, brand) => generateSelectOptions(filterEntitiesByRelation('brand', baseEntities.models, [brand]))
  );

  render() {
    const {
      isMobile,
      location,
      match: {
        params: {
          release,
        },
      },
    } = this.props;

    const {
      brand,
      baseEntities,
    } = this.state;

    // Зависимые опции
    const filteredModels = this.getModelOptions(baseEntities, brand);

    const FormWrapper = this.isFullFormOpened ? FormModal : Fragment;

    return (
      <FormWrapper>
        <Form
          formState={this.state}
          formOptions={{
            filteredModels,
            ...this.formOptions,
          }}
          ranges={this.ranges}
          isMobile={isMobile}
          onChange={this.handleControlChange}
          onCarTypeChange={this.handleCarTypeChange}
          location={location}
          navigateToNewUrl={this.navigateToNewUrl}
          toggleFullForm={this.toggleFullForm}
          resetAllForm={this.resetAllForm}
          allFiltersCount={this.allFiltersCount}
          fullFiltersCount={this.fullFiltersCount}
          isFiltersDisabled={!!release && !this.isFullFormOpened}
        />
      </FormWrapper>
    );
  }
}

MotoForm.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  location: PropTypes.object,

  /** @ignore */
  match: PropTypes.object,

  /** @ignore */
  history: PropTypes.object,
};

MotoForm.contextTypes = {
  servicesApi: PropTypes.object.isRequired,
};

export default withNonPureBreakpoint(withRouter(MotoForm));
