import PropTypes from 'prop-types';

import ColumnLayout from 'core/components/ColumnLayout';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { Mobile } from 'core/components/breakpoint';
import { PageIndent } from 'core/components/Wrappers';
import EmptyWrapper from 'core/components/EmptyWrapper';

import InnerSideColumn from 'site/components/InnerSideColumn';
import { TopBanner } from 'site/components/Ads/mobile';

import TopicHeaderPremium from './TopicHeaderPremium';


function TopicWrapper(props) {
  const {
    infinityIndex,
    isCommentsPage,
    children,
    content,
  } = props;

  const {
    attributes: {
      is_premium: isPremium,
    },
  } = content;

  const Wrapper = isPremium ? EmptyWrapper : ColumnLayout;

  return (
    <PageIndent>
      {infinityIndex > 0 && (
        <Mobile>
          <AdWrapper bottom={30}>
            <TopBanner lazy />
          </AdWrapper>
        </Mobile>
      )}
      {(!isCommentsPage && isPremium) && (
        <TopicHeaderPremium content={content} infinityIndex={infinityIndex} />
      )}
      <Wrapper rightColumn={<InnerSideColumn showBoesque={!isPremium} />}>
        {children}
      </Wrapper>
    </PageIndent>
  );
}

TopicWrapper.propTypes = {
  isCommentsPage: PropTypes.bool,
  infinityIndex: PropTypes.number,
  content: PropTypes.object,
};

TopicWrapper.defaultProps = {
  isCommentsPage: false,
};

export default TopicWrapper;
