/**
 * Утилиты из этого файла должны быть вызваны:
 *   - для обычной версии сайта - в `site/index.js`;
 *   - для AMP-версии - в `site/amp.js`;
 *   - storybook decorators - в `.storybook/preview.js`
 */
/* eslint-disable import/prefer-default-export */
export function initAccounting() {
  const accounting = require('accounting');

  accounting.settings = {
    currency: {
      symbol: '₽',
      format: '%v %s',
      decimal: ',',
      thousand: ' ',
      precision: 0,
    },
    number: {
      precision: 0,
      thousand: ' ',
      decimal: ',',
    },
  };
}
