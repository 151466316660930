import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import Label from 'site/components/Label';
import cardServiceHOC from 'site/components/cardService';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import styles from './index.styl';


function CardServiceRelease1(props) {
  const {
    theme,
    content: {
      attributes: {
        short_name: shortName,
        name,
        url,
        image,
        is_new: isNew,
      },
    },
    content,
    reverseRowInMobile,
    showFullName,
    children,
  } = props;

  if (!content) return null;

  const cover = getThumbnail(image);

  return (
    <div className={cx('cardServiceRelease1', isNew && styles._isNew)}>
      <style jsx>{`
        .${styles.name}
          color ${theme.colors.primary}
          transition color ${theme.animations.hover}

        .cardServiceRelease1
          &:hover
            .${styles.name}
              color ${theme.colors.active1}
      `}</style>
      <Link
        to={url}
        type='secondary'
        className={reverseRowInMobile && styles.releaseModel}
      >
        {cover && <Image
          src={cover}
          maxWidth={180}
        />}
        <div className={reverseRowInMobile && styles.description}>
          <div className={styles.name}>
            {isNew && <Label className={styles.label} type={1} />}
            {showFullName ? name : shortName}
            {children}
          </div>
        </div>
      </Link>
    </div>
  );
}

CardServiceRelease1.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** Данные для карточки, соответствующие модели `carReleaseAttributes` */
  content: modelPropTypes(carReleaseAttributes),
  reverseRowInMobile: PropTypes.bool,
  showFullName: PropTypes.bool,
};

const CardWithHOCs = cardServiceHOC(CardServiceRelease1);
CardWithHOCs.displayName = 'CardServiceRelease1';

export default CardWithHOCs;
export { CardServiceRelease1 as StorybookComponent };
