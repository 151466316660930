import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import queryString from 'core/libs/query-string';

import { getOffset } from 'core/utils/url-helper';

import H1 from 'core/components/H1';
import Feed from 'core/components/Feed';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import { reviewsAttributes } from 'site/utils/prop-types/model';

import ListSortPage from 'site/components/ListSortPage';
import PageLayoutService from 'site/components/PageLayoutService';
import { Context } from 'site/components/Ads/desktop';
import { Listing1 } from 'site/components/Ads/mobile';
import {
  ViewportHeightWrap,
  Indent,
} from 'site/components/Wrappers';

import CardServiceReview1 from 'site/cardsService/CardServiceReview1';
import CardServiceReview2 from 'site/cardsService/CardServiceReview2';


import { buildSearchRequestParams } from 'site/utils';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  reviewSortFilters,
} from 'site/constants';


const REVIEWS_LIMIT = 9;

function Reviews(props) {
  const {
    reviews,
    isMobile,
    match,
    reviewsSeo,
  } = props;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const {
    brand: brandSlug,
    model: modelSlug,
  } = match.params;

  const feedProps = {
    grid: true,
    interitemSpacing: isMobile ? 14 : 20,
    card: isMobile ? CardServiceReview1 : CardServiceReview2,
  };

  function findNameByType(type, slug) {
    if (!slug) return '';
    const foundItem = reviews.included.find(item => item.type === type);
    return foundItem?.attributes.name;
  }

  const brandName = findNameByType('car_brand', brandSlug);
  const modelName = findNameByType('car_model', modelSlug);

  const titleText = `Отзывы об автомобилях ${brandName || ''} ${modelName || ''}`;

  return (
    <PageLayoutService>
      <ViewportHeightWrap>
        <H1 dataQa='page-title'>{titleText}</H1>
        <Indent bottom={vertical} />
        <ListSortPage
          title={reviewsSeo.title}
          description={reviewsSeo.description}
          entitiesData={reviews}
          limit={REVIEWS_LIMIT}
          sortHeader={{
            type: 1,
            filters: reviewSortFilters,
          }}
        >
          {({ content: denormalizedReviews }) => {
            const firstGroup = denormalizedReviews.slice(0, 4);
            const secondGroup = denormalizedReviews.slice(4);
            return (
              <AdWrapper top={vertical} bottom={vertical}>
                <Feed
                  content={firstGroup}
                  {...feedProps}
                />
                <Desktop>
                  <Context />
                </Desktop>
                <Mobile>
                  <Listing1 />
                </Mobile>
                <Feed
                  content={secondGroup}
                  {...feedProps}
                />
                <Indent bottom={vertical} />
              </AdWrapper>
            );
          }}
        </ListSortPage>
      </ViewportHeightWrap>
      <Indent bottom={vertical} />
    </PageLayoutService>
  );
}

Reviews.propTypes = {
  isMobile: PropTypes.bool,
  reviews: reviewsAttributes,
  match: PropTypes.object.isRequired,
  reviewsSeo: PropTypes.object,
};

const dataProvider = resolve({
  reviews: props => {
    const {
      servicesApi,
      renderError,
      match: {
        params: {
          brand,
          model,
          generation,
          body,
        },
      },
      location: { search },
    } = props;

    const queryFilters = queryString.parse(search);
    const sortFilter = queryFilters.sort || '-like';

    const requestParams = buildSearchRequestParams(search);

    return servicesApi
      .getReviews({
        ...requestParams,
        'filter[brand]': brand,
        'filter[model]': model,
        'filter[generation]': generation,
        'filter[body]': body,
        offset: getOffset(search, REVIEWS_LIMIT),
        limit: REVIEWS_LIMIT,
        sort: sortFilter,
        with_filtered_count: true,
        'relations[review]': 'brand,model,release,modification,user',
        include: 'car_brand,car_model,car_release,car_modification,user',
        'attributes[review]': 'base,image,text',
        'attributes[user]': 'base,image',
      })
      .catch(renderError);
  },

  reviewsSeo: ({ servicesApi, reviewsSeo, match }) => {
    if (reviewsSeo) return reviewsSeo;

    return servicesApi.getReviewsSeo(match)
      .then(response => response.data)
      .catch(() => ({}));
  },
});

export default withPageHocs(dataProvider)(withBreakpoint(Reviews));
