import PropTypes from 'prop-types';
import Gallery from 'core/components/Gallery';
import { InGallery } from 'site/components/Ads/desktop';
import { InGallery as InGalleryMobile } from 'site/components/Ads/mobile';


function GalleryWithAds(props) {
  return (
    <Gallery
      {...props.hashNavigation && {
        adsToReload: [
          {
            name: 'Billboard',
            count: 4,
          },
          {
            name: '240x400',
            count: 4,
          },
          {
            name: 'TopBanner',
            count: 4,
          },
        ],
      }}
      injectAds={{
        desktop: {
          component: InGallery,
          count: 5,
        },
        mobile: {
          component: InGalleryMobile,
          count: 5,
        },
      }}
      {...props}
    />
  );
}

GalleryWithAds.propTypes = {
  hashNavigation: PropTypes.bool,
};

export default GalleryWithAds;
