import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import BaseForm from 'site/components/BaseForm';

import { withRouter } from 'core/libs/router';
import queryString from 'core/libs/query-string';

import { carBrandAttributes } from 'core/utils/prop-types/model';

import withPageHocs from 'core/components/withPageHocs';

import { denormalizeData } from 'core/utils/api';
import servicesApiPropTypes from 'site/utils/prop-types/api';
import resolve from 'core/resolver/resolve';

import modelPropTypes, {
  carModelAttributes,
} from 'site/utils/prop-types/model';

import {
  filterEntitiesByRelation,
} from 'site/utils';

import {
  generateSelectOptions,
} from 'site/utils/forms';

import Form from './Form';

import {
  REVIEW_URL,
} from 'site/constants';

import {
  PATHNAME_FIELDS,
  RANGES,
  DEPENDENCIES,
} from './constants';

class ReviewsForm extends BaseForm {
  constructor(props) {
    super(props);

    this.state = this.getFormStateFromLocation();
  }

  pathnameFields = PATHNAME_FIELDS;

  dependencies = DEPENDENCIES;

  ranges = RANGES;

  basePath = REVIEW_URL;

  getFormStateFromLocation = () => {
    const {
      selectorsData,
      match: {
        params,
      },
      location: {
        search,
      },
    } = this.props;

    const filteredBrands = this.getBrandOptions(selectorsData);

    /**
     * Если значение не найдено, то устанавливаем его в null вместо
     * undefined, потому что undefined при сбросе фильтров не воспринимается
     * для некоторых контролов и там остается предыдущее установленное
     * значение.
     */
    const brand = filteredBrands.find(item => (
      item.attributes.slug === params.brand
    )) || null;

    const filteredModels = this.getModelOptions(selectorsData, brand);
    const model = brand && filteredModels.find(item => (
      item.attributes.slug === params.model
    )) || null;

    const {
      year_min: min,
      year_max: max,
    } = queryString.parse(search);

    return {
      brand,
      model,
      year: {
        min,
        max,
      },
    };
  };

  getBrandOptions = memoize(
    (selectorsData = {}) => generateSelectOptions(selectorsData.brands)
  );

  getModelOptions = memoize(
    (selectorsData = {}, brand) => generateSelectOptions(filterEntitiesByRelation('brand', selectorsData.models, [brand]))
  );

  render() {
    const {
      selectorsData,
    } = this.props;

    const {
      brand,
    } = this.state;

    // Зависимые опции
    const filteredBrands = this.getBrandOptions(selectorsData);
    const filteredModels = this.getModelOptions(selectorsData, brand);

    return (
      <Form
        formState={this.state}
        formOptions={{
          filteredBrands,
          filteredModels,
        }}
        onChange={this.handleControlChange}
      />
    );
  }
}

ReviewsForm.propTypes = {
  selectorsData: PropTypes.shape({
    brands: PropTypes.arrayOf(modelPropTypes(carBrandAttributes)),
    models: PropTypes.arrayOf(modelPropTypes(carModelAttributes)),
  }),
  match: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
};

ReviewsForm.contextTypes = {
  servicesApi: servicesApiPropTypes(),
};

const dataProvider = resolve({
  selectorsData({ match, servicesApi, renderError }) {
    return servicesApi
      .getBrands({ 'filter[with_reviews]': true })
      .then(denormalizeData)
      .then(denormalizedBrands => {
        if (!match.params.brand) return [denormalizedBrands];

        return Promise.all([
          denormalizedBrands,
          servicesApi.getModels({
            'filter[with_reviews]': true,
            'filter[brand]': match.params.brand,
            'relations[car_model]': 'brand',
            include: 'car_brand',
          }).then(denormalizeData),
        ]);
      })
      .then(([denormalizedBrands, denormalizedModels]) => ({
        brands: denormalizedBrands || [],
        models: denormalizedModels || [],
      }))
      .catch(renderError);
  },
});

export default withRouter(withPageHocs(dataProvider)(ReviewsForm));
