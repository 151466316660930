import { Fragment } from 'react';

import { Desktop, Mobile } from 'core/components/breakpoint';

import { Context } from 'site/components/Ads/desktop';
import { ListingSpec } from 'site/components/Ads/mobile';

export default function SecondAdvPlace() {
  return (
    <Fragment>
      <Desktop>
        <Context />
      </Desktop>
      <Mobile>
        <ListingSpec />
      </Mobile>
    </Fragment>
  );
}
