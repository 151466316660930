import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import bindProps from 'core/components/bindProps';
import { withNonPureBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import { Section, Block } from 'core/components/Grid';
import Text from 'core/components/Text';

import { Indent } from 'site/components/Wrappers';
import InputDropdown from 'site/components/InputDropdown';
import Chip from 'site/components/Chip';
import InputRange from 'site/components/InputRange';
import FullFilterToggler from 'site/components/FullFilterToggler';
import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';
import FullFormTopline from 'site/components/FullFormTopline';
import FormContainer from 'site/components/FormContainer';
import Divider from 'site/components/Divider';
import RadioClouds from 'site/components/RadioClouds';
import Checkboxes from 'site/components/Checkboxes';
import InputRangeInput from 'site/components/InputRangeInput';
import RadioCloudsRange from 'site/components/RadioCloudsRange';
import ChipRange from 'site/components/ChipRange';
import Switch from 'site/components/Switch';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  FULL_FILTER_HASH,
  SIDE_INDENT_MOBILE,
  SIDE_INDENT,
} from 'site/constants';

import { rangeMarksArray, updateRangeValue } from 'site/utils/forms';

import { TRUNK_VOLUME_OPTIONS, CLEARANCE_OPTIONS, EXCLUDED_STATE_FIELDS } from '../constants';

import styles from './index.styl';


const InputDropdownSingle = bindProps({
  isMulti: false,
})(InputDropdown);

function Form(props) {
  const {
    formState,
    formOptions,
    isMobile,
    onChange,
    location,
    navigateToNewUrl,
    toggleFullForm,
    fullFiltersCount,
    allFiltersCount,
    resetAllForm,
    ranges,
  } = props;

  const {
    filteredBrands,
    filteredModels,
    filteredGenerations,
    filteredBodies,
    filteredConditions,
    filteredTransmissions,
    filteredGeos,
    filteredDefaultGeo,
    filteredEngineTypes,
    filteredControls,
    filteredDealerType,
    filteredCountries,
    filteredClasses,
    filteredAssemblies,
    filteredBoosts,
    filteredSeats,
  } = formOptions;

  const {
    brand,
    model,
    generation,
    body,
    year,
    condition,
    price,
    transmission,
    geo,
    engine_type: engineType,
    control,
    dealer_type: dealerType,
    country,
    class: classType,
    assembly,
    power,
    boost,
    acceleration,
    trunk_volume: trunkVolume,
    clearance,
    number_of_seats: numberOfSeats,
  } = formState;

  const verticalIndent = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const horizontalIndent = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  const isFullFilterOpened = location.hash === FULL_FILTER_HASH;
  // определяем что выбранный регион, соответсвует региону пользователя
  const geoEqualContextGeo = filteredDefaultGeo.value === geo.value;
  // значение для фильтра "Только 7 и более мест"
  const seatsFilter = filteredSeats?.filter(seat => seat >= 7).join();

  const chipRangeDropdownProps = {
    ...isMobile && { contentPadding: (SIDE_INDENT_MOBILE - 2) + 'px' },
    contentPosition: isMobile ? 'center' : 'left',
  };

  return (
    <Fragment>
      {isFullFilterOpened && (
        <FullFormTopline
          toggleFullForm={toggleFullForm}
          navigateToNewUrl={navigateToNewUrl}
          allFiltersCount={allFiltersCount + (geoEqualContextGeo ? -1 : 0)}
          resetAllForm={resetAllForm}
          formType='offers'
          formState={formState}
          excludedStateFields={EXCLUDED_STATE_FIELDS}
          ranges={ranges}
        />
      )}
      <ThemeSwitchWithColor tone='dark'>
        <FormContainer>
          <Section>
            <Block width={12} desktop={4}>
              <Indent right={!isMobile && 20}>
                <InputDropdownSingle
                  name='brand'
                  options={filteredBrands}
                  label='Марка'
                  placeholder='Введите название марки'
                  value={brand}
                  onChange={onChange}
                  isClearable
                  {...isMobile && { hideBorder: true }}
                />
              </Indent>
            </Block>
            <Block width={12} desktop={4}>
              <Indent right={!isMobile && 35} top={isMobile && 1}>
                <InputDropdownSingle
                  name='model'
                  options={filteredModels}
                  label='Модельный ряд'
                  value={model}
                  onChange={onChange}
                  placeholder='Введите название модели'
                  isDisabled={filteredModels.length < 1}
                  isClearable
                  {...isMobile && {
                    roundingPosition: 'bottom',
                    hideBorder: true,
                  }}
                />
              </Indent>
            </Block>
            <Block width={12} desktop={4}>
              <Indent top={isMobile && 20}>
                <InputRange
                  name='price'
                  title='Цена'
                  min={ranges.price.min}
                  max={ranges.price.max}
                  step={100000}
                  unit='₽'
                  value={price}
                  onChange={onChange}
                  arrangeJustify
                />
              </Indent>
            </Block>
          </Section>
          <Indent top={isMobile ? 10 : 5} />
          <Section>
            <div className={styles.chipWrapper}>
              <Chip
                name='condition'
                options={filteredConditions}
                placeholder='Состояние'
                menuWidth={290}
                value={condition}
                onChange={onChange}
              />
            </div>
            <div className={styles.chipWrapper}>
              <ChipRange
                name='year'
                title='Год выпуска'
                placeholder='Год выпуска'
                min={ranges.year.min}
                max={ranges.year.max}
                value={year}
                onChange={onChange}
                dropdownProps={chipRangeDropdownProps}
              />
            </div>
            <div className={styles.chipWrapper}>
              <Chip
                name='generation'
                options={filteredGenerations}
                placeholder='Поколение'
                value={generation}
                isDisabled={filteredGenerations.length < 1}
                isMulti={false}
                onChange={onChange}
                closeMenuOnSelect
                {...!(isFullFilterOpened || isMobile) && { overflowSelectValue: 150 }}
              />
            </div>
            <div className={styles.chipWrapper}>
              <Chip
                name='body'
                placeholder='Кузов'
                options={filteredBodies}
                value={body}
                onChange={onChange}
              />
            </div>
            <div className={styles.chipWrapper}>
              <Chip
                name='engine_type'
                options={filteredEngineTypes}
                placeholder='Двигатель'
                value={engineType}
                onChange={onChange}
              />
            </div>
            <div className={styles.chipWrapper}>
              <Chip
                name='transmission'
                placeholder='Коробка передач'
                options={filteredTransmissions}
                value={transmission}
                onChange={onChange}
              />
            </div>
            <div className={styles.chipWrapper}>
              <Chip
                name='geo'
                options={filteredGeos}
                placeholder='Регион'
                value={geo}
                onChange={(value, context) => {
                  /**
                   * При сбросе фильтра устанавливаем регион,
                   * который задан в geoContext пользователя.
                   */
                  if (!value) {
                    onChange(filteredDefaultGeo, context);
                    return;
                  }
                  onChange(value, context);
                }}
                isMulti={false}
                {...!(isFullFilterOpened || isMobile) && { overflowSelectValue: 150 }}
                isClearable={!geoEqualContextGeo}
                showDropdownWithValue={geoEqualContextGeo}
                closeMenuOnSelect
              />
            </div>
            <div className={styles.fullFilterToggler}>
              <Indent top={15}>
                <FullFilterToggler
                  location={location}
                  toggleFullForm={toggleFullForm}
                  fullFiltersCount={fullFiltersCount}
                />
              </Indent>
            </div>
          </Section>
          {isFullFilterOpened && (
            <Fragment>
              <Divider
                type={isMobile ? 4 : 3}
                top={isMobile ? verticalIndent : verticalIndent / 2}
                bottom={verticalIndent}
                right={-horizontalIndent}
                left={-horizontalIndent}
              />
              <RadioClouds
                title='Где искать'
                name='dealer_type'
                onChange={onChange}
                value={dealerType}
                options={filteredDealerType || []}
                customDefaultOption={{
                  title: 'везде',
                  value: '',
                  label: 'везде',
                }}
              />
              <Divider
                type={isMobile ? 4 : 3}
                top={verticalIndent}
                bottom={verticalIndent}
                right={-horizontalIndent}
                left={-horizontalIndent}
              />
              <Section>
                <Block calcWidthByContent>
                  <Text is='div'>Производители</Text>
                  <Indent bottom={20} />
                  <div className={styles._chipSlimmer}>
                    <Chip
                      name='country'
                      placeholder='Любые'
                      hideTitleFromMenu
                      options={filteredCountries}
                      value={country}
                      onChange={onChange}
                    />
                  </div>
                </Block>
                <Block mobile={12} width='90px'>
                  <Mobile>
                    <Divider
                      type={4}
                      top={verticalIndent}
                      bottom={verticalIndent}
                      left={-SIDE_INDENT_MOBILE}
                      right={-SIDE_INDENT_MOBILE}
                    />
                  </Mobile>
                </Block>
                <Block calcWidthByContent>
                  <Text is='div'>Класс</Text>
                  <Indent bottom={20} />
                  <Chip
                    name='class'
                    placeholder='Любой'
                    hideTitleFromMenu
                    options={filteredClasses}
                    value={classType}
                    onChange={onChange}
                  />
                </Block>
              </Section>
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <RadioClouds
                title='Сборка'
                name='assembly'
                onChange={onChange}
                value={assembly}
                options={filteredAssemblies || []}
              />
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Section>
                <Block mobile={12}>
                  <InputRange
                    name='power'
                    title='Мощность (л.с.)'
                    value={power}
                    onChange={onChange}
                    min={ranges.power.min}
                    max={ranges.power.max}
                    step={10}
                    {...!isMobile && {
                      marks: rangeMarksArray(ranges.power.min, ranges.power.max, 70),
                    }}
                  />
                </Block>
                <Block mobile={12} desktop='80px'>
                  <Mobile>
                    <Divider
                      type={4}
                      top={verticalIndent}
                      bottom={verticalIndent}
                      left={-SIDE_INDENT_MOBILE}
                      right={-SIDE_INDENT_MOBILE}
                    />
                  </Mobile>
                </Block>
                <Block mobile={12}>
                  <Checkboxes
                    title='Наддув'
                    options={filteredBoosts || []}
                    inputName='boost'
                    checkboxesState={boost}
                    onChange={onChange}
                  />
                </Block>
              </Section>
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <RadioClouds
                title='Привод'
                name='control'
                onChange={onChange}
                value={control}
                options={filteredControls || []}
              />
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Section>
                <Block mobile={12}>
                  <InputRange
                    name='acceleration'
                    title='Разгон до 100 (сек.)'
                    value={acceleration}
                    onChange={onChange}
                    min={ranges.acceleration.min}
                    max={ranges.acceleration.max}
                    step={1}
                    {...!isMobile && {
                      marks: rangeMarksArray(ranges.acceleration.min, ranges.acceleration.max, 1),
                      customStartMark: 'min',
                      customEndMark: 'max',
                    }}
                  />
                </Block>
                <Block mobile={0} desktop='80px' />
                <Block mobile={12} />
              </Section>
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Desktop>
                <InputRangeInput
                  label='от'
                  name='trunk_volume'
                  value={trunkVolume.min}
                  onChange={onChange}
                  min={ranges.trunk_volume.min}
                  max={ranges.trunk_volume.max}
                  blurHandler={onBlurValue => updateRangeValue({
                    min: onBlurValue,
                    value: trunkVolume,
                    range: ranges.trunk_volume,
                    onChange: onChange,
                    name: 'trunk_volume',
                  })}
                  formatNumberType={1}
                  title='Объем багажника (л.)'
                />
              </Desktop>
              <Mobile>
                <Text>Объем багажника (л.)</Text>
              </Mobile>
              <Indent bottom={20} />
              <RadioCloudsRange
                name='trunk_volume'
                value={trunkVolume}
                onChange={onChange}
                options={TRUNK_VOLUME_OPTIONS}
                ranges={ranges.trunk_volume}
              />
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Section>
                <Block mobile={12}>
                  <InputRange
                    name='clearance'
                    title={(isMobile ? 'Клиренс' : 'Дорожный просвет') + ' (мм.)'}
                    value={clearance}
                    onChange={onChange}
                    min={ranges.clearance.min}
                    max={ranges.clearance.max}
                    step={1}
                    {...!isMobile && {
                      marks: [ranges.clearance.min, 105, 140, 175, 210, 245, ranges.clearance.max],
                      customStartMark: 'min',
                      customEndMark: 'max',
                    }}
                  />
                </Block>
                <Block mobile={12} desktop='80px' />
                <Block mobile={0}>
                  <div className={styles.clearance}>
                    <RadioCloudsRange
                      name='clearance'
                      value={clearance}
                      onChange={onChange}
                      options={CLEARANCE_OPTIONS}
                      ranges={ranges.clearance}
                    />
                  </div>
                </Block>
              </Section>
              <Divider
                type={4}
                top={verticalIndent}
                bottom={VERTICAL_INDENT}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Switch
                input={{
                  name: 'number_of_seats',
                  checked: numberOfSeats === seatsFilter,
                  onChange: checked => onChange(checked ? seatsFilter : undefined, { name: 'number_of_seats' }),
                }}
                labelAfter='Только 7 и более мест'
              />
              <Mobile><Indent bottom={50} /></Mobile>
            </Fragment>
          )}
        </FormContainer>
      </ThemeSwitchWithColor>
    </Fragment>
  );
}

Form.propTypes = {
  isMobile: PropTypes.bool,
  formState: PropTypes.object,
  formOptions: PropTypes.object,
  location: PropTypes.object,
  onChange: PropTypes.func,
  navigateToNewUrl: PropTypes.func,
  toggleFullForm: PropTypes.func,
  resetAllForm: PropTypes.func,
  fullFiltersCount: PropTypes.number,
  allFiltersCount: PropTypes.number,
  ranges: PropTypes.object,
};

Form.contextTypes = {
  servicesApi: PropTypes.object.isRequired,
};

export default withNonPureBreakpoint(withRouter(Form));
