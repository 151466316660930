import { compose } from 'core/libs/recompose';

import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';
import ContentSpecNativeStyles from './styles/card1';
import ListingSpecStyles from './styles/listing-spec';

/**
 * У мобильных баннеров нужно указывать мобильный `siteId` (`begun-auto-pad`).
 */

const MobileAd = bindProps({
  siteId: '579291639',
  height: 250,
})(Ad);

const listingSpecProps = bindProps({
  name: 'Listing Spec (COM)',
  lazy: true,
  blockId: '579291649',
});

export const TopBanner = bindProps({
  name: 'TopBanner',
  blockId: '579291641',
})(MobileAd);

export const Fullscreen = bindProps({
  name: 'Fullscreen',
  height: null,
  blockId: '579291661',
})(MobileAd);

export const Rich = bindProps({
  name: 'Rich',
  height: null,
  blockId: '579291645',
})(MobileAd);

export const Listing1 = bindProps({
  name: 'Listing1 (COM)',
  lazy: true,
  blockId: '579291657',
})(MobileAd);

export const Listing2 = bindProps({
  name: 'Listing2 (COM)',
  lazy: true,
  blockId: '579291655',
})(MobileAd);

export const Listing3 = bindProps({
  name: 'Listing3 (COM)',
  lazy: true,
  blockId: '579291653',
})(MobileAd);

export const Listing4 = bindProps({
  name: 'Listing4 (COM)',
  lazy: true,
  blockId: '579291651',
})(MobileAd);

export const ListingSpec = compose(
  listingSpecProps,
  ListingSpecStyles,
)(MobileAd);

export const Footer = bindProps({
  name: 'Footer (COM)',
  lazy: true,
  blockId: '579291663',
})(MobileAd);

export const Content1 = bindProps({
  name: 'Content1 (COM)',
  lazy: true,
  blockId: '579291675',
})(MobileAd);

export const Content2 = bindProps({
  name: 'Content2 (COM)',
  lazy: true,
  blockId: '579291673',
})(MobileAd);

export const Content3 = bindProps({
  name: 'Content3 (COM)',
  lazy: true,
  blockId: '579291671',
})(MobileAd);

export const Content4 = bindProps({
  name: 'Content4 (COM)',
  lazy: true,
  blockId: '579291669',
})(MobileAd);

export const Content5 = bindProps({
  name: 'Content5 (COM)',
  lazy: true,
  blockId: '579291667',
})(MobileAd);

export const ContentSpecAsCard1 = compose(
  bindProps({
    name: 'Content Spec (COM)',
    lazy: true,
    blockId: '579291665',
  }),
  ContentSpecNativeStyles,
)(MobileAd);

export const ContentMarketing = bindProps({
  name: 'Content Marketing (COM)',
  lazy: true,
  blockId: '579291647',
})(MobileAd);

export const InPage = bindProps({
  name: 'InPage',
  height: 200,
  blockId: '579291659',
})(MobileAd);

export const Sponsored = compose(
  bindProps({
    name: 'Sponsored (COM)',
    height: null,
    blockId: '579291643',
  }),
  sponsoredStyles,
)(MobileAd);

export const InGallery = bindProps({
  name: 'InGallery',
  blockId: '579389976',
  disableAutoInit: true,
  height: null,
})(MobileAd);
