import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import PremiumTopicHeader from 'core/components/PremiumTopicHeader';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import SocialShare from 'site/components/SocialShare';
import { Indent } from 'site/components/Wrappers';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import styles from './index.styl';


function TopicHeaderPremium(props) {
  const {
    content,
    isMobile,
    theme: {
      controls: {
        topicHeaderTexts: atoms,
        topicContent: topicContentAtoms,
      },
    },
    infinityIndex,
  } = props;

  const {
    attributes: {
      link,
      is_comments_disabled: isCommentsDisabled,
      announce,
    },
  } = content;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Indent bottom={vertical}>
      <style jsx>{`
        .${styles.lead}
          font ${atoms.lead.font}
          color ${atoms.lead.color}
          letter-spacing ${atoms.lead.letterSpacing || 0}
        .${styles.wrapper}
          :global(.desktop) &
            max-width ${topicContentAtoms.widgetsMaxWidth}px
      `}</style>
      {(infinityIndex !== 0) && (
        <PremiumTopicHeader
          content={content}
          isPremiumInInfinity
        />
      )}
      <div className={styles.wrapper}>
        <MarkdownWrapper className={styles.lead}>
          {announce}
        </MarkdownWrapper>
        <Indent top={vertical} />
        <SocialShare topicLink={link} isCommentsDisabled={isCommentsDisabled} />
      </div>
    </Indent>
  );
}

TopicHeaderPremium.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
  infinityIndex: PropTypes.number,
};

export default withBreakpoint(withTheme(TopicHeaderPremium));
