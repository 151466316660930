import { denormalizeData } from 'core/utils/api';

import resolve from 'core/resolver/resolve';

import { isMotoPath } from 'site/utils';
import rejectEmptyResult from 'site/utils/rejectEmptyResult';


export default resolve({
  offerWithDealer: props => {
    const {
      renderError,
      servicesApi,
      match: {
        params: {
          slug,
        },
      },
      location: {
        pathname,
      },
    } = props;

    const isMoto = isMotoPath(pathname);
    const vehiclePrefix = isMoto ? 'moto' : 'car';

    const offersAPI = isMoto ? 'getMotoOffers' : 'getOffers';

    return servicesApi[offersAPI]({
      'filter[offer]': slug,
      'filter[with_inactive]': true,
      [`relations[dealer_offer${isMoto ? '_moto' : ''}]`]: 'body,release,modification,geo,dealer,class',
      'relations[dealer]': 'geo_parent',
      'include': `car_modification,${vehiclePrefix}_release,${vehiclePrefix}_body,${vehiclePrefix}_class,geo,dealer,moto_class`,
      [`attributes[dealer_offer${isMoto ? '_moto' : ''}]`]: 'base,text,media',
      [`attributes[${vehiclePrefix}_modification]`]: 'base,extended',
      [`attributes[${vehiclePrefix}_release]`]: 'base,media,extended',
      'limit': 1,
    })
      .then(offersData => {
        const offer = denormalizeData(offersData)[0];

        if (!offer) return rejectEmptyResult();

        const hasRelatedDealer = Boolean(offer.relationships) && Boolean(offer.relationships.dealer);
        const included = offersData.included || [];
        // Из полученных данных собираем дилера со связью geo_parent для передачи в DealerInfo
        const dealer = hasRelatedDealer
          ? denormalizeData({
            data: {
              ...offer.relationships.dealer.data,
              relationships: (included.find(({ type }) => type === 'dealer') || {}).relationships,
            },
            included,
          })
          : undefined;

        return {
          offer,
          dealer,
        };
      })
      .catch(renderError);
  },

  offerSeo: ({ servicesApi, consoleError, match, location }) => {
    const isMoto = isMotoPath(location.pathname);

    const seoAPI = isMoto ? servicesApi.getMotoOffersOfferSeo : servicesApi.getCarOffersOfferSeo;

    return seoAPI({
      'filter[geo]': match.params.geo,
      'filter[offer]': match.params.slug,
    })
      .then(denormalizeData)
      .catch(consoleError('offerSeo', {}));
  },
});
