export default {
  1: {
    borderRadius: '5px',
    padding: '0 10px',
  },
  2: {
    borderRadius: 0,
    padding: 0,
  },
  3: {
    borderRadius: '5px',
    padding: '0 10px',
    withIndents: true,
  },
  4: {
    borderRadius: 0,
    padding: 0,
    withIndents: true,
  },
};
