import PropTypes from 'prop-types';
import StarRatings from 'react-star-ratings';
import { get } from 'core/libs/lodash';

import resolveRelationships from 'core/utils/relationships';
import themePropTypes from 'core/utils/prop-types/theme';

import bindProps from 'core/components/bindProps';
import withTheme from 'core/components/theme';

import styles from './index.styl';

const StarRatingsWithConfig = bindProps({
  starRatedColor: '#858585',
  numberOfStars: 5,
  name: 'reviewRating',
  starSpacing: '3px',
})(StarRatings);

const relationships = resolveRelationships(['modification'], {}, {});


function ReviewTable({ theme, review }) {
  const {
    attributes: {
      ratings,
    },
  } = review;

  const {
    modification: {
      power,
      name: modificationName,
      specifications_primary: specificationsPrimary,
    },
  } = relationships(review);

  const engineString = [
    get(specificationsPrimary, 'engine.value'),
    modificationName,
    power ? `${power} л.с.` : '',
  ].filter(Boolean).join(', ');

  const commonRating = ratings[ratings.length - 1];
  const specifications = [
    ['Двигатель', engineString],
    ['Трансмиссия', get(specificationsPrimary, 'transmission.value')],
    ['Привод', get(specificationsPrimary, 'drive.value')],
    ['Разгон до 100 км/ч', get(specificationsPrimary, 'acceleration.value'), 'с'],
    ['Максимальная скорость', get(specificationsPrimary, 'max_speed.value'), 'км/ч'],
    ['Расход топлива', get(specificationsPrimary, 'fuel_consumption_mixed.value'), 'л/100 км'],
  ].filter(item => Boolean(item[1]));

  return (
    <div className={styles.reviewTable}>
      <style jsx>{`
        .${styles.header}
          font-family ${theme.fonts.display}

        .${styles.section}
          & + &
            border-color ${theme.colors.divider}
      `}</style>
      <div className={styles.section}>
        <div className={styles.header}>
          <div>Оценка автора</div>
          <div>
            <StarRatingsWithConfig
              rating={Number(commonRating.value)}
              starDimension='20px'
            />
            <span className={styles.ratingValue}>{Number.parseFloat(commonRating.value).toFixed(1)}</span>
          </div>
        </div>

        {ratings.slice(0, ratings.length - 1).map(({ value, category }) => (
          <div className={styles.item} key={category.name}>
            <div>{category.name}</div>
            <div>
              <StarRatingsWithConfig
                rating={Number(value)}
                starDimension='17px'
              />
              <span className={styles.ratingValue}>{Number.parseFloat(value).toFixed(1)}</span>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.section}>
        <div className={styles.header}>
          <div>Характеристики</div>
        </div>

        {specifications.map(([name, value, unit]) => (
          <div className={styles.item} key={name}>
            <span>{name}</span>
            <span>{`${value} ${unit || ''}`}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

ReviewTable.propTypes = {
  review: PropTypes.object,
  theme: themePropTypes(`{
    colors: {
      divider,
    },
  }`),
};

export default withTheme(ReviewTable);
