import color from 'core/libs/color';

import deepmerge from 'core/libs/deepmerge';

import bindProps from 'core/components/bindProps';

import Logo from 'site/components/Logo';
import LogoLime from 'site/icons/LogoLime.svg';


const LogoSm = bindProps({ type: 6 })(Logo);

export default (colors) => {
  colors.content = colors.active2;
  colors.primary = '#FFF';
  colors.text = '#FFF';
  return deepmerge(
    {
      controls: {
        button: {
          secondary: {
            idle: {
              color: colors.text,
              background: 'transparent',
              border: `1px solid ${color(colors.text).alpha(0.2).string()}`,
            },

            hover: {
              color: '#FFF',
              background: 'rgba(255, 255, 255, 0.1)',
              border: `1px solid ${color(colors.text).alpha(0.4).string()}`,
            },

            active: {
              color: '#FFF',
              background: 'transparent',
              border: `1px solid ${color(colors.text).alpha(0.4).string()}`,
            },

            disabled: {
              color: color(colors.text).alpha(0.25).string(),
              background: 'transparent',
              border: `1px solid ${color(colors.text).alpha(0.1).string()}`,
            },
          },

          primary: {
            idle: {
              color: colors.content,
              background: 'linear-gradient(360deg, #CEFF00 0%, #E0FF30 47.52%, #EDFF00 100%), #FFEA00',
              border: 'none',
            },

            hover: {
              color: colors.content,
              background: 'linear-gradient(360deg, #CEFF00 0%, #E0FF30 47.52%, #EDFF00 100%), #FFEA00',
              border: 'none',
            },

            active: {
              color: colors.content,
              background: 'linear-gradient(360deg, #CEFF00 0%, #E0FF30 47.52%, #EDFF00 100%), #FFEA00',
              border: 'none',
            },

            disabled: {
              color: colors.content,
              background: 'linear-gradient(360deg, #CEFF00 0%, #E0FF30 47.52%, #EDFF00 100%), #FFEA00',
              border: 'none',
            },
          },

          primaryGrey: {
            idle: {
              color: colors.text,
              background: '#424242',
              border: 'none',
            },

            hover: {
              color: colors.text,
              background: '#424242',
              border: 'none',
            },

            active: {
              color: colors.text,
              background: '#fff',
              border: 'none',
            },

            disabled: {
              color: colors.text,
              background: '#fff',
              border: 'none',
            },
          },

        },
        paginatron: {
          outerButtons: {
            innerBorder: `1px solid ${color(colors.text).alpha(0.2).string()}`,
            idle: {
              background: 'transparent',
              color: colors.text,
            },
            disabled: {
              background: 'transparent',
              color: colors.text,
            },
            hover: {
              background: 'rgba(255, 255, 255, 0.1)',
              color: colors.text,
            },
          },
        },
        chip: {
          idle: {
            color: '#FFF',
            backgroundColor: 'transparent',
            borderColor: 'rgba(255, 255, 255, 0.4)',
            indicatorColor: '#FFF',
            indicatorBackground: 'transparent',
          },
          hover: {
            color: '#FFF',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderColor: 'rgba(255, 255, 255, 0.4)',
            indicatorColor: '#FFF',
            indicatorBackground: 'transparent',
          },
          active: {
            color: '#FFF',
            backgroundColor: 'transparent',
            borderColor: '#FFF',
            indicatorColor: '#FFF',
            indicatorBackground: 'transparent',
          },
          hasValue: {
            color: colors.active2,
            backgroundColor: '#FFF',
            borderColor: '#FFF',
            indicatorColor: '#FFF',
            indicatorBackground: colors.active2,
          },
          hasValueHover: {
            color: colors.active2,
            backgroundColor: '#FFF',
            borderColor: '#FFF',
            indicatorColor: '#FFF',
            indicatorBackground: colors.error,
          },
          disabled: {
            color: 'rgba(255, 255, 255, 0.4)',
            backgroundColor: 'transparent',
            borderColor: 'rgba(255, 255, 255, 0.2)',
            indicatorColor: 'rgba(255, 255, 255, 0.4)',
            indicatorBackground: 'transparent',
          },
        },
        inputCompact: {
          label: {
            color: 'rgba(255, 255, 255, 0.4)',
          },
          input: {
            color: 'rgba(255, 255, 255, 1)',
          },
          idle: {
            borderColor: 'rgba(255, 255, 255, 0.2)',
          },
          hover: {
            borderColor: 'rgba(255, 255, 255, 0.5)',
          },
          focus: {
            borderColor: 'rgba(255, 255, 255, 1)',
          },
          invalid: {
            borderColor: colors.error,
          },
        },
        inputDropdown: {
          idle: {
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            color: '#FFF',
            labelColor: '#FFF',
            borderBottom: '2px solid rgba(255, 255, 255, 0.4)',
            indicatorColor: 'rgba(255, 255, 255, 0.5)',
          },
          hover: {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            color: '#FFF',
            labelColor: '#FFF',
            borderBottom: '2px solid rgba(255, 255, 255, 0.4)',
            indicatorColor: 'rgba(255, 255, 255, 0.6)',
          },
          active: {
            backgroundColor: '#FFF',
            color: '#000',
            labelColor: 'rgba(0, 0, 0, 0.6)',
            borderBottom: '2px solid transparent',
            indicatorColor: color(colors.active1).alpha(0.5).string(),
          },
          hasValue: {
            backgroundColor: '#FFF',
            color: '#000',
            labelColor: 'rgba(0, 0, 0, 0.6)',
            borderBottom: '2px solid transparent',
            indicatorColor: color(colors.active1).alpha(0.5).string(),
          },
          disabled: {
            backgroundColor: 'rgba(187, 187, 187, 0.15)',
            color: '#FFF',
            labelColor: 'rgba(255, 255, 255, 0.3)',
            borderBottom: '2px solid transparent',
            indicatorColor: 'rgba(255, 255, 255, 0.3)',
          },
        },
        inputRange: {
          title: {
            color: '#FFF',
          },
          input: {
            color: '#FFF',
          },
          label: {
            color: 'rgba(255, 255, 255, 0.4)',
          },
          track: {
            outside: {
              background: 'rgba(255, 255, 255, 0.2)',
            },
            between: {
              background: '#FFF',
            },
          },
          thumb: {
            background: '#FFF',
          },
          unit: {
            color: 'rgba(255, 255, 255, 0.5)',
          },
          mark: {
            color: '#FFF',
          },
        },
        switcher: {
          colors: {
            off: color(colors.content).mix(color(colors.primary), 0.5).hex(),
            on: colors.primary,
            boxShadow: colors.primary, // It's important for accessibility.
            text: colors.text,
          },
        },
        drum: {
          arrow: {
            fade: {
              outer: color(colors.active2).alpha(0.7).string(),
              inner: color(colors.active2).alpha(0).string(),
            },
          },
          idle: {
            color: colors.text,
          },
          hover: {
            color: (
              color(colors.text).alpha(0.6).string()
            ),
          },
        },
        radioCloud: {
          idle: {
            color: colors.text,
            background: color(colors.active2).mix(color('#FFF'), 0.3).string(),
          },
          hover: {
            color: colors.text,
            background: color(colors.active2).mix(color('#FFF'), 0.4).string(),
          },
          hoverChecked: {
            color: colors.active2,
            background: colors.primary,
          },
          checked: {
            color: colors.active2,
            background: colors.primary,
          },
          focus: {
            color: colors.text,
            background: color(colors.active2).mix(color('#FFF'), 0.3).string(),
          },
          focusChecked: {
            color: colors.active2,
            background: colors.primary,
          },
          disabled: {
            color: color(colors.primary).alpha(0.5).string(),
            background: color(colors.active2).mix(color('#FFF'), 0.1).string(),
          },
          disabledChecked: {
            color: color(colors.active2).alpha(0.9).string(),
            background: color('#FFF').alpha(0.9).string(),
          },
        },
        comments: {
          const: {
            themeMode: 'dark',
          },
        },
        shapka: {
          logo: {
            Icon: LogoSm,
            width: 190,
            height: 44,
          },
        },
        scooter: {
          logo: {
            Icon: LogoLime,
            width: 100,
            height: 44,
          },
          social: {
            idle: {
              color: '#FFF',
              background: '#292929',
              border: 'none',
            },
            hover: {
              color: '#FFF',
              background: '#292929',
              border: 'none',
            },
          },
        },
        topicTopline: {
          rubric: {
            color: '#ADFF00',
          },
        },
        reactions: {
          topic: {
            idle: {
              color: colors.active2,
            },
            checked: {
              color: colors.active2,
            },
            focus: {
              color: colors.active2,
            },
            disabled: {
              color: color(colors.active2),
            },
            checkedDisabled: {
              color: colors.active2,
            },
          },
        },
        reactionsEmojiBar: {
          const: {
            background: colors.lime1,
            border: 'none',
            boxShadow: 'none',
          },
          title: {
            color: '#000',
          },
        },
        login: {
          popup: {
            content: {
              color: colors.primary,
            },
          },
        },
      },
    }, colors);
};
