import { Fragment } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import { withRouter } from 'core/libs/router';

import resolveRelationships from 'core/utils/relationships';
import { capitalize } from 'core/utils/string-helper';

import withTheme from 'core/components/theme';

import H3 from 'core/components/H3';

import TitleBackward from 'site/components/TitleBackward';
import Price from 'site/components/Price';

import { MOTO_URL } from 'site/constants';
import { isMotoPath } from 'site/utils';

import styles from './index.styl';

const relationships = resolveRelationships(['release', 'geo'], {},
  {
    release: {
      name: '',
      url: '',
    },
    geo: {
      name: '',
      url: '',
    },
  });

function Header({ offer, match, theme, location }) {
  const {
    attributes: {
      price,
      status,
      condition_title: conditionTitle,
    },
  } = offer;

  const {
    brand,
    model,
    generation,
  } = match.params;

  const {
    release: {
      name: releaseName,
    },
    geo: {
      url: geoUrl,
    },
  } = relationships(offer);

  const isMoto = isMotoPath(location.pathname);
  const url = `${isMoto ? MOTO_URL : ''}/inventory/${geoUrl}/${brand}/${model}/${generation}`;

  return (
    <Fragment>
      <style jsx>{`
        .condition
          color ${theme.colors.green}

        .sold
          color ${theme.colors.error}
      `}</style>
      <TitleBackward
        url={url}
        title={(
          <div className={styles.title}>
            <H3 is='h1' className={styles.heading}>
              {status === 'sold'
                ? <span className='sold'>{isMoto ? 'Мотоцикл продан' : 'Машина продана'}</span>
                : <span className='condition'>{capitalize(conditionTitle)}</span>
              }
              {' '}
              {releaseName}
            </H3>
            <Price
              className={styles.price}
              value={accounting.formatMoney(price)}
              type={2}
            />
          </div>
        )}
      />
    </Fragment>
  );
}


Header.propTypes = {
  offer: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  theme: PropTypes.object,
};

export default withRouter(withTheme(Header));
