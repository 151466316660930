import PropTypes from 'prop-types';
import { useContext } from 'react';
import accounting from 'accounting';

import styles from './index.styl';

import ArrowDropdown from 'site/icons/ArrowDropdown';

import { ConfiguratorContext } from 'site/components/ConfiguratorProvider';

export default function Prices({ modificationId, basePrice, hideArrow }) {
  const configurations = useContext(ConfiguratorContext);
  const {
    configuration: {
      installed_price: installedPrice,
      price,
    },
  } = configurations[modificationId];

  const totalPrice = price || basePrice;

  if (!totalPrice) return null;

  return (
    <div className={styles.prices}>
      <div className={styles.price}>{accounting.formatMoney(price || basePrice)}</div>
      <div className={styles.additional}>
        Включая опции: {accounting.formatNumber(installedPrice)}
      </div>
      {!hideArrow && <ArrowDropdown className={styles.arrow} />}
    </div>
  );
}

Prices.propTypes = {
  basePrice: PropTypes.number,
  hideArrow: PropTypes.bool,
  modificationId: PropTypes.string,
};
