import { compose, withContext } from 'core/libs/recompose';

import servicesApiPropTypes from 'site/utils/prop-types/api';

import { servicesApi } from './ServicesApiClient';

/**
 * Создаем контекст для сервисной апишки.
 *
 * Описываем контекст апи в легаси стиле.
 * Это сделано для того, чтобы апишка была доступна в props.servicesApi core/resolver/resolve.
 * Т.к. сама эта утилита написана в легаси стиле.
 */
export default function servicesApiHOC(Component) {
  return compose(
    withContext(
      { servicesApi: servicesApiPropTypes() },
      () => ({ servicesApi }),
    ),
  )(Component);
}
