import PropTypes from 'prop-types';
import cx from 'classnames';

import resolve from 'core/resolver/resolve';
import { denormalizeData } from 'core/utils/api';

import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';
import AdWrapper from 'core/components/Ad/AdWrapper';
import Page from 'core/components/Page';

import ReleasePromo from 'site/components/ReleasePromo';
import Price from 'site/components/Price';
import ButtonCompare from 'site/components/ButtonCompare';
import Specifications from 'site/components/Specifications';
import SpecificationList from 'site/components/SpecificationList';
import Classmates from 'site/components/Classmates';
import SimilarOffers from 'site/components/SimilarOffers';
import WhereToBuy from 'site/components/WhereToBuy';
import { Listing2 } from 'site/components/Ads/mobile';
import { Indent, NegativeMobile } from 'site/components/Wrappers';

import modelPropTypes, { motoReleaseAttributes, seoAttributes } from 'site/utils/prop-types/model';
import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


import styles from './index.styl';

function MotoReleasePage(props) {
  const {
    seo,
    release,
    isMobile,
  } = props;

  const {
    id: releaseId,
    attributes: {
      min_price: minPrice,
      max_price: maxPrice,
      is_price_undeclared: isPriceUndeclared,
      specifications,
      specification_groups: specificationGroups,
    },
    type,
  } = release;

  const averagePrice = (+minPrice + +maxPrice) / 2;
  const brandName = release?.relationships?.brand?.attributes?.name;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Page
      title={seo.title}
      description={seo.description}
    >
      <AdWrapper bottom={vertical}>
        <div className={cx(isMobile && styles.mobile)}>
          <NegativeMobile>
            <ReleasePromo release={release} />
          </NegativeMobile>
          <Indent bottom={vertical} />
          <div className={styles.panel}>
            {Boolean(averagePrice || isPriceUndeclared) &&
            <Price
              value={averagePrice}
              isPriceUndeclared={isPriceUndeclared}
              type={2}
            /> }
            <Mobile>
              <Indent bottom={vertical} />
            </Mobile>
            <ButtonCompare modelId={releaseId} transportType={type} />
          </div>
          <Indent bottom={vertical} />
          <Specifications modification={release} />
          <Indent bottom={vertical} />
          <SpecificationList
            specifications={specifications}
            specificationGroups={specificationGroups}
          />
          <Indent bottom={vertical} />
          <Classmates withShadowBg />
          <SimilarOffers />
        </div>
        <Indent bottom={vertical} />
        <WhereToBuy brandName={brandName} />
        <Indent bottom={vertical} />
        <Mobile>
          <Listing2 />
        </Mobile>
      </AdWrapper>
    </Page>
  );
}

const dataProvider = resolve({
  release: props => {
    const {
      servicesApi,
      renderError,
      match,
    } = props;

    const {
      brand: brandSlug,
      model: modelSlug,
      release: releaseSlug,
    } = match.params;

    return (
      servicesApi
        .getMotoReleases({
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[year]': releaseSlug,
          'filter[usages]': 'catalog,archive',
          include: 'moto_brand,moto_release',
          'attributes[moto_release]': 'base,image,media,specifications,extended',
          'attributes[moto_brand]': 'base,image',
          'relations[moto_release]': 'brand',
          limit: 1,
        })
        .then(releasesData => denormalizeData(releasesData)[0])
        .then(rejectEmptyResult)
        .catch(renderError)
    );
  },

  seo: ({ servicesApi, consoleError, match }) => {
    return servicesApi
      .getMotoReleaseSeo({
        'filter[brand]': match.params.brand,
        'filter[model]': match.params.model,
        'filter[year]': match.params.release,
      })
      .then(denormalizeData)
      .catch(consoleError('seo', {}));
  },
});

MotoReleasePage.propTypes = {
  isMobile: PropTypes.bool,
  release: modelPropTypes(motoReleaseAttributes),
  seo: seoAttributes,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default withPageHocs(dataProvider)(withBreakpoint(MotoReleasePage));
