import PropTypes from 'prop-types';
import Video from 'core/components/Video';
import resolveRelationships from 'core/utils/relationships';

const relationships = resolveRelationships(
  ['video'],
  {},
  { video: {} },
);

export default function TopicHeaderVideo({ content }) {
  const {
    video: {
      code, provider,
    },
  } = relationships(content);

  return code && provider ? (
    <Video
      code={code}
      providerName={provider}
    />
  ) : null;
}

TopicHeaderVideo.propTypes = {
  content: PropTypes.object,
};
