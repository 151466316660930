function getNotInstalledDependencies(dependencies, installedOptions) {
  return [].concat(dependencies)
    .filter(({ child }) => !~installedOptions.findIndex(({ id: localId }) => localId === child));
}

function summarize(dependencies, options, packages) {
  return dependencies
    .map(({ child }) => (options[child] || packages[child] || {}).price)
    .reduce((sum, dependencyPrice) => sum + dependencyPrice, 0);
}

/**
 * Зависимсти за исключением радио групп
 */
export function getDependencies(dependencies, type) {
  return dependencies.filter(({ type: localType, is_selection: isSelection }) => !isSelection && localType === type);
}

/**
 * Зависимости только из радиогрупп
 */
export function getRadioDependencies(dependencies, type) {
  return dependencies.filter(({ type: localType, is_selection: isSelection }) => (
    !!isSelection && (type ? localType === type : true)
  ));
}

export function hasInstalledDependencies(ids, installedOptions) {
  return !!~installedOptions.findIndex(({ id: localId }) => !!~ids.findIndex(id => id === localId));
}

export function hasNotInstalledIncludes(dependencies, installedOptions = []) {
  const includeDependencies = getDependencies(dependencies, 'include');
  const includesNotInstalled = getNotInstalledDependencies(includeDependencies, installedOptions);

  return Boolean(includesNotInstalled.length);
}

export function getExcludeSummary(option, options, packages, installedOptions) {
  const excludeDependencies = getDependencies(option.dependencies, 'exclude');

  const hasExcludesInstalled = hasInstalledDependencies(
    excludeDependencies.map(({ child }) => child),
    installedOptions,
  );

  const excludeSummary = summarize(excludeDependencies, options, packages);

  return hasExcludesInstalled ? -excludeSummary : 0;
}

export function getDiffSummary(option, options, packages, installedOptions) {
  const includeDependencies = getDependencies(option.dependencies, 'include');
  const includesNotInstalled = getNotInstalledDependencies(includeDependencies, installedOptions);
  const hasOptionInstalled = hasInstalledDependencies([option.id], installedOptions);
  const optionSummary = option.price;
  const includeSummary = summarize(includeDependencies, options, packages);
  const includeNotInstalledSummary = summarize(includesNotInstalled, options, packages);

  return hasOptionInstalled
    ? -(optionSummary + includeSummary)
    : optionSummary + includeNotInstalledSummary;
}

/**
 * Опция с нулевой стоимостью, где все зависимости также имеют нулевую стоимость.
 */
export function checkOnAllConsist(dependencies) {
  const consistDependencies = getDependencies(dependencies, 'consist');
  return consistDependencies &&  dependencies.length === consistDependencies.length;
}
