import { Fragment } from 'react';

import styles from './index.styl';

function Input(props) {
  const {
    /* eslint-disable react/prop-types, no-unused-vars */
    cx: reactSelectCx,
    getStyles,
    innerRef,
    onFocus,
    onBlur,
    isDisabled,
    isHidden,
    selectProps: {
      setFocused,
    },
    /* eslint-enable react/prop-types, no-unused-vars */
    ...inputProps
  } = props;

  const handleFocus = e => {
    setFocused(true);
    onFocus(e);
  };

  const handleBlur = e => {
    setFocused(false);
    onBlur(e);
  };

  return (
    <Fragment>
      <input
        {...inputProps}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={styles.input}
        ref={innerRef}
      />
    </Fragment>
  );
}

export default Input;
