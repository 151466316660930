import { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import cx from 'classnames';
import Select from 'react-select';

import { debounce } from 'core/libs/lodash';
import { withRouter } from 'core/libs/router';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';
import { denormalizeData } from 'core/utils/api';

import H3 from 'core/components/H3';
import Checkbox from 'core/components/Checkbox';
import { Block, Section } from 'core/components/Grid';
import Spinner from 'core/components/Icon/Spinner';

import { Indent } from 'site/components/Wrappers';
import Input from 'site/components/Input';

import modelPropTypes, { geoAttributes } from 'site/utils/prop-types/model';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE, SIDE_INDENT } from 'site/constants';
import { expensesDic } from 'site/dictionaries';

import styles from './index.styl';


const NUMBER_OF_YEARS = 5;

class Expenses extends PureComponent {
  static propTypes = {
    isMobile: PropTypes.bool,
    geos: PropTypes.arrayOf(modelPropTypes(geoAttributes)),
    ownershipCost: PropTypes.object,
    breakpoint: PropTypes.string,
    match: PropTypes.object,
    servicesApi: PropTypes.object,
    theme: themePropTypes(`{
      colors: {
        primary100,
        primary500,
        divider,
        contentList
      },
    }`),
  };

  static contextTypes = {
    servicesApi: PropTypes.object,
  };


  constructor(props) {
    super(props);
  }

  state = {
    selectedExpenses: {
      depreciation: true,
      maintenance: true,
      fuel: true,
      transport_tax: true,
      osago: true,
      kasko: false,
    },
    ownershipCost: this.props.ownershipCost,
    run: this.props.ownershipCost.run,
    geoUrl: this.props.ownershipCost.geo_url,
    geos: [],
    loaded: true,
  };

  componentDidMount() {
    this.context.servicesApi
      .getCatalogOsagoGeos()
      .then(data => {
        const geos = denormalizeData(data);

        const groupedOptions = geos.map((geo) => {
          if (geo.attributes.children.length) {
            return {
              label: geo.attributes.name,
              options: geo.attributes.children.concat([{
                attributes: {
                  name: `${geo.attributes.name} - другой город`,
                  url: geo.attributes.url,
                },
              }]),
            };
          }
          return geo;
        });

        this.setState({ geos, groupedOptions });
      })
      .catch(console.error);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.run !== this.state.run || prevState.geoUrl !== this.state.geoUrl) {
      this.receiveNewExpenses();
    }
  }

  onRunChange = e => {
    this.setState({ run: e.target.value.replace(/[^\d]/g, ''), loaded: false });
  };

  onGeoChange = (val) => {
    this.setState({ geoUrl: val.attributes.url });
  };

  toggleExpense(expense) {
    this.setState({
      selectedExpenses: {
        ...this.state.selectedExpenses,
        [expense]: !this.state.selectedExpenses[expense],
      },
    });
  }

  receiveNewExpenses = debounce(() => {
    const {
      params: {
        brand,
        model,
        generation,
        body,
        modification,
      },
    } = this.props.match;

    this.props.servicesApi.getModifications({
      'filter[brand]': brand,
      'filter[model]': model,
      'filter[generation]': generation,
      'filter[body]': body,
      'filter[modification]': modification,
      'ownership[run]': this.state.run,
      'ownership[geo]': this.state.geoUrl,
      'attributes[car_modification]': 'ownership',
    })
      .then(denormalizeData)
      .then(data => {
        this.setState({ ownershipCost: data[0].attributes.cost_of_ownership, loaded: true });
      })
      .catch(e => console.error(e));
  }, 800);

  renderExpenses(item) {
    return (
      <tr
        className={cx(
          'row',
          !this.state.selectedExpenses[item.title] && styles.uncheckedExpense
        )}
        key={item.key}
      >
        <style jsx>{`
          .row
            td
              border-bottom-color ${this.props.theme.colors.divider}
      `}</style>
        <td>
          <Checkbox
            checked={this.state.selectedExpenses[item.title]}
            onChange={() => { this.toggleExpense(item.title); }}
          >
            <span>{expensesDic[item.title]}</span>
          </Checkbox>
        </td>
        {item.expenses.map((yearExpense, i) => {
          return <td key={i}>{accounting.formatNumber(yearExpense)}</td>;
        })}
      </tr>
    );
  }

  renderTableOfCosts(expenses) {
    const {
      theme,
    } = this.props;

    const {
      run,
      loaded,
    } = this.state;

    if (run) {
      const totalByYears = [];

      for (const [key, value] of expenses) {
        if (this.state.selectedExpenses[key]) {
          value.reduce((yearSum, currVal, i) => {
            yearSum[i] = yearSum[i] ? yearSum[i] + currVal : currVal;
            return yearSum;
          }, totalByYears);
        }
      }

      const total = totalByYears.reduce((acc, curr)=>(acc + curr), 0);
      const avrPerYear = total / NUMBER_OF_YEARS;
      const avrPerKm = avrPerYear / (+run || 1);

      return (
        <Fragment>
          <style jsx>{`
          .row
            td
            th
              border-bottom-color ${theme.colors.divider}

            &:last-child
              td
                border-bottom-color ${theme.colors.primary100}

          .summaryRow
            &:before
              border-bottom 1px dashed ${theme.colors.divider}

          .name
          .value
            background ${theme.colors.contentList}

          .hint
            color ${theme.colors.primary500}
            font 13px/15px ${theme.fonts.text}
        `}</style>
          <div className={styles.expensesWrapper}>
            <table className={styles.expensesTable}>
              <tbody>
                <tr className='row'>
                  <th>Расходы на:</th>
                  <th>1 год</th>
                  <th>2 год</th>
                  <th>3 год</th>
                  <th>4 год</th>
                  <th>5 год</th>
                </tr>
                {Array.from(expenses, ([title, values], i) => {
                  if (values.length) {
                    return this.renderExpenses({ title: title, expenses: values, key: i });
                  }
                  return false;
                })}
                <tr>
                  <td>Итого</td>
                  {totalByYears.map((yearTotal, i) => <td key={i}>{accounting.formatNumber(yearTotal)}</td>)}
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.summary}>
            <div className={cx(styles.summaryRow, 'summaryRow')}>
              <div className={cx(styles.name, 'name')}>Всего за 5 лет</div>
              <div className={cx(styles.value, 'value')}>{accounting.formatMoney(total)}</div>
            </div>
            <div className={cx(styles.summaryRow, 'summaryRow')}>
              <div className={cx(styles.name, 'name')}>За 1 год (в среднем)</div>
              <div className={cx(styles.value, 'value')}>{accounting.formatMoney(avrPerYear, '₽/год')}</div>
            </div>
            <div className={cx(styles.summaryRow, 'summaryRow')}>
              <div className={cx(styles.name, 'name')}>За 1 км. пути</div>
              <div className={cx(styles.value, 'value')}>{loaded ? accounting.formatMoney(avrPerKm, '₽/км') : <Spinner width={15} height={15} />}</div>
            </div>
            <p className='hint'><sup>*</sup> Представленные данные носят ознакомительно-информационный характер и не являются публичной офертой</p>
          </div>
        </Fragment>
      );
    }
    return <p>Введите данные по годовому пробегу</p>;
  }

  render() {
    const {
      isMobile,
      breakpoint,
    } = this.props;

    const {
      depreciation,
      maintenance,
      fuel,
      transport_tax: transportTax,
      osago,
      kasko,
    } = this.state.ownershipCost;

    const {
      run,
      geoUrl,
      geos,
      groupedOptions,
    } = this.state;

    const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

    const expenses = new Map([
      ['depreciation', depreciation],
      ['maintenance', maintenance],
      ['fuel', fuel],
      ['transport_tax', transportTax],
      ['osago', osago],
      ['kasko', kasko],
    ]);

    return (
      <div className={styles[breakpoint]}>
        <H3 id='tco'>Стоимость владения<sup>*</sup></H3>
        <Indent bottom={vertical} />
        <Section>
          <Block width={12} desktop={4}>
            <Input
              value={accounting.formatNumber(run)}
              onChange={this.onRunChange}
              label='Годовой пробег (км)'
              placeholder='15 000'
              pattern='[0-9 ]*'
              maxLength={7}
            />
          </Block>
          <Block desktop={SIDE_INDENT + 'px'} mobile={12}>
            {isMobile && <Indent bottom={vertical} />}
          </Block>
          <Block width={12} desktop={4}>
            <Select
              options={groupedOptions}
              getOptionLabel={value => value.attributes.name}
              getOptionValue={value => value.attributes.url}
              value={geos.find(geo => geo.attributes.url === geoUrl)}
              onChange={this.onGeoChange}
            />
          </Block>
        </Section>
        <Indent bottom={vertical} />
        {this.renderTableOfCosts(expenses)}
      </div>
    );
  }
}

export default withRouter(withTheme(withBreakpoint(Expenses)));
