import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { carBrandAttributes } from 'core/utils/prop-types/model';

import Link from 'core/components/Link';
import Image from 'core/components/SmartImage';
import QutoBrandIcon from 'core/components/QutoBrandIcon';

import cardServiceHOC from 'site/components/cardService';
import Divider from 'site/components/Divider';
import { Indent } from 'site/components/Wrappers';

import relationshipsPropTypes from 'site/utils/prop-types/relationships';
import modelPropTypes, {
  dealerAttributes,
  geoAttributes,
  dealerNetworkAttributes,
} from 'site/utils/prop-types/model';
import getThumbnail from 'site/utils/get-thumbnail';
import { buildDealerUrl } from 'site/utils';

import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = ['car_brands', 'geo', 'dealer_network'];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  car_brands: [],
  geo: {},
  dealer_network: {},
});


function CardServiceDealer(props) {
  const {
    content,
    position,
    brandSlug,
    type,
    handleClick,
    isDesktop,
    isMobile,
    theme: {
      colors,
      controls: {
        link: {
          secondary: linkAtoms,
        },
      },
    },
  } = props;

  if (!content) return null;

  const hasBorder = position > 0;

  const {
    attributes: {
      name,
      slug,
      is_official: isOfficial,
      offers_count: offersCount,
      is_moto: isMoto,
      address,
    },
  } = content;

  const {
    car_brands: brands,
    geo: {
      name: city,
      url: geoSlug,
    },
    dealer_network: {
      image,
    },
  } = relationships(content);

  const {
    showExtendedInfo,
    imageSize,
  } = mapTypes[type] || {};

  const cardAsLink = typeof handleClick !== 'function';

  const addressString = isDesktop
    ? [address, city].filter(Boolean).join(', ')
    : address;
  const logo = getThumbnail(image);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .name
        .official
          color ${colors.text}

        .name
          .cardServiceDealer:hover &
            color ${linkAtoms.hover.color}
          .cardServiceDealer:active &
            color ${linkAtoms.active.color}
          .cardServiceDealer:visited &
            color ${linkAtoms.visited.color}

        .offersCount
          color ${colors.active1}

        .address
          color ${colors.primary600}
      `}</style>
    </scope>
  );

  const CardWrapper = cardAsLink ? Link : 'div';
  const linkProps = {
    to: buildDealerUrl(geoSlug, brandSlug, slug, isMoto),
    type: 'secondary',
  };
  const cardWrapperProps = {
    className: scope.wrapClassNames(
      'cardServiceDealer',
      styles.cardServiceDealer,
    ),
    'data-qa': 'dealers-cards',
    ...cardAsLink
      ? linkProps
      : { onClick: () => handleClick(content, position) },
  };

  return (
    <CardWrapper {...cardWrapperProps}>
      {hasBorder && <Divider />}
      <Indent top={isMobile ? 13 : 17} />
      {logo && (
        <div className={styles.image}>
          <Image
            src={logo}
            width={imageSize}
            height={imageSize}
            placement='contain'
            position='top center'
            instant
          />
        </div>
      )}
      <div className={scope.wrapClassNames('name', styles.name)}>
        {name}
      </div>
      {showExtendedInfo && (
        <div className={styles.extended}>
          <ul className={styles.brands}>
            {brands.map(brand => (
              <li key={brand.id} className={styles.brandIcon}>
                <QutoBrandIcon size={30} brand={brand} />
              </li>
            ))}
          </ul>
          {isOfficial &&
            <span className={scope.wrapClassNames('official', styles.official)}>
              Официальный дилер
            </span>
          }
          {/* TODO: сделать ссылкой на предложения дилера */}
          {offersCount > 0 &&
            <span className={scope.wrapClassNames('offersCount', styles.offersCount)}>
              {offersCount} авто в продаже
            </span>
          }
        </div>
      )}
      <address className={scope.wrapClassNames('address', styles.address)}>{addressString}</address>
      {/* TODO: дистанция, режим работы на текущий день, отзывы (параметр showRating в types) */}
      <div className={styles.clear} />
      <Indent bottom={19} />
      <scope.styles />
    </CardWrapper>
  );
}

CardServiceDealer.propTypes = {
  /** Модель дилера. */
  content: modelPropTypes(
    dealerAttributes,
    relationshipsPropTypes({
      geo: modelPropTypes(geoAttributes),
      car_brands: modelPropTypes(carBrandAttributes),
      dealer_network: modelPropTypes(dealerNetworkAttributes),
    })),

  /** Позиция карточки в фиде. Влияет на отображение верхней границы. */
  position: PropTypes.number,

  /** Слаг бренда для формирования ссылки карточки. */
  brandSlug: PropTypes.string,

  /** Тип карточки. */
  type: PropTypes.oneOf([0, 1]),

  /**
   * Обработчик клика по карточке. Если не передан, карточка отрисовывается
   * как ссылка.
   * @param {Object} content - данные карточки.
   * @param {number} position - 0-based позиция карточки в списке.
   */
  handleClick: PropTypes.func,

  /** @ignore */
  isDesktop: PropTypes.bool,

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  theme: PropTypes.object,
};

CardServiceDealer.defaultProps = {
  type: 0,
  position: 0,
};

const CardWithHOCs = cardServiceHOC(CardServiceDealer);
CardWithHOCs.displayName = 'CardServiceDealer';

export default CardWithHOCs;
export { CardServiceDealer as StorybookComponent };
