import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';

import { withRouter } from 'core/libs/router';

import pluralize from 'core/utils/pluralize';

import { getRequestParamsWithFilter, excludeObjectValues } from 'site/utils';

import {
  buildSearchParamsFromValues,
} from 'site/utils/forms';


const DECLINATIONS = {
  catalog: ['модель', 'модели', 'моделей'],
  offers: ['объявление', 'объявления', 'объявлений'],
};

function Count(props, { servicesApi }) {
  const [count, setCount] = useState(0);

  const {
    formType,
    formState,
    isLcv,
    isMoto,
    ranges,
    excludedStateFields,
    isDesktop,
  } = props;

  useEffect(() => {
    let ignore = false;

    const processedState = excludeObjectValues(formState, excludedStateFields);

    const processedFormValues = buildSearchParamsFromValues(processedState, ranges);

    let specificRequestParams = {};

    let apiEndpoint = isMoto
      ? servicesApi.getMotoOffers
      : servicesApi.getOffers;

    if (formType === 'catalog') {
      const {
        match: {
          params: {
            geo,
          },
        },
      } = props;

      const {
        brand,
        model,
        generation,
        usages,
      } = processedState;

      specificRequestParams = {
        ...(isLcv && { 'filter[car_type]': 'lcv' }),
        'filter[usages]': usages?.join() || 'catalog,archive',
        /**
         * Каталог фильтрует поля ниже по id, поэтому переопределяем
         * их тут на слаги.
         */
        'filter[brand]': brand && brand.attributes.slug,
        'filter[model]': model && model.attributes.slug,
        'filter[generation]': generation && generation.attributes.slug,
        ...(geo && { 'filter[geo]': geo }),
      };

      apiEndpoint = isMoto
        ? servicesApi.getMotoReleases
        : servicesApi.getReleases;
    }

    apiEndpoint({
      ...getRequestParamsWithFilter(processedFormValues),
      ...specificRequestParams,
      only_filtered_count: true,
    })
      .then(({ meta }) => meta.filtered_count)
      .then(data => {
        if (!ignore) {
          setCount(data);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setCount(0);
        }
      });

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formState), isLcv]);

  const pluralizeText = pluralize(Number(count), DECLINATIONS[formType]);
  const prefix = !count || isDesktop ? 'Показать ' : '';

  return prefix + accounting.formatNumber(count) + ' ' + pluralizeText;
}

Count.propTypes = {
  isDesktop: PropTypes.bool,
  formType: PropTypes.oneOf(['catalog', 'offers']),
  formState: PropTypes.object,
  isLcv: PropTypes.bool,
  isMoto: PropTypes.bool,
  ranges: PropTypes.object,
  excludedStateFields: PropTypes.array,
  match: PropTypes.object,
};

Count.contextTypes = {
  servicesApi: PropTypes.object.isRequired,
};

export default withRouter(Count);
