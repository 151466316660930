export default {
  sm: {
    width: 120,
    titleSize: '500 14px/16px',
    swordsSize: 22,
  },
  md: {
    width: 180,
    titleSize: '500 16px/20px',
    swordsSize: 36,
  },
  lg: {
    width: 300,
    titleSize: '500 16px/20px',
    swordsSize: 36,
  },
};
