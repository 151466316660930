import PropTypes from 'prop-types';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import cutDown from 'core/utils/cut-down';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import cardServiceHOC from 'site/components/cardService';
import Divider from 'site/components/Divider';
import Battery from 'site/components/Battery';
import User from 'site/components/User';
import LikeAndDislike from 'site/components/LikeAndDislike';
import { Indent } from 'site/components/Wrappers';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { reviewAttributes } from 'site/utils/prop-types/model';

import Star from 'site/icons/Star';

import styles from './index.styl';

const WIDTH = 345;
const HEIGHT = 210;

const relationships = resolveRelationships(['user', 'modification'], {}, {});

function CardServiceReview1(props) {
  const {
    theme: {
      colors: {
        primary,
        primary100,
        primary150,
        primary250,
        primary400,
        content: contentColor,
        active1,
        active2,
      },
      animations: {
        hover,
      },
    },
    content,
    dataQa,
  } = props;

  if (!content) return null;

  const {
    attributes: {
      like,
      dislike,
      name,
      ratings: [{ value: rating }],
      image,
      url = '/',
      text: reviewText,
    },
  } = content;

  const {
    user,
    modification: {
      name: modificationName = '',
      power = '',
      battery,
    },
  } = relationships(content);

  const batteryTotal = battery && battery.total;

  const cover = getThumbnail(image, '3/2');

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          color ${primary}
          background ${contentColor}
          border 1px solid transparent
          transition border-color ${hover}

          &:before
            transition box-shadow ${hover}
            box-shadow 0px 2px 5px ${primary150}

          &:hover
            color ${primary}
            border 1px solid ${primary100}

            &:before
              box-shadow 0px 2px 5px ${primary250}

            .${styles.name}
              color ${active1}

          &:visited
          &:visited:hover
            color ${primary}

          .${styles.name}
            color ${primary}
      `}</style>
    </scope>
  );

  return (
    <div className={styles.cardServiceReview1} data-qa={dataQa}>
      <style jsx>{`
        .${styles.ratings}
          color ${contentColor}
          background ${active2}

        .${styles.engineItem}
          &:after
            background ${primary400}
      `}</style>
      <Link
        to={url}
        className={scope.wrapClassNames(styles.link)}
        type='secondary'
      >
        <div className={styles.ratings}>
          {`${rating} из 5`}
          <Star />
        </div>
        <div className={styles.image}>
          {cover && (
            <Image
              src={cover}
              maxWidth={WIDTH}
              height={HEIGHT}
            />
          )}
        </div>
        <Divider />
        <div className={styles.content}>
          <div className={scope.wrapClassNames(styles.name)} data-qa='card-title'>{name}</div>
          <div className={styles.engineInfo}>
            <span className={styles.engineItem}>
              {modificationName && modificationName + ' '}
              {power && `(${power} л.с.)`}
            </span>
            {!!batteryTotal && (
              <span className={styles.engineItem}>
                <Battery value={batteryTotal} />
              </span>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <Divider top={17} bottom={17} />
          <div className={styles.controls}>
            <LikeAndDislike like={like} dislike={dislike} />
          </div>
          <Divider top={17} bottom={17} />
          <User user={user} />
          <Indent bottom={14} />
          <div className={styles.review}>{cutDown(reviewText)}</div>
        </div>
      </Link>
      <scope.styles />
    </div>
  );
}

CardServiceReview1.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  breakpoint: breakpointPropTypes(),
  /** Данные для карточки, соответствующие модели `reviewAttributes` */
  content: modelPropTypes(reviewAttributes),
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  dataQa: PropTypes.string,
};

const CardWithHOCs = cardServiceHOC(CardServiceReview1);
CardWithHOCs.displayName = 'CardServiceReview1';

export default CardWithHOCs;
export { CardServiceReview1 as StorybookComponent };
