import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import H3 from 'core/components/H3';

import styles from './index.styl';


function DescriptionOfMoto({ isMobile }) {
  return (
    <Fragment>
      <H3 is='h1'>
        Мотоциклы, квадроциклы,{isMobile && <br />} скутеры – каталог
        <br />
        с ценами и характеристиками
      </H3>
      <div className={styles.lead}>
        <p>Представляем вашему вниманию каталог мотоциклов с ценами и характеристиками. На данной странице представлены практически все бренды мотоциклов, скутеров и квадроциклов, продажа которых осуществляется на территории РФ в официальных автосалонах. Каталог мототехники содержит подробную информацию о характеристиках, актуальных ценах на новые модели, а также фотогалереи и видео подборки.</p>

        <p>Представленная страница - не «каталог лучших мотоциклов» или, наоборот неудачных: данный мотокаталог является информационно-аналитическим и не считается редакторской рекомендацией к покупке той или иной модели. Однако, благодаря разработанным сервисам, любой посетитель сам может сделать выбор: на сайте доступно сравнение мотоциклов, скутеров и квадроциклов по различным параметрам. Также есть возможность самостоятельно, задав характеристики мотоцикла, подобрать мототехнику. </p>
      </div>
      <div className={styles.text}>
        <p className={styles.title}>
          МОТОЦИКЛ
        </p>

        <p>Продажа мотоциклов, в отличие от авто, пока не имеет повышенного спроса, о чем свидетельствует хотя бы значительно меньшее количество официальных дилеров, если сравнивать с автомобилями. Впрочем, своя целевая аудитория у мотоциклов есть и, возможно, со временем, в связи со сложными дорожными условиями, количество мотоциклов на улицах России будет расти.</p>

        <p>Безусловно, одно из главных преимуществ, которое видят те, кто хочет купить мотоцикл, — его мобильность, маневренность и, разумеется, «резвость». Хотя есть и те, кто банально следует за детской мечтой: мол, куплю мотоцикл и буду «первым парнем на деревне». Кстати, именно с деревней, действительно, и связана мода на мотоциклы в России, когда в середине 50-х это был единственный доступный транспорт для работяг, и тот, у кого появлялся новый мотоцикл, в самом деле становился первым парнем. Сегодня иметь импортный мотоцикл больше становится делом престижа, нежели прямой необходимости. И для многих мотоциклистов это все-таки второй транспорт, дополнительный к автомобилю, когда авто для «хозяйства», мотоцикл для души. Есть тому и объективные причины: к моменту, когда осуществление детской мечты доступно, многие уже обзаводятся семьями, и мотоцикл не сможет в полной мере обеспечить выполнение «семейных задач»: забрать ребенка из садика, съездить на дачу всей семьей, сделать покупки...</p>

        <p>Мотоциклы, разумеется, во многом проигрывают автомобилям, в плане безопасности, грузоподъемности, вместительности.</p>

        <p>Но зато динамические характеристики мотоциклов для большинства автомобилей недосягаемы. А такое авто, которое способно ускоряться на уровне, скажем, 600-кубового спортбайка, будет стоить дороже этого мотоцикла на целый порядок.</p>

        <p>И, к сожалению, для нашего климата мотоциклы не могут быть универсальным решением: транспорт «сезонный» и зимой мотоциклисты на дороге встречаются редко. В общем и целом, аудиторию владельцев мотоциклов можно поделить на несколько основных групп.</p>

        <p>Убежденные байкеры, для которых «купить мотоцикл» - было главной целью в жизни! Они проводят встречи, собираются в клубы, организуют различные, в том числе благотворительные, мероприятия, вроде мотопробегов, проще говоря — для таких мотоцикл — это религия. Кстати, культ этот также берет начало еще в Советский период, когда молодежь повально увлекалась Чопперами.</p>

        <p>Любители, для которых мотоцикл не представляет ни «духовной», ни «культурной» ценности, но сам факт поездки доставляет им удовольствие, которое нередко можно подменить понятием «покрасоваться».</p>

        <p>Есть и коллекционеры. Правда, они не всегда покупают именно новые мотоциклы — цены для таких людей значения не имеют, и они скорее гоняются за раритетами. При этом сами могут ни разу и не воспользоваться своими экспонатами по прямому назначению. Примечательно, что последнее время все чаще встречаются на дорогах девушки на мотоциклах. Но даже если отбросить стереотипы о женщинах на дорогах, пока не совсем понятно, что это за тип мотоциклистов.</p>

        <p>Иными словами, чтобы понять, какой мотоцикл нужен именно вам, рекомендуем подробно изучить разделы нашего мотокаталога, где вы найдете подробные характеристики мотоциклов, цены на мотоциклы, а также получите возможность сравнить несколько наиболее понравившихся моделей.</p>

        <p>Нужно подчеркнуть, что в разделах представлены официальные, актуальные цены на мотоциклы, продажа моделей осуществляется официальными дилерами.</p>

        <p className={styles.title}>
          Скутер
        </p>

        <p>Все скутеры делятся на две категории: до 50 кубов и больше. На дорогах, конечно, чаще всего можно встретить первую категорию, в виду низкой цены на скутеры до 50.</p>

        <p>Тем не менее прежде, чем купить новый скутер, стоит подумать, где большей частью вы станете его использовать. Скутеры делятся на несколько «классов», каждый из которых имеет свои особенности.</p>

        <p>Также, если купить скутер исключительно для передвижения по городу, с небольшим диаметром колес, то по сути, на нем нельзя будет выехать за город. Строго говоря, при покупке скутера важно точно понимать, какой он: городской, туристический, внедорожный, или спортивный. Причем от характеристик зависят не только условия эксплуатации, но и сколько стоит скутер.</p>

        <p>Разумеется, купить новый скутер — дешевле, чем купить мотоцикл или квадроцикл, однако большинство потенциальных покупателей и вовсе не обладают достаточным количеством средств на более дорогую мототехнику, так что разница в ценах скутеров, зависимая от характеристик и других параметров, может быть существенна.</p>

        <p>Сегодня на новые скутеры цены находятся в диапазоне от 30 до 600 тысяч рублей. Продажа скутера может осуществляться и с рук (что не рекомендовано) и в официальном салоне. В каталоге представлены и отмечены на карте адреса официальных дилеров заинтересовавшей вас марки, где можно купить скутер.</p>

        <p className={styles.title}>
          Квадроцикл
        </p>

        <p>Еще совсем недавно вопрос, где купить квадроцикл, если и не ставил в тупик, то заставлял задуматься. Действительно, мода на этот вид транспорта в России появилась недавно. Обусловлено это спецификой использования квадроциклов. Для города этот транспорт не предназначен. В случае выезда на природу, прогулок гораздо удобнее пользоваться автомобилем. Для рабочих нужд, в том числе сельских работ, все еще годятся «древние» мотоциклы с коляской. Тем не менее продажа квадроциклов постепенно набирает обороты, и они с каждым сезоном становятся все более востребованы. Новые квадроциклы выбирает себе более молодое поколение, которым важны не только функциональность, но и «эстетическая» составляющая.</p>

        <p>Сегодняшние, даже недорогие квадроциклы, гораздо интереснее с дизайнерской точки зрения, они выглядят брутально, современно.</p>

        <p>В нашем каталоге представлены:</p>

        <p>- Спортивные квадроциклы</p>

        <p>- Утилитарные квадроциклы</p>

        <p>Спортивные квадроциклы предпочитают скорее для выездов за город с целью «погонять» «по грунтовке», посоревноваться с друзьями или получить дозу адреналина в одиночку. Для тяжелого бездорожья они не подойдут, т.к. привод лишь на задние колеса, да и подвеска их зачастую не независимая. Купить квадроцикл утилитарного типа могут и степенные дачники, которым, например, нужно несколько раз в сутки объехать большие угодья в условиях бездорожья. Так же утилитарные квадроциклы хорошо сгодятся и для прогулочных поездок, в т.ч. по местности со сложным рельефом.</p>

        <p>Столь широкая целевая аудитория обусловлена тем, что цены на квадроциклы начинаются в среднем от 100 тысяч рублей, что делает их доступным транспортом. Впрочем, прежде, чем купить дешевый квадроцикл, стоит детально изучить его характеристики.</p>
      </div>
    </Fragment>
  );
}

DescriptionOfMoto.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};


export default withBreakpoint(DescriptionOfMoto);
