import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Link from 'core/components/Link';
import ButtonWithIcon from 'core/components/ButtonWithIcon';
import ButtonTextWrap from 'site/components/Wrappers/ButtonTextWrap';

import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';
import Drum from 'core/components/Drum';

import { Card1Type8, Card1Type10 } from 'site/cards/Card1';
import ArrowRightSm from 'site/icons/ArrowRightSm';

import { LimeGradientBg, Indent } from 'site/components/Wrappers';
import { SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';

function NewInJournal({ content, theme, isMobile }) {
  const horisontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  return (
    <LimeGradientBg className={styles.gradient}>
      <style jsx>{`
        .title
          color ${theme.colors.primary}
          font 800 22px/1.2 ${theme.fonts.display}
          :global(.mobile) &
            font 800 18px/20px ${theme.fonts.display}
      `}</style>
      <Indent left={horisontal}>
        <div className={styles.content}>
          <div className={styles.header} data-qa='new-in-journal-header'>
            <Link
              to='/journal'
              type='secondary'
            >
              <h3 className={cx(styles.title, 'title')}>Автомобильный журнал Quto</h3>
            </Link>
            {isMobile &&
              <Link
                to='/journal'
                type='secondary'
              >
                <ButtonWithIcon
                  icon={ArrowRightSm}
                  iconPosition='right'
                  iconMargin= {6}
                  size='extraSmall'
                  radius='28px'
                >
                  <ButtonTextWrap>Все</ButtonTextWrap>
                </ButtonWithIcon>
              </Link>
            }
          </div>
          <YandexMetrikaGoalSender
            click={'Block_new_in_magazine_click'}
            skipTargetCheck
            className={styles.news}
          >
            <Drum
              content={content}
              card={isMobile ? Card1Type10 : Card1Type8}
              interitemSpacing={15}
              itemWidth={376}
              itemWidthMobile={350}
              arrowsShift={-20}
              showArrowsOnHover
              counterSwitchThreshold={10}
              fadeOuter='#0000'
              fadeInner='#0000'
            />
          </YandexMetrikaGoalSender>
        </div>
      </Indent>
    </LimeGradientBg>
  );
}

NewInJournal.propTypes = {
  content: PropTypes.array,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withTheme(withBreakpoint(NewInJournal));
