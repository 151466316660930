import PropTypes from 'prop-types';
import { pddModePropTypes } from 'site/utils/prop-types/pdd';

import Block from './Block';
import H3 from 'core/components/H3';

import styles from './index.styl';

import sign from './img/sign.png';
import watch from './img/watch.png';

function Blocks(props) {
  const { mode, finish } = props;

  const base = {
    img: sign,
    widthImg: 60,
    heightImg: 60,
    rectangle: 'true',
  };

  const tren = {
    ...base,
    mode: 'tren',
    title: 'Тренировка',
    text: '20 вопросов, 20 минут и неограниченное количество ошибок. Просмотр ошибок после окончания тренировки',
    link: 'ПРОЙТИ ТРЕНИРОВКУ',
    url: '/pdd/tren',
  };

  const exam = {
    ...base,
    mode: 'exam',
    title: 'Экзамен',
    text: '20 вопросов, 20 минут. Просмотр ошибок после окончания экзамена',
    link: 'ПРОЙТИ ЭКЗАМЕН',
    url: '/pdd/exam',
  };

  const marafon = {
    mode: 'marafon',
    title: 'Марафон',
    text: 'Все 800 вопросов, неограниченное время и количество ошибок, подсказки',
    link: 'НАЧАТЬ МАРАФОН',
    url: '/pdd/marafon',
    img: watch,
    widthImg: 65,
    heightImg: 64,
  };

  const modeArray = [marafon, exam, tren].filter((item) => item.mode !== mode);

  return (
    <div>
      {finish && <H3>Попробуйте другие режимы</H3>}
      <div className={styles.container}>
        {modeArray.map((regime, i)=> {
          return (
            <Block
              key={i}
              title= {regime.title}
              text={regime.text}
              link={regime.link}
              url={regime.url}
              img={regime.img}
              widthImg={regime.widthImg}
              heightImg={regime.heightImg}
              rectangle={regime.rectangle}
              marginRight={regime.marginRight}
              lineTop={regime.lineTop}
            />
          );
        })}
      </div>
    </div>
  );
}

Blocks.propTypes = {
  mode: pddModePropTypes,
  finish: PropTypes.bool,
};

Blocks.defaultProps = {
  mode: 'exam',
};

export default Blocks;
