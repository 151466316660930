import PropTypes from 'prop-types';

import StickyPortal from 'core/components/StickyPortal';

import { VERTICAL_INDENT } from 'site/constants';
import { Indent } from 'site/components/Wrappers';

import { PartnersVertical } from 'site/components/Partners';

import { Ad240x400Second } from 'site/components/Ads/desktop';

export default function Part2({ isShowRnet }) {
  return (
    <StickyPortal>
      <Ad240x400Second />
      <Indent bottom={VERTICAL_INDENT} />
      {isShowRnet && <PartnersVertical />}
    </StickyPortal>
  );
}

Part2.propTypes = {
  isShowRnet: PropTypes.bool,
};

Part2.defaultProps = {
  isShowRnet: true,
};
