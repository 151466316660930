import PropTypes from 'prop-types';

import mapTypes from './types';

/**
 * Логотипы получились довольно тяжеловесные в SVG формате,
 * поэтому решили выводить их в .png
 *
 * исходники в svg сохранены на всякий случай
 * import LogoLg from 'site/icons/LogoLg';
 * import LogoMd from 'site/icons/LogoMd';
 */
export default function Logo({ type }, { amp, host }) {
  if (!mapTypes[type]) return null;

  const params = {
    ...mapTypes[type],
    alt: host,
  };

  return amp
    ? <amp-img {...params} />
    : <img {...params} />;
}

Logo.contextTypes = {
  amp: PropTypes.bool,
  host: PropTypes.string,
};

Logo.defaultProps = {
  type: 1,
};

Logo.propTypes = {
  /** Тип логотипа */
  type: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8]),
  /** Флаг AMP-страницы */
  amp: PropTypes.bool,
};
