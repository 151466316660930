import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';

import { filterRequiredParams } from 'core/utils/api';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';
import { withRouter } from 'core/libs/router';

import H3 from 'core/components/H3';
import Feed from 'core/components/Feed';
import AdWrapper from 'core/components/Ad/AdWrapper';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';

import Card1, { Card1Type4 } from 'site/cards/Card1';
import Card2, { Card2Type5 } from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card5 from 'site/cards/Card5';
import CompareCard from 'site/cards/CompareCard';

import Divider from 'site/components/Divider';
import GameCompareRubric from './GameCompareRubric';

import {
  Indent,
  NegativeMobile,
} from 'site/components/Wrappers';

import { isLegacyPost, isGameCompare } from 'site/utils';
import { VERTICAL_INDENT } from 'site/constants';

import {
  SuperFooter,
  Context,
  Native1asCard3,
  Native2asCard3,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpec,
} from 'site/components/Ads/mobile';

function replaceBigCard(topic) {
  const publishedAt = get(topic, 'attributes.published_at');
  return isLegacyPost(publishedAt);
}

const dataQaLargeCard = 'topic-large-card';
const dataQaSmallCard = 'topic-small-card';

class SelectionList extends React.Component {
  /**
   * Из-за того что размер картинок в старой версии сайта слишком маленький,
   * для legacy топиков показываем карточки с маленькими картинками.
   * @param topic
   * @returns Component
   */
  renderHeroCard(topic, canShowAvdTooltip) {
    const vertical = this.props.isMobile ? 0 : VERTICAL_INDENT;

    return (
      <Indent bottom={vertical}>
        <Desktop>
          {replaceBigCard(topic)
            ? <Card3 content={topic} />
            : <Card5 content={topic} dataQa={dataQaLargeCard} />
          }
        </Desktop>
        <Mobile>
          <NegativeMobile>
            <Card2Type5
              content={topic}
              dataQa={dataQaLargeCard}
              canShowAvdTooltip={canShowAvdTooltip}
            />
          </NegativeMobile>
          <Divider top={vertical} />
        </Mobile>
      </Indent>
    );
  }

  render() {
    const { isMobile, topics, location } = this.props;

    const isGameComparePage = isGameCompare(location.pathname);

    if (isGameComparePage) {
      return <GameCompareRubric topics={topics} isMobile={this.props.isMobile} />;
    }

    if (!topics || topics.length === 0) {
      return <H3>Материалы не найдены</H3>;
    }

    const vertical = isMobile ? 0 : VERTICAL_INDENT;

    const CardForFeed = bindProps({ dataQa: dataQaSmallCard })(isMobile ? Card1Type4 : Card3);

    const first = topics[0];
    // Карточка отображаемые в Feed для мобильной версии не подходит для добавления на нее рекламного бабла.
    // Поэтому для мобильной версии используем дополнительный HeroCard на второй позиции.
    const additionalHero = isMobile && topics[1];
    const second = isMobile ? topics.slice(2, 5) : topics.slice(1, 4);
    const third = isMobile ? topics[5] : topics[4];
    const fourth = isMobile ? topics.slice(6, 10) : topics.slice(5, 9);
    const fifth = isMobile ? topics[10] : topics[9];
    const sixth = isMobile ? topics.slice(11, topics.length)  : topics.slice(10, topics.length);

    return (
      <AdWrapper bottom={vertical}>
        {first && this.renderHeroCard(first)}
        <Mobile>
          {additionalHero && this.renderHeroCard(additionalHero, true)}
        </Mobile>

        {second.length > 0 && (
          <Indent bottom={vertical}>
            <AdWrapper bottom={0}>
              <Feed
                card={CardForFeed}
                content={second}
                interitemSpacing={vertical}
                cardsReplacement={{
                  1: replaceCardWithAd(Native1asCard3, !isMobile),
                }}
              />
            </AdWrapper>
          </Indent>
        )}

        {third && this.renderHeroCard(third)}

        {fourth.length > 0 && (
          <Indent bottom={vertical}>
            <Desktop>
              <SuperFooter />
            </Desktop>
            <Mobile>
              <Listing1 />
            </Mobile>
            <AdWrapper bottom={0}>
              <Feed
                card={CardForFeed}
                content={fourth}
                interitemSpacing={vertical}
                cardsReplacement={{
                  1: replaceCardWithAd(Native2asCard3, !isMobile),
                }}
              />
            </AdWrapper>
          </Indent>
        )}

        {fifth && this.renderHeroCard(fifth)}

        {sixth.length > 0 && (
          <Indent bottom={vertical}>
            <Desktop>
              <Context />
            </Desktop>
            <Mobile>
              <ListingSpec />
            </Mobile>
            <Feed
              card={CardForFeed}
              content={sixth}
              interitemSpacing={vertical}
            />
          </Indent>
        )}
      </AdWrapper>
    );
  }
}

SelectionList.propTypes = {
  /** Список топиков */
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)).isRequired,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  location: PropTypes.object,
};

const List = withBreakpoint(withRouter(SelectionList));
List.displayName = 'SelectionList';

List.requiredPayloadImports = filterRequiredParams([Card1, Card2, Card3, Card5, CompareCard], 'include');
List.requiredPayloadFields = filterRequiredParams([Card1, Card2, Card3, Card5, CompareCard], 'fields');

export default List;
export { SelectionList as StorybookComponent };
