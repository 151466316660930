import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';
import resolveRelationships from 'core/utils/relationships';

import Page from 'core/components/Page';
import H2 from 'core/components/H2';
import H3 from 'core/components/H3';
import Feed from 'core/components/Feed';
import { withBreakpoint } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import ReleaseBoroda from 'site/components/ReleaseBoroda';
import { Indent, ReleaseInnerPages } from 'site/components/Wrappers';

import CardReleaseColor from 'site/cardsService/CardReleaseColor';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';
import modelPropTypes, { carReleaseAttributes, seoAttributes } from 'site/utils/prop-types/model';

import { releaseCommonProviders } from 'site/pages/release/releaseDataProvider';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


const relationships = resolveRelationships(['colors'], {}, {});

function ReleaseColors(props) {
  const {
    seo,
    isMobile,
    release,
    isTopicsByBrandExist,
  } = props;

  const { name } = release.attributes;
  const { colors } = relationships(release);

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const colorsByGroupName = colors.reduce((groups, color) => {
    const { color_group_name: groupName } = color.attributes;

    if (groups[groupName]) {
      groups[groupName].push(color);
    } else {
      groups[groupName] = [color];
    }

    return groups;
  }, {});

  return (
    <Page title={seo.title} description={seo.description}>
      <ReleaseInnerPages release={release} isTopicsByBrandExist={isTopicsByBrandExist}>
        <H2 as='h1'>{`Цвета ${name}`}</H2>
        <Indent bottom={vertical} />
        {Object.keys(colorsByGroupName).map(groupName => {
          const content = colorsByGroupName[groupName];
          const title = content.length > 1
            ? `${groupName} (${content.length})`
            : groupName;

          return (
            <Indent bottom={vertical} key={groupName}>
              <H3>{title}</H3>
              <Indent bottom={vertical} />
              <Feed
                grid
                content={colorsByGroupName[groupName]}
                interitemSpacing={isMobile ? 0 : 15}
                columns={3}
                card={CardReleaseColor}
              />
            </Indent>
          );
        })}
        <ReleaseBoroda release={release} />
      </ReleaseInnerPages>
    </Page>
  );
}

ReleaseColors.propTypes = {
  isMobile: PropTypes.bool,
  release: modelPropTypes(carReleaseAttributes),
  isTopicsByBrandExist: PropTypes.bool,
  seo: seoAttributes,
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
    }),
  }).isRequired,
};

const dataProvider = resolve({
  release: ({ servicesApi, renderError, match }) => {
    const {
      params: {
        brand,
        model,
        generation,
        body,
      },
    } = match;

    return (
      servicesApi
        .getReleases({
          'filter[brand]': brand,
          'filter[model]': model,
          'filter[generation]': generation,
          'filter[body]': body,
          include: 'car_brand,car_release_color,car_feature',
          limit: 1,
          'relations[car_release]': 'brand,colors,features',
          'attributes[car_release]': 'base,media,seo,image,extended',
          'attributes[car_release_color]': 'base,image',
        })
        .then(releases => denormalizeData(releases)[0])
        .then(rejectEmptyResult)
        .catch(renderError)
    );
  },

  seo: ({ servicesApi, consoleError, match: { params } }) => {
    return (
      servicesApi
        .getReleasesSeo({
          'filter[brand]': params.brand,
          'filter[model]': params.model,
          'filter[generation]': params.generation,
          'filter[submodel]': params.body,
        })
        .then(denormalizeData)
        .catch(consoleError('seo', {}))
    );
  },
  ...releaseCommonProviders,
});

export default withBreakpoint(withPageHocs(dataProvider)(ReleaseColors));
