import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { withBreakpoint } from 'core/components/breakpoint';

import H2 from 'core/components/H2';
import Video from 'core/components/Video';

import GalleryWithAds from 'site/components/GalleryWithAds';
import { Indent, NegativeMobile } from 'site/components/Wrappers';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

function PhotoAndVideo({ isMobile, release }) {
  const {
    attributes: {
      name,
      photogallery,
      photogallery: {
        attributes: {
          images: photoGalleryImages,
        },
      },
      video: videos,
    },
  } = release;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;


  return (
    <Fragment>
      {Boolean(photoGalleryImages.length) && (
        <Fragment>
          <H2 as='h1'>{`Фото ${name}`}</H2>
          <Indent bottom={vertical} />
          <NegativeMobile>
            <GalleryWithAds
              photoGallery={photogallery}
              firstImageFetchPriority='high'
              instantOnFirstImage
              hashNavigation
            />
          </NegativeMobile>
        </Fragment>
      )}
      {Boolean(videos.length) && (
        <Fragment>
          <Indent bottom={vertical} />
          <H2>Видео</H2>
          <Indent bottom={vertical} />
          {videos.map((video, index) => {
            const {
              attributes: {
                code, caption, provider,
              },
            } = video;

            return (
              <Fragment key={code}>
                {index > 0 && <Indent top={vertical} />}
                <Video
                  code={code}
                  caption={caption}
                  providerName={provider}
                />
              </Fragment>
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );
}

PhotoAndVideo.propTypes = {
  isMobile: PropTypes.bool,
  release: PropTypes.object,
};

export default withBreakpoint(PhotoAndVideo);
