import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { Desktop, Mobile } from 'core/components/breakpoint';

import Page from 'core/components/Page';

import PageLayout from 'site/components/PageLayout';
import PddExam from '../components/PddExam';

import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing1 } from 'site/components/Ads/mobile';
import { Indent } from 'site/components/Wrappers';

import { VERTICAL_INDENT_MOBILE } from 'site/constants';


function PddTrenPage({ isMobile }) {
  return (
    <Page>
      <PageLayout>
        <Indent bottom={isMobile ? VERTICAL_INDENT_MOBILE : 24} />
        <PddExam mode='tren' />
        <Indent bottom={40} />
        <Desktop>
          <SuperFooter />
        </Desktop>
        <Mobile>
          <Listing1 />
        </Mobile>
      </PageLayout>
    </Page>
  );
}

PddTrenPage.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(PddTrenPage);
