import { Fragment } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import { denormalizeData } from 'core/utils/api';

import {
  generateSelectOptions,
  generateOptionsGroup,
  initSwitchWithSeparatorValues,
  initSelectValue,
  initRadioValue,
  initCheckboxValue,
  initRangeValue,
  isLcvOnly,
} from 'site/utils/forms';

import {
  filterEntitiesByRelation,
} from 'site/utils';

import Form from './Form';
import BaseForm from 'site/components/BaseForm';
import FormModal from 'site/components/FormModal';

import { CATALOG_URL } from 'site/constants';

import {
  RANGES,
  FULL_FILTER_FIELDS,
  PATHNAME_FIELDS,
  EXCLUDED_STATE_FIELDS,
  DEPENDENCIES,
} from './constants';


const usages = [
  { title: 'В продаже', value: 'catalog' },
];

class CatalogForm extends BaseForm {
  constructor(props) {
    super(props);

    this.formOptions = this.getFormOptions({});

    const {
      match: {
        params: {
          lcv,
        },
      },
    } = props;

    this.state = {
      ...this.getFormStateFromLocation({}),
      isLcv: lcv,
      baseEntities: {},
    };
  }

  componentDidMount() {
    const { servicesApi } = this.context;

    Promise.allSettled([
      servicesApi
        .getCatalogBaseEntities({ type: this.state.isLcv ? 'lcv' : 'all' })
        .then(denormalizeData),

      servicesApi.getCatalogDictionaries(),
    ])
      .then(results => results.map(i => i.value))
      .then(([baseEntities, dictionaries]) => ({
        baseEntities: baseEntities || [],
        dictionaries: dictionaries || {},
      }))
      .then(data => {
        const { baseEntities, dictionaries } = data;

        /**
         * TODO: переименовать имена списков в dictionaries во множественное число.
         */
        // Формирует неизменяемы опции для контролов
        this.formOptions = this.getFormOptions(dictionaries);

        this.setState({
          ...this.getFormStateFromLocation({ baseEntities }),
          baseEntities,
        });
      })
      .catch(e => console.error(e));
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLcv } = this.state;

    // переинициализируем форму, если изменилось значение isLcv
    if (prevState.isLcv !== isLcv) {
      if (!isLcv && isLcvOnly(this.state.brand)) {
        this.handleControlChange(null, { name: 'brand' });
        return;
      }
      this.handleFormChange();
    }
  }

  dependencies = DEPENDENCIES;

  ranges = RANGES;

  pathnameFields = PATHNAME_FIELDS;
  excludedFields = EXCLUDED_STATE_FIELDS;

  fullFilterFields = FULL_FILTER_FIELDS;

  get basePath() {
    return this.state.isLcv || isLcvOnly(this.state.brand)
      ? '/lcv'
      : '/';
  }

  get emptyPath() { return this.state.isLcv ? '/lcv' : CATALOG_URL; }

  getFormStateFromLocation = ({ baseEntities = {} }) => {
    const {
      match: {
        params,
      },
      location,
    } = this.props;

    const filteredBrands = this.getBrandOptions(baseEntities);

    /**
     * Если значение не найдено, то устанавливаем его в null вместо
     * undefined, потому что undefined при сбросе фильтров не воспринимается
     * для некоторых контролов и там остается предыдущее установленное
     * значение.
     */
    const brand = filteredBrands.find(item => (
      item.attributes.slug === params.brand
    )) || null;

    const filteredModels = this.getModelOptions(baseEntities, brand);
    const model = brand && filteredModels.find(item => (
      item.attributes.slug === params.model
    )) || null;

    const filteredGenerations = this.getGenerationOptions(baseEntities, model);
    const generation = model && filteredGenerations.find(item => (
      item.attributes.slug === params.generation
    )) || null;

    const formValuesFromUrl = queryString.parse(location.search);

    return {
      brand,
      model,
      generation,
      price: initRangeValue(
        'price',
        formValuesFromUrl,
        this.ranges.price
      ),
      body: initSelectValue(
        'body',
        formValuesFromUrl,
        this.formOptions.filteredBodies,
        'attributes.slug',
      ),
      engine_type: initSelectValue(
        'engine_type',
        formValuesFromUrl,
        this.formOptions.filteredEngineTypes,
      ),
      transmission: initSelectValue(
        'transmission',
        formValuesFromUrl,
        this.formOptions.filteredTransmissions,
      ),

      country: initSelectValue(
        'country',
        formValuesFromUrl,
        this.formOptions.filteredCountries,
      ),
      class: initSelectValue(
        'class',
        formValuesFromUrl,
        this.formOptions.filteredClasses,
        'attributes.url'
      ),
      assembly: initRadioValue(
        'assembly',
        formValuesFromUrl,
      ),
      power: initRangeValue(
        'power',
        formValuesFromUrl,
        this.ranges.power
      ),
      boost: initCheckboxValue(
        'boost',
        formValuesFromUrl,
      ),
      control: initRadioValue(
        'control',
        formValuesFromUrl,
      ),
      razgon: initRangeValue(
        'razgon',
        formValuesFromUrl,
        this.ranges.razgon
      ),
      rashod: initRangeValue(
        'rashod',
        formValuesFromUrl,
        this.ranges.rashod
      ),
      trunk_volume: initRangeValue(
        'trunk_volume',
        formValuesFromUrl,
        this.ranges.trunk_volume
      ),
      number_of_seats: initSwitchWithSeparatorValues(
        'number_of_seats',
        formValuesFromUrl,
        this.formOptions.filteredSeats,
      ),
      clearance: initRangeValue(
        'clearance',
        formValuesFromUrl,
        this.ranges.clearance
      ),
      options: initCheckboxValue(
        'options',
        formValuesFromUrl,
      ),
      usages: initCheckboxValue(
        'usages',
        formValuesFromUrl,
      ),
    };
  };

  getFormOptions = dictionaries => ({
    filteredBodies: generateSelectOptions(dictionaries.body, { value: 'attributes.slug' }),
    filteredEngineTypes: generateSelectOptions(dictionaries.engine_type, { label: 'title', value: 'value' }),
    filteredTransmissions: generateSelectOptions(dictionaries.transmission, { label: 'title', value: 'value' }),
    filteredCountries: generateSelectOptions(dictionaries.country, { label: 'title', value: 'value' }),
    filteredClasses: generateSelectOptions(dictionaries.class, { label: 'attributes.name', value: 'attributes.url' }),
    filteredAssemblies: generateSelectOptions(dictionaries.assembly, { label: 'title', value: 'value' }),
    filteredBoosts: generateSelectOptions(dictionaries.boost, { label: 'title', value: 'value' }),
    filteredControls: generateSelectOptions(dictionaries.control, { label: 'title', value: 'value' }),
    filteredSeats: dictionaries.seats,
    filteredOptions: generateOptionsGroup(dictionaries.option || {}),
    filteredUsages: generateSelectOptions(usages, { label: 'title', value: 'value' }),
  });

  handleCarTypeChange = e => {
    this.setState({
      isLcv: e.target.checked,
    });
  };

  getBrandOptions = memoize(
    (baseEntities = {}) => generateSelectOptions(baseEntities.brands)
  );

  getModelOptions = memoize(
    (baseEntities = {}, brand) => generateSelectOptions(filterEntitiesByRelation('brand', baseEntities.models, [brand]))
  );

  getGenerationOptions = memoize(
    (baseEntities = {}, model) => generateSelectOptions(filterEntitiesByRelation('model', baseEntities.generations, [model]))
  );

  render() {
    const {
      location,
      match: {
        params: {
          body: submodel,
        },
      },
    } = this.props;

    const {
      brand,
      model,
      baseEntities,
      isLcv,
    } = this.state;

    // Зависимые опции
    const filteredBrands = this.getBrandOptions(baseEntities);
    const filteredModels = this.getModelOptions(baseEntities, brand);
    const filteredGenerations = this.getGenerationOptions(baseEntities, model);

    const FormWrapper = this.isFullFormOpened ? FormModal : Fragment;

    return (
      <FormWrapper>
        <Form
          formState={this.state}
          formOptions={{
            filteredBrands,
            filteredModels,
            filteredGenerations,
            ...this.formOptions,
          }}
          ranges={this.ranges}
          onChange={this.handleControlChange}
          onCarTypeChange={this.handleCarTypeChange}
          location={location}
          navigateToNewUrl={this.navigateToNewUrl}
          toggleFullForm={this.toggleFullForm}
          allFiltersCount={this.allFiltersCount}
          fullFiltersCount={this.fullFiltersCount}
          resetAllForm={this.resetAllForm}
          isFiltersDisabled={!!submodel && !this.isFullFormOpened}
          isLcv={isLcv}
        />
      </FormWrapper>
    );
  }
}

CatalogForm.propTypes = {
  /** @ignore */
  location: PropTypes.object,

  /** @ignore */
  match: PropTypes.object,

  /** @ignore */
  history: PropTypes.object,
};

CatalogForm.contextTypes = {
  servicesApi: PropTypes.object.isRequired,
};

export default withRouter(CatalogForm);
