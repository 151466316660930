import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import styles from './index.styl';


function Headline({
  children,
  theme,
  dataQa,
}) {
  return (
    <h1 className={styles.headline}
      {...dataQa && { 'data-qa': dataQa }}
    >
      <style jsx>{`
        .${styles.headline}
          font ${theme.texts.headline.font}
          color ${theme.texts.headline.color}
      `}
      </style>
      {children}
    </h1>
  );
}

Headline.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  dataQa: PropTypes.string,
};

const HeadlineWithHOCs = withTheme(Headline);
HeadlineWithHOCs.displayName = 'Headline';

export default HeadlineWithHOCs;
export { Headline as StorybookComponent };
