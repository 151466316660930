import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';
import resolveRelationships from 'core/utils/relationships';
import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import Page from 'core/components/Page';
import H2 from 'core/components/H2';
import Feed from 'core/components/Feed';
import { withBreakpoint } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import ReleaseBoroda from 'site/components/ReleaseBoroda';
import { Indent, ReleaseInnerPages } from 'site/components/Wrappers';

import CardReleaseFeature from 'site/cardsService/CardReleaseFeature';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';
import modelPropTypes, { carReleaseAttributes, seoAttributes } from 'site/utils/prop-types/model';

import { releaseCommonProviders } from 'site/pages/release/releaseDataProvider';


const relationships = resolveRelationships(['features'], {}, {});

function ReleaseFeatures(props) {
  const {
    seo,
    release,
    isMobile,
    isTopicsByBrandExist,
  } = props;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const { name } = release.attributes;
  const { features } = relationships(release);

  return (
    <Page title={seo.title} description={seo.description}>
      <ReleaseInnerPages release={release} isTopicsByBrandExist={isTopicsByBrandExist} >
        <H2 as='h1'>{`Особенности ${name}`}</H2>
        <Indent bottom={vertical} />
        <Feed
          grid
          content={features}
          interitemSpacing={isMobile ? 0 : 20}
          columns={3}
          card={CardReleaseFeature}
        />
        <ReleaseBoroda release={release} />
      </ReleaseInnerPages>
    </Page>
  );
}

ReleaseFeatures.propTypes = {
  release: modelPropTypes(carReleaseAttributes),
  isMobile: PropTypes.bool,
  isTopicsByBrandExist: PropTypes.bool,
  seo: seoAttributes,
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
    }),
  }).isRequired,
};

const dataProvider = resolve({
  release: ({ servicesApi, renderError, match }) => {
    const {
      params: {
        brand,
        model,
        generation,
        body,
      },
    } = match;

    return (
      servicesApi
        .getReleases({
          limit: 1,
          'filter[brand]': brand,
          'filter[model]': model,
          'filter[generation]': generation,
          'filter[body]': body,
          include: 'car_brand,car_feature',
          'relations[car_release]': 'brand,features',
          'attributes[car_release]': 'base,media,seo,image,extended',
          'attributes[car_feature]': 'base,image,text',
        })
        .then(releases => denormalizeData(releases)[0])
        .then(rejectEmptyResult)
        .catch(renderError)
    );
  },

  seo: ({ servicesApi, consoleError, match: { params } }) => {
    return (
      servicesApi
        .getReleasesFeaturesSeo({
          'filter[brand]': params.brand,
          'filter[model]': params.model,
          'filter[generation]': params.generation,
          'filter[submodel]': params.body,
        })
        .then(denormalizeData)
        .catch(consoleError('seo', {}))
    );
  },
  ...releaseCommonProviders,
});

export default withPageHocs(dataProvider)(withBreakpoint(ReleaseFeatures));
