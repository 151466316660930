import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import resolveRelationships from 'core/utils/relationships';
import themePropTypes from 'core/utils/prop-types/theme';

import { Indent } from 'site/components/Wrappers';
import { VERTICAL_INDENT } from 'site/constants';

const relationships = resolveRelationships(
  ['video'],
  {},
  { video: {} },
);

function CaptionsVideo(props) {
  const {
    content,
    theme,
    isMobile,
  } = props;

  const {
    video: { caption },
  } = relationships(content);

  if (!caption) return null;

  const vertical = isMobile ? 0 : VERTICAL_INDENT;

  return (
    <Indent bottom={vertical}>
      <style jsx>{`
        .caption
          font 13px/1.2 ${theme.fonts.text}
          color ${theme.colors.primary500}
      `}
      </style>
      {caption && <div className='caption'>{caption}</div>}
    </Indent>
  );
}

CaptionsVideo.propTypes = {
  content: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  theme: themePropTypes(`{
    colors: {
      primary500,
    },
  }`),
};

export default withTheme(withBreakpoint(CaptionsVideo));
