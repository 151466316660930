import { useContext } from 'react';
import PropTypes from 'prop-types';

import themePropTypes from 'core/utils/prop-types/theme';

import pluralize from 'core/utils/pluralize';

import Link from 'core/components/Link';
import H3 from 'core/components/H3';
import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';
import Button from 'core/components/Button';

import NothingFound from 'site/components/NothingFound';

import { createCompareLink } from 'site/utils/compare';
import modelPropTypes, { carModificationAttributes } from 'site/utils/prop-types/model';

import { Indent } from 'site/components/Wrappers';

import { CompareContext } from 'site/components/CompareProvider';
import { ComparePageContext } from 'site/pages/compare/ComparePageProvider';

import Header from '../Header';
import CommonContent from '../CommonContent';


import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  MOTO_URL,
  CATALOG_URL,
} from 'site/constants';

import styles from './index.styl';

function ComparePage({ isMobile }) {
  const { carUuids, motoUuids } = useContext(CompareContext);
  const { comparisons, isMoto } = useContext(ComparePageContext);
  const uuids = isMoto ? motoUuids : carUuids;
  const { modifications, releases } = comparisons || {};
  const hasUuid = uuids && uuids.length > 0;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  /**
   * Если перейти по пустой ссылке `/compare`
   * и storage заполнен тремя ids, то отрисовывается ссылка в сравнении есть 3 модификации.
   */
  if (!modifications && hasUuid && !releases) {
    const carPluralizedText = pluralize(uuids.length, ['модификация', 'модификации', 'модификаций']);
    const motoPluralizedText = pluralize(uuids.length, ['мотоцикл', 'мотоцикла', 'мотоциклов']);
    const compareText = isMoto ? motoPluralizedText : carPluralizedText;

    return (
      <div className={styles.alignment}>
        <Header isMoto={isMoto} />
        <Indent top={vertical} bottom={vertical}>
          <H3 is='span'>
            В сравнении <Link to={createCompareLink(uuids, isMoto)}>есть {`${uuids.length} ${compareText}`}</Link>
          </H3>
        </Indent>
      </div>
    );
  }

  /**
   * Если перейти по пустой ссылке `/compare`
   * но storage пустой, то в сравнении ничего нет и тут срабатывает функционал "добавить в сравнение".
   */
  if (!modifications && !releases) {
    const catalogLink = isMoto ? MOTO_URL : CATALOG_URL;
    const btnText = `Добавьте ${isMoto ? 'мотоциклы' : 'автомобили'} из каталога`;

    return (
      <div className={styles.alignment}>
        <Header isMoto={isMoto} />
        <Indent top={vertical} bottom={vertical}>
          <NothingFound title='В сравнении ничего нет' />
        </Indent>
        <Link to={catalogLink}>
          <Button>{btnText}</Button>
        </Link>
      </div>
    );
  }

  return <CommonContent />;
}

ComparePage.propTypes = {
  modifications: PropTypes.arrayOf(modelPropTypes(carModificationAttributes)),
  isMobile: PropTypes.bool,
  theme: themePropTypes(`{
    colors: {
      primary100,
      divider,
    },
  }`),
};

export default withTheme(withBreakpoint(ComparePage));
