import PropTypes from 'prop-types';

import { Route, Switch } from 'core/libs/router';

import Page from 'core/components/Page';
import { withNonPureBreakpoint } from 'core/components/breakpoint';

import SecondaryMenu from 'site/components/SecondaryMenu';
import PageLayoutService from 'site/components/PageLayoutService';

import Review from 'site/pages/review';
import ReviewComments from 'site/pages/review/ReviewCommentsPage';

const routes = [
  {
    exact: true,
    path: '/review/:brand/:model/:generation/:body/:review_id/comments',
    component: ReviewComments,
  }, {
    exact: true,
    path: '/review/:brand/:model/:generation/:body/:review_id',
    component: Review,
  },
];

function Index({ isDesktop }) {
  return (
    <Page>
      <SecondaryMenu section='review' />
      <PageLayoutService bgContentList={isDesktop}>
        <Switch>
          {routes.map((routeParams, i) => {
            return <Route key={i} {...routeParams} />;
          })}
        </Switch>
      </PageLayoutService>
    </Page>
  );
}

Index.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withNonPureBreakpoint(Index);
