import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import themePropTypes from 'core/utils/prop-types/theme';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import cutDown from 'core/utils/cut-down';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import cardServiceHOC from 'site/components/cardService';
import bindProps from 'core/components/bindProps';
import Battery from 'site/components/Battery';
import User from 'site/components/User';
import LikeAndDislike from 'site/components/LikeAndDislike';
import { Indent } from 'site/components/Wrappers';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { reviewAttributes } from 'site/utils/prop-types/model';

import Star from 'site/icons/Star';

import styles from './index.styl';

import mapTypes from './types';

const WIDTH = 250;
const HEIGHT = 156;

const relationships = resolveRelationships(['user', 'release', 'modification'], {}, {});

function CardServiceReview2(props) {
  const [opened, toggleReview] = useState(false);

  const {
    position,
    theme,
    type,
    content,
  } = props;

  if (!content) return null;

  const {
    attributes: {
      like,
      dislike,
      name,
      ratings: [{ value: rating }],
      image,
      url = '/',
      text: reviewText,
    },
  } = content;

  const {
    user,
    modification: {
      name: modificationName = '',
      power = '',
      battery,
    },
    release: {
      short_name: shortName,
    },
  } = relationships(content);

  const { total: batteryTotal } = battery || {};

  const { withoutAutorInfo } = mapTypes[type] || {};

  const cover = getThumbnail(image, '3/2');

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .titleLink
          color ${theme.colors.primary}

          &:hover
          &:focus
            color ${theme.colors.active1}
      `}</style>
    </scope>
  );

  return (
    <div
      className={cx(
        'cardServiceReview2',
        styles.cardServiceReview2,
        opened && styles.opened,
        position && styles.hasBorder,
        position && 'hasBorder',
        withoutAutorInfo && styles.withoutAutorInfo,
      )}
      data-qa='review-cards'
    >
      <style jsx>{`
        .cardServiceReview2
          background ${theme.colors.content}

          &.hasBorder
            border-top 1px solid ${theme.colors.divider}

        .ratings
          color ${theme.colors.content}
          background ${theme.colors.active2}

        .engineItem
          &:after
            background ${theme.colors.primary400}

        .more
          color ${theme.colors.active1}

          &:hover
            color ${theme.colors.active2}
      `}</style>
      <div className={cx('image', styles.image)}>
        <div className={cx('ratings', styles.ratings)}>
          {`${rating} из 5`}
          <Star />
        </div>
        {cover && (
          <Image
            src={cover}
            width={WIDTH}
            aspectRatio={WIDTH / HEIGHT}
          />
        )}
      </div>
      <div className={styles.content}>
        <Link
          to={url}
          className={cx(scope.wrapClassNames('titleLink'), styles.titleLink)}
          type='secondary'
        >
          {name}
        </Link>
        <div className={cx('engineInfo', styles.engineInfo)}>
          <span className={cx('engineItem', styles.engineItem)}>
            {shortName && shortName + ' '}
            {modificationName && modificationName + ' '}
            {power && `(${power} л.с.)`}
          </span>
          {!!batteryTotal && (
            <span className={cx('engineItem', styles.engineItem)}>
              <Battery value={batteryTotal} />
            </span>
          )}
        </div>
        <div className={cx('review', styles.review)}>
          {reviewText.length > 200 ? (
            <Fragment>
              {opened ? reviewText : cutDown(reviewText, 200, true)}
              <span
                className={cx('more', styles.more)}
                onClick={() => toggleReview(!opened)}
              >{opened ? 'Скрыть' : 'Подробнее'}</span>
            </Fragment>
          ) : reviewText}
        </div>
      </div>
      {!withoutAutorInfo && (
        <div className={styles.meta}>
          <User user={user} />
          <Indent bottom={24} />
          <LikeAndDislike like={like} dislike={dislike} />
        </div>
      )}
      <scope.styles />
    </div>
  );
}

CardServiceReview2.propTypes = {
  /** Позиция карточки */
  position: PropTypes.number,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  breakpoint: breakpointPropTypes(),
  /** Данные для карточки, соответствующие модели `reviewAttributes` */
  content: modelPropTypes(reviewAttributes),
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1]),
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      primary400,
      divider,
    },
  }`),
};

CardServiceReview2.defaultProps = {
  position: 0,
  type: 0,
};

const CardWrapper = cardServiceHOC(CardServiceReview2);
CardWrapper.displayName = 'CardServiceReview2';

export const CardServiceReview2Type1 = bindProps({ type: 1 })(CardWrapper);
export default CardWrapper;
export { CardServiceReview2 as StorybookComponent };
