/**
 * Возвращает массив цен на основании текущей цены и снижения стоимости по годам
 */
export function calcPrices(basePrice, depreciations) {
  return [basePrice].concat(depreciations).reduce((acc, item, index) => {
    if (item === basePrice) {
      acc.push(item);
      return acc;
    }

    const newPrice = acc[index - 1] - item;
    acc.push(newPrice);
    return acc;
  }, []);
}

/**
 * Вычисляет средние значение в процентах на который модель дешевеет за год
 */
export function calcAveragePercentage(basePrice, depreciations) {
  const sumOfPercents = depreciations
    .map(depreciation => (depreciation * 100) / basePrice)
    .reduce((sum, percent) => sum + percent);

  return (sumOfPercents / depreciations.length).toFixed(0);
}
