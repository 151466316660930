import PropTypes from 'prop-types';

import { Fragment } from 'react';
import { Helmet } from 'core/libs/helmet';
import { Position } from 'core/components/Grid';
import H3 from 'core/components/H3';
import Image from 'core/components/Image';

import cones from './cones.png';
import { Indent } from 'site/components/Wrappers';

function NothingFound({ text, title, seoTitle, seoDescription }) {
  return (
    <Fragment>
      {seoTitle && seoDescription &&
        <Helmet>
          <title>{seoTitle}</title>
          <meta name='description' content={seoDescription} />
        </Helmet>
      }
      <Indent bottom={50} />
      <Position center>
        <Image
          src={{ rel_url: cones }}
          width={181}
          height={181}
        />
      </Position>
      <Position center>
        <H3 is='span'>{title}</H3>
      </Position>
      {text.length > 0 && (
        <Fragment>
          <Indent bottom={30} />
          <Position center>
            {text}
          </Position>
        </Fragment>
      )}
      <Indent bottom={120} />
    </Fragment>
  );
}

NothingFound.propTypes = {
  /** Заголовок */
  title: PropTypes.string,
  /** Пояснительный текст */
  text: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
};

NothingFound.defaultProps = {
  title: 'Ничего не найдено :(',
  text: '',
};

export default NothingFound;
