import PropTypes from 'prop-types';
import themePropTypes from 'core/utils/prop-types/theme';
import withTheme from 'core/components/theme';


function ListContentBg(props) {
  const {
    theme,
    style,
    marginCollapse,
    ...otherProps
  } = props;

  return (
    <div
      {...otherProps}
      style={{
        ...style,
        backgroundColor: theme.colors.contentList,
        ...marginCollapse && {
          borderTop: `1px solid ${theme.colors.contentList}`,
          borderBottom: `1px solid ${theme.colors.contentList}`,
        },
      }}
    />
  );
}

ListContentBg.propTypes = {
  marginCollapse: PropTypes.bool,
  style: PropTypes.object,
  theme: themePropTypes(`{
    colors: {
      contentList,
    }
  }`),
};

ListContentBg.defaultProps = {
  marginCollapse: false,
};

export default withTheme(ListContentBg);
