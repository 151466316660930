import PropTypes from 'prop-types';
import Divider from 'site/components/Divider';

import styles from './index.styl';

export default function TableDivider(props) {
  const {
    indentTop,
    indentBottom,
    colspan,
  } = props;

  return (
    <tr>
      <td
        className={styles.divider}
        colSpan={colspan}
        style={{
          ...indentTop && { paddingTop: `${indentTop}px` },
          ...indentBottom && { paddingBottom: `${indentBottom}px` },
        }}
      >
        <Divider />
      </td>
    </tr>
  );
}

TableDivider.propTypes = {
  colspan: PropTypes.number,
  indentTop: PropTypes.number,
  indentBottom: PropTypes.number,
};
