import { Fragment } from 'react';

import { Mobile, Desktop } from 'core/components/breakpoint';
import Scroller from 'core/components/Scroller';

import { Indent } from 'site/components/Wrappers';

import { SIDE_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';

export default function TabsWrapper({ children }) {
  return (
    <Fragment>
      <Desktop>
        {children}
      </Desktop>
      <Mobile>
        <Indent left={SIDE_INDENT_MOBILE}>
          <Scroller listClassName={styles.scrollerList}>
            {children}
          </Scroller>
        </Indent>
      </Mobile>
    </Fragment>
  );
}
