import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import { denormalizeData } from 'core/utils/api';
import resolveRelationships from 'core/utils/relationships';

import { SimpleLoader } from 'core/components/Loader';
import Drum from 'core/components/Drum';

import { withBreakpoint } from 'core/components/breakpoint';

import FeedHeader from 'site/components/FeedHeader';
import { ShadowContentBg } from 'site/components/Wrappers';

import CardRelease1WithPrice from 'site/cardsService/CardServiceRelease1/cards/CardRelease1WithPrice';

import { isMotoPath } from 'site/utils';


const relationships = resolveRelationships(['classmates'], {}, {});

function Classmates(props, { servicesApi }) {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    mainReleaseId,
    isMobile,
    match: {
      params,
    },
    location,
    withShadowBg,
  } = props;

  useEffect(() => {
    let ignore = false;

    const {
      brand,
      model,
      generation,
      body,
    } = params;

    let releaseAPI;
    const isMoto = isMotoPath(location.pathname);

    const vehiclePrefix = isMoto ? 'moto' : 'car';

    const requestParams = {
      'filter[brand]': brand,
      'filter[model]': model,
      'filter[generation]': generation,
      'filter[body]': body,
      'filter[usages]': 'catalog,archive',
      [`attributes[${vehiclePrefix}_release]`]: 'base,image',
      [`relations[${vehiclePrefix}_release]`]: 'classmates',
      include: `${vehiclePrefix}_release`,
    };

    if (isMoto) {
      releaseAPI = servicesApi.getMotoReleases;
    } else {
      releaseAPI = servicesApi.getReleases;
    }

    releaseAPI(requestParams)
      .then(releases => {
        const relations = relationships(denormalizeData(releases)[0]);
        return relations.classmates;
      })
      .then(releases => releases.filter(item => item.id !== mainReleaseId))
      .then(data => {
        if (!ignore) {
          setContent(data);
          setLoading(false);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setLoading(false);
        }
      });

    return () => {
      ignore = true;
    };
  }, [params, location.pathname, mainReleaseId, servicesApi]);

  if (loading) return <SimpleLoader />;

  if (!content || content.length === 0) return null;

  const commonProps = {
    hideArrows: true,
    showHeaderArrows: true,
    title: 'Конкуренты',
    headerComponent: FeedHeader,
    grid: true,
    itemWidth: '25%',
    itemWidthMobile: '50%',
    spaceBetween: 20,
    content,
    card: CardRelease1WithPrice,
  };

  if (withShadowBg) {
    return (
      <ShadowContentBg type={isMobile ? 4 : 3}>
        <Drum {...commonProps} />
      </ShadowContentBg>
    );
  }

  return (
    <Drum {...commonProps} />
  );
}

Classmates.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  match: PropTypes.object,
  /** @ignore */
  location: PropTypes.object,
  /** Флаг, включающий обрамление блока тенью */
  withShadowBg: PropTypes.bool,
  /** Модель для сравнения */
  mainReleaseId: PropTypes.string,

};

Classmates.contextTypes = {
  /** @ignore */
  servicesApi: PropTypes.object.isRequired,
};

const ClassmatesWithHOCs = withBreakpoint(withRouter(Classmates));

ClassmatesWithHOCs.displayName = 'Classmates';

export default ClassmatesWithHOCs;
export { Classmates as StorybookComponent };
