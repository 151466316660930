import { useState, useEffect, useContext, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';

import skip from 'core/resolver/skip';

import Link from 'core/components/Link';
import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import ButtonWithIcon from 'core/components/ButtonWithIcon';
import Notify from 'site/components/Notify';
import { CompareContext } from 'site/components/CompareProvider';
import ButtonTextWrap from 'site/components/Wrappers/ButtonTextWrap';

import PlusMd from 'site/icons/PlusMd';
import Add from 'site/icons/Add';
import Added from 'site/icons/Added';

import { createCompareLink } from 'site/utils/compare';
import { useCompareCheck } from 'site/utils/compare/hooks';

import styles from './index.styl';


function ButtonCompare(props) {
  const {
    modelId,
    transportType,
    fullWidth,
    round,
    theme,
    isMobile,
    customTextButton,
  } = props;

  const [checked, toggleChecked] = useCompareCheck(modelId, transportType);
  const [showNotify, toggleNotify] = useState(false);
  const timeoutRef = useRef(null);

  const isMoto = transportType === 'moto_release';
  const {
    [isMoto ? 'motoUuids' : 'carUuids']: uuids,
  } = useContext(CompareContext);

  const clear = () => clearTimeout(timeoutRef.current);


  useEffect(() => clear, []);

  function onClick() {
    toggleChecked();
    if (!checked) {
      toggleNotify(true);
      timeoutRef.current = setTimeout(() => toggleNotify(false), 5000);
    } else {
      toggleNotify(false);
    }
  }

  const iconSize = isMobile ? 24 : 30;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          &
          &:hover
          &:visited
            color ${theme.colors.contentList}
            text-decoration none

        .${styles.control}
          width ${iconSize}px
          height ${iconSize}px
          &:before
            transition opacity ${theme.animations.hover}
      `}</style>
    </scope>
  );

  const Icon = checked ? Added : Add;
  const textButton = checked ? customTextButton[0] : customTextButton[1];
  return (
    <Fragment>
      {round ? (
        <div className={scope.wrapClassNames(styles.control)} title={textButton}>
          <Icon
            width={iconSize}
            height={iconSize}
            onClick={onClick}
          />
        </div>
      ) :
        <ButtonWithIcon
          radius='20px'
          onClick={onClick}
          {...fullWidth && { minWidth: '100%' }}
          {...!checked && { icon: PlusMd }}
        >
          <ButtonTextWrap>{textButton}</ButtonTextWrap>
        </ButtonWithIcon>
      }
      <Notify isShow={showNotify}>
        <div className={styles.text}>Добавлено в сравнение</div>
        <Link to={createCompareLink(uuids, isMoto)} className={scope.wrapClassNames('link')}>
          СРАВНИТЬ ({uuids.length})
        </Link>
      </Notify>
      <scope.styles />
    </Fragment>
  );
}

ButtonCompare.defaultProps = {
  customTextButton: ['Убрать из сравнения', 'Добавить в сравнение'],
};

ButtonCompare.propTypes = {
  /** ID модели */
  modelId: PropTypes.string,
  /** Тип транспорта */
  transportType: PropTypes.string,
  /** Растягивает кнопку на всю ширину контейнера */
  fullWidth: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
  /** Флаг позволяет отобразить круглую кнопку */
  round: PropTypes.bool,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Массив, переопределяющий текст на кнопке */
  customTextButton: PropTypes.array,
};

const ButtonCompareWithHOCs = skip(withTheme(withBreakpoint(ButtonCompare)));
ButtonCompareWithHOCs.displayName = 'ButtonCompare';

export default ButtonCompareWithHOCs;

export { ButtonCompare as StorybookComponent };
