export default {
  1: { size: 'tiny', isReverse: true },
  2: { size: 's', borderDash: true },
  3: { size: 's', borderSolid: true },
  4: { size: 's', borderSolid: true, withoutBg: true },
  5: { size: 'xs', borderSolid: true },
  6: { size: 'm', borderSolid: true },
  7: { size: 'm', borderRadius: true, boxShadow: true },
  8: {
    size: 'miniature',
    isReverse: true,
    borderRadius: true,
    boxShadow: true,
    fitContent: true,
  },
  9: {
    size: 's',
    borderDash: true,
    withoutBg: true,
    borderRadius: true,
  },
  10: {
    size: 'miniatureMobile',
    isReverse: true,
    borderRadius: true,
    boxShadow: true,
    fitContent: true,
  },
};
