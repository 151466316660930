import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'core/libs/router';

import color from 'core/libs/color';
import resolveRelationships from 'core/utils/relationships';

import cardHOC from 'core/components/card';
import AdvTooltip from 'core/components/AdvTooltip';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import { cardDateFormat } from 'site/utils';
import { JOURNAL_URL } from 'site/constants';

import mapTypes from './types';
import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'topic_type',
  'published_at',
  'ad_label',
  'link_with_announce_erid',
  'advertiser_name',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card3(props) {
  const {
    content,
    theme,
    type,
    dataQa,
    location,
    position,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    topic_type: topicType,
    published_at: publishedAt,
    ad_label: adLabel,
    link_with_announce_erid: linkWithAnnounceErid,
    advertiser_name: advertiserName,
  } = content.attributes;

  const {
    width: imageWidth,
    height: imageHeight,
    iconSize,
  } = mapTypes[type] || {};

  const {
    icons: {
      Video,
      Photo,
    },
  } = theme;

  const mapIconByType = {
    video: Video,
    gallery: Photo,
  };

  const Icon = mapIconByType[topicType];

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const isJournalPage = location.pathname === JOURNAL_URL;
  const shouldShowAdvTooltip = !!(isJournalPage && position === 0 && linkWithAnnounceErid && advertiserName && adLabel);
  const topicLink = shouldShowAdvTooltip ? linkWithAnnounceErid : link;

  return (
    <div className={cx('card3', styles.card3)} data-qa={dataQa}>
      <style jsx>{`
        .card3
          background ${theme.colors.content}
          box-shadow 0px 3px 5px ${color(theme.colors.primary).alpha(0.15)}

          &:hover
            .headline
              color ${theme.colors.active1}

        .image
          flex 0 0 ${imageWidth}px
          &:after
            background ${theme.colors.primary}
            transition opacity ${theme.animations.hover}

        .headline
          color ${theme.colors.primary}
          font 500 20px/1 ${theme.fonts.text}
          transition color ${theme.animations.hover}

        .announce
          color ${theme.colors.primary}
          font 14px/1.2 ${theme.fonts.text}
          transition color ${theme.animations.hover}

        .date
          color ${theme.colors.hint}
          font 14px/1.2 ${theme.fonts.text}
      `}
      </style>
      <Link
        to={topicLink}
        type='secondary'
        className={styles.link}
      >
        <div className={cx(styles.image, 'image')}>
          <Image
            src={originalCover}
            previewSrc={previewCover}
            width={imageWidth}
            height={imageHeight}
          />
          {Icon && (
            <span className={styles.icon}>
              <Icon width={iconSize} height={iconSize} />
            </span>
          )}
        </div>
        <div className={styles.content}>
          <div className={cx(styles.headline, 'headline')} data-qa='card-headline'>{listHeadline || headline}</div>
          <div className={styles.footer}>
            <div className={cx(styles.date, 'date')}>
              {cardDateFormat(publishedAt)}
            </div>
          </div>
        </div>
      </Link>
      {shouldShowAdvTooltip && (
        <AdvTooltip text={advertiserName} adLabel={adLabel} />
      )}
    </div>
  );
}

Card3.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([1]),
  /** Данные для карточки */
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  dataQa: PropTypes.string,
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  position: PropTypes.number,
};

Card3.defaultProps = {
  type: 1,
};

const Card = cardHOC(withRouter(Card3));

Card.displayName = 'Card3';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card3 as StorybookComponent };
