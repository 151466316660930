import cx from 'classnames';

import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';

import modelPropTypes, { carModificationAttributes } from 'site/utils/prop-types/model';

import styles from './index.styl';


function Specifications({ modification, theme }) {
  const {
    attributes: {
      power,
      specifications_primary: {
        transmission,
        drive,
        engine,
        acceleration,
        power: motoPower,
      },
    },
  } = modification;

  const { value: transmissionValue } = transmission || {};
  const { value: driveValue } = drive || {};
  const { value: engineValue } = engine || {};
  const { value: accelerationValue } = acceleration || {};
  const { value: powerValue } = power || motoPower || {};

  return (
    <div className={styles.specifications}>
      <style jsx>{`
        .${styles.title}
          color ${theme.colors.primary500}
          font 13px/15px ${theme.fonts.text}

        .${styles.value}
          color ${theme.colors.primary}
          font 16px/23px ${theme.fonts.text}
      `}</style>
      {Boolean(transmissionValue) &&  (
        <div className={styles.item}>
          <div className={cx(styles.sprite, styles.transmissions)} />
          <div className={styles.body}>
            <div className={styles.title}>Коробка передач</div>
            <div className={styles.value}>
              {transmissionValue}
            </div>
          </div>
        </div>
      )}

      {Boolean(driveValue) &&  (
        <div className={styles.item}>
          <div className={cx(styles.sprite, styles.drives)} />
          <div className={styles.body}>
            <div className={styles.title}>Привод</div>
            <div className={styles.value}>
              {driveValue}
            </div>
          </div>
        </div>
      )}

      {Boolean(engineValue && powerValue) && (
        <div className={styles.item}>
          <div className={cx(styles.sprite, styles.engines)} />
          <div className={styles.body}>
            <div className={styles.title}>Двигатель</div>
            <div className={styles.value}>
              {`${engineValue} ${powerValue} л.с.`}
            </div>
          </div>
        </div>
      )}

      {accelerationValue &&  (
        <div className={styles.item}>
          <div className={cx(styles.sprite, styles.accelerations)} />
          <div className={styles.body}>
            <div className={styles.title}>Разгон до 100 км/ч</div>
            <div className={styles.value}>
              {`${accelerationValue} сек`}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

Specifications.propTypes = {
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      primary500,
    },
  }`),
  /** Данные модификации, соответствующие модели `carModificationAttributes` */
  modification: modelPropTypes(carModificationAttributes),
};

const SpecificationsWithHOCs = withTheme(Specifications);
SpecificationsWithHOCs.displayName = 'Specifications';

export default SpecificationsWithHOCs;
export { Specifications as StorybookComponent };
