import PropTypes from 'prop-types';

import { withBreakpoint, Mobile, Desktop } from 'core/components/breakpoint';

import AdWrapper from 'core/components/Ad/AdWrapper';

import { Context } from 'site/components/Ads/desktop';
import { Footer as FootBanner } from 'site/components/Ads/mobile';

import { SideIndent } from 'site/components/Wrappers';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

function FooterAds({ isMobile, contextCount }) {
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <SideIndent>
      <AdWrapper bottom={vertical}>
        <Desktop>
          <Context count={contextCount} />
        </Desktop>
        <Mobile>
          <FootBanner />
        </Mobile>
      </AdWrapper>
    </SideIndent>
  );
}

FooterAds.propTypes = {
  /**
   * Устанавливает порядковый номер puid44 для рекламного места Context
   */
  contextCount: PropTypes.number,
  /** @ignore */
  isMobile: PropTypes.bool,
};

FooterAds.defaultProps = {
  contextCount: 1,
};

const FooterAdsWithHOCs = withBreakpoint(FooterAds);
FooterAdsWithHOCs.displayName = 'FooterAds';

export default FooterAdsWithHOCs;
export { FooterAds as StorybookComponent };
