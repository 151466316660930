import cx from 'classnames';

import Close from 'site/icons/Close';
import styles from './index.styl';


function BalloonWrapper(props) {
  const {
    children,
  } = props;

  /** Классы без styles не удалять, нужны для позиционирования и закрытия баллуна. Используются в DealersMap */
  return (
    <div className={cx(styles.balloonWrapper, 'balloonWrapper')}>
      <Close className={cx(styles.close, 'balloonButtonClose')} />
      <div className={styles.ballonContent}>
        {children}
      </div>
      <div className={cx(styles.arrow, 'balloonArrow')} />
    </div>
  );
}

export default BalloonWrapper;
