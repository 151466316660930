import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Desktop, Mobile, withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';
import { Block, Section } from 'core/components/Grid';
import bindProps from 'core/components/bindProps';

import themePropTypes from 'core/utils/prop-types/theme';

import H3 from 'core/components/H3';
import Feed from 'core/components/Feed';

import FeedHeader from 'site/components/FeedHeader';
import Divider from 'site/components/Divider';
import { Indent, ShadowContentBg } from 'site/components/Wrappers';

import CardServiceComparison from 'site/cardsService/CardServiceComparison';

import { carModificationAttributes } from 'site/utils/prop-types/model';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';


function Comparison({
  content,
  isMobile,
  theme,
  withShadowBg,
  title,
  vehicleType,
}) {
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  if (!content || content.length === 0) return null;

  const first = content[0];
  const second = content.slice(1, 4);

  const justOneComparison = content.length === 1;
  const Wrapper = withShadowBg ? bindProps({ type: isMobile ? 4 : 3 })(ShadowContentBg) : Fragment;

  const firstCardDesktopType = justOneComparison ? 3 : 2;

  return (
    <Wrapper>
      <div className='comparison'>
        <style jsx>{`
          .separator
            border-right 1px dashed ${theme.colors.divider}
        `}</style>

        <Indent bottom={vertical}>
          <Mobile>
            <FeedHeader>{title}</FeedHeader>
          </Mobile>
          <Desktop>
            <H3>{title}</H3>
          </Desktop>
        </Indent>

        {justOneComparison
          ? (
            <Fragment>
              <CardServiceComparison
                type={isMobile ? 1 : firstCardDesktopType}
                content={first}
                vehicleType={vehicleType}
              />
              <Mobile>
                <Divider type={2} top={10} />
              </Mobile>
            </Fragment>
          ) : (
            <Section>
              <Block width={justOneComparison ? 12 : 6} mobile={12}>
                <CardServiceComparison
                  type={isMobile ? 1 : firstCardDesktopType}
                  content={first}
                  vehicleType={vehicleType}
                />
                <Mobile>
                  <Divider type={2} top={10} />
                </Mobile>
              </Block>
              <Block width={1} mobile={0}>
                <div className={cx(styles.separator, 'separator')} />
              </Block>
              <Block width={5} mobile={12}>
                <Feed
                  card={bindProps({ type: 1, vehicleType })(CardServiceComparison)}
                  content={second}
                  interitemSpacing={10}
                />
              </Block>
            </Section>
          )
        }
      </div>
    </Wrapper>
  );
}

Comparison.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      items: PropTypes.arrayOf(carModificationAttributes),
    })
  ),
  isMobile: PropTypes.bool,
  theme: themePropTypes(`{
    colors: {
      divider,
    }
  }`),
  withShadowBg: PropTypes.bool,
  title: PropTypes.string,
  vehicleType: PropTypes.string,
};

Comparison.defaultProps = {
  title: 'Часто сравнивают',
};

const ComparsionWithHOCs = withBreakpoint(withTheme(Comparison));

ComparsionWithHOCs.displayName = 'Comparsion';

export default ComparsionWithHOCs;
export { Comparison as StorybookComponent };
