import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';

import queryString from 'core/libs/query-string';

import Link from 'core/components/Link';
import Button from 'core/components/Button';
import withTheme from 'core/components/theme';

import pluralize from 'core/utils/pluralize';

import withGeo from 'site/components/GeoContext/withGeo';

import {
  excludeObjectValues,
  getSlugInObject,
} from 'site/utils';

import {
  buildPathnameFromValues,
  buildSearchParamsFromValues,
} from 'site/utils/forms';

import styles from './index.styl';

import { BASE_PATH, PATHNAME_FIELDS, EXCLUDED_STATE_FIELDS } from '../constants';

const OFFERS_WORD_FORMS = ['объявление', 'объявления', 'объявлений'];

function ButtonOffers({ geoSlug, formState, ranges }, { servicesApi }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let ignore = false;

    const {
      brand,
      model,
      models,
      condition = [],
      price = {},
      body = [],
      year = {},
    } = formState;

    if (brand && (!models.length || models.filter(m => brand.id !== m.relationships.brand.data.id).length)) {
      return () => {
        ignore = true;
      };
    }

    servicesApi.getOffers({
      'filter[brand]': brand?.value || brand,
      'filter[model]': model?.value || model,
      'filter[body_type]': body ? getSlugInObject(body) : '',
      'filter[condition]': condition.map(item => item.value).join(','),
      'filter[price_min]': price ? price.min : '',
      'filter[price_max]': price ? price.max : '',
      'filter[year_min]': year ? year.min : '',
      'filter[year_max]': year ? year.max : '',
      'filter[geo]': geoSlug,
      only_filtered_count: true,
    })
      .then(({ meta }) => meta.filtered_count)
      .then(data => {
        if (!ignore) {
          setCount(data);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setCount(0);
        }
      });

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formState), geoSlug]);

  const EMPTY = {};

  const ButtonWrapper = count ? Link : Fragment;

  const pathname = buildPathnameFromValues(
    formState,
    PATHNAME_FIELDS,
    BASE_PATH + (geoSlug ? `/${geoSlug}` : '')
  );

  const searchParams = buildSearchParamsFromValues(
    excludeObjectValues(formState, PATHNAME_FIELDS.concat(EXCLUDED_STATE_FIELDS)),
    ranges,
  );

  const buttonWrapperProps = count ? { to: [pathname, queryString.stringify(searchParams)].join('?') } : EMPTY;

  const formattedCount = accounting.formatMoney(count, pluralize(count, OFFERS_WORD_FORMS));
  const buttonText = count === 0 ? 'Нет объявлений' : formattedCount;

  return (
    <ButtonWrapper {...buttonWrapperProps}>
      <Button
        type='limeGradient'
        radius='50px'
        minWidth='100%'
        disabled={count === 0}
      >
        <span className={styles.buttonText}>{buttonText}</span>
      </Button>
    </ButtonWrapper>
  );
}

ButtonOffers.propTypes = {
  countOffers: PropTypes.number,
  geoSlug: PropTypes.string,
  formState: PropTypes.object,
  ranges: PropTypes.object,
};

ButtonOffers.contextTypes = {
  servicesApi: PropTypes.object.isRequired,
};

export default withGeo(withTheme(ButtonOffers));
