import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';

import withTheme from 'core/components/theme';
import { withNonPureBreakpoint } from 'core/components/breakpoint';

import { withRouter } from 'core/libs/router';

import { FULL_FILTER_HASH, SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

const FormContainer = (props) => {
  const horizontalIndent = props.isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;
  const isFullFilterOpened = props.location.hash === FULL_FILTER_HASH;
  return (
    <div style={{
      padding: `${props.isMobile ? 25 : 20}px ${horizontalIndent}px`,
      background: props.theme.colors.active2,
      color: props.theme.colors.text,
      ...isFullFilterOpened && {
        flexGrow: 1,
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
      },
    }}
    >
      {props.children}
    </div>
  );
};

FormContainer.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  location: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
};

const FormContainerWithHOCs = compose(
  withTheme,
  withNonPureBreakpoint,
  withRouter,
)(FormContainer);
FormContainerWithHOCs.displayName = 'FormContainer';

export default FormContainerWithHOCs;
export { FormContainer as StorybookComponent };
