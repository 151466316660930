import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import { carBrandAttributes } from 'core/utils/prop-types/model';

import { withNonPureBreakpoint } from 'core/components/breakpoint';

import Block from 'core/components/GridBlock';
import Section from 'core/components/GridSection';

import DealersMap from 'site/components/DealersMap';
import Modal from 'site/components/Modal';
import NothingFound from 'site/components/NothingFound';

import relationshipsPropTypes from 'site/utils/prop-types/relationships';
import modelPropTypes, {
  geoAttributes,
  dealerNetworkAttributes,
  dealerAttributes,
} from 'site/utils/prop-types/model';

import List from '../List';
import ViewToggle, { ViewToggleButton } from '../ViewToggle';
import TopPanel from '../TopPanel';


class DealersPageSearchResults extends React.Component {
  state = {
    mapView: false,
    selectedItemId: null,
    isToggleButtonHidden: false,
    showViewButton: true,
  };

  componentDidMount() {
    if (this.listWrapper) {
      this.observer = new IntersectionObserver(this.observerCallback, {
        threshold: [0.25, 0.75],
      });
      this.observer.observe(this.listWrapper);
    }
  }

  componentWillUnmount() {
    this.observer && this.listWrapper && this.observer.unobserve(this.listWrapper);
  }

  observerCallback = entries => {
    const firstEntry = entries[0];

    if (this.state.isToggleButtonHidden === firstEntry.isIntersecting) {
      this.setState({ isToggleButtonHidden: !firstEntry.isIntersecting });
    }
  };

  toggleMapView = () => this.setState(({ mapView }) => ({ mapView: !mapView }));

  handleCardClick = ({ id }) => this.setState({ selectedItemId: id });

  assignListWrapperRef = el => { this.listWrapper = el; };

  toggleViewButton = (showViewButton) => this.setState({ showViewButton: showViewButton });

  render() {
    const {
      dealers,
      isDesktop,
      isMobile,
      match: {
        params: {
          geo: geoSlug,
        },
      },
    } = this.props;

    const {
      mapView,
      selectedItemId,
      isToggleButtonHidden,
      showViewButton,
    } = this.state;

    if (!dealers || !dealers.length) {
      const errorMessage = dealers && !dealers.length
        ? 'Ничего не найдено :('
        : 'Что-то пошло не так. Попробуйте обновить страницу.';

      return (
        <NothingFound title={errorMessage} />
      );
    }

    const mapProps = {
      dealers,
      geoSlug,
      height: isMobile ? '100vh' : '100%',
      selectedItemId,
      controls: isMobile ? ['zoomControl'] : undefined,
      handlePinClick: this.toggleViewButton,
    };

    if (isDesktop) {
      return (
        <Fragment>
          <Section>
            <Block width={4}>
              <div style={{ height: 'calc(100vh - 240px)', overflow: 'auto' }}>
                <List
                  dealers={dealers}
                  cardClickHandler={this.handleCardClick}
                />
              </div>
            </Block>
            <Block>
              <DealersMap {...mapProps} />
            </Block>
          </Section>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Modal isOpen={mapView}>
          <TopPanel handleBackClick={this.toggleMapView} />
          <DealersMap {...mapProps} />
          {showViewButton &&
            <ViewToggleButton
              text='Показать список'
              clickHandler={this.toggleMapView}
            />
          }

        </Modal>
        <div ref={this.assignListWrapperRef}>
          <List dealers={dealers} />
        </div>
        {!mapView && (
          <ViewToggle
            text='На карте'
            clickHandler={this.toggleMapView}
            hidden={isToggleButtonHidden}
          />
        )}
      </Fragment>
    );
  }
}

DealersPageSearchResults.propTypes = {
  /** Список дилеров для отображения. */
  dealers: PropTypes.arrayOf(modelPropTypes(
    dealerAttributes,
    relationshipsPropTypes({
      geo: modelPropTypes(geoAttributes),
      car_brands: modelPropTypes(carBrandAttributes),
      dealer_network: modelPropTypes(dealerNetworkAttributes),
    }),
  )),

  /** @ignore */
  match: PropTypes.shape({
    params: PropTypes.shape({
      geo: PropTypes.string,
      brand: PropTypes.string,
    }),
  }),

  /** @ignore */
  isDesktop: PropTypes.bool,

  /** @ignore */
  isMobile: PropTypes.bool,
};

export default withRouter(withNonPureBreakpoint(DealersPageSearchResults));
