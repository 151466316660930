import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';

import { Desktop, Mobile } from 'core/components/breakpoint';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import Page from 'core/components/Page';
import Bouesque from 'core/components/Bouesque';
import AdWrapper from 'core/components/Ad/AdWrapper';
import H3 from 'core/components/H3';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent, Indent } from 'core/components/Wrappers';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import BorodaService from 'site/components/BorodaService';
import Brands from 'site/components/Brands';
import NewReleases from 'site/components/NewReleases';
import Comparison from 'site/components/Comparison';
import FooterAds from 'site/components/FooterAds';
import Stories from 'site/components/Stories';

import {
  SuperFooter,
  Context,
} from 'site/components/Ads/desktop';

import {
  ListingSpec,
  Listing2,
} from 'site/components/Ads/mobile';

import ParallaxWrapper from 'site/components/Ads/ParallaxWrapper';
import { InnerSideColumnPart1, InnerSideColumnPart2 } from 'site/components/InnerSideColumn';
import withGeo from 'site/components/GeoContext/withGeo';

import NewInJournal from './NewInJournal/';
import NowOnSale from './NowOnSale/';
import Popular from './Popular/';
import Reviews from './Reviews/';
import OffersSearch from './OffersSearch';

import mainResolver from './mainTopics';


function MainPage(props) {
  const {
    newInJournal,
    popular,
    brands,
    comparisons,
    newReleases,
    nowOnSale,
    geoSlug,
    isMobile,
  } = props;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Page>
      <AdWrapper bottom={vertical}>
        <Stories />
        <NewInJournal content={newInJournal} />
        <PageIndent>
          <ColumnLayout rightColumn={<InnerSideColumnPart1 showCarNews={false} />}>
            <H3 is='h1'>Каталог автомобилей</H3>
            <Indent top={vertical} />
            <Brands brands={brands} />
            <Mobile>
              <Indent top={vertical} />
              <Bouesque />
            </Mobile>
            <Indent top={vertical} />
            <NewReleases content={newReleases} />
            <Indent top={vertical} />
            <Desktop>
              <SuperFooter />
            </Desktop>
            <Popular topics={popular} />
            <Indent top={vertical} />
            <Mobile>
              <ListingSpec />
            </Mobile>
          </ColumnLayout>

          <OffersSearch geoSlug={geoSlug} />

          <Indent top={vertical} />
          <ParallaxWrapper />

          <H3>Сейчас в продаже</H3>
          <NowOnSale content={nowOnSale} geoSlug={geoSlug} />

          <Indent top={vertical} />
          <ColumnLayout rightColumn={<InnerSideColumnPart2 />}>
            <Comparison content={comparisons} />
            <Indent top={vertical} />
            <Mobile>
              <Listing2 />
            </Mobile>
            <Reviews />
            <Indent top={vertical} />
            <Desktop>
              <Context />
            </Desktop>
            <BorodaService />
          </ColumnLayout>
        </PageIndent>
        <FooterAds contextCount={2} />
      </AdWrapper>
    </Page>
  );
}

MainPage.propTypes = {
  newInJournal: PropTypes.array,
  popular: PropTypes.array,
  brands: PropTypes.array,
  comparisons: PropTypes.array,
  newReleases: PropTypes.array,
  nowOnSale: PropTypes.array,
  geoSlug: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default compose(
  withBreakpoint,
  withGeo,
  withPageHocs(mainResolver),
)(MainPage);
