import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';

import CommentsPreview from 'core/components/CommentsPreview';
import Page from 'core/components/Page';
import Scroller from 'core/components/Scroller';
import { Desktop, Mobile, withBreakpoint } from 'core/components/breakpoint';
import { TopicContentMaxWidth, Indent, NegativeMobile } from 'core/components/Wrappers';

import withTheme from 'core/components/theme';
import withPageHocs from 'core/components/withPageHocs';

import themePropTypes from 'core/utils/prop-types/theme';

import Divider from 'site/components/Divider';
import TitleBackward from 'site/components/TitleBackward';
import SocialShare from 'site/components/SocialShare';

import modelPropTypes, { reviewAttributes } from 'site/utils/prop-types/model';
import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import { CoverImage, CoverGallery } from './covers';
import ReviewTable from './ReviewTable/';
import Info from './Info/';
import MoreReviews from './MoreReviews';

import { REVIEW_COMMENTS_ID_PREFIX, VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';

function ReviewPage(props) {
  const {
    theme,
    isMobile,
    reviewData,
    reviews,
    reviewSeo,
  } = props;

  const {
    review,
  } = reviewData;

  const {
    brand,
    model,
  } = props.match.params;

  const {
    url,
    name,
    slug: qutoId,
    text: reviewText,
    image,
    photogallery,
  } = review.attributes;

  const imageCount = photogallery?.attributes?.image_count;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Page title={reviewSeo.title} description={reviewSeo.description}>
      <style jsx>{`
        .date
          color ${theme.colors.primary400}

        .title
          font-family ${theme.fonts.display}

        .cover
          background ${theme.colors.content}
          box-shadow ${isMobile ? 'none' : `0 2px 5px 0 ${theme.colors.primary100}`}
      `}</style>
      <TitleBackward
        url={`/review/${brand}/${model}`}
        title={name}
      />
      <Indent bottom={28} />
      <SocialShare topicLink={url} />
      <Indent bottom={20} />
      <NegativeMobile>
        {image && imageCount === 1 && <CoverImage image={image} />}
        {+imageCount > 1 && <CoverGallery photoGallery={photogallery} />}
      </NegativeMobile>
      <div className={cx(styles.cover, 'cover')}>
        <div className={styles.info}>
          <Desktop>
            <Info review={review} />
            <Divider top={22} bottom={28} />
          </Desktop>
          <Mobile>
            <NegativeMobile>
              <Scroller>
                <Info review={review} />
              </Scroller>
              <Divider top={14} bottom={20} />
            </NegativeMobile>
          </Mobile>
          <ReviewTable review={review} />
        </div>
      </div>
      <Mobile>
        <Divider top={26} bottom={20} />
      </Mobile>
      <TopicContentMaxWidth>
        <Indent bottom={20} />
        {reviewText.split('\n').map((item, i) => (
          <p key={i}>{item}</p>
        ))}
        {reviews.length > 0 && (
          <Fragment>
            <Desktop>
              <Divider top={26} bottom={20} />
            </Desktop>
            <MoreReviews reviews={reviews} />
          </Fragment>
        )}
        <Indent bottom={20} />
        <CommentsPreview
          xid={REVIEW_COMMENTS_ID_PREFIX + qutoId}
          commentsUrl={`${url}/comments`}
        />
      </TopicContentMaxWidth>
      <Indent bottom={vertical} />
    </Page>
  );
}

ReviewPage.propTypes = {
  isMobile: PropTypes.bool,
  reviewData: PropTypes.shape({
    review: modelPropTypes(reviewAttributes),
    seo: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      seo: PropTypes.string,
    }),
  }),
  reviewSeo: PropTypes.object,
  reviews: PropTypes.arrayOf(modelPropTypes(reviewAttributes)),
  match: PropTypes.object.isRequired,
  theme: themePropTypes(`{
    colors: {
      primary100,
      primary400,
      content,
    },
  }`),
};

const dataProvider = resolve({
  reviewData: props => {
    const {
      servicesApi,
      renderError,
      match: {
        params: {
          brand,
          model,
          generation,
          body,
          review_id: reviewId,
        },
      },
    } = props;

    return servicesApi
      .getReviews({
        'filter[brand]': brand,
        'filter[model]': model,
        'filter[body]': body,
        'filter[generation]': generation,
        'filter[review]': reviewId,
        'relations[review]': 'release,modification,user',
        include: 'car_release,car_modification,user',
        'attributes[review]': 'base,text,image,media',
        'attributes[car_release]': 'base,image',
        'attributes[car_modification]': 'base,extended',
        'attributes[user]': 'base,image',
        limit: 1,
      })
      .then(({ meta, ...otherData }) => ({
        seo: meta.seo,
        review: denormalizeData(otherData)[0],
      }))
      .then(rejectEmptyResult)
      .catch(renderError);
  },
  reviews: props => {
    const {
      servicesApi,
      consoleError,
      match: {
        params: {
          brand,
          model,
          generation,
          body,
          review_id: reviewId,
        },
      },
    } = props;

    return servicesApi.getReviews({
      limit: 4,
      'filter[brand]': brand,
      'filter[model]': model,
      'filter[body]': body,
      'filter[generation]': generation,
      'filter[review]': `!${reviewId}`,
      'relations[review]': 'release,modification,user',
      include: 'car_release,car_modification,user',
      'attributes[review]': 'base,text,image',
      'attributes[user]': 'base,image',
    })
      .then(denormalizeData)
      .catch(consoleError('reviews', []));
  },

  reviewSeo: ({ servicesApi, reviewSeo, match }) => {
    if (reviewSeo) return reviewSeo;

    return servicesApi.getReviewsSeo(match)
      .then(response => response.data)
      .catch(() => ({}));
  },
});

export default withPageHocs(dataProvider)(withBreakpoint(withTheme(ReviewPage)));
