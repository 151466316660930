import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import { LimeGradientBg } from 'site/components/Wrappers';
import { TopicContentMaxWidth, NegativeMobile } from 'core/components/Wrappers';

import './index.styl';

const NativeStyles = ({ theme }) => {
  const {
    animations: {
      hover: hoverAnimation,
    },
    fonts: fontsAtoms,
    colors: colorsAtoms,
  } = theme;

  return (
    <Fragment>
      <style global jsx>{`
      .native-ad.card-read-more
        .ad_native_content-wrapper
          background ${colorsAtoms.content}

        .ad_native_img
          &:after
            transition opacity ${hoverAnimation}

        .ad_native_title
          font-family ${fontsAtoms.text}
          color ${colorsAtoms.primary}
          transition color ${hoverAnimation}

        .ad_native_wrapper
          &:hover
            .ad_native_title
              color ${colorsAtoms.active1}

        .ad_label__text
          color ${colorsAtoms.text}
          font-family ${fontsAtoms.text}
    `}</style>
    </Fragment>
  );
};

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function card1Styles(Component) {
  return withTheme(props => (
    <div className={cx(
      'native-ad',
      'card-read-more',
    )}
    >
      <TopicContentMaxWidth>
        <NegativeMobile>
          <LimeGradientBg>
            <NativeStyles {...props} />
            <Component {...props} />
          </LimeGradientBg>
        </NegativeMobile>
      </TopicContentMaxWidth>
    </div>
  ));
}
