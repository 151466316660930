import Close from 'site/icons/Close.svg';

import styles from './index.styl';


/* eslint-disable react/prop-types */
function ClearIndicator(props) {
  return (
    <div {...props.innerProps} className={styles.clear}>
      <Close width={12} height={12} />
    </div>
  );
}

export default ClearIndicator;
