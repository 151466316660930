import PropTypes from 'prop-types';
import accounting from 'accounting';

import { withRouter } from 'core/libs/router';

import themePropTypes from 'core/utils/prop-types/theme';
import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import Price from 'site/components/Price';
import Battery from 'site/components/Battery';
import Divider from 'site/components/Divider';

import bindProps from 'core/components/bindProps';
import cardServiceHOC from 'site/components/cardService';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { offerAttributes } from 'site/utils/prop-types/model';

import { isMotoPath } from 'site/utils';

import { mapEngine, MOTO_URL } from 'site/constants';

import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = ['brand', 'model', 'modification', 'dealer', 'dealer_network'];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  brand: {
    name: '',
  },
  model: {
    name: '',
  },
  modification: {
    name: '',
    power: '',
    battery: { total: '' },
  },
  dealer: {
    name: '',
  },
  dealer_network: {
    image: '',
  },
});

function CardServiceOffer1(props) {
  const {
    theme,
    content,
    location: {
      pathname,
    },
    type,
    isDesktop,
    dataQa,
  } = props;

  if (!content) return null;

  const {
    attributes: {
      url,
      image,
      year,
      run,
      engine,
      price,
      condition,
    },
  } = content;

  const engineType = mapEngine[engine];

  const {
    brand: {
      name: brandName,
    },
    model: {
      name: modelName,
    },
    modification: {
      name: modificationName,
      power,
      battery: { total: batteryTotal },
    },
    dealer: {
      name: dealerName,
    },
    dealer_network: {
      image: dealerLogoEntity,
    },
  } = relationships(content);

  const offerTitle = `${brandName} ${modelName} ${modificationName}`;

  const cover = getThumbnail(image, '3/2');
  const dealerLogo = getThumbnail(dealerLogoEntity);

  const info = [
    year && `${year} г.`,
    accounting.formatMoney(run, { symbol: 'км' }),
    power && `${power} л.с.`,
    engineType,
  ].filter(Boolean);

  const isMoto = isMotoPath(pathname);

  const linkUrl = `${isMoto ? MOTO_URL : ''}${url}`;
  const {
    imageHeight,
    imageAspectRatio,
  } = mapTypes[type] || {};

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          background ${theme.colors.content}
          border 1px solid ${theme.colors.primary100}

          &:before
            transition box-shadow ${theme.animations.hover}
            box-shadow 0px 2px 5px ${theme.colors.primary150}

          &:hover
            .${styles.title}
              color ${theme.colors.active1}

            &:before
              box-shadow 0px 2px 5px ${theme.colors.primary250}

        .${styles.title}
          color ${theme.colors.primary}
          font 800 16px/21px ${theme.fonts.text}
      `}</style>
    </scope>
  );

  return (
    <div
      className={styles.cardServiceOffer1}
      data-qa={dataQa}
    >
      <style jsx>{`
        .${styles.dealerName}
          color ${theme.colors.primary500}
          font 13px/16px ${theme.fonts.text}

        .${styles.infoItem}
          color ${theme.colors.primary}
          font 13px/20px ${theme.fonts.text}

          &:after
            background ${theme.colors.primary400}

        .${styles.isNew}
          color ${theme.colors.darkGreen}
      `}</style>
      <Link
        to={linkUrl}
        type='secondary'
        className={scope.wrapClassNames(styles.link)}
        title={offerTitle}
      >
        <div className={styles.image}>
          <Image
            src={cover}
            height={imageHeight}
            aspectRatio={imageAspectRatio}
            {...isDesktop && { maxWidth: 300 }}
          />
        </div>
        <div className={styles.content}>
          <div className={scope.wrapClassNames(styles.title)} data-qa='card-title'>
            {condition === 'new' && <span className={styles.isNew}>Новый</span>}
            {offerTitle}
          </div>
          <div className={styles.header}>
            {dealerLogo && (
              <div className={styles.dealerLogo}>
                <Image
                  src={dealerLogo}
                  width={30}
                  placement='contain'
                />
              </div>
            )}
            <div className={styles.dealerName}>{dealerName}</div>
            <Price className={styles.price} value={price} />
          </div>
          <Divider top={8} bottom={8} />
          <ul className={styles.info}>
            {info.map(item => (
              <li key={item} className={styles.infoItem}>
                {item}
              </li>
            ))}
            {!!batteryTotal && <li className={styles.infoItem}>
              <Battery value={batteryTotal} />
            </li>}
          </ul>
        </div>
      </Link>
      <scope.styles />
    </div>
  );
}

CardServiceOffer1.defaultProps = {
  type: 0,
};

CardServiceOffer1.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1]),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      content,
      primary,
      primary100,
      primary400,
      primary500,
      darkGreen,
    },
    fonts: {
      text,
    },
  }`),
  /** Данные для карточки, соответствующие модели `offerAttributes` */
  content: modelPropTypes(offerAttributes),
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  /** @ignore */
  isDesktop: PropTypes.bool,
  /** @ignore */
  dataQa: PropTypes.string,
};

const Card = cardServiceHOC(withRouter(CardServiceOffer1));
Card.displayName = 'CardServiceOffer1';

export const CardServiceOffer1Type1 = bindProps({ type: 1 })(Card);

export default Card;
export { CardServiceOffer1 as StorybookComponent };

