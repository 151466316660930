import { useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { Mobile } from 'core/components/breakpoint';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import modelPropTypes, {
  pddTicketAttributes,
} from 'site/utils/prop-types/model';

import { NegativeMobile } from 'site/components/Wrappers';
import Button from 'core/components/Button';
import PddImage from 'site/components/PddImage';
import Divider from 'core/components/Divider';

import styles from './index.styl';

import ArrowLeftDrum from 'site/icons/ArrowLeftDrum.svg';
import ArrowRightDrum from 'site/icons/ArrowRightDrum.svg';
import IconFalseAnswer from 'site/icons/PddFalseAnswerIcon.svg';
import IconTrueAnswer from 'site/icons/IconTrueAnswer.svg';
import IconEnterButton from 'site/icons/IconEnterButton.svg';

function PddQuestion(props) {
  const {
    theme,
    isMobile,
    isDesktop,
    mode,
    userAnswer,
    saveAnswer,
    handleNextQuestion,
    handlePrevQuestion,
    isOver,
    question,
    question: {
      attributes: {
        ticket,
        sort,
        question: questionText,
        answers,
        correct_answer: correctAnswer,
        comment,
      },
    },
  } = props;

  const [showComment, setShowComment] = useState(!!isOver);

  const handleAnswer = useCallback((answerNumber) => {
    if (mode !== 'exam') setShowComment(true);
    saveAnswer(answerNumber);
  }, [mode, saveAnswer]);

  const isGetAnswer = userAnswer === null || userAnswer === undefined ? false : true;

  const isWrongAnswer = isGetAnswer && userAnswer !== correctAnswer;

  const keydownHandler = useCallback(e => {
    switch (e.key) {
      case 'Left':
      case 'ArrowLeft':
        handlePrevQuestion && handlePrevQuestion();
        break;
      case 'Right':
      case 'ArrowRight':
        handleNextQuestion && handleNextQuestion();
        break;
      case 'Enter':
        isGetAnswer && handleNextQuestion && handleNextQuestion();
        break;
      default:
        if (isGetAnswer) return;
        const clickedButton = Number(e.key);
        if (!clickedButton || clickedButton < 1 && clickedButton > answers.length) return;
        const anwerIndex = clickedButton - 1;
        answers[anwerIndex] && handleAnswer(anwerIndex);
        return;
    }
  }, [answers, handleAnswer, handleNextQuestion, handlePrevQuestion, isGetAnswer]);

  useEffect(() => {
    window.addEventListener('keydown', keydownHandler);
    return () => window.removeEventListener('keydown', keydownHandler);
  }, [keydownHandler]);

  useEffect(() => {
    if (!isOver) {
      setShowComment(false);
    }
  }, [question.id, isOver]);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.button}
          padding 0
          font ${theme.texts.menu.font}
          letter-spacing ${theme.texts.menu.letterSpacing}
          color ${theme.colors.active1}
          min-height 16px

        .${styles.buttonNext}
          margin-top 20px
      `}</style>
    </scope>
  );

  const buttonProps = {
    type: 'ghost',
    minWidth: '100px',
    className: scoped.wrapClassNames(styles.button),
  };

  return (
    <NegativeMobile>
      <div className={cx(styles.pddQuestion)}>
        <style jsx>{`
          .${styles.number}
            color ${theme.texts.caption1.color}
            font ${theme.texts.caption1.font}

          .${styles.question}
            color ${theme.texts.lead.color}
            font ${theme.texts.lead.font}
            font-weight 700

          .${styles.answerText}
            font ${theme.texts.body.font}
            font-weight 400
            color ${theme.texts.body.color}

          .${styles.answerNumber}
            font ${theme.texts.title6.font}
            font-weight 500
            color ${theme.texts.title6.color}

        `}</style>
        {!isOver && <span className={styles.number}>Билет {ticket}, Вопрос {sort}</span>}
        <div className={styles.content}>
          <PddImage question={question} />
          <div className={styles.bar}>
            {!isMobile && handlePrevQuestion && (
              <button
                className={cx(styles.buttonArrow, isOver && styles.buttonArrow_hidden)}
                onClick={!isOver ? handlePrevQuestion : undefined}
              >
                <ArrowLeftDrum />
              </button>
            )}
            <span className={styles.question}>{questionText}</span>
            {!isMobile && handleNextQuestion && (
              <button
                className={cx(styles.buttonArrow, isOver && styles.buttonArrow_hidden)}
                onClick={!isOver ? handleNextQuestion : undefined}
              >
                <ArrowRightDrum />
              </button>
            )}
          </div>
          <Divider />
          <ul className={styles.list}>
            {answers &&
              answers.map((answerOption, index) => {
                const questionNumber = index + 1;
                const isAnswerIncorrect = isWrongAnswer && userAnswer === index;
                const isAnswerCorrect = isGetAnswer && correctAnswer === index;

                return (
                  <Fragment key={index}>
                    <li
                      className={cx(
                        styles.answer,
                        isAnswerCorrect && styles.answer_true,
                        isAnswerIncorrect && styles.answer_false
                      )}
                    >
                      <label htmlFor={`answerInputNumber${index}`} className={styles.label} />
                      <div className={styles.answerWrapper}>
                        <div className={styles.wrapperInput}>
                          <input
                            disabled={isGetAnswer}
                            value={index}
                            onClick={() => !isOver && handleAnswer(index)}
                            name={`answerInputNumber${index}`}
                            className={styles.inputNumber}
                            id={`answerInputNumber${index}`}
                            type='radio'
                          />
                          {!isMobile && (
                            <div className={styles.answerNumber}>
                              {isAnswerIncorrect && <IconFalseAnswer />
                              || isAnswerCorrect && <IconTrueAnswer />
                              || questionNumber}
                            </div>
                          )}
                        </div>
                        <span className={styles.answerText}>{answerOption}</span>
                        <Mobile><Divider className={styles.divider} /></Mobile>
                      </div>
                      {answers.length !== questionNumber && isDesktop && <Divider />}
                    </li>
                  </Fragment>
                );
              })}
          </ul>
        </div>
        {!isGetAnswer &&
          <div className={styles.controls}>
            {(mode === 'marafon') && (
              <Button
                {...buttonProps}
                onClick={() => setShowComment(!showComment)}
              >
                {showComment ? 'СКРЫТЬ ПОДСКАЗКУ' : 'ПОКАЗАТЬ ПОДСКАЗКУ'}
              </Button>
            )}
            <Button {...buttonProps} onClick={!isOver ? handleNextQuestion : undefined} >ПРОПУСТИТЬ</Button>
          </div>
        }
        {showComment && <div className={styles.answerComment}>{comment}</div>}
        {isGetAnswer && mode !== 'exam' && !isOver &&
          <Button
            minWidth='282px'
            className={scoped.wrapClassNames(styles.buttonNext)}
            onClick={handleNextQuestion}
          >
            <IconEnterButton className={styles.iconEnter} />
            СЛЕДУЮЩИЙ ВОПРОС
          </Button>
        }
        <scoped.styles />
      </div>
    </NegativeMobile>
  );
}

PddQuestion.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  isDesktop: PropTypes.bool,
  /** Функция для перехода к предыдущему вопросу */
  handlePrevQuestion: PropTypes.func,
  /** Функция для перехода к следующему вопросу */
  handleNextQuestion: PropTypes.func,
  /** Колбек, который вызывается когда пользователь отвечает на вопрос. Принимает номер ответа в качетсве аргумента */
  saveAnswer: PropTypes.func,
  /** Ответ пользователя */
  userAnswer: PropTypes.number,
  /** Вопрос ПДД */
  question: modelPropTypes(pddTicketAttributes),
  /** Режим ПДД три возможных варианта 'tren', 'exam', 'marafon' */
  mode: PropTypes.oneOf(['tren', 'exam', 'marafon']),
  /** Завершен ли режим */
  isOver: PropTypes.bool,
};

const PddQuestionWithHOCs = withTheme(withBreakpoint(PddQuestion));

PddQuestionWithHOCs.displayName = 'PddQuestion';

export default PddQuestionWithHOCs;
export { PddQuestion as StorybookComponent };
