import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import { withBreakpoint } from 'core/components/breakpoint';

import Feed from 'core/components/Feed';

import modelPropTypes, {
  carModelAttributes,
  motoModelAttributes,
} from 'site/utils/prop-types/model';

import CardRelease1WithPrice from 'site/cardsService/CardServiceRelease1/cards/CardRelease1WithPrice';


const FeedCard = bindProps({ showFullName: true, reverseRowInMobile: true })(CardRelease1WithPrice);

function PopularModels(props) {
  const {
    models,
    isMobile,
  } = props;

  return (
    <Feed
      card={FeedCard}
      content={models}
      columns={4}
      interitemSpacing={isMobile ? 12 : 20}
      title='Популярные модели'
    />
  );
}

PopularModels.propTypes = {
  models: PropTypes.oneOfType([
    PropTypes.arrayOf(modelPropTypes(carModelAttributes)),
    PropTypes.arrayOf(modelPropTypes(motoModelAttributes)),
  ]),
  isMobile: PropTypes.bool.isRequired,
};

export default withBreakpoint(PopularModels);
