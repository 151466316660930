import PropTypes from 'prop-types';

import Image from 'core/components/Image';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import resolveRelationships from 'core/utils/relationships';

import { NegativeMobile } from 'site/components/Wrappers';

import {
  LAYOUT_MAX_WIDTH,
  LAYOUT_MAX_WIDTH_MOBILE,
  CONTENT_AREA,
} from 'site/constants';

import styles from './index.styl';

const relationships = resolveRelationships(
  ['image'],
  {},
  { image: { versions: {} } },
);

function TopicHeaderImage({ content, isDesktop }) {
  const {
    image: {
      versions,
      caption,
    },
  } = relationships(content);

  let maxWidth = LAYOUT_MAX_WIDTH_MOBILE;
  let maxHeight = 400;
  const { headline } = content.attributes;
  const altAndTitle = caption || headline;

  if (isDesktop) {
    const {
      attributes: {
        is_premium: isPremium,
      },
    } = content;

    maxWidth = isPremium ? LAYOUT_MAX_WIDTH : CONTENT_AREA;
    maxHeight = isPremium ? 708 : 600;
  }

  return (
    <NegativeMobile>
      <div className={styles.image}>
        <Image
          versions={versions}
          widthFromSrc
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          placement='contain'
          useSchema
          alt={altAndTitle}
          title={altAndTitle}
        />
      </div>
    </NegativeMobile>
  );
}

TopicHeaderImage.propTypes = {
  content: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withTheme(withBreakpoint(TopicHeaderImage));
