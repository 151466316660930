import {  MOTO_URL } from 'site/constants';

export const PATHNAME_FIELDS = ['geo', 'brand', 'model', 'release'];
export const EXCLUDED_STATE_FIELDS = ['models', 'geos'];


// Список кастомных ренджей

export const RANGES = {
  price: {
    min: 200000,
    max: 20000000,
  },
  volume: {
    min: 40,
    max: 2500,
  },
  power: {
    min: 0,
    max: 325,
  },
  cylinders: {
    min: 1,
    max: 5,
  },
  year: {
    min: 1980,
    max: new Date().getFullYear(),
  },
};


// Список полей, которые входят только в полный фильтр
export const FULL_FILTER_FIELDS = [
  'cylinders',
  'configurate',
  'cooling',
  'transfer',
  'transmission',
  'abs',
  'power',
  'control',
];

export const DEPENDENCIES = {
  brand: {
    model: () => null,
    release: () => null,
  },
  model: {
    release: () => null,
  },
};

export const BASE_PATH = `${MOTO_URL}/inventory`;
