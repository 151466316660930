import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { withBreakpoint } from 'core/components/breakpoint';
import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';

import { LimeGradientBg, Indent, NegativeMobile } from 'site/components/Wrappers';
import Recommender from 'site/components/Recommender';
import { PartnersHorizontal } from 'site/components/Partners';

import { getMetrikaProps } from 'site/utils';
import { VERTICAL_INDENT,  VERTICAL_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';

function Boroda(props) {
  const {
    isTopicPage,
    isDesktop,
    infinityIndex,
  } = props;

  const vertical = isDesktop ? VERTICAL_INDENT : VERTICAL_INDENT_MOBILE;
  const disableMetrika = isDesktop || infinityIndex > 1;
  const MetrikaWrapper = disableMetrika ? Fragment : YandexMetrikaGoalSender;
  const recommendedTopicsProps = {
    checkScrollY: true,
    ...getMetrikaProps('_popular', infinityIndex),
  };

  const isTopicEven = !!(infinityIndex % 2);
  const isTopicOdd = !isTopicEven;

  const recommender = (
    <MetrikaWrapper {...!disableMetrika && recommendedTopicsProps}>
      <Recommender />
    </MetrikaWrapper>
  );

  return (
    <NegativeMobile>
      {(!isTopicPage || isTopicEven) && (
        <LimeGradientBg className={styles.gradient}>
          {recommender}
        </LimeGradientBg>
      )}
      {(!isTopicPage || isTopicOdd) && (
        <>
          <Indent bottom={vertical} />
          <PartnersHorizontal />
        </>
      )}
    </NegativeMobile>
  );
}

Boroda.propTypes = {
  isTopicPage: PropTypes.bool,
  isDesktop: PropTypes.bool,
  infinityIndex: PropTypes.number,
};


export default withBreakpoint(Boroda);
