import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import cx from 'classnames';

import { get } from 'core/libs/lodash';
import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import Hint from 'site/components/Hint';
import OptionDependencies from 'site/components/OptionDependencies';

import styles from './index.styl';

import mapTypes from './types.js';


function OptionSimple(props) {
  const {
    option,
    type,
    theme,
    modificationId,
    compactView,
  } = props;

  const {
    name,
    name_full: fullName,
    price,
    dependencies,
    install_variants: installVariants,
  } = option;

  const {
    cancelType,
    listView,
    isConsist,
    withoutHighlight = false,
  } = mapTypes[type] || {};

  const termName = get(option, 'term.name');
  const termExplanation = get(option, 'term.explanation');

  const mustQualify = dependencies.length > 0 || installVariants.length > 1;

  const [opened, setModal] = useState(false);

  const toggleModal = useCallback(() => {
    setModal(!opened);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const clickHandler = (mustQualify && !withoutHighlight) && { onClick: toggleModal };

  return (
    <div
      className={cx(
        styles.row,
        (!price || isConsist) && styles.freePrice,
        styles.mustQualify,
        !compactView && listView && styles.listView,
        cancelType && styles.cancelType,
        withoutHighlight && styles.withoutHighlight,
        compactView && styles.compactView,
      )}
    >
      <style jsx>{`
        .nameInner
          .${styles.mustQualify} &
            color ${theme.colors.blue1}
            border-bottom 1px dashed ${theme.colors.blue1}
            transition border-color ${theme.animations.hover}
            &:hover
            &:focus
              border-color transparent
      `}</style>
      {mustQualify &&
        <OptionDependencies
          option={option}
          toggleModal={toggleModal}
          isOpen={opened}
          modificationId={modificationId}
        />
      }
      <div
        className={styles.nameColumn}
        {...clickHandler}
      >
        <div className={styles.name}>
          {withoutHighlight
            ? (fullName || name).trim()
            : <span className='nameInner'>{(fullName || name).trim()}</span>
          }
        </div>
        <Hint title={termName} description={termExplanation} />
      </div>
      <div className={styles.value}>
        <div className={styles.price}>
          {accounting.formatMoney(isConsist ? 0 : price)}
        </div>
      </div>
    </div>
  );
}

OptionSimple.propTypes = {
  /**
   * Либо опция, либо пакет опций
   */
  option: PropTypes.object.isRequired,
  /** Тип отображения опции */
  type: PropTypes.oneOf([0, 1, 2, 3, 4]),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      blue1,
    },
  }`),
  modificationId: PropTypes.string,
  /**
   * Флаг для компактного отображения.
   * Приходит из таблицы сравнения.
   */
  compactView: PropTypes.bool,
};

OptionSimple.defaultProps = {
  type: 0,
};

const OptionSimpleWithHOCs = withTheme(OptionSimple);
OptionSimpleWithHOCs.displayName = 'OptionSimple';

export default OptionSimpleWithHOCs;
export { OptionSimple as StorybookComponent };
