import mapSizes from './sizes';
import { get } from 'core/libs/lodash';

/**
 * Возвращает указанную версию изображения или null.
 * @param {Object} thumbnail - сущность Image.
 * @param {string} ratioType - тип заглушки, которая будет возвращена в случае отсутствия нужной версии картинки.
 * @param {string} [version=original] - версия картинки.
 * @returns {Object|null}
 */
export default function getThumbnail(thumbnail = {}, ratioType, version = 'original') {
  const path = [
    'attributes',
    'versions',
    version,
  ];

  if (!ratioType) {
    return get(thumbnail, path, null);
  }

  // здесь лежат картинки по умолчанию
  const { options: defaultOptions = null } = mapSizes[ratioType] || {};

  return get(thumbnail, path, defaultOptions);
}
