import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { denormalizeData } from 'core/utils/api';

import bindProps from 'core/components/bindProps';
import { SimpleLoader } from 'core/components/Loader';
import Drum from 'core/components/Drum';
import H3 from 'core/components/H3';

import CardServiceReview1 from 'site/cardsService/CardServiceReview1';


const DrumCard = bindProps({ dataQa: 'card-reviews-base-drum' })(CardServiceReview1);

function Reviews({}, { servicesApi }) {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let ignore = false;
    servicesApi
      .getReviews({
        limit: 7,
        include: 'car_release,car_modification,user',
        'attributes[review]': 'base,image,text',
        'relations[review]': 'release,modification,user',
        'attributes[car_modification]': 'base,battery',
        'filter[with_images]': true,
      })
      .then(data => {
        if (!ignore) {
          setContent(denormalizeData(data));
          setLoading(false);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setLoading(false);
        }
      });
    return () => {
      ignore = true;
    };
  }, [servicesApi]);

  if (loading) return <SimpleLoader />;

  return (
    <Drum
      grid
      title='Отзывы владельцев'
      showHeaderArrows
      showArrowsOnHover
      arrowsShift={-10}
      headerComponent={H3}
      itemWidth='40%'
      itemWidthMobile='100%'
      content={content}
      card={DrumCard}
      dataQa='reviews'
    />
  );
}

Reviews.contextTypes = {
  servicesApi: PropTypes.object,
};

export default Reviews;
