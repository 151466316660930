import skip from 'core/resolver/skip';

import Page from 'core/components/Page';

import ComparePageProvider from './ComparePageProvider';
import Content from './sections/Content';

function ComparePage() {
  return (
    <ComparePageProvider>
      <Page>
        <Content />
      </Page>
    </ComparePageProvider>
  );
}

export default skip(ComparePage);
