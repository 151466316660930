import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import { withBreakpoint } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';
import ColumnLayout from 'core/components/ColumnLayout';
import Bouesque from 'core/components/Bouesque';

import TopicToplineSkeleton from 'core/components/TopicTopline/Skeleton';
import TopicHeaderTextsSkeleton from 'core/components/TopicHeaderTexts/Skeleton';
import GameCompareSkeleton from 'core/components/GameCompare/Skeleton';

import { PageIndent } from 'site/components/Wrappers';

import { isGameCompare } from 'site/utils';

import { VERTICAL_INDENT } from 'site/constants';

import Common from './Common';


function getTopicType(pathname) {
  if (isGameCompare(pathname)) {
    return 'gameCompare';
  }

  return 'default';
}

const loadersMap = {
  gameCompare: GameCompareSkeleton,
  default: Common,
};

function Skeleton({ location, isMobile }) {
  const Content = loadersMap[getTopicType(location.pathname)];
  return (
    <PageIndent>
      <ColumnLayout
        rightColumn={(
          <Indent bottom={VERTICAL_INDENT}>
            <Bouesque />
          </Indent>
        )}
      >
        <TopicToplineSkeleton height={isMobile ? 26 : 22} />
        <Indent top={isMobile ? 16 : 20} />
        <TopicHeaderTextsSkeleton
          titleHeight={isMobile ? 60 : 100}
          leadHeight={isMobile ? 150 : 200}
        />
        <Content />
      </ColumnLayout>
    </PageIndent>
  );
}

Skeleton.propTypes = {
  location: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withRouter(withBreakpoint(Skeleton));
