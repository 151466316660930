import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import { withRouter } from 'core/libs/router';

import H3 from 'core/components/H3';

import { denormalizeData } from 'core/utils/api';

import { Indent } from 'site/components/Wrappers';
import BaseForm from 'site/components/BaseForm';

import {
  generateSelectOptions,
  initSelectValue,
  initRangeValue,
} from 'site/utils/forms';

import Form from './Form';

import {
  EXCLUDED_STATE_FIELDS,
  RANGES,
  DEPENDENCIES,
} from './constants';

import styles from './index.styl';


class OffersNavigator extends BaseForm {
  constructor(props) {
    super(props);

    this.formOptions = {};

    this.state = {
      brands: [],
      models: [],
      ...this.getFormStateFromLocation(),
    };

    this.getLocationFromState = () => {};
  }

  dependencies = DEPENDENCIES;

  excludedFields = EXCLUDED_STATE_FIELDS;

  ranges = RANGES;

  componentDidMount() {
    const { servicesApi } = this.context;
    const { brand } = this.state;

    Promise.allSettled([
      servicesApi
        .getBrands()
        .then(denormalizeData),

      brand ? servicesApi
        .getModels({
          'filter[brand]': brand?.value,
          'relations[car_model]': 'brand',
          include: 'car_brand',
        }) : [],

      servicesApi.getOffersDictionaries(),
    ])
      .then(results => results.map(i => i.value))
      .then(([brands, models, dictionaries]) => {
        this.ranges = {
          ...RANGES,
          ...dictionaries.ranges,
        };

        const {
          body,
          condition,
        } = dictionaries;

        // Формирует неизменяемы опции для контролов
        this.formOptions = {
          filteredBodies: generateSelectOptions(body, { value: 'attributes.slug' }),
          filteredConditions: generateSelectOptions(condition, { label: 'title', value: 'value' }),
        };

        this.setState({
          ...this.getFormStateFromLocation(),
          brands,
          models,
        });
      })
      .catch(e => console.error(e));
  }

  componentDidUpdate(prevProps, prevState) {
    const { brand, models } = this.state;
    const { servicesApi } = this.context;

    if (brand?.value === prevState.brand?.value) return;

    brand
      ? (brand?.value !== prevState.brand?.value)
        && servicesApi
          .getModels({
            'filter[brand]': brand?.value,
            'relations[car_model]': 'brand',
            include: 'car_brand',
          })
          .then(denormalizeData)
          .then(data => {
            this.setState({ models: data });
          })
          .catch(e => {
            console.error(e);
            this.setState({ models: [] });
          })
      : models.length && this.setState({ models: [] });
  }

  getFormStateFromLocation = () => ({
    price: initRangeValue(
      'price',
      {},
      this.ranges.price
    ),
    body: initSelectValue(
      'body',
      {},
      this.formOptions.filteredBodies,
      'attributes.slug',
    ),
    year: initRangeValue(
      'year',
      {},
      this.ranges.year
    ),
    condition: initSelectValue(
      'condition',
      {},
      this.formOptions.filteredConditions,
    ),
  });

  getBrandOptions = memoize(
    (brands = []) => generateSelectOptions(brands, { value: 'attributes.slug' })
  );

  getModelOptions = memoize(
    (models = []) => generateSelectOptions(models, { value: 'attributes.slug' })
  );

  render() {
    const { models, brands } = this.state;

    // Зависимые опции
    const filteredBrands = this.getBrandOptions(brands);
    const filteredModels = this.getModelOptions(models);

    return (
      <div className={styles.formWrapper} data-qa='offers-search-form'>
        <Indent bottom={25}>
          <H3 is='h2' dataQa='offers-search-header'>Поиск по объявлениям</H3>
        </Indent>
        <Form
          formState={{ ...this.state }}
          formOptions={{
            filteredBrands,
            filteredModels,
            ...this.formOptions,
          }}
          onChange={this.handleControlChange}
          ranges={this.ranges}
        />
      </div>
    );
  }
}

OffersNavigator.contextTypes = {
  servicesApi: PropTypes.object,
};

export default withRouter(OffersNavigator);
