import CircleCross from 'site/icons/CircleCross';

/**
 * Иконка используется в кнопках закрытия и сброса в некоторых контролах
 * Отличительной особенностью является то, что она смещена вправо,
 * чтобы красиво смотрелась в кнопках с закругленными краями.
 */
export default function CircleCrossShifted() {
  return (
    <CircleCross
      width='18'
      height='18'
      style={{ marginRight: -5, marginLeft: 10 }}
    />
  );
}
