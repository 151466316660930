import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import resolve from 'core/resolver/resolve';

import brandsFetcher from 'site/fetchers/brands';
import { newReleasesFetcher } from 'site/fetchers/releases';
import comparisonsFetcher from 'site/fetchers/comparisons';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card5 from 'site/cards/Card5';


export const nowOnSaleApiParams = {
  include: 'car_brand,car_model,car_modification,dealer,dealer_network',
  'filter[price_min]': 2900000,
  'attributes[dealer_offer]': 'base,image',
  'attributes[dealer_network]': 'base,image',
  'relations[dealer_offer]': 'brand,model,modification,dealer,dealer_network',
  'relations[dealer]': 'dealer_network',
  limit: 7,
};

export default resolve({
  newInJournal: ({ bebopApi, consoleError, isMobile }) => {
    return bebopApi
      .getTopics({
        limit: isMobile ? 7 : 10,
        include: filterRequiredParams([Card1], 'include'),
        fields: filterRequiredParams([Card1], 'fields'),
        visibility: 'main_page',
      })
      .then(denormalizeData)
      .catch(consoleError('newInJournal', []));
  },

  popular: ({ bebopApi, consoleError }) => {
    return bebopApi
      .getTopics({
        limit: 4,
        include: filterRequiredParams([Card1, Card2, Card3, Card5], 'include'),
        fields: filterRequiredParams([Card1, Card2, Card3, Card5], 'fields'),
        sort: '-views_count',
      })
      .then(denormalizeData)
      .catch(consoleError('popular', []));
  },

  brands: brandsFetcher(),
  newReleases: newReleasesFetcher(),
  comparisons: comparisonsFetcher(),

  nowOnSale: ({ servicesApi, consoleError, geoSlug }) => {
    if (!geoSlug) return [];

    return servicesApi
      .getOffersList({
        'filter[geo]': geoSlug,
        type: 'popular',
        ...nowOnSaleApiParams,
      })
      .then(denormalizeData)
      .catch(consoleError('nowOnSale', []));
  },
});
