import { useState } from 'react';
import PropTypes from 'prop-types';

import { carBrandAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import modelPropTypes from 'site/utils/prop-types/model';

import withTheme from 'core/components/theme';
import { Desktop, Mobile } from 'core/components/breakpoint';

import Link from 'core/components/Link';

import ArrowRightSm from 'site/icons/ArrowRightSm';

import styles from './index.styl';


import Divider from 'site/components/Divider';
import { MOTO_URL } from 'site/constants';

import Logos from './Logos';
import ModalBrands from './ModalBrands';

const COLUMNS_COUNT = 5;

/**
 * В десктоп версии логотипы показываем по 9 штук, выбирая рандомом из набора популярных брендов.
 * Для этого используем state machine, чтобы не было рахождений между клиент/сервер.
 */

function Brands(props) {
  const {
    brands,
    theme,
    isMoto,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  /**
   * Считаем колличество элементов в колонке.
   * Добавляем в расчет общего колличества элементов кнопку "Все марки".
   * Полученную величину умножаем на line-height каждой ссылки.
   * Ограничиваем полученной высотой весь блок.
   */
  const COLUMN_ITEM_COUNT = Math.ceil((brands.length + 1) / COLUMNS_COUNT);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.names}
          height calc(${COLUMN_ITEM_COUNT} * 30px)

        .${styles.nameLink}
          color ${theme.colors.primary}
          font-family ${theme.fonts.text}

          &:visited
            color ${theme.colors.primary}

          &:hover
          &:focus
            color ${theme.colors.blue1}

        .${styles.allBrands}
          font-family ${theme.fonts.text}
          color ${theme.colors.blue1}

          &:visited
          &:hover
          &:focus
            color ${theme.colors.blue1}
      `}</style>
    </scope>
  );

  const btnAllBrands = (
    <div
      className={scope.wrapClassNames(styles.allBrands)}
      onClick={() => setIsOpen(true)}
      data-qa='all-brands-button'
    >
      <span className={styles.allBrandsText}>
        Все марки
      </span>
      <ArrowRightSm />
    </div>
  );

  return (
    <div className={styles.brands} data-qa='brands-catalog'>
      <Logos brands={brands} isMoto={isMoto}>
        <Mobile>
          {btnAllBrands}
        </Mobile>
      </Logos>
      <Desktop>
        <div className={scope.wrapClassNames(styles.names)} data-qa='brand-list'>
          {brands.map(brand => (
            <Link
              key={brand.id}
              type='secondary'
              to={`${isMoto ? MOTO_URL : ''}/${brand.attributes.slug}`}
              className={scope.wrapClassNames(styles.nameLink)}
              data-qa='brand-link'
            >
              {brand.attributes.name}
            </Link>
          ))}
          {btnAllBrands}
        </div>
        <Divider type={2} />
      </Desktop>
      <ModalBrands
        isMoto={isMoto}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <scope.styles />
    </div>
  );
}

Brands.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Список брендов, соответствующих модели `carBrandAttributes` */
  brands: PropTypes.arrayOf(modelPropTypes(carBrandAttributes)),
  /** @ignore */
  theme: PropTypes.object,
  /** Отображать только логотипы мототехники */
  isMoto: PropTypes.bool,
};

const BrandsWithHOCs = withTheme(Brands);

BrandsWithHOCs.displayName = 'Brands';

export default BrandsWithHOCs;

export { Brands as StorybookComponent };
