import PropTypes from 'prop-types';

import modelPropTypes, { publicationLinkAttributes } from 'site/utils/prop-types/model';

import Image from 'core/components/Image';
import Link from 'core/components/Link';

import { Indent, LimeGradientBg } from 'site/components/Wrappers';

import styles from './index.styl';

/**
 * @author Aminev Ramil <r.aminev@rambler-co.ru>
 */

export default function PublicationLink(props) {
  const {
    block,
    parentProps: {
      theme,
    },
  } = props;

  return (
    <Indent top={60}>
      <LimeGradientBg className={styles.gradient}>
        <Link
          to={block.attributes.link}
          type='secondary'
        >
          <style jsx>{`
            .${styles.content}
              color ${theme.colors.primary}
              &:hover
                color ${theme.colors.active1}
                transition opacity ${theme.animations.hover}
            .${styles.imageContainer}
              box-shadow 3px 3px 5px ${theme.colors.primary400}
            .${styles.caption}
              font 700 20px/23px ${theme.fonts.display}
            .${styles.credits}
              font 400 20px/23px ${theme.fonts.text}
          `}</style>
          <div className={styles.content}>
            {block.attributes.image && (
              <div className={styles.imageContainer}>
                <Image
                  src={block.attributes.image.attributes.versions.original}
                  width={125}
                  height={180}
                />
              </div>
            )}
            <div className={styles.textBlock}>
              <div className={styles.caption}>
                <span className={styles.fromBook}>Из книги </span>
                &#171;{block.attributes.caption}&#187;
              </div>
              <div className={styles.credits}>Автор: {block.attributes.credits}</div>
            </div>
          </div>
        </Link>
      </LimeGradientBg>
    </Indent>
  );
}

PublicationLink.propTypes = {
  /** Данные виджета из API */
  block: modelPropTypes(publicationLinkAttributes),
  /** @ignore */
  parentProps: PropTypes.object,
};
