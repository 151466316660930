import { Fragment } from 'react';

import { Route, Switch } from 'core/libs/router';

import SecondaryMenu from 'site/components/SecondaryMenu';
import PageLayoutService from 'site/components/PageLayoutService';

import Moto from 'site/pages/moto';
import Brand from 'site/pages/brand';
import Model from 'site/pages/model';
import MotoRelease from 'site/pages/motoRelease';

import MotoForm from 'site/forms/Moto';

import { MOTO_URL } from 'site/constants';


const routes = [
  {
    exact: true,
    path: MOTO_URL,
    component: Moto,
  }, {
    exact: true,
    path: `${MOTO_URL}/:brand/:type_slug(atv|scooter)?`,
    component: Brand,
  }, {
    exact: true,
    path: `${MOTO_URL}/:brand/:model`,
    component: Model,
  }, {
    exact: true,
    path: `${MOTO_URL}/:brand/:model/:release`,
    component: MotoRelease,
  },
];

export default function MotoCatalogLayout() {
  return (
    <Fragment>
      <SecondaryMenu section='catalog' />
      <MotoForm />
      <PageLayoutService>
        <Switch>
          {routes.map((routeParams, i) => {
            return <Route key={i} {...routeParams} />;
          })}
        </Switch>
      </PageLayoutService>
    </Fragment>
  );
}
