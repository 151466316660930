import PropTypes from 'prop-types';
import cx from 'classnames';

import themePropTypes from 'core/utils/prop-types/theme';
import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import cardHOC from 'core/components/card';
import bindProps from 'core/components/bindProps';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import mapTypes from './types';
import mapSizes from './sizes';

import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'topic_type',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card1(props) {
  const {
    content,
    theme,
    type,
    position,
    dataQa,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    topic_type: topicType,
  } = content.attributes;

  const {
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    size,
    borderDash,
    borderSolid,
    isReverse,
    withoutBg,
    borderRadius,
    boxShadow,
    fitContent,
  } = mapTypes[type] || {};

  const {
    headlineSize,
    width: imageWidth,
    height: imageHeight,
    cardPadding,
  } = mapSizes[size] || {};

  const {
    icons: {
      Video,
      Photo,
    },
  } = theme;

  const mapIconByType = {
    video: Video,
    gallery: Photo,
  };

  const Icon = mapIconByType[topicType];

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .headline
          color ${theme.colors.primary}
          font ${headlineSize} ${theme.fonts.text}

        .link
          &:hover
            .headline
              color ${theme.colors.active1}
      `}</style>
    </scope>
  );

  const hasBorder = position && (borderSolid || borderDash);

  return (
    <div
      className={cx(
        'card1',
        styles.card1,
        boxShadow && 'boxShadow',
        borderDash && styles.borderDash,
        borderSolid && styles.borderSolid,
        borderRadius && styles.borderRadius,
        isReverse && styles.isReverse,
        fitContent && styles.fitContent,
      )}
      data-qa={dataQa}
    >
      <style jsx>{`
        .card1
          padding ${cardPadding}
          background ${withoutBg ? 'transparent' : theme.colors.content}
          border-top-width ${hasBorder ? '1px' : 0}
          border-top-color ${theme.colors.divider}
          max-height ${fitContent ? imageHeight + 'px' : '100%'}

          &.boxShadow
            box-shadow 0px 2px 6px ${theme.colors.primary100}

        .image
          flex 0 0 ${imageWidth}px

          &:after
            background ${theme.colors.primary}
            transition opacity ${theme.animations.hover}

        .content
          max-height ${fitContent ? imageHeight + 'px' : '100%'}
      `}
      </style>
      <Link
        to={link}
        type='secondary'
        className={cx(scope.wrapClassNames('link'), styles.link)}
      >
        <div className={cx(styles.content, 'content')}>
          <div className={scope.wrapClassNames('headline')} data-qa='card-headline'>{listHeadline || headline}</div>
        </div>
        <div className={cx(styles.image, 'image')}>
          <Image
            {...cover && { src: cover }}
            {...cover && { previewSrc: previewCover }}
            {...remoteImage && { url: remoteImage }}
            width={imageWidth}
            height={imageHeight}
          />
          {Icon && (
            <span className={cx(styles.icon, 'icon')}>
              <Icon width={22} height={22} />
            </span>
          )}
        </div>
      </Link>
      <scope.styles />
    </div>
  );
}

Card1.propTypes = {
  /** Позиция карточки */
  position: PropTypes.number,
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  /** Данные для карточки */
  content: PropTypes.object,
  /** @ignore */
  dataQa: PropTypes.string,
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      primary100,
      divider,
    },
  }`),
};

Card1.defaultProps = {
  type: 0,
  position: 0,
};

const Card = cardHOC(Card1);
export const Card1Type1 = bindProps({ type: 1 })(Card);
export const Card1Type2 = bindProps({ type: 2 })(Card);
export const Card1Type3 = bindProps({ type: 3 })(Card);
export const Card1Type4 = bindProps({ type: 4 })(Card);
export const Card1Type5 = bindProps({ type: 5 })(Card);
export const Card1Type6 = bindProps({ type: 6 })(Card);
export const Card1Type7 = bindProps({ type: 7 })(Card);
export const Card1Type8 = bindProps({ type: 8 })(Card);
export const Card1Type9 = bindProps({ type: 9 })(Card);
export const Card1Type10 = bindProps({ type: 10 })(Card);

Card.displayName = 'Card1';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card1 as StorybookComponent };
