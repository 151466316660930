import { components } from 'react-select';

/* eslint-disable react/prop-types */
function SelectContainer(props) {
  return (
    <components.SelectContainer
      {...props}
      {...props.selectProps.dataQa && { innerProps: {
        ...props.innerProps,
        'data-qa': props.selectProps.dataQa,
      } }}
    />
  );
}

export default SelectContainer;
