export default {
  1: { isPackage: true },
  2: { isSubgroup: true },
  3: { shortView: true },
  4: { shortView: true, isPackage: true },
  5: { shortView: true, withoutChecked: true },
  6: { shortView: true, isPackage: true, withoutChecked: true },
  7: { withoutNames: true, compactView: true },
  8: { isPackage: true, withoutNames: true, compactView: true },
};
