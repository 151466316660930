import { compose, branch, renderComponent } from 'core/libs/recompose';
import { Redirect } from 'core/libs/router';

import withGeo from './withGeo';

export default function withGeoRedirect(Component) {
  return compose(
    withGeo,
    branch(
      props => !props.match.params.geo,
      renderComponent(props => {
        return <Redirect to={`${props.match.url}/${props.geoSlug}`} />;
      })
    )
  )(Component);
}
