import PropTypes from 'prop-types';

import MarkdownWrapper from 'core/components/MarkdownWrapper';

import withTheme from 'core/components/theme';

import Text from 'core/components/Text';


function HtmlWithTheme(props) {
  const {
    theme: {
      controls: {
        link: {
          article: articleLinkAtoms,
        },
      },
    },
    html,
  } = props;

  if (!html) return null;

  return (
    <div className='htmlWithTheme'>
      <Text>
        <MarkdownWrapper children={html} inline />
      </Text>
      <style jsx>{`
        .htmlWithTheme
          :global(a)
            color ${articleLinkAtoms.idle.color}
            text-decoration ${articleLinkAtoms.idle.decoration}

            &:hover
              color ${articleLinkAtoms.hover.color}
              text-decoration ${articleLinkAtoms.hover.decoration}

            &:active
              color ${articleLinkAtoms.active.color}
              text-decoration ${articleLinkAtoms.active.decoration}

            &:visited
              color ${articleLinkAtoms.visited.color}
              text-decoration ${articleLinkAtoms.visited.decoration}
      `}</style>
    </div>
  );
}

HtmlWithTheme.propTypes = {
  /** html-код, возвращаемый API, который необходимо стилизовать */
  html: PropTypes.string,

  /** @ignore */
  theme: PropTypes.object,
};

const HtmlWithThemeWithHOCs = withTheme(HtmlWithTheme);
HtmlWithThemeWithHOCs.displayName = 'HtmlWithTheme';

export default HtmlWithThemeWithHOCs;
export { HtmlWithTheme as StorybookComponent };
