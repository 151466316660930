import PropTypes from 'prop-types';

import { Section, Block } from 'core/components/Grid';
import { withNonPureBreakpoint } from 'core/components/breakpoint';

import { Indent } from 'site/components/Wrappers';
import InputDropdown from 'site/components/InputDropdown';
import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';
import FormContainer from 'site/components/FormContainer';


function Form(props) {
  const {
    formState,
    formOptions,
    isMobile,
    onChange,
  } = props;

  const {
    brandsDealersOptions,
    geoOptions,
  } = formOptions;

  const {
    search,
    geo,
  } = formState;

  return (
    <ThemeSwitchWithColor tone='dark'>
      <FormContainer>
        <Section>
          <Block width={12} desktop={9}>
            <Indent right={!isMobile && 20}>
              <InputDropdown
                name='search'
                label='Название дилера или марка'
                placeholder='Введите название'
                options={brandsDealersOptions}
                value={search}
                onChange={onChange}
                isMulti={false}
                {...isMobile && { hideBorder: true }}
              />
            </Indent>
          </Block>
          <Block width={12} desktop={3}>
            <Indent top={isMobile && 1}>
              <InputDropdown
                name='geo'
                label='Город'
                options={geoOptions}
                value={geo}
                onChange={onChange}
                isMulti={false}
                {...isMobile && {
                  roundingPosition: 'bottom',
                  hideBorder: true,
                }}
              />
            </Indent>
          </Block>
        </Section>
      </FormContainer>
    </ThemeSwitchWithColor>
  );
}

Form.propTypes = {
  isMobile: PropTypes.bool,
  formState: PropTypes.object,
  formOptions: PropTypes.object,
  onChange: PropTypes.func,
};

export default withNonPureBreakpoint(Form);
