import PropTypes from 'prop-types';

import coreSnippet from 'core/components/TopicContent/blocks/snippet';

import styles from './index.styl';

export default function Snippet({ block }) {
  return (
    <div className={styles.snippetWrapper}>
      {coreSnippet(block)}
    </div>
  );
}

Snippet.propTypes = {
  /** Данные виджета из API */
  block: PropTypes.shape({
    html: PropTypes.string,
    css: PropTypes.number,
    js: PropTypes.number,
  }),
};
