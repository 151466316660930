import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';
import { denormalizeData } from 'core/utils/api';

import Page from 'core/components/Page';
import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';
import AdWrapper from 'core/components/Ad/AdWrapper';

import WhereToBuy from 'site/components/WhereToBuy';
import PhotoAndVideo from 'site/components/PhotoAndVideo';
import { Listing2 } from 'site/components/Ads/mobile';
import { Indent, ReleaseInnerPages } from 'site/components/Wrappers';

import modelPropTypes, { motoReleaseAttributes, seoAttributes } from 'site/utils/prop-types/model';
import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


function MotoReleasePhoto(props) {
  const {
    seo,
    release,
    isMobile,
  } = props;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const brandName = release?.relationships?.brand?.attributes?.name;

  return (
    <Page title={seo.title} description={seo.description}>
      <ReleaseInnerPages release={release}>
        <AdWrapper bottom={vertical}>
          <PhotoAndVideo release={release} isMoto />
          <Indent bottom={vertical} />
          <WhereToBuy brandName={brandName} />
          <Indent bottom={vertical} />
          <Mobile>
            <Listing2 />
          </Mobile>
        </AdWrapper>
      </ReleaseInnerPages>
    </Page>
  );
}

const dataProvider = resolve({
  release: props => {
    const {
      servicesApi,
      renderError,
      match,
    } = props;

    const {
      brand: brandSlug,
      model: modelSlug,
      release: releaseSlug,
    } = match.params;

    return (
      servicesApi.getMotoReleases({
        'filter[brand]': brandSlug,
        'filter[model]': modelSlug,
        'filter[year]': releaseSlug,
        'filter[usages]': 'catalog,archive',
        include: 'moto_brand,moto_release',
        'relations[moto_release]': 'brand',
        'attributes[moto_release]': 'base,image,media',
        'attributes[moto_brand]': 'base',
        limit: 1,
      })
        .then(releasesData => denormalizeData(releasesData)[0])
        .then(rejectEmptyResult)
        .catch(renderError)
    );
  },

  seo: ({ servicesApi, consoleError, match }) => {
    return servicesApi
      .getMotoReleasePhotosSeo({
        'filter[brand]': match.params.brand,
        'filter[model]': match.params.model,
        'filter[year]': match.params.release,
      })
      .then(denormalizeData)
      .catch(consoleError('seo', {}));
  },
});

MotoReleasePhoto.propTypes = {
  isMobile: PropTypes.bool,
  seo: seoAttributes,
  release: modelPropTypes(motoReleaseAttributes),
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default withPageHocs(dataProvider)(withBreakpoint(MotoReleasePhoto));
