import { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import color from 'core/libs/color';
import withTheme from 'core/components/theme';

import styles from './index.styl';

const NativeStyles = ({ theme }) => (
  <Fragment>
    <style jsx global>{`
      .native-ad.native-ad_card3
        .ad_native_wrapper
          a
            background ${theme.colors.content}
            box-shadow 0px 3px 5px ${color(theme.colors.primary).alpha(0.15)}

        .ad_native_title
          color ${theme.colors.primary}
          font 500 20px/1 ${theme.fonts.text}

        .ad_native_desc
          color ${theme.colors.primary}
          font 14px/1.2 ${theme.fonts.text}

        .ad_label__text
          color ${theme.colors.hint}
          font 14px/1.2 ${theme.fonts.text}
    `}</style>
  </Fragment>
);

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function card3Styles(Component) {
  return withTheme(props => (
    <div className={cx(styles['native-ad_card3'], 'native-ad native-ad_card3')}>
      <NativeStyles {...props} />
      <Component {...props} />
    </div>
  ));
}
