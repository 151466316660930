import cx from 'classnames';
import PropTypes from 'prop-types';

import { capitalize } from 'core/utils/string-helper';

import Checkbox from 'core/components/Checkbox';

import styles from './index.styl';


function Checkboxes(props) {
  const {
    isDisabled,
    title,
    options = [],
    inputName,
    checkboxesState,
    onChange,
    isVerticalView,
  } = props;

  return (
    <div
      className={cx(
        'checkboxes',
        isVerticalView && styles._isVerticalView,
        isDisabled && styles._disabled
      )}
    >
      {title && <div className={styles.title}>{capitalize(title)}</div>}
      <div className={styles.wrapper}>
        {options.map(option => {
          const checked = checkboxesState.includes(option.value.toString());

          return (
            <div className={styles.checkbox} key={option.value}>
              <Checkbox
                onChange={e => {
                  const newValue = e.target.checked
                    ? [...checkboxesState, e.target.value]
                    : checkboxesState.filter(value => value !== e.target.value);

                  onChange(newValue, { name: inputName });
                }}
                {...option}
                disabled={isDisabled}
                checked={checked}
              >
                {capitalize(option.label)}
              </Checkbox>
            </div>
          );
        })}
      </div>
    </div>
  );
}

Checkboxes.propTypes = {
  /**
   * Флаг, позволяющий задизейблить группу чекбоксов
   */
  isDisabled: PropTypes.bool,
  /**
   * Заголовок блока
   */
  title: PropTypes.string,
  /**
   * Данные для отрисовки чекбоксов
   */
  options: PropTypes.arrayOf(PropTypes.object),
  /**
   * Имя чекбокс-группы
   */
  inputName: PropTypes.string.isRequired,
  /**
   * Состояние чекбокс группы
   */
  checkboxesState: PropTypes.array.isRequired,
  /**
   * Обработчик изменения состояния чекбоксов
   */
  onChange: PropTypes.func,
  /**
   * Представляет список чекбоксов вертикально
   */
  isVerticalView: PropTypes.bool,
};

export default Checkboxes;
