import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import themePropTypes from 'core/utils/prop-types/theme';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import Image from 'core/components/Image';

import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';

import { getAvatar } from 'site/utils';

import styles from './index.styl';

function Author({ author, theme, withoutLink }) {
  const {
    attributes: {
      name,
      job_title: jobTitle,
      avatar: avatarAttrs,
    },
  } = author;

  const avatar = getAvatar(avatarAttrs);

  return (
    <div className={styles.author}>
      <style jsx>{`
        .${styles.author}
          &:hover
            .name
              color ${withoutLink ? 'initial' : theme.colors.active2}

        .${styles.avatar}
          background ${theme.colors.primary100}

        .name
          font 16px/20px ${theme.fonts.text}
          color ${theme.colors.primary}

        .job
          font 13px/18px ${theme.fonts.text}
          color ${theme.colors.placeholder}
      `}</style>
      {avatar && <div className={styles.avatar}>
        <Image
          src={avatar}
          aspectRatio={1}
          width={48}
        />
      </div>}
      <div className='info'>
        <div className='name'>
          <MarkdownWrapper>
            {name}
          </MarkdownWrapper>
        </div>
        {jobTitle && (
          <div className='job'>
            <MarkdownWrapper>
              {jobTitle}
            </MarkdownWrapper>
          </div>
        )}
      </div>
    </div>
  );
}

Author.propTypes = {
  withoutLink: PropTypes.bool,
  theme: themePropTypes(`{
    colors: {
      primary100,
    },
  }`),
  author: modelPropTypes(personAttributes),
};

export default withTheme(Author);
