import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Mobile, Desktop, withBreakpoint } from 'core/components/breakpoint';
import Feed from 'core/components/Feed';
import Drum from 'core/components/Drum';
import H3 from 'core/components/H3';

import modelPropTypes, { carEditorsChoiceAttributes } from 'site/utils/prop-types/model';

import Divider from 'site/components/Divider';
import { Indent } from 'site/components/Wrappers';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import CardServiceEditorChoice from 'site/cardsService/CardServiceEditorChoice';

function BestChoice({ isMobile, content, isMainPage }) {
  if (!content) return null;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const DrumComp = (
    <Drum
      spaceBetween={20}
      itemWidth={`${100 / 3}%`}
      itemWidthMobile={300}
      card={CardServiceEditorChoice}
      content={content}
      showArrowsOnHover
      arrowsShift={-10}
    />
  );

  return (
    <div className='editorChoice'>
      <H3>Подборки от редакторов</H3>
      <Indent bottom={isMobile ? 10 : 20} />
      {isMainPage ? (
        <Fragment>
          <Desktop>
            <Feed
              grid
              interitemSpacing={16}
              columns={3}
              card={CardServiceEditorChoice}
              content={content}
            />
            <Indent bottom={vertical} />
            <Divider type={2} />
          </Desktop>
          <Mobile>
            {DrumComp}
          </Mobile>
        </Fragment>
      ) : DrumComp}
    </div>
  );
}

BestChoice.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Список подборок, соответствующих модели `carEditorsChoiceAttributes` */
  content: PropTypes.arrayOf(modelPropTypes(carEditorsChoiceAttributes)),
  isMainPage: PropTypes.bool,
};

const BestChoiceWithHOCs = withBreakpoint(BestChoice);

BestChoiceWithHOCs.displayName = 'BestChoice';

export default BestChoiceWithHOCs;

export { BestChoice as StorybookComponent };
