export default {
  1: {
    width: 32,
    height: 12,
    capHeight: 4,
    borderRadius: 2,
    valueFontSize: '14px/18px',
  },
  2: {
    width: 66,
    height: 20,
    capHeight: 6,
    borderRadius: 4,
    withHover: true,
    valueFontSize: '14px/18px',
  },
  3: {
    width: 114,
    height: 32,
    capHeight: 12,
    borderRadius: 4,
    valueFontSize: '16px/20px',
    innerPosition: true,
  },
  4: {
    width: 164,
    height: 40,
    capHeight: 12,
    borderRadius: 4,
    valueFontSize: '16px/20px',
    innerPosition: true,
  },
  5: {
    width: 18,
    height: 8,
    capHeight: 4,
    borderRadius: 2,
    valueFontSize: '14px/18px',
  },
  6: {
    width: 63,
    height: 16,
    capHeight: 6,
    borderRadius: 4,
    valueFontSize: '14px/18px',
  },
};
