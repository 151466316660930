import PropTypes from 'prop-types';
import cx from 'classnames';
import { Fragment } from 'react';

import withTheme from 'core/components/theme';

import styles from './index.styl';


function Tab(props) {
  const {
    children,
    theme,
    type,
    onClick,
    tabIcon: TabIcon,
  } = props;

  const isActive = type === 'primary';

  return (
    <Fragment>
      <style jsx>{`
        .${styles.tab}
          color ${theme.colors.primary}
          &:not(.${styles.active}):hover
            color ${theme.colors.active1}
            transition opacity ${theme.animations.hover}
        .${styles.active}
          &:after
            background ${theme.colors.primary}
      `}</style>
      <div
        className={cx(styles.tab, isActive && styles.active)}
        onClick={onClick}
        data-qa={isActive ? 'now-on-sale-tab-active' : 'now-on-sale-tab'}
      >
        <TabIcon />
        <span>
          {children}
        </span>
      </div>
    </Fragment>
  );
}

Tab.propTypes = {
  children: PropTypes.string,
  theme: PropTypes.object,
  type: PropTypes.string,
  onClick: PropTypes.func,
  tabIcon: PropTypes.func,
};

export default withTheme(Tab);
