import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import withTheme from 'core/components/theme';

import { cardDateFormat } from 'site/utils';

import styles from './index.styl';

const relationships = resolveRelationships(['geo'], {},
  {
    geo: {
      name: '',
      url: '',
    },
  });

function AuxiliaryInfo({ theme, offer }) {
  const {
    attributes: {
      publish_time: publishTime,
    },
  } = offer;

  const {
    geo: {
      name: geoName,
    },
  } = relationships(offer);

  return (
    <div className='auxiliaryInfo'>
      <style jsx>{`
        .auxiliaryInfo
          color ${theme.colors.primary250}
          font ${theme.texts.caption3.font}
      `}</style>
      {geoName}<span className={styles.bullet}>&bull;</span>{cardDateFormat(publishTime)}
    </div>
  );
}

AuxiliaryInfo.propTypes = {
  offer: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

export default withTheme(AuxiliaryInfo);
