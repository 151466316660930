import accounting from 'accounting';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import CardServiceModification1 from '../../';

import styles from './index.styl';


function CardModification1WithPrice(props) {
  const {
    theme,
    content: {
      attributes: {
        min_price: minPrice,
        max_price: maxPrice,
      },
    },
  } = props;

  return (
    <CardServiceModification1 {...props}>
      <style jsx>{`
        .${styles.price}
          color ${theme.colors.primary}
      `}</style>
      {Boolean(minPrice || maxPrice) && (
        <div className={styles.price}>
          {minPrice && accounting.formatNumber(minPrice)}
          {minPrice && maxPrice && ' - '}
          {maxPrice && accounting.formatMoney(maxPrice)}
        </div>
      )}
    </CardServiceModification1>
  );
}

CardModification1WithPrice.propTypes = {
  /** Данные для карточки, соответствующие модели `carReleaseAttributes` */
  content: modelPropTypes(carReleaseAttributes),
  /** @ignore */
  theme: PropTypes.object,
};

const CardWithHOCs = withTheme(CardModification1WithPrice);
CardWithHOCs.displayName = 'CardModification1WithPrice';

export default CardWithHOCs;
export { CardModification1WithPrice as StorybookComponent };
