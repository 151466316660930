import PropTypes from 'prop-types';

import ColumnLayout from 'core/components/ColumnLayout';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { withRouter } from 'core/libs/router';

import Boroda from 'site/components/Boroda';
import InnerSideColumn from 'site/components/InnerSideColumn';
import FooterAds from 'site/components/FooterAds';

import { ListContentBg, PageIndent, Indent } from 'site/components/Wrappers';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';
import { isGameCompare } from '../../utils';

function PageLayout({ isMobile, children, location }) {
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const isGameComparePage = isGameCompare(location.pathname);

  return (
    <ListContentBg marginCollapse>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn showCarNews={false} />}>
          {children}
          <Indent bottom={vertical} />
          {!isGameComparePage && <Boroda />}
        </ColumnLayout>
      </PageIndent>
      <FooterAds contextCount={2} />
    </ListContentBg>
  );
}

PageLayout.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
};

export default withBreakpoint(withRouter(PageLayout));
