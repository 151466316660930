import { Fragment } from 'react';
import { withRouter } from 'core/libs/router';
import PropTypes from 'prop-types';

import Bouesque from 'core/components/Bouesque';
import AdWrapper from 'core/components/Ad/AdWrapper';
import StickyPortal from 'core/components/StickyPortal';
import MotoCatalogLink from 'site/components/MotoCatalogLink';
import CarNews from 'site/components/CarNews';

import { Indent } from 'site/components/Wrappers';

import { isMotoPath } from 'site/utils';

import { VERTICAL_INDENT } from 'site/constants';

import { Ad240x400 } from 'site/components/Ads/desktop';
import SelfPromo from 'core/components/SelfPromo';


function Part1(props) {
  const {
    showBoesque,
    additionalOffsetTop,
    showCarNews,
    location,
  } = props;

  const isMoto = isMotoPath(location.pathname);

  return (
    <Fragment>
      {showBoesque && (
        <Indent bottom={VERTICAL_INDENT}>
          <Bouesque />
        </Indent>
      )}
      <StickyPortal topOffset={additionalOffsetTop} listenStickyRemoveEvent>
        <AdWrapper bottom={VERTICAL_INDENT}>
          <Ad240x400 />
          <SelfPromo />
          <Indent bottom={VERTICAL_INDENT} />
          {showCarNews && (
            <Indent bottom={VERTICAL_INDENT}>
              <CarNews />
            </Indent>
          )}
          {!isMoto && (
            <Indent bottom={VERTICAL_INDENT}>
              <MotoCatalogLink />
            </Indent>
          )}
        </AdWrapper>
      </StickyPortal>
    </Fragment>
  );
}

Part1.defaultProps = {
  showBoesque: true,
  showCarNews: true,
};

Part1.propTypes = {
  showBoesque: PropTypes.bool,
  showCarNews: PropTypes.bool,
  /**
   * Задает дополнительное смещение для StickyPortal
   */
  additionalOffsetTop: PropTypes.number,
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Part1);
