import { Fragment } from 'react';
import accounting from 'accounting';
import PropTypes from 'prop-types';

import { get } from 'core/libs/lodash';
import { withRouter } from 'core/libs/router';

import resolveRelationships from 'core/utils/relationships';
import cutDown from 'core/utils/cut-down';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import Price from 'site/components/Price';
import Battery from 'site/components/Battery';

import cardServiceHOC from 'site/components/cardService';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { offerAttributes } from 'site/utils/prop-types/model';

import { isMotoPath } from 'site/utils';

import { mapEngine, MOTO_URL } from 'site/constants';

import styles from './index.styl';

const requiredPayloadImports = ['brand', 'model', 'modification', 'dealer', 'dealer_network'];

const relationships = resolveRelationships(requiredPayloadImports, {}, {});

function CardServiceOffer2(props) {
  const {
    theme,
    content,
    location: {
      pathname,
    },
  } = props;

  if (!content) return null;

  const {
    attributes: {
      url,
      image,
      year,
      run,
      engine,
      description,
      price,
      power,
    },
  } = content;

  const engineType = mapEngine[engine];

  const {
    brand: {
      name: brandName,
    },
    model: {
      name: modelName,
    },
    modification: {
      name: modificationName,
      battery,
    },
    dealer: {
      name: dealerName,
    },
    dealer_network: {
      image: dealerLogoEntity,
    },
  } = relationships(content);

  const batteryTotal = get(battery, 'total');

  const offerTitle = `${brandName} ${modelName} ${modificationName !== undefined ? modificationName : ''}`;

  const cover = getThumbnail(image, '3/2');
  const dealerLogo = getThumbnail(dealerLogoEntity);

  const info = [
    year && `${year} г.`,
    accounting.formatMoney(run, { symbol: 'км' }),
    power && `${power} л.с.`,
    engineType,
  ].filter(Boolean);

  const isMoto = isMotoPath(pathname);

  const linkUrl = `${isMoto ? MOTO_URL : ''}${url}`;

  return (
    <div className={styles.cardServiceOffer2}>
      <style jsx>{`
        .${styles.cardServiceOffer2}
          background ${theme.colors.content}
          border 1px solid ${theme.colors.primary100}
          box-shadow 0px 2px 5px ${theme.colors.primary150}
          transition box-shadow ${theme.animations.hover}

          &:hover
            box-shadow 0px 2px 5px ${theme.colors.primary250}
            .${styles.title}
              color ${theme.colors.active1}

        .${styles.dealerName}
          color ${theme.colors.primary500}

        .${styles.title}
        .${styles.description}
          color ${theme.colors.primary}

        .${styles.infoItem}
          color ${theme.colors.primary}

          &:after
            background ${theme.colors.primary500}

        .${styles.dealer}
          &:after
            background ${theme.colors.divider}

        ${styles.descriptionMore}
          color ${theme.colors.active1}
      `}</style>
      <Link
        to={linkUrl}
        type='secondary'
        className={styles.link}
        title={offerTitle}
      >
        <div className={styles.image}>
          <Image
            src={cover}
            width={270}
            aspectRatio={1.5}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{offerTitle}</div>
          <ul className={styles.info}>
            <Price className={styles.price} value={price} />
            {info.map(item => (
              <li key={item} className={styles.infoItem}>
                {item}
              </li>
            ))}
            {!isMoto && !!batteryTotal && (
              <li className={styles.infoItem}>
                <Battery value={batteryTotal} />
              </li>
            )}
          </ul>
          <div className={styles.dealer}>
            {dealerLogo && (
              <div className={styles.dealerLogo}>
                <Image
                  src={dealerLogo}
                  width={30}
                  placement='contain'
                />
              </div>
            )}
            <div className={styles.dealerName}>{dealerName}</div>
          </div>
          {!!description &&
            <div className={styles.description}>
              {description.length > 200 ? (
                <Fragment>
                  {cutDown(description, 200, true)}
                  <span className={styles.descriptionMore}>Подробнее</span>
                </Fragment>
              ) : description}
            </div>
          }
        </div>
      </Link>
    </div>
  );
}

CardServiceOffer2.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** Данные для карточки, соответствующие модели `offerAttributes` */
  content: modelPropTypes(offerAttributes),
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

const CardWithHOCs = cardServiceHOC(withRouter(CardServiceOffer2));
CardWithHOCs.displayName = 'CardServiceOffer2';

export default CardWithHOCs;
export { CardServiceOffer2 as StorybookComponent };
