import resolve from 'core/resolver/resolve';

import queryString from 'core/libs/query-string';

import { denormalizeData } from 'core/utils/api';

import { isMotoPath } from 'site/utils';
import { getDealersWithCoordinats } from 'site/utils/dealers';


function getValueDataFromRoute(location, match) {
  const { brand } = match.params;

  const { search } = queryString.parse(location.search);

  return {
    brand,
    search,
  };
}

export default resolve({
  dealersSeo: ({ servicesApi, consoleError, location, match }) => {
    const { geo, brand } = match.params;

    const isMoto = isMotoPath(location.pathname);

    const method = `get${isMoto ? 'Moto' : 'Car'}Dealers${brand ? 'Brand' : 'Geo'}Seo`;
    const seoAPI = servicesApi[method];

    return seoAPI({
      'filter[geo]': geo,
      'filter[brand]': brand,
    })
      .then(denormalizeData)
      .catch(consoleError('dealersSeo', {}));
  },

  dealers(props) {
    const {
      servicesApi,
      consoleError,
      location,
      match,
    } = props;

    const isMoto = isMotoPath(location.pathname);

    const {
      brand,
      search: dealerNetworkId,
    } = getValueDataFromRoute(location, match);

    const type = isMoto ? 'moto' : 'car';
    const searchFilter =  brand ? `${type}_brand` : 'dealer_network';

    const requestParams = {
      'filter[geo]': match.params.geo,
      [`filter[${searchFilter}]`]: brand || dealerNetworkId,
      'relations[dealer]': `geo,${type}_brands,dealer_network`,
      include: `geo,${type}_brand,dealer_network`,
      'attributes[dealer]': 'base,stats',
      'attributes[dealer_network]': 'image',
      [`attributes[${type}_brand]`]: 'base,image',
      'filter[type]': type,
      'filter[status]': 'official',
    };

    return servicesApi
      .getDealers({
        // Получаем первую часть дилеров.
        ...requestParams,
        with_filtered_count: true,
      })
      .then(firstPart => {
        const limitPerRequest = firstPart.data.length;
        const filteredCount = firstPart.meta.filtered_count;

        // Если все дилеры поместились в первом ответе, возвращаем массив,
        // состоящий только из первой части дилеров.
        if (filteredCount <= limitPerRequest) return [firstPart];

        // Если дилеров для гео больше, чем пришло в ответе на первый запрос,
        // формируем список отступов для последующих запросов...
        const offsets = [];

        for (let i = limitPerRequest; i < filteredCount; i += limitPerRequest) {
          offsets.push(i);
        }

        // ... и отправляем эти запросы, дополняя их уже полученными дилерами.
        return Promise.all([
          firstPart,
          ...offsets.map(offset => servicesApi
            .getDealers({
              ...requestParams,
              offset,
            })
          ),
        ]);
      })
      .then(dealersParts => {
        const mergedDealers = denormalizeData(dealersParts.reduce((acc, { data, included }) => {
          acc.data = acc.data.concat(data);
          acc.included = acc.included.concat(included);
          return acc;
        }, { data: [], included: [] }));

        return mergedDealers;
      })
      .then(getDealersWithCoordinats)
      .catch(consoleError('dealers', []));
  },
});
