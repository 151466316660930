import React from 'react';

export const DEFAULT_GEO_SLUG = 'msk';

export const DEFAULT_GEO = {
  'id': '0a648a66-e96e-491c-94cd-8c1d37aa4df8',
  'type': 'geo',
  'attributes': {
    'quto_id': 1,
    'url': 'msk',
    'type': 'city_federal',
    'name': 'Москва',
    'name_prepositional': 'Москве',
    'name_genitive': 'Москвы',
    'children': [],
  },
};

export default React.createContext({
  geo: DEFAULT_GEO,
  geoSlug: DEFAULT_GEO.attributes.url,
  updateGeo: () => null,
  // TODO: Почему-то при hot reload в контексте оказываются именно эти
  // функции, поэтому мы получим всегда msk. Хорошо бы с этим разобраться
  getGeoAttributes: () => ({}),
});
