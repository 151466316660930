import PropTypes from 'prop-types';
import Shapka from 'core/components/Shapka';

import CompareInfo from 'site/components/CompareInfo';


function Shlyapa({ isSecondShapkaForPremium }) {
  return (
    <Shapka
      extra={<CompareInfo />}
      isSecondShapkaForPremium={isSecondShapkaForPremium}
      fullwidth={isSecondShapkaForPremium}
    />
  );
}

Shlyapa.propTypes = {
  isSecondShapkaForPremium: PropTypes.bool,
};

export default Shlyapa;
