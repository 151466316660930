import { Fragment, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import accounting from 'accounting';

import { compose } from 'core/libs/recompose';
import skip from 'core/resolver/skip';

import H3 from 'core/components/H3';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withTheme from 'core/components/theme';

import { Indent } from 'site/components/Wrappers';

import themePropTypes from 'core/utils/prop-types/theme';

import { calcPrices, calcAveragePercentage } from './utils';

import styles from './index.styl';

const YEARS_SIZE = 5; // график за YEARS_SIZE лет
const HORIZONTAL_OFFSET = 5; // боковые отступы для буллитов
const CHART_TOP_OFFSET = 50; // отступ сверху для цен
const CHART_BOTTOM_OFFSET = 24; // отступ снизу для годов

function CostLineChart({ theme, basePrice, depreciation, isMobile }) {
  const SVG_WIDTH = isMobile ? 360 : 860;
  const SVG_HEIGHT = isMobile ? 180 : 290;
  const COLUMN_WIDTH = SVG_WIDTH / YEARS_SIZE; // ширина колонки
  const CHART_HEIGHT = SVG_HEIGHT - CHART_BOTTOM_OFFSET; // высота графика

  const prices = calcPrices(basePrice, depreciation);
  const averagePercentage = calcAveragePercentage(basePrice, depreciation);

  const min = 0;
  const max = Math.max(...prices);

  /*
   * Нормализуем диапазон цен от 0 до CHART_HEIGHT
   *
   * Алгоритм: https://en.wikipedia.org/wiki/Feature_scaling
   * Статья: https://medium.com/better-programming/react-real-time-price-chart-svg-components-3f93767fc350
   */
  const axisesY = [];
  prices.forEach((price, index) => {
    let newPrice = (parseFloat(prices[index]) - min) / (min - max);
    if (isNaN(newPrice)) {
      newPrice = 0;
    }
    axisesY.push(Math.abs((Math.abs(newPrice * CHART_HEIGHT)) - CHART_HEIGHT) + CHART_TOP_OFFSET);
  });

  /**
   * Сам график резиновый.
   * Фиксируем масштаб текстовых блоков на нем.
   * https://bl.ocks.org/veltman/5cd1ba0b3c623e7b5146
   */
  const canvasEl = useRef(null);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    setScale(SVG_WIDTH / canvasEl.current.getBoundingClientRect().width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <style jsx>{`
        .columns
          stroke ${theme.colors.active1}

        .points
          fill ${theme.colors.content}
          stroke ${theme.colors.active1}

        .polyline
          stroke ${theme.colors.active1}

        .polygon
          fill ${theme.colors.active1}

        .years
        .prices
          font 13px/15px ${theme.fonts.text}

        .prices
          stroke ${theme.colors.contentList}

        .averagePercentage
          font 16px/23px ${theme.fonts.text}
      `}</style>
      <H3>Сохранность остаточной стоимости</H3>
      <Indent top={6} bottom={15}>
        <div className={cx(styles.averagePercentage, 'averagePercentage')}>
          {`Эта модель дешевеет в среднем на ${averagePercentage}% в год`}
        </div>
      </Indent>
      <Indent left={`-${HORIZONTAL_OFFSET}`} right={`-${HORIZONTAL_OFFSET}`}>
        <svg
          ref={canvasEl}
          xmlns='http://www.w3.org/2000/svg'
          viewBox={`-${HORIZONTAL_OFFSET} 0 ${SVG_WIDTH + HORIZONTAL_OFFSET * 2} ${SVG_HEIGHT}`}
        >
          <polyline
            className={cx(styles.polyline, 'polyline')}
            points={axisesY.map((axis, index) => COLUMN_WIDTH * index + ',' + axis).join(' ')}
          />
          <polygon
            className={cx(styles.polygon, 'polygon')}
            points={axisesY
              .map((axis, index) => COLUMN_WIDTH * index + ',' + axis)
              .concat(`${SVG_WIDTH},${CHART_HEIGHT}`, `0,${CHART_HEIGHT}`)
              .join(' ')}
          />
          <g className={cx(styles.columns, 'columns')}>
            {axisesY.map((axis, index) => (
              <line
                key={'chartColumns' + index}
                x1={COLUMN_WIDTH * index}
                x2={COLUMN_WIDTH * index}
                y1={axis}
                y2={CHART_HEIGHT}
              />
            ))}
          </g>
          <g className={cx(styles.points, 'points')}>
            {axisesY.map((axis, index) => (
              <circle
                key={'chartPoints' + index}
                cx={COLUMN_WIDTH * index}
                cy={axis}
                r='4'
              />
            ))}
          </g>
          <g className={cx(styles.years, 'years')}>
            {Array.from(Array(YEARS_SIZE + 1)).map((empty, index) => {
              if (index === 0) return 0;

              let year;
              if (isMobile) {
                year = index === 1 ? `${index} год` : index;
              } else {
                year = index + ' год';
              }

              return (
                <text
                  transform={`scale(${scale}, ${scale})`}
                  key={'chartYears' + index}
                  x={(COLUMN_WIDTH * index) / scale}
                  y={SVG_HEIGHT / scale - 5}
                >{year}</text>
              );
            })}
          </g>
          <g className={cx(styles.prices, 'prices')}>
            <text x='0' y='15'>Цена нового автомобиля</text>
            {prices.map((price, index) => {
              let yPos;
              if (isMobile && index !== 0 && index % 2 === 0) {
                yPos = axisesY[index] + 20;
              } else {
                yPos = axisesY[index] - 15;
              }
              return (
                <text
                  transform={`scale(${scale}, ${scale})`}
                  key={'chartPrices' + index}
                  x={(COLUMN_WIDTH * index) / scale}
                  y={yPos / scale}
                >{accounting.formatMoney(price)}</text>
              );
            })}
          </g>
        </svg>
      </Indent>
    </Fragment>
  );
}

CostLineChart.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Базовое значение цены */
  basePrice: PropTypes.number,
  /** Список цен, показывающих изменение базовой цены */
  depreciation: PropTypes.arrayOf(PropTypes.number),
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      contentList,
    },
  }`),
};

const CostLineChartWithHOCs = compose(
  withBreakpoint,
  withTheme,
  skip,
)(CostLineChart);
CostLineChartWithHOCs.displayName = 'CostLineChart';

export default CostLineChartWithHOCs;
export { CostLineChart as StorybookComponent };
