import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import Link from 'core/components/Link';

import styles from './index.styl';


function MenuItem(props) {
  const {
    children,
    isActive,
    link,
    theme,
  } = props;

  const secondaryMenuAtoms = theme.controls.secondaryMenu;

  return (
    <>
      <style jsx>{`
        .${styles.linkContent}
          font ${secondaryMenuAtoms.const.font}
          color ${secondaryMenuAtoms.idle.color}
          border-bottom 3px solid ${secondaryMenuAtoms.idle.itemBorderColor}

          &:hover
            color ${secondaryMenuAtoms.hover.color}
            border-bottom 3px solid ${secondaryMenuAtoms.hover.itemBorderColor}

          &._active
            color ${secondaryMenuAtoms.active.color}
            border-bottom 3px solid ${secondaryMenuAtoms.active.itemBorderColor}
      `}</style>
      {link && (
        <Link
          className={styles.link}
          to={link}
          type='tertiary'
        >
          <div
            className={cx(styles.linkContent, isActive && '_active')}
            data-qa='link-content'
          >
            {children}
          </div>
        </Link>
      )}
      {!link && (
        <div
          className={cx(
            styles.linkContent,
            isActive && '_active',
          )}
          data-qa='link-content'
        >
          {children}
        </div>
      )}
    </>
  );
}

MenuItem.propTypes = {
  isActive: PropTypes.bool,
  theme: PropTypes.object,
  link: PropTypes.string,
};

export default withTheme(MenuItem);
