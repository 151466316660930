import cx from 'classnames';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import Close from 'site/icons/Close';

import styles from './index.styl';


function MultiValueRemove(props) {
  const {
    /* eslint-disable react/prop-types */
    innerProps,
    theme: {
      controls: {
        formInput: inputAtoms,
      },
    },
    /* eslint-enable react/prop-types */
    selectProps: {
      themeType,
    },
  } = props;

  const themeAtoms = inputAtoms[themeType];

  return (
    <div
      {...innerProps}
      className={cx('multiValueRemove', styles.multiValueRemove)}
    >
      <style jsx>{`
        .multiValueRemove
          background-color ${themeAtoms.idle.multiValueRemove.backgroundColor}
          :global([id*="$"])
            fill ${themeAtoms.idle.multiValueRemove.fill}
          &:hover
            background-color ${themeAtoms.hover.multiValueRemove.backgroundColor}
            :global([id*="$"])
              fill ${themeAtoms.hover.multiValueRemove.fill}
      `}</style>
      <Close width={7} height={7} />
    </div>
  );
}

MultiValueRemove.propTypes = {
  selectProps: PropTypes.shape({
    themeType: PropTypes.string,
  }),
  theme: PropTypes.object,
};

export default withTheme(MultiValueRemove);
