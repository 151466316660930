import { Fragment } from 'react';
import PropTypes from 'prop-types';

import H2 from 'core/components/H2';

import { withBreakpoint } from 'core/components/breakpoint';

import { Indent } from 'site/components/Wrappers';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';


function EntitledBlock(props) {
  const {
    title,
    titleComponent: TitleComponent,
    children,
    isMobile,
  } = props;

  if (!children) return null;

  return (
    <Fragment>
      <TitleComponent>{title}</TitleComponent>
      <Indent bottom={(isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT) / 2} />
      {children}
    </Fragment>
  );
}

EntitledBlock.propTypes = {
  /** Содержимое заголовка. */
  title: PropTypes.string,

  /** Компонент, в котором будет отрендерен title. */
  titleComponent: PropTypes.func,

  /** @ignore */
  isMobile: PropTypes.bool,
};

EntitledBlock.defaultProps = {
  titleComponent: H2,
};

const EntitledBlockWithHOCs = withBreakpoint(EntitledBlock);
EntitledBlockWithHOCs.displayName = 'EntitledBlock';

export default EntitledBlockWithHOCs;
export { EntitledBlock as StorybookComponent };
