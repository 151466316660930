import { Fragment } from 'react';
import PropTypes from 'prop-types';

import TopicContent from 'core/components/TopicContent';
import { withBreakpoint, Desktop } from 'core/components/breakpoint';

import { capitalize } from 'core/utils/string-helper';

// eslint-disable-next-line import/no-namespace
import * as Vikonts from 'site/vikonts';

import {
  InRead,
  Native3asCard1,
  AdCenter,
} from 'site/components/Ads/desktop';

import {
  Content1,
  Content2,
  Content3,
  Content4,
  ContentSpecAsCard1,
  InPage,
} from 'site/components/Ads/mobile';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';
import Caption from './captions';

const desktopAds = [
  {
    before: 1200,
    after: 800,
    showOnlyOnce: true,
    renderer: AdCenter,
  },
  {
    before: 1200,
    after: 800,
    showOnlyOnce: true,
    renderer: InRead,
  },
  {
    before: 1000,
    after: 400,
    renderer: Native3asCard1,
  },
  {
    before: 1200,
    after: 400,
    renderer: AdCenter,
  },
];

const mobileAds = [
  Content1,
  InPage,
  ContentSpecAsCard1,
  Content2,
  Content3,
  Content4,
].map((renderer, index, ads) => ({
  before: 600,
  after: 200,
  showOnlyOnce: ads.length !== index + 1,
  renderer: renderer,
}));

const blocks = [
  'photoGallery',
  'incut',
  'number',
  'opinion',
  'readMore',
  'snippet',
  'publicationLink',
].reduce((items, renderer) => {
  const VikontComponent = Vikonts[capitalize(renderer)];
  items[renderer] = (block, parentProps, ctx) => (
    <VikontComponent {...{ block, parentProps, ctx }} />
  );
  return items;
}, {});

function SiteTopicContent(props) {
  const {
    content,
    isMobile,
  } = props;

  const {
    attributes: {
      topic_type: topicType,
      is_premium: isPremium,
    },
  } = content;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Fragment>
      {(topicType !== 'gallery' && !isPremium) && (
        <Desktop>
          <Caption content={content} />
        </Desktop>
      )}
      <TopicContent
        interitemSpacing={vertical}
        {...{
          blocks,
          mobileAds,
          desktopAds,
        }}
      />
    </Fragment>
  );
}

SiteTopicContent.propTypes = {
  content: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SiteTopicContent);
