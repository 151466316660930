import PropTypes from 'prop-types';

import Image from 'core/components/Image';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';
import Position from 'core/components/GridPosition';

import { NegativeMobile } from 'site/components/Wrappers';

import { LAYOUT_MAX_WIDTH_MOBILE, CONTENT_AREA } from 'site/constants';

function CoverImage({ image, isDesktop }) {
  const {
    attributes: {
      versions: {
        original: cover,
      },
    },
  } = image;

  if (!cover) return null;

  return (
    <NegativeMobile>
      <Position center>
        <Image
          src={cover}
          maxWidth={isDesktop ? CONTENT_AREA : LAYOUT_MAX_WIDTH_MOBILE}
          maxHeight={isDesktop ? 600 : 400}
          placement='contain'
        />
      </Position>
    </NegativeMobile>
  );
}

CoverImage.propTypes = {
  image: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withTheme(withBreakpoint(CoverImage));
