import PropTypes from 'prop-types';

import H3 from 'core/components/H3';
import bindProps from 'core/components/bindProps';
import EntitledBlock from 'site/components/EntitledBlock';
import HtmlWithTheme from 'site/components/HtmlWithTheme';
import { Indent } from 'site/components/Wrappers';

import { indentPropTypes } from 'site/utils/prop-types/common';

const H1asH3 = bindProps({ is: 'h1' })(H3);

function SeoBlock({ title, text, indents }) {
  return (
    <Indent {...indents}>
      <EntitledBlock title={title} titleComponent={H1asH3}>
        <HtmlWithTheme html={text} />
      </EntitledBlock>
    </Indent>
  );
}

SeoBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  indents: PropTypes.objectOf(indentPropTypes),
};

export default SeoBlock;
