import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import Portal from 'core/components/Portal';

import styles from './index.styl';

function Notify({ children, isShow, theme }) {
  if (!isShow) return null;

  return (
    <Portal className={styles.notifyWrapper}>
      <style jsx>{`
        .${styles.notify}
          background-color ${theme.colors.primary}
      `}</style>
      <div className={styles.notify}>
        {children}
      </div>
    </Portal>
  );
}

Notify.propTypes = {
  /** Свойство, управляющее показом нотификации (скрыта/показана) */
  isShow: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
};

const NotifyWithHOCs = withTheme(Notify);
NotifyWithHOCs.displayName = 'Notify';

export default NotifyWithHOCs;
export { Notify as StorybookComponent };
