import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import Feed from 'core/components/Feed';
import Link from 'core/components/Link';
import AdWrapper from 'core/components/Ad/AdWrapper';
import ListPage from 'core/components/ListPage';

import { withNonPureBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import ListSortHeader from 'site/components/ListSortHeader';

import styles from './index.styl';

import modelPropTypes, {
  offersAttributes,
  geoAttributes,
  carModelAttributes,
  motoModelAttributes,
  seoDataAttributes,
} from 'site/utils/prop-types/model';
import { isMotoPath } from 'site/utils';

import EntitledBlock from 'site/components/EntitledBlock';
import HtmlWithTheme from 'site/components/HtmlWithTheme';
import { Indent } from 'site/components/Wrappers';

import { CardServiceOffer1Type1 } from 'site/cardsService/CardServiceOffer1';
import CardServiceOffer2 from 'site/cardsService/CardServiceOffer2';

import { Context } from 'site/components/Ads/desktop';
import { Listing1 } from 'site/components/Ads/mobile';

import Title from './Title';

import dataProvider from './offersDataProvider';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  OFFERS_PAGE_LIMIT,
  offersSortFilters,
} from 'site/constants';


function OffersPage(props) {
  const {
    geos,
    brands,
    offers,
    offersSeo,
    generationSeo,
    models,
    match,
    isMobile,
    isDesktop,
    location,
  } = props;

  const {
    brand: brandSlug,
    geo: geoUrl,
    model: modelSlug,
    generation: generationSlug,
  } = match.params;

  const {
    brandSeoTitle,
    title: pageSeoTitle,
    description: seoDescription,
    text: seoText,
  } = offersSeo;

  const {
    filtered_count: itemsCount,
  } = offers.meta || {};

  const isMoto = isMotoPath(location.pathname);
  const brand = brandSlug && offers.included.find(item => {
    return item.type === 'car_brand' || item.type === 'moto_brand'
      && item.attributes?.name?.toLowerCase() === brandSlug.toLowerCase();
  });

  const brandName = brandSlug ? (brand?.attributes?.name || brands?.attributes?.name) : '';

  const offersModel = modelSlug && offers.included.find(item => {
    return item.type === 'car_model' || item.type === 'moto_model'
      && item?.attributes?.name?.toLowerCase() === modelSlug.toLowerCase();
  });

  const seoTitle = offers.included.length > 0 ? pageSeoTitle : brandSeoTitle;
  const geoEntity = geos.find(({ attributes: { url } }) => url === match.params.geo);

  const basePath = match.path.slice(0, match.path.indexOf(':'));
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const geoLinksMapper = geo => {
    const linkTo = basePath + geo.attributes.url + (brandSlug ? '/' + brandSlug : '');
    return (
      <div key={geo.id}>
        <Link
          to={linkTo}
          type='underlined'
        >
          {geo.attributes.name}
        </Link>
      </div>
    );
  };

  const modelsMapper = model => {
    const linkTo = `${basePath}${match.params.geo}/${brandSlug}/${model.attributes.slug}`;

    return (
      <li key={model.id} className={styles.modelItem}>
        <Link
          to={linkTo}
          type='underlined'
        >
          {model.attributes.name}
        </Link>
      </li>
    );
  };

  const interItemSpacing = isMobile ? 14 : 20;

  const feedProps = {
    grid: true,
    interitemSpacing: interItemSpacing,
    card: isMobile ? CardServiceOffer1Type1 : CardServiceOffer2,
  };

  const listSortType = isMoto ? 4 : 2;

  const canonical = [
    'inventory',
    geoUrl,
    brandSlug,
    modelSlug,
    generationSlug,
  ]
    .filter(Boolean)
    .join('/');

  return (
    <Fragment>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name='description' content={seoDescription} />
        <link rel='canonical' href={'https://quto.ru/' + canonical} />
      </Helmet>
      <Title
        offersGeo={geoEntity}
        brand={brand}
        model={offersModel}
        generation={generationSeo?.attributes?.name}
        isMoto={isMoto}
      />
      <Indent bottom={vertical} />
      <ListSortHeader
        filteredCount={itemsCount}
        location={location}
        type={isMobile ? 3 : listSortType}
        filters={offersSortFilters}
        defaultOrder='desc'
      />
      <Indent bottom={vertical} />
      <ListPage
        title={seoTitle}
        description={seoDescription}
        rawData={offers}
        limit={OFFERS_PAGE_LIMIT}
      >
        {({ content: denormalizedOffers }) => {
          const firstGroup = denormalizedOffers.slice(0, 4);
          const secondGroup = denormalizedOffers.slice(4);

          return (
            <AdWrapper bottom={interItemSpacing}>
              <Feed
                content={firstGroup}
                {...feedProps}
              />
              <Indent top={interItemSpacing} />
              <Desktop>
                <Context />
              </Desktop>
              <Mobile>
                <Listing1 />
              </Mobile>
              <Feed
                content={secondGroup}
                {...feedProps}
              />
              <Indent bottom={interItemSpacing} />
            </AdWrapper>
          );
        }}
      </ListPage>
      <Indent bottom={vertical} />

      <EntitledBlock title='Модельный ряд'>
        {models.length > 0 && (
          <ul className={styles.model}>
            {models.map(modelsMapper)}
          </ul>
        )}
      </EntitledBlock>
      <Indent bottom={vertical} />

      <EntitledBlock title={seoTitle}>
        <HtmlWithTheme html={seoText} />
      </EntitledBlock>

      <Indent bottom={vertical} />

      <EntitledBlock title={`${isMoto ? 'Мотоциклы' : 'Автомобили'} ${brandName} в вашем регионе`}>
        <div style={isDesktop ? { columnCount: 3 } : undefined}>
          {geos.map(geoLinksMapper)}
        </div>
      </EntitledBlock>
      <Indent bottom={vertical} />
    </Fragment>
  );
}

OffersPage.propTypes = {
  url: PropTypes.string,
  brands: PropTypes.object,
  offers: offersAttributes,
  offersSeo: seoDataAttributes,
  geos: PropTypes.arrayOf(modelPropTypes(geoAttributes)),
  models: PropTypes.arrayOf(PropTypes.oneOfType([
    modelPropTypes(carModelAttributes),
    modelPropTypes(motoModelAttributes),
  ])),
  generationSeo: PropTypes.object,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

OffersPage.defaultProps = {
  offersSeo: {},
  models: [],
};

export default withNonPureBreakpoint(withPageHocs(dataProvider)(OffersPage));
