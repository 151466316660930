import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { Desktop, Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import AdWrapper from 'core/components/Ad/AdWrapper';
import Page from 'core/components/Page';
import H3 from 'core/components/H3';

import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing2 } from 'site/components/Ads/mobile';

import Releases from 'site/components/Releases';
import Brands from 'site/components/Brands';
import { Indent } from 'site/components/Wrappers';
import PopularModels from 'site/components/PopularModels';

import brandsFetcher from 'site/fetchers/brands';
import { releasesFetcher } from 'site/fetchers/releases';

import DescriptionOfMoto from './DescriptionOfMoto';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

const title = 'Мотоциклы, квадроциклы, скутеры - онлайн каталог мотоциклов, квадроциклов и скутеров с ценами официальных дилеров.';
const description = 'Подбор мотоциклов, квадроциклов и скутеров. Удобное сравнение, данные по комплектациям и модификациям. Цены дилеров и объявления о продаже.';

function MotoPage({ brands, models, releasesDataAndMeta, isMobile, location }) {
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  if (location.search) {
    return (
      <Page title={title} description={description}>
        <Releases releasesDataAndMeta={releasesDataAndMeta} isMoto />
        <Indent top={vertical} />
      </Page>
    );
  }

  return (
    <Page title={title} description={description}>
      <AdWrapper bottom={vertical}>
        <Desktop>
          <PopularModels models={models} />
          <Indent bottom={vertical} />
        </Desktop>
        <Mobile>
          <Indent bottom={vertical} />
        </Mobile>
        <H3 dataQa='page-title'>Каталог новой мототехники</H3>
        <Indent bottom={vertical} />
        <Brands brands={brands} isMoto />
        <Desktop>
          <SuperFooter />
        </Desktop>
        <Mobile>
          <Indent bottom={40} />
          <PopularModels models={models} />
        </Mobile>
        <Indent top={vertical} />
        <DescriptionOfMoto />
        <Indent top={vertical} />
        <Mobile>
          <Listing2 />
        </Mobile>
      </AdWrapper>
    </Page>
  );
}

MotoPage.propTypes = {
  brands: PropTypes.array,
  models: PropTypes.array,
  releasesDataAndMeta: PropTypes.object,
  isMobile: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

const dataProvider = resolve({
  brands: brandsFetcher({ isMoto: true }),
  models: ({ servicesApi, consoleError }) => {
    return servicesApi.getMotoReleases({
      is_popular: true,
      'attributes[moto_release]': ['base', 'image'],
      limit: 4,
    })
      .then(denormalizeData)
      .catch(consoleError('models', []));
  },
  releasesDataAndMeta: releasesFetcher({ isMoto: true }),
});

export default withPageHocs(dataProvider)(withBreakpoint(MotoPage));
