import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';
import withGlobalTopicContext from 'core/components/GlobalTopicProvider/withGlobalTopicContext';

import ContentBackground from 'core/components/ContentBackground';
import { StickyContainer } from 'core/components/Shapka';
import Layout, { Header, Content, Footer } from 'core/components/Layout';
import { Mobile, Desktop } from 'core/components/breakpoint';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import Stories from 'site/components/Stories';
import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';

import { isGameCompare } from '../../utils';

import Shapka from '../Shapka';
import Scooter from '../Scooter';

import { Billboard } from '../Ads/desktop';
import { TopBanner } from '../Ads/mobile';


function SiteLayout(props) {
  const {
    children,
    location,
    content,
  } = props;

  const isMainPage = location.pathname === '/';
  const isGameComparePage = isGameCompare(location.pathname);

  const {
    is_premium: isPremiumTopic,
  } = content?.attributes || {};

  return (
    <StickyContainer>
      <ThemeSwitchWithColor tone={isGameComparePage ? 'dark' : 'light'}>
        <ContentBackground>
          <Layout>
            <Header>
              <Shapka isSecondShapkaForPremium={isPremiumTopic} />
            </Header>
            <Content>
              {!isPremiumTopic && (
                <>
                  <Desktop>
                    <Billboard />
                  </Desktop>
                  <Mobile>
                    <TopBanner />
                  </Mobile>
                </>
              )}
              {(!isMainPage && !isPremiumTopic) && <Stories />}
              {children}
            </Content>
            <Footer>
              <Scooter />
            </Footer>
          </Layout>
        </ContentBackground>
      </ThemeSwitchWithColor>
    </StickyContainer>
  );
}

SiteLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  content: modelPropTypes(topicAttributes),
};

export default withRouter(withGlobalTopicContext(SiteLayout));
