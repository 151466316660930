import PropTypes from 'prop-types';
import cx from 'classnames';

import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';

import styles from './index.styl';

const inputStateContract = `{
  backgroundColor,
  color,
  borderColor,
}`;

const inputTypeContract = `{
  idle: ${inputStateContract},
  hover: ${inputStateContract},
  active: ${inputStateContract},
  disabled: ${inputStateContract},
}`;

function Input(props) {
  const {
    styleType,
    label,
    theme: {
      controls: {
        formInput: inputAtoms,
      },
    },
    ...otherProps
  } = props;
  const themeAtoms = inputAtoms[styleType];

  const { disabled } = otherProps;
  const hasValue = otherProps.value || otherProps.defaultValue;

  return (
    <div
      className={cx(
        styles.inputWrapper,
        hasValue && styles._hasValue,
        hasValue && '_hasValue',
        disabled && '_disabled',
      )}
    >
      <style jsx>{`
        .input
          background-color ${themeAtoms.idle.backgroundColor}
          color ${themeAtoms.idle.color}
          border-bottom 2px solid ${themeAtoms.idle.borderColor}
          &:hover
            background-color ${themeAtoms.hover.backgroundColor}
            color ${themeAtoms.hover.color}
            border-bottom-color ${themeAtoms.hover.borderColor}
          ._hasValue &
            background-color ${themeAtoms.hasValue.backgroundColor}
            color ${themeAtoms.hasValue.color}
            border-bottom-color ${themeAtoms.hasValue.borderColor}
          &:focus
          &:active
            background-color ${themeAtoms.active.backgroundColor}
            color ${themeAtoms.active.color}
            border-bottom-color ${themeAtoms.active.borderColor}
            &::placeholder
              color ${themeAtoms.active.color}
            &:-ms-input-placeholder
              color ${themeAtoms.active.color}
          ._disabled &
            background-color ${themeAtoms.disabled.backgroundColor}
            color ${themeAtoms.disabled.color}
            border-bottom-color ${themeAtoms.disabled.borderColor}
        .label
          color ${themeAtoms.idle.label.color}
          ._hasValue &
            color ${themeAtoms.hasValue.label.color}
          .input:hover ~ &
            color ${themeAtoms.hover.label.color}
          .input:focus &
          .input:active &
            color ${themeAtoms.active.label.color}
          ._disabled .input ~ &
            color ${themeAtoms.disabled.label.color}
      `}</style>
      <input
        className={cx(styles.input, 'input')}
        {...otherProps}
      />
      <div className={cx('label', styles.label)}>{label}</div>
    </div>
  );
}

Input.propTypes = {
  /** Тип инпута, определяющий его цветовую схему. */
  styleType: PropTypes.oneOf(['basic']),

  /** Заголовок инпута. */
  label: PropTypes.string.isRequired,

  /** @ignore */
  theme: themePropTypes(`{
    controls: {
      formInput: {
        basic: ${inputTypeContract}
      },
    }
  }`),
};

Input.defaultProps = {
  styleType: 'basic',
};

const InputWithHOCs = withTheme(Input);
InputWithHOCs.displayName = 'Input';

export default InputWithHOCs;
export { Input as StorybookComponent };
