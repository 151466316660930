import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import SmartImage from 'core/components/SmartImage';
import getThumbnail from 'site/utils/get-thumbnail';

import {
  LAYOUT_MAX_WIDTH_MOBILE,
  CONTENT_AREA,
  SLIDE_HEIGHT_MOBILE,
  SLIDE_HEIGHT,
} from 'site/constants';

import styles from './index.styl';

function CarReleaseImage({ image, withLcpFixer, isMobile }) {
  const cover = getThumbnail(image);

  const maxWidth = Math.min(cover.width, isMobile ? LAYOUT_MAX_WIDTH_MOBILE : CONTENT_AREA);
  const maxHeight = Math.min(cover.height, isMobile ? SLIDE_HEIGHT_MOBILE : SLIDE_HEIGHT);

  return (
    <div className={styles.image}>
      <SmartImage
        src={cover}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        {...withLcpFixer && {
          fetchPriority: 'high',
          instant: true,
        }}
      />
    </div>
  );
}

CarReleaseImage.propTypes = {
  image: PropTypes.object,
  withLcpFixer: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default (withBreakpoint(CarReleaseImage));
