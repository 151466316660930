import { Fragment } from 'react';
import accounting from 'accounting';
import StarRatings from 'react-star-ratings';
import { object } from 'prop-types';

import withTheme from 'core/components/theme';

import pluralize from 'core/utils/pluralize';
import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import modelPropTypes, { carModificationAttributes } from 'site/utils/prop-types/model';

import ArrowRightSm from 'site/icons/ArrowRightSm';

import styles from './index.styl';


const relationships = resolveRelationships(['release'], {}, {});


function CostList({ theme, modification }) {
  if (!modification) return null;

  const {
    attributes: {
      cost_of_ownership: {
        cost_of_ownership: costOfOwnershipValue,
        kasko: kaskoValue,
      },
    },
  } = modification;

  const {
    release: {
      warranty,
      reviews_count: reviewsCount,
      main_avg_rating: averageRating,
    },
  } = relationships(modification);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          &:visited
          &:hover
          &:focus
            color ${theme.colors.blue1}

          :global([id*="!"])
            stroke ${theme.colors.blue1}
      `}</style>
    </scope>
  );

  const reviewsText = `(${reviewsCount} ${pluralize(reviewsCount, ['отзыв', 'отзыва', 'отзывов'])})`;

  return (
    <div>
      <style jsx>{`
        .${styles.name}
        .${styles.value}
          color ${theme.colors.primary}
          font 16px/24px ${theme.fonts.text}

        .${styles.name}
          :global(.mobile) &
            color ${theme.colors.primary500}
            font 13px/15px ${theme.fonts.text}

        .${styles.row}
          :global(.mobile) &
            border-bottom 1px solid ${theme.colors.divider}
      `}</style>
      {warranty && (
        <div className={styles.row}>
          <div className={styles.wrapperName}>
            <div className={styles.name}>Гарантия</div>
            <div className={styles.dashed} />
          </div>
          <div className={styles.value}>{warranty}</div>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.wrapperName}>
          <div className={styles.name}>Стоимость владения (без КАСКО)</div>
          <div className={styles.dashed} />
        </div>
        <div className={styles.value}>
          <a href='#tco' className={scope.wrapClassNames(styles.link)}>
            {accounting.formatMoney(costOfOwnershipValue, { symbol: '₽/год' })}
            <ArrowRightSm className={styles.arrowRight} />
          </a>
        </div>
      </div>
      {kaskoValue[kaskoValue.length - 1] !== 0 && (
        <div className={styles.row}>
          <div className={styles.wrapperName}>
            <div className={styles.name}>КАСКО</div>
            <div className={styles.dashed} />
          </div>
          <div className={styles.value}>
            <a href='#tco' className={scope.wrapClassNames(styles.link)}>
              {accounting.formatNumber(kaskoValue[kaskoValue.length - 1])}
              <span className={styles.price} /> за 1-й год
              <ArrowRightSm className={styles.arrowRight} />
            </a>
          </div>
        </div>
      )}
      {!!(Number(averageRating) || Number(reviewsCount)) && (
        <div className={styles.row}>
          <div className={styles.wrapperName}>
            <div className={styles.name}>Рейтинг владельцев</div>
            <div className={styles.dashed} />
          </div>
          <div className={styles.value}>
            <div>
              {Number(averageRating) && (
                <Fragment>
                  <span className={styles.averageRating}>{averageRating}</span>
                  <StarRatings
                    rating={Number(averageRating)}
                    starRatedColor={theme.colors.blue2}
                    numberOfStars={5}
                    name='rating'
                    starDimension='15px'
                    starSpacing='2px'
                  />
                </Fragment>
              )}
              {!!Number(reviewsCount) && <span className={styles.reviews}>{reviewsText}</span>}
            </div>
          </div>
        </div>
      )}
      <scope.styles />
    </div>
  );
}

CostList.propTypes = {
  /** @ignore */
  theme: object,
  /** Данные о модели */
  modification: modelPropTypes(carModificationAttributes),
};

const CostListWithHOCs = withTheme(CostList);
CostListWithHOCs.displayName = 'CostList';

export default CostListWithHOCs;
export { CostList as StorybookComponent };
