import { Fragment, useState, useContext, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import cx from 'classnames';

import { get } from 'core/libs/lodash';
import color from 'core/libs/color';

import { Desktop, Mobile, withBreakpoint } from 'core/components/breakpoint';

import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';

import Hint from 'site/components/Hint';

import PlusIcon from 'site/icons/Plus';
import MinusIcon from 'site/icons/Minus';

import OptionDependencies from 'site/components/OptionDependencies';
import OptionDependenciesSimple from 'site/components/OptionDependenciesSimple';
import OptionInstallVariants from 'site/components/OptionInstallVariants';

import mapTypes from './types';

import styles from './index.styl';

import { checkOnAllConsist, hasInstalledDependencies } from 'site/utils/configurator';

import { ConfiguratorContext } from 'site/components/ConfiguratorProvider';

function Option(props) {
  const configurations = useContext(ConfiguratorContext);
  const [opened, setModal] = useState(false);
  const [disabled, setDiabled] = useState(false);
  const [checked, toggleChecked] = useState(false);
  const [baseOptionInstalled, checkOptionInstalled] = useState(false);

  const {
    option,
    type,
    theme: {
      colors: {
        primary500,
        blue1,
        divider,
      },
      animations: {
        hover,
      },
    },
    modificationId,
    isMobile,
  } = props;

  const { getConfiguration } = configurations;
  const {
    configuration: {
      installed_options: installedOptions,
      disabled_options: disabledOptions,
    } = {},
  } = configurations[modificationId] || {};
  const updateConfiguration = useCallback(() => {
    if (!option) return;
    getConfiguration(modificationId, {
      option_id: option.id,
      is_install_option: !checked,
    });
  }, [checked, getConfiguration, modificationId, option]);

  const toggleModal = useCallback(() => {
    setModal(!opened);
  }, [opened]);

  useEffect(() => {
    if (!installedOptions || !option) return;
    toggleChecked(installedOptions.find(({ id: localId }) => option.id === localId));
    checkOptionInstalled(hasInstalledDependencies([option.id], installedOptions));
  }, [installedOptions, option]);

  useEffect(() => {
    if (!disabledOptions || !option) return;
    const mustBeDisable = !!~disabledOptions.findIndex(localId => localId === option.id);
    setDiabled(mustBeDisable);
  }, [disabledOptions, option]);

  const {
    isPackage,
    isSubgroup,
    shortView,
    withoutChecked,
    withoutNames,
    compactView,
  } = mapTypes[type] || {};

  if (!option) return null;

  const {
    name,
    name_full: fullName,
    price,
    presence,
    dependencies,
    install_variants: installVariants,
  } = option;

  const termName = get(option, 'term.name');
  const termExplanation = get(option, 'term.explanation');

  /**
   * Попап "Требуются уточнения".
   * Включаем если у опции/пакета есть зависимости или варианты установки
   */
  const withDependencies = dependencies.length > 0;
  const withInstallVariants = installVariants.length > 1;
  // опция с нулевой стоимостью, где все зависимости также имеют нулевую стоимость.
  const alreadyConsist = withDependencies && price === 0 && checkOnAllConsist(dependencies);

  /**
   * Если опция не включена в модификацию, помечаем ее как canBeChecked.
   * yes - опция в составе модификации.
   * dependent - опция зависит от пакета или другой опции, сама по себе быть выбрана не может.
   */
  const canBeChecked = presence !== 'yes' && presence !== 'dependent' && !withoutChecked;

  /**
   * Показываем только в списке установленных опций.
   */
  if (presence === 'dependent' && !shortView) return null;

  // опция без зависимостей, но с возможностью установки
  const simpleOption = canBeChecked && !(disabled || withDependencies || withInstallVariants);
  // опция с зависимостями и с возможностью установки
  const mustQualify = !disabled && !baseOptionInstalled && (withDependencies || withInstallVariants);
  const clickHandler = (simpleOption || baseOptionInstalled) && { onClick: updateConfiguration };
  const clickHandlerModal = mustQualify && { onClick: toggleModal };

  const hintProps = {
    className: styles.hint,
    title: termName,
    description: termExplanation,
  };

  return (
    <Fragment>
      {withInstallVariants &&
      <OptionInstallVariants
        option={option}
        toggleModal={toggleModal}
        isOpen={opened}
        modificationId={modificationId}
      />
      }
      {(!withInstallVariants && !alreadyConsist && withDependencies) &&
      <OptionDependencies
        option={option}
        toggleModal={toggleModal}
        isOpen={opened}
        modificationId={modificationId}
      />
      }
      <div
        className={cx(
          styles.row,
          {
            [styles.compactView]: compactView,
            [styles.isPackage]: isPackage,
            [styles.isSubgroup]: isSubgroup,
            [styles.shortView]: shortView,
            [styles.disabled]: disabled,
            [styles.mustQualify]: !alreadyConsist && mustQualify,
            [styles.alreadyConsist]: alreadyConsist,
            [styles.mobile]: isMobile,
          }
        )}
        {...clickHandlerModal}
      >
        <style jsx>{`
          .${styles.row}
            border-color ${divider}

          .${styles.mustQualify}
            .${styles.nameInner}
              color ${blue1}
              border-bottom 1px dashed ${blue1}
              transition border-color ${hover}

              .${styles.disabled} &
                color ${primary500}

            &:hover
            &:focus
              .${styles.nameInner}
                border-color transparent

              .${styles.disabled} &
                border-bottom 1px dashed ${primary500}

          .${styles.mustQualify}
          .${styles.value}
            :global(svg)
              transition color ${hover}

            &:hover
            &:focus
              :global(svg)
                color ${color(checked ? '#ED364C' : '#2AB56E').mix(color(primary500), 0.3).rgb().hex()}

          .${styles.disabled}
            :global(svg)
            .${styles.price}
              color ${primary500}

            &:hover
            &:focus
              :global(svg)
                color ${primary500}

          .simpleValue
            color ${primary500}
        `}</style>
        {!withoutNames && (
          <div className={styles.nameColumn}>
            <div className={styles.name}>
              <Mobile>
                <Hint {...hintProps} />
              </Mobile>
              <span className={styles.nameInner}>{(fullName || name).trim()}</span>
              <Desktop>
                <Hint {...hintProps} />
              </Desktop>
            </div>
          </div>
        )}
        {canBeChecked
          ? (
            <div className={styles.value} {...clickHandler}>
              <div className={styles.price}>{accounting.formatMoney(price)}</div>
              <Fragment>
                {checked
                  ? <MinusIcon className={styles.controlChoice} />
                  : <PlusIcon className={styles.controlChoice} />
                }
              </Fragment>
            </div>
          ) : (
            <Fragment>
              {disabled
                ? <div className='simpleValue'>&mdash;</div>
                : <div className={styles.checked} />
              }
            </Fragment>
          )
        }
      </div>
      {alreadyConsist &&
      <OptionDependenciesSimple
        type={2}
        modificationId={modificationId}
        option={option}
        compactView={compactView}
      />
      }
    </Fragment>
  );
}

Option.propTypes = {
  type: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8]),
  /**
   * Либо опция, либо пакет опций
   */
  option: PropTypes.object.isRequired,
  /**
   * Опция как часть подгруппы
   */
  isSubgroup: PropTypes.bool,
  /**
   * Это пакет опций
   */
  isPackage: PropTypes.bool,
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      blue1,
      primary500,
      divider,
    },
  }`),
  modificationId: PropTypes.string,
  /** @ignore */
  isMobile: PropTypes.bool,
};

const OptionWithHOCs = withBreakpoint(withTheme(Option));
OptionWithHOCs.displayName = 'Option';

export default OptionWithHOCs;
export { Option as StorybookComponent };
