import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import H3 from 'core/components/H3';
import Link from 'core/components/Link';
import { dateFormat } from 'core/utils/dates';

import ArrowStickLeft from 'site/icons/ArrowStickLeft.svg';
import Clock from 'site/icons/Clock.svg';
import Flag from 'site/icons/Flag.svg';

import styles from './index.styl';


function PddHeading(props) {
  const {
    theme,
    finish,
    time,
    mode,
    children,
  } = props;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.img}
          color ${theme.colors.primary} !important
      `}</style>
    </scope>
  );

  const rightBlock = mode === 'exam' || mode === 'tren';

  const formateTime = time && dateFormat(new Date(time * 1000), 'mm:ss');

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Link to='/pdd' className={scope.wrapClassNames(styles.img)}>
          <ArrowStickLeft  />
        </Link>
        <H3 is='h1'>
          {children}
        </H3>
      </div>
      { rightBlock &&
        <div className={styles.wrapper}>
          {finish ? <Flag width={15} height={17} /> : <Clock width={20} height={20} />}
          <H3 className={styles.time}>{formateTime}</H3>
        </div>
      }
      <scope.styles />
    </div>
  );
}

PddHeading.propTypes = {
  /** Завершен ли экзамен */
  finish: PropTypes.bool,

  /** Таймер в секундах */
  time: PropTypes.number,

  /** Режимы */
  mode: PropTypes.oneOf(['exam', 'tren', 'marafon']),

  /** @ignore */
  theme: PropTypes.object,
};

const PddHeadingWithHOCs = withTheme(PddHeading);
PddHeadingWithHOCs.displayName = 'PddHeading';

export default PddHeadingWithHOCs;
export { PddHeading as StorybookComponent };

