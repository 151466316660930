import { useCallback, Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import { compose, branch, renderComponent } from 'core/libs/recompose';

import modelPropTypes, { questionsAttributes } from 'site/utils/prop-types/model';
import { pddAnswersPropTypes } from 'site/utils/prop-types/pdd';

import { Indent } from 'site/components/Wrappers';
import PddQuestion from 'site/components/PddQuestion';
import PddResult from 'site/components/PddResult';
import Blocks from 'site/pages/pdd/components/Blocks';


function Questions(props) {
  const {
    isFinish,
    goToPrevQuestion,
    goToNextQuestion,
    additionalQuestions,
    answers,
    resetTicket,
    questionIndex,
    questions,
    handleAnswer,
    examResult,
    mode,
  } = props;

  const allQuestions = questions.concat(additionalQuestions);
  const question = allQuestions[questionIndex];

  const saveAnswer = useCallback(answer => {
    handleAnswer(question, answer);
  }, [handleAnswer, question]);

  return (
    <Fragment>
      {!isFinish && question && (
        <PddQuestion
          question={question}
          mode={mode}
          handlePrevQuestion={goToPrevQuestion}
          handleNextQuestion={goToNextQuestion}
          saveAnswer={saveAnswer}
        />
      )}

      {isFinish && (
        <Fragment>
          <PddResult
            questions={allQuestions}
            answers={answers}
            handleReset={resetTicket}
            examResult={examResult}
            mode={mode}
          />
          <Indent top={40} />
          <Blocks mode={mode} finish />
        </Fragment>
      )}
    </Fragment>
  );
}


Questions.propTypes = {
  questions: modelPropTypes(questionsAttributes),
  servicesApi: PropTypes.object,
  isFinish: PropTypes.bool,
  answers: pddAnswersPropTypes,
  questionIndex: PropTypes.number,
  additionalQuestions: PropTypes.array.isRequired,
  goToPrevQuestion: PropTypes.func.isRequired,
  goToNextQuestion: PropTypes.func.isRequired,
  resetTicket: PropTypes.func.isRequired,
  handleAnswer: PropTypes.func.isRequired,
  examResult: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  mode: PropTypes.oneOf(['tren', 'exam']),
};

export default compose(
  branch(
    ({ questions }) => !questions,
    renderComponent(() => <div>Произошла ошибка! Попробуйте перезагрузить страницу или зайти позже.</div>),
  ),
  memo,
)(Questions);
