import PropTypes from 'prop-types';
import { Redirect } from 'core/libs/router';

import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';
import resolveRelationships from 'core/utils/relationships';

import Page from 'core/components/Page';
import { withBreakpoint } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import ReleaseBoroda from 'site/components/ReleaseBoroda';
import { ReleaseInnerPages } from 'site/components/Wrappers';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';
import modelPropTypes, { carReleaseAttributes, seoAttributes } from 'site/utils/prop-types/model';

import { releaseCommonProviders } from 'site/pages/release/releaseDataProvider';

import Feature from './Feature';
import Comment from './Comment';


const relationships = resolveRelationships(['features'], {}, {});

function ReleaseFeature(props) {
  const {
    seo,
    match: {
      params: {
        lcv,
        brand,
        model,
        generation,
        body,
        feature_id: featureId,
        suffix,
        comments,
      },
    },
    release,
    isTopicsByBrandExist,
  } = props;

  const { features } = relationships(release);
  const releaseFeatureExists = features.some(feature => String(feature.attributes.slug) === featureId);

  /**
   * TODO: Обрабатываем редирект по мотивам задачки UCMS-5693
   */
  if (!releaseFeatureExists) {
    const redirectPath = [
      lcv,
      brand,
      model,
      generation,
      body,
      features.length > 0 ? suffix : null,
    ].filter(Boolean).join('/');

    return <Redirect to={`/${redirectPath}`} />;
  }

  const currentFeature = features.find(feature => String(feature.attributes.slug) === featureId);
  const filteredFeatures = features.filter(feature => String(feature.attributes.slug) !== featureId);

  const featureProps = { currentFeature, filteredFeatures };

  return (
    <Page title={seo.title} description={seo.description}>
      <ReleaseInnerPages release={release} isTopicsByBrandExist={isTopicsByBrandExist}>
        {comments
          ? <Comment {...featureProps} />
          : <Feature {...featureProps} />
        }
        <ReleaseBoroda release={release} />
      </ReleaseInnerPages>
    </Page>
  );
}

ReleaseFeature.propTypes = {
  release: modelPropTypes(carReleaseAttributes),
  isTopicsByBrandExist: PropTypes.bool,
  seo: seoAttributes,
  isMobile: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
      brand: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
      generation: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      suffix: PropTypes.string.isRequired,
      feature_id: PropTypes.string.isRequired,
      comments: PropTypes.string,
    }),
  }),
};

const dataProvider = resolve({
  release: ({ servicesApi, renderError, match }) => {
    const {
      params: {
        brand,
        model,
        generation,
        body,
      },
    } = match;

    return (
      servicesApi
        .getReleases({
          limit: 1,
          'filter[brand]': brand,
          'filter[model]': model,
          'filter[generation]': generation,
          'filter[body]': body,
          include: 'car_brand,car_feature',
          'relations[car_release]': 'brand,features',
          'attributes[car_release]': 'base,media,seo,image,extended',
          'attributes[car_feature]': 'base,image,text',
        })
        .then(releases => denormalizeData(releases)[0])
        .then(rejectEmptyResult)
        .catch(renderError)
    );
  },

  seo: ({ servicesApi, consoleError, match: { params } }) => {
    return (
      servicesApi
        .getReleasesFeatureSeo({
          'filter[brand]': params.brand,
          'filter[model]': params.model,
          'filter[generation]': params.generation,
          'filter[submodel]': params.body,
          'filter[feature]': params.feature_id,
        })
        .then(denormalizeData)
        .catch(consoleError('seo', {}))
    );
  },
  ...releaseCommonProviders,
});

export default withPageHocs(dataProvider)(withBreakpoint(ReleaseFeature));
