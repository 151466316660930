import { components } from 'react-select';

/* eslint-disable react/prop-types */
function MenuList(props) {
  const {
    children,
    ...otherProps
  } = props;

  return (
    <components.MenuList {...otherProps}>
      <div data-qa='dropdown-input-menu-list'>
        {children}
      </div>
    </components.MenuList>
  );
}

export default MenuList;
