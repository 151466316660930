import { get } from 'core/libs/lodash';

import {
  replaceLastCharacter,
} from 'site/utils';

import {
  MIN,
  MAX,
} from 'site/constants';


/**
 * Преобразуем данные в структуру, под которую заточен react-select.
 * Иначе, при использовании getOptionLabel и getOptionValue, react-select
 * дёргает коллбеки этих параметров даже при скролле выпадающего списка.
 *
 * @param {Object[]} options - сущности из API, из которых будет сформирован список.
 * @param {Object} mapping - объект, содержащий данные для `lodash/get`.
 * @param {string|string[]} [mapping.label=attributes.name] - путь до атрибута, значение которого будет использоваться как label.
 * @param {string|string[]} [mapping.value=id] - путь до атрибута, значение которого будет использоваться как value.
 * @returns {Object[]} - options, дополненные полями label и value.
 */
export function generateSelectOptions(options, mapping = {}) {
  if (!Array.isArray(options) || options.length === 0) return [];

  const {
    label: labelPath = 'attributes.name',
    value: valuePath = 'id',
  } = mapping;

  return options.map(sourceOption => ({
    ...sourceOption,
    // Дополняем сущность полями, которые нужны react-select'у.
    label: get(sourceOption, labelPath),
    value: get(sourceOption, valuePath),
  }));
}

/**
 * Форматируем опции в группах опций, для поддержки компонентом Checkboxes
 * @param {Object[]} groups - группы опций, связанные с конфигуратором и тех.характеристиками
 */
export function generateOptionsGroup(groups) {
  if (Object.keys(groups).length === 0) {
    return {
      title: '',
      options: [],
    };
  }

  return Object.keys(groups).reduce((formattedGroup, groupName) => {
    const currentGroup = groups[groupName];

    return {
      ...formattedGroup,
      [groupName]: {
        title: currentGroup.title,
        options: generateSelectOptions(currentGroup.options, { label: 'title', value: 'value' }),
      },
    };
  }, {});
}

/**
 * Забираем параметры поиска из URL для использования их в качестве
 * initialValues react-select'а.
 *
 * @param {string} fieldName - имя поля.
 * @param {Object} urlValues - объект с именами полей в качестве ключей и их значениями,
 *                             распаршенными из урла.
 * @param {Object[] = {}} options - элементы списка.
 * @param {string} options[].label - название элемента.
 * @param {string} options[].value - ключ элемента.
 * @param {string|string[]} [optionPath=value] - пусть до поля опции, по которому будет
 *                                       производиться поиск значения с помощью `lodash.get`.
 *
 * @returns {Object[]} - Массив из элементов списка.
 */
export function initSelectValue(fieldName, urlValues = {}, options = [], optionPath = 'value') {
  // Возвращать будем именно массив. Это нужно для мультиселектов.
  const initialValue = [];

  if (fieldName && options.length) {
    const searchValues = [].concat(urlValues[fieldName]);

    options.forEach((option = {}) => {
      if (searchValues.indexOf(get(option, optionPath)) !== -1) {
        initialValue.push(option);
      }
    });
  }

  return initialValue;
}

/**
 * Забираем параметры поиска из URL для использования их в качестве
 * initialValues у Switch. Могут быть переданы несколько значений в один чекбокс, через separator.
 * @param {string} fieldName - имя поля.
 * @param {Object} urlValues - объект с именами полей в качестве ключей и их значениями,
 *                             распаршенными из урла.
 * @param options - элементы списка.
 * @param separator - разделитель в строке
 * @returns {string} - Строка из элементов списка.
 */
export function initSwitchWithSeparatorValues(fieldName, urlValues = {}, options = [], separator = ',') {
  const searchValues = urlValues[fieldName];
  const initialValue = [];

  if (searchValues) {
    options.forEach(option => {
      if (searchValues.split(separator).indexOf(option.toString()) !== -1) {
        initialValue.push(option);
      }
    });
  }
  return initialValue.join();
}

/**
 * Забираем параметры поиска из URL для использования их в качестве
 * initialValues react-range'а.
 *
 * @param {string} fieldName - имя поля.
 * @param {Object} parsedSearch - результат выполнения queryString.parse(location.search).
 * @param {Object} fallbackValues - значения, которые будут использованы, если в URL нет искомых параметров.
 * @param {number} fallbackValues.min - значение min.
 * @param {number} fallbackValues.max - значение max.
 *
 * @returns {Object} - Объект с ключами `min` и `max`.
 */
export function initRangeValue(fieldName, parsedSearch = {}, fallbackValues) {
  return {
    [MIN]: parsedSearch[fieldName + '_' + MIN] || fallbackValues[MIN],
    [MAX]: parsedSearch[fieldName + '_' + MAX] || fallbackValues[MAX],
  };
}

/**
 * Забираем параметры поиска из URL для использования их в качестве
 * initialValues react-select'а.
 *
 * @param {string} fieldName - имя поля.
 * @param {Object} parsedSearch - результат выполнения queryString.parse(location.search).
 *
 * @returns [string] - массив имен полей
 */
export function initCheckboxValue(fieldName, parsedSearch) {
  const value = parsedSearch[fieldName];

  if (Array.isArray(value)) return value;

  return [value].filter(Boolean);
}

/**
 * Устанавливаем значение checked для радио-опций со значением range (компонент RadioCloudsRange).
 * @param options - список свойств radio группы.
 * @param {Object} currentValue - объект, содержащий текущее выбранное значение
 * @param {Object} ranges - объект, содержащий предельные значения для полей
 * @returns [{}] - options с проставленными значениями checked
 */
export function setCheckedRadioRange(options, currentValue, ranges) {
  const currentMin = Number(currentValue.min);
  const currentMax = Number(currentValue.max);
  const rangeMin = ranges.min;
  const rangeMax = ranges.max;

  const minNumbers = options.map(item => item.value.min).sort((a, b) => b - a).filter(Boolean);
  const maxNumbers = options.map(item => item.value.max).sort((a, b) => b - a).filter(Boolean);

  /**
   * Находим ближайшее min/max от текущего выбранного.
   * Сравнение для установки checked должно происходить с этим значением.
   */
  const closestMin = minNumbers.find(num => num <= currentMin);
  const closestMax = maxNumbers.find(num => num <= currentMax);

  return options.map((item, index) => {
    /**
     * Для опции "Любой" устанавливаем checked только в крайнем диапазоне
     */
    if (index === 0) {
      item.checked = currentMin === rangeMin && currentMax === rangeMax;
      return item;
    }

    const min = item.value.min;
    const max = item.value.max;

    if (min && max && closestMin && closestMax) {
      item.checked = min === closestMin && max === closestMax;
    } else if (min && closestMin) {
      item.checked = min === closestMin;
    } else if (max && closestMax) {
      item.checked = max === closestMax;
    }

    return item;
  });
}

/**
 * Забираем параметры поиска из URL для использования их в качестве initialValues
 *
 * @param {string} fieldName - имя поля.
 * @param {Object} parsedSearch - результат выполнения queryString.parse(location.search).
 *
 * @returns {string}
 */
export function initRadioValue(fieldName, parsedSearch) {
  return parsedSearch[fieldName] || '';
}

/**
 * Раскладываем values формы в параметры URL.
 *
 * @param {Object} formValues - объект, содержащий значения полей ввода.
 * @param {Object={}} ranges - объект, содержащий предельные значения для полей `InputRange`.
 * @returns {Object} - объект для использования в `location.search`.
 */
export function buildSearchParamsFromValues(formValues, ranges = {}) {
  return Object.keys(formValues).reduce((result, fieldName) => {
    const fieldData = formValues[fieldName];

    if (fieldData && fieldData.length && Array.isArray(fieldData)) {
      /**
       * Для значений из мультиселекта используем value.
       * Для значений из checkbox группы используем элемент как есть.
       */
      result[fieldName] = fieldData.map(valueItem => valueItem.value || valueItem);
    } else if (fieldData && ranges[fieldName] && MIN in fieldData && MAX in fieldData) {
      // Значения range раскладываем на два параметра, `param_min` и `param_max`
      result[`${fieldName}_${MIN}`] = fieldData[MIN] === ranges[fieldName][MIN]
        ? undefined
        : fieldData[MIN];
      result[`${fieldName}_${MAX}`] = fieldData[MAX] === ranges[fieldName][MAX]
        ? undefined
        : fieldData[MAX];
    } else if (fieldData && (fieldData.value || fieldData.value === '')) {
      // В остальных случаях, когда у значения есть value, используем его
      result[fieldName] = fieldData.value;
    } else {
      // Всё остальное забираем как есть.
      // TODO: `|| undefined` - фикс для ReactSelect, для случая, когда в value лежит null.
      result[fieldName] = fieldData || undefined;
    }

    return result;
  }, {});
}


/**
 * Возвращает поле `value` переданного объекта.
 *
 * @param {Object} item - объект с полем `value`.
 * @returns {*} - значение поля `value`.
 */
export function getOptionValue(item) {
  if (!item) return item;
  return item.value;
}

/**
 * Возвращает значение поля переданной сущности, находящееся
 * в ней по переданному valuePath.
 * Если entity - строка, возвращает строку. Если массив -
 * использует первый элемент массива для поиска в нём по valuePath.
 * @param {*} entity - сущность или массив сущностей.
 * @param {string|string[]} valuePath - путь для передачи в lodash.get.
 *
 * @returns {*}
 */
export function getFieldValue(entity, valuePath = 'attributes.slug') {
  if (!entity || typeof entity === 'string') {
    return entity;
  }

  return get(Array.isArray(entity) ? entity[0] : entity, valuePath);
}


/**
 * Фильтрует значения зависимых полей по значениям родительских с
 * помощью relationships.
 *
 * @param {Object} formValues - объект со значениями всех полей формы.
 * @param {string} superiorName - имя родительского поля.
 * @param {Object|Object[]} superiorValues - значение родительского поля.
 * @param {string} dependentName - имя зависимого поля.
 * @param {string} [searchPath=data.id] - путь для поиска зависимости в родителе (может быть по id или slug).
 * @returns {Object[]} - отфильтрованные значения зависимого поля.
 */
export function filterValues(
  formValues,
  superiorName,
  superiorValues,
  dependentName,
  searchPath = 'data.id'
) {
  return [].concat(formValues[dependentName])
    .filter(dependentItem => {
      const superiorRelation = dependentItem
        && dependentItem.relationships
        && dependentItem.relationships[superiorName];

      if (!superiorRelation) return false;

      return !!~[].concat(superiorValues)
        .map(getOptionValue)
        .indexOf(get(superiorRelation, searchPath));
    });
}


/**
 * Строит pathname из значений формы.
 *
 * @param {Object} formValues - значения формы.
 * @param {string[]} [pathnameFields=[]] - имена полей, значения которых должны быть частью pathname.
 * @param {string} [basePath=/] - строка, которая будет подставляться перед `pathnameFields`.
 * @param {string} [emptyFormPath] - адрес, который будет использован, если поля формы пусты.
 *                                 Если не указан, вместо него будет использоваться `basePath`.
 * @param {Object} [fieldValuePaths={}] - список путей до атрибута сущности, которые будут переданы
 *                                       в `lodash.get` для использования в URL.
 *                                       Ключ - название поля.
 *                                       Путь по умолчанию - `attributes.slug`.
 *
 * @returns {string}
 */
export function buildPathnameFromValues(
  formValues = {},
  pathnameFields = [],
  basePath = '/',
  emptyFormPath,
  fieldValuePaths = {},
) {
  if (!pathnameFields.length) return emptyFormPath || basePath;

  let pathnameValues = [replaceLastCharacter(basePath, '/')];

  // Заполняем pathnameValues до нахождения первого пустого formValue
  pathnameFields.some((fieldName, index) => {
    const formValue = getFieldValue(formValues[fieldName], fieldValuePaths[fieldName]);

    if (formValue) {
      pathnameValues.push(formValue);
      return false;
    }

    if (index === 0) pathnameValues = [emptyFormPath || basePath];
    return true;
  });

  return pathnameValues.join('/');
}

/**
 * Проверяет относится ли бренд или модель и к лекковым и к коммерческим
 */
export function isBothLcvAndCar(entity) {
  if (!entity) return false;

  const {
    attributes: {
      is_car: isCar,
      is_lcv: isLcv,
    },
  } = entity;

  return isCar && isLcv;
}

export function isLcvOnly(entity) {
  if (!entity) return false;

  const {
    attributes: {
      is_car: isCar,
      is_lcv: isLcv,
    },
  } = entity;

  return isLcv && !isCar;
}

/**
 * Простая обработка зависимых полей в форме.
 */
export function resolveDependenciesFields(name, dependencies) {
  if (!dependencies[name]) return null;

  return Object.keys(dependencies[name]).reduce((acc, field) => {
    return { ...acc, [field]: dependencies[name][field]() };
  }, {});
}

/**
 * Считает сколько фильтров было изменено
 * @param {Object} formState={} - значения формы.
 * @param {Object={}} ranges - объект, содержащий предельные значения для полей `InputRange`.
 * @param {Object[]} _fields=[] - имена полей, которые будем считать. Если не передоно, то будут считаться все поля в formState.
 *
 * @returns {number}
 */
export function getFiltersCount(formState, ranges, _fields) {
  const fileds = _fields || Object.keys(formState);

  return fileds.reduce((count, field) => {
    const value = formState[field];

    let isValuable = value;

    if (ranges[field]) {
      isValuable = (ranges[field].max !== +value.max) || (ranges[field].min !== +value.min);
    } else if (Array.isArray(value)) {
      isValuable = value.length;
    }

    return isValuable ? count + 1 : count;
  }, 0);
}


/**
 * Утилита генерирует последовательный массив для отображения marks на треке в InputRange.
 * `rangeMarksArray(0, 4, 1) -> [0, 1, 2, 3, 4]`;
 * `rangeMarksArray(1, 10, 2)` -> `[1, 3, 5, 7, 9]`;
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from#Sequence_generator_range
 *
 * Для кастомных реализаций делать унифицированную утилиту нет смысла.
 * Легче передавать массив в ручную `[MIN, 100, 130, 160, 180, 200, 220, 250, MAX]`.
 *
 * @param {number} start - минимальное значение в слайдере
 * @param {number} stop - максимальное значение в слайдере
 * @param {number} step - шаг в последовательности
 */
export function rangeMarksArray(start, stop, step) {
  return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
}

/**
 * Утилита обновляет состояние формы, на основании range.
 *
 * @param min - текущее минимальное значение текстового поля
 * @param max - текущее максимальное значение текстового поля
 * @param value - текущее значение min/max для формы
 * @param range - значение min/max по умолчанию
 * @param onChange - обработчик контрола формы
 * @param name - имя обновляемого поля
 * @param replaceValue[]
 * [0] индекс элемента который хотим заменить
 * [1] значение на которое заменяем
 */
export function updateRangeValue({ min, max, value, range, onChange, name, replaceValue }) {
  let newMin = (min ||  min === 0) ? min : (value.min || range.min);
  let newMax = max || value.max || range.max;

  const minMustBeReplaced = replaceValue && replaceValue[0] === newMin;
  const maxMustBeReplaced = replaceValue && replaceValue[0] === newMax;

  if (minMustBeReplaced) newMin = replaceValue[1];
  if (maxMustBeReplaced) newMax = replaceValue[1];

  if (newMin === value.min && newMax === value.max) return;

  onChange({ min: newMin, max: newMax }, { name });
}

export function getParamsFromState(state) {
  const {
    geo,
    brand,
    model,
  } = state;

  return {
    geo: geo && geo.attributes && geo.attributes.url || '',
    brand: brand && brand.attributes.slug || '',
    model: model && model.attributes.slug || '',
  };
}

export function getDataProviderCacheKeyFromState(state) {
  const params = getParamsFromState(state);

  const {
    geo = '',
    brand = '',
    model = '',
  } = params;

  return geo + brand + model;
}
