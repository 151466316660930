import { Fragment } from 'react';
import PropTypes from 'prop-types';

import InnerSideColumnPart1 from './Part1';
import InnerSideColumnPart2 from './Part2';

/**
 * Компоненты сайдбара разделенные на две части.
 * Создано для разделения колонки рекламным местом Parallax.
 */
export { InnerSideColumnPart1, InnerSideColumnPart2 };

export default function InnerSideColumn(props) {
  const {
    showBoesque,
    showCarNews,
    additionalOffsetTop,
  } = props;

  return (
    <Fragment>
      <InnerSideColumnPart1
        showBoesque={showBoesque}
        showCarNews={showCarNews}
        additionalOffsetTop={additionalOffsetTop}
        noSideColumn
      />
      <InnerSideColumnPart2 noSideColumn />
    </Fragment>
  );
}

InnerSideColumn.defaultProps = {
  showBoesque: true,
  showCarNews: true,
};

InnerSideColumn.propTypes = {
  showBoesque: PropTypes.bool,
  showCarNews: PropTypes.bool,
  /**
   * Задает дополнительное смещение для StickyPortal
   */
  additionalOffsetTop: PropTypes.number,
};
