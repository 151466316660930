import { Mobile } from 'core/components/breakpoint';

import H3 from 'core/components/H3';

import { Indent } from 'site/components/Wrappers';
import { VERTICAL_INDENT_MOBILE } from 'site/constants';

import Divider from 'site/components/Divider';

import styles from './index.styl';

export default function FeedHeader({ children }) {
  return (
    <div className={styles.feedHeader}>
      <H3>{children}</H3>
      <Mobile>
        <Indent bottom={VERTICAL_INDENT_MOBILE} />
        <Divider />
      </Mobile>
    </div>
  );
}
