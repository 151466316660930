import PropTypes from 'prop-types';
import cx from 'classnames';

import { compose } from 'core/libs/recompose';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import withTheme from 'core/components/theme';
import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import H6 from 'core/components/H6';
import Page from 'core/components/Page';
import AdWrapper from 'core/components/Ad/AdWrapper';

import DealerInfo from 'site/components/DealerInfo';

import PageLayoutService from 'site/components/PageLayoutService';
import TextExpander from 'site/components/TextExpander';
import { Indent } from 'site/components/Wrappers';
import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing1 } from 'site/components/Ads/mobile';
import SimilarOffers from 'site/components/SimilarOffers';

import { isMotoPath } from 'site/utils';
import relationshipsPropTypes from 'site/utils/prop-types/relationships';
import modelPropTypes, {
  offerAttributes,
  dealerAttributes,
  geoAttributes,
  seoFullDataAttributes,
  reviewAttributes,
} from 'site/utils/prop-types/model';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import Header from './Header';
import Cover from './Cover';
import Reviews from './Reviews';
import Properties from './Properties';
import AuxiliaryInfo from './AuxiliaryInfo';

import dataProvider from './offerDataProvider';

import styles from './index.styl';


function OfferPage(props) {
  const {
    offerWithDealer: {
      offer,
      dealer,
    },
    offerSeo,
    isMobile,
    breakpoint,
    theme,
    location,
    match,
  } = props;

  const {
    attributes: {
      description,
      external_url: externalUrl,
    },
  } = offer;

  const { title: seoTitle, description: seoDescription } = offerSeo;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const isMoto = isMotoPath(location.pathname);

  return (
    <Page
      title={seoTitle}
      description={seoDescription}
    >
      <style jsx>{`
        .section
          border-bottom 1px solid ${theme.colors.primary250}
      `}</style>
      <AdWrapper bottom={vertical}>
        <PageLayoutService bgContentList>
          <div className={cx(styles.wrapper, styles[breakpoint])}>
            <Header offer={offer} />

            <Mobile>
              <Indent top={20}>
                <AuxiliaryInfo offer={offer} />
              </Indent>
            </Mobile>

            <Indent top={vertical} bottom={vertical}>
              <Cover offer={offer} />
            </Indent>

            <div className={styles.column}>
              <Desktop>
                <AuxiliaryInfo offer={offer} />
              </Desktop>

              <Indent top={vertical} bottom={vertical}>
                <Properties offer={offer} />
              </Indent>

              <div className={cx(styles.section, 'section')}>
                <H6>Комментарий продавца</H6>
                <TextExpander maxParagraphs={10} text={description} />
              </div>
              <Indent bottom={vertical} />
              <AuxiliaryInfo offer={offer} />
              <Indent bottom={vertical} />
            </div>


            <Desktop>
              <SuperFooter />
            </Desktop>
            <Mobile>
              <Listing1 />
            </Mobile>
            <DealerInfo
              externalUrl={externalUrl}
              content={dealer}
              showHeader
            />
            <Indent bottom={vertical} />
            <SimilarOffers offer={offer} />
            {!isMoto && (
              <Reviews match={match} />
            )}
          </div>
        </PageLayoutService>
      </AdWrapper>
    </Page>
  );
}

OfferPage.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
  offerWithDealer: PropTypes.exact({
    offer: modelPropTypes(offerAttributes),
    dealer: modelPropTypes(
      dealerAttributes,
      relationshipsPropTypes({
        geo_parent: modelPropTypes(geoAttributes),
      })
    ),
  }),

  offerSeo: seoFullDataAttributes,
  reviews: PropTypes.arrayOf(modelPropTypes(reviewAttributes)),

  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
    }).isRequired,
  }).isRequired,
  breakpoint: breakpointPropTypes(),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default compose(
  withPageHocs(dataProvider),
  withBreakpoint,
  withTheme,
)(OfferPage);
