import { Fragment } from 'react';
import PropTypes from 'prop-types';

import themePropTypes from 'core/utils/prop-types/theme';
import { carBrandAttributes } from 'core/utils/prop-types/model';

import withTheme from 'core/components/theme';

import QutoBrandIcon from 'core/components/QutoBrandIcon';
import Divider from 'core/components/Divider';
import Link from 'core/components/Link';

import modelPropTypes from 'site/utils/prop-types/model';

import { MOTO_URL } from 'site/constants';

import mapTypes from './types';
import styles from './index.styl';

function CardServiceBrand(props) {
  const { content: brand, type, theme } = props;
  const { withIcon } = mapTypes[type] || {};
  return (
    <Link
      to={`${brand.type === 'moto_brand' ? MOTO_URL : ''}/${brand.attributes.slug}`}
      type='tertiary'
      className={styles.cardBrand}
      dataQa='brands-name-link'
    >
      <style jsx>{`
        .${styles.brandName}
          color ${theme.colors.primary}
          font-family ${theme.fonts.text}

          &:visited
            color ${theme.colors.primary}

          &:hover
          &:focus
            color ${theme.colors.blue1}
      `}</style>
      {withIcon && (
        <Fragment>
          <QutoBrandIcon
            brand={brand}
            className={styles.brandLogo}
          />
          <Divider className={styles.divider} />
        </Fragment>
      )}
      <div className={styles.brandName} data-qa='brands-name'>
        {brand.attributes.name}
      </div>
    </Link>
  );
}

CardServiceBrand.defaultProps = {
  type: 0,
};

CardServiceBrand.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1]),
  /** Контент карточки, соответствующий модели `carBrandAttributes` */
  content: modelPropTypes(carBrandAttributes),
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      blue1
    },
  }`),
};

const CardWithHOCs = withTheme(CardServiceBrand);
CardWithHOCs.displayName = 'CardServiceBrand';

export default CardWithHOCs;
export { CardServiceBrand as StorybookComponent };
