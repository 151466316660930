import { Fragment } from 'react';

import { Desktop, Mobile } from 'core/components/breakpoint';

import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing1 } from 'site/components/Ads/mobile';

export default function FirstAdvPlace() {
  return (
    <Fragment>
      <Desktop>
        <SuperFooter />
      </Desktop>
      <Mobile>
        <Listing1 />
      </Mobile>
    </Fragment>
  );
}
