import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { Desktop, Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import Page from 'core/components/Page';
import Bouesque from 'core/components/Bouesque';
import AdWrapper from 'core/components/Ad/AdWrapper';

import NewReleases from 'site/components/NewReleases';
import Comparison from 'site/components/Comparison';
import Releases from 'site/components/Releases';
import TitleWithReleasesCount from 'site/components/TitleWithReleasesCount';
import Brands from 'site/components/Brands';
import { Indent, NegativeMobile } from 'site/components/Wrappers';

import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing1, Listing2 } from 'site/components/Ads/mobile';

import brandsFetcher from 'site/fetchers/brands';
import comparisonsFetcher from 'site/fetchers/comparisons';
import { newReleasesFetcher, releasesFetcher, releasesCountFetcher } from 'site/fetchers/releases';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';


function CatalogPage(props) {
  const {
    brands,
    comparisons,
    newReleases,
    releasesDataAndMeta,
    releasesCount,
    isMobile,
    location,
    match,
  } = props;

  const {
    params: {
      lcv: isLcv,
    },
  } = match;

  let title;
  let description;

  if (isLcv) {
    title = 'Коммерческие автомобили, цены и характеристики, продажа коммерческих автомобилей официальными дилерами.';
    description = 'Одна из ключевых бизнес-задач — купить коммерческий автомобиль. Чтобы не ошибиться в выборе, нужно чётко понимать потребности своего предприятия — будь то представительский минивэн или фургон для перевозки фруктов-овощей.';
  } else {
    title = 'Каталог автомобилей - новые легковые авто в онлайн каталоге с ценами, описанием, фото и видео материалами.';
    description = 'Подробный каталог автомобилей с подробной информацией о марках и моделях автомобилей, доступных для покупки в России. Автокаталог позволяет онлайн получить официальную информацию о новых автомобилях, уточнить технические параметры, посмотреть фото и отзывы';
  }

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  if (location.search) {
    return (
      <Page title={title} description={description}>
        <Releases releasesDataAndMeta={releasesDataAndMeta} />
        <Indent top={vertical} />
      </Page>
    );
  }

  return (
    <Page title={title} description={description}>
      <AdWrapper bottom={vertical}>
        <TitleWithReleasesCount releasesCount={releasesCount} />
        <Indent top={vertical} />
        <Mobile>
          <NegativeMobile>
            <Listing1 />
          </NegativeMobile>
        </Mobile>
        <Brands brands={brands} />
        <Indent top={vertical} />
        <Mobile>
          <Indent top={vertical} />
          <Bouesque />
        </Mobile>
        <Indent top={vertical} />
        <NewReleases content={newReleases} />
        <Indent top={vertical} />
        <Desktop>
          <SuperFooter />
        </Desktop>
        <Comparison content={comparisons} />
        <Indent top={vertical} />
        <Mobile>
          <Listing2 />
        </Mobile>
      </AdWrapper>
    </Page>
  );
}

CatalogPage.propTypes = {
  brands: PropTypes.array,
  newReleases: PropTypes.array,
  releasesDataAndMeta: PropTypes.object,
  releasesCount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  comparisons: PropTypes.array,
  isMobile: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
    }),
  }).isRequired,
};

const dataProvider = resolve({
  brands: brandsFetcher(),
  comparisons: comparisonsFetcher(),
  newReleases: newReleasesFetcher(),
  releasesDataAndMeta: releasesFetcher(),
  releasesCount: releasesCountFetcher(),
});

export default withPageHocs(dataProvider)(withBreakpoint(CatalogPage));
