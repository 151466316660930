import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import ButtonWithIcon from 'core/components/ButtonWithIcon';

import cardServiceHOC from 'site/components/cardService';
import ArrowRightSm from 'site/icons/ArrowRightSm';

import styles from './index.styl';

function DealerInfoSberAuto(props) {
  const {
    isMobile,
    theme,
    externalUrl,
  } = props;


  return (
    <div className={styles.topRow}>
      <style jsx>{`
        .${styles.caption}
          font 400 13px/15px ${theme.fonts.text}
          color ${theme.colors.greyText}
        .${styles.dealerName}
          font 700 16px/21px ${theme.fonts.text}
          :global(.mobile) &
            font 700 13px/16px ${theme.fonts.text}
      `}</style>

      <div className={styles.content}>
        <div className={styles.caption}>Объявление от паpтнёра</div>
        <div className={styles.dealerName}>СберАвто</div>
      </div>
      <Link type='secondary' to={externalUrl}>
        <ButtonWithIcon
          className={styles.button}
          target='_blank'
          type='green'
          minWidth={isMobile ? '100%' : 0}
          icon={ArrowRightSm}
          iconPosition={isMobile && 'right'}
        >
          <span className={styles.buttonText}>
            Подробнее на сайте партнёра
          </span>
        </ButtonWithIcon>
      </Link>
    </div>
  );
}

DealerInfoSberAuto.propTypes = {
  isMobile: PropTypes.bool,
  externalUrl: PropTypes.string,
  theme: PropTypes.object,
};

export default cardServiceHOC(DealerInfoSberAuto);
