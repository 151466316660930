import PropTypes from 'prop-types';
import cx from 'classnames';

import { Mobile } from 'core/components/breakpoint';
import Link from 'core/components/Link';
import H3 from 'core/components/H3';
import styles from './index.styl';


import ArrowRightSm from 'site/icons/ArrowRightSm';

function Block(props) {
  return (
    <div className={styles.block}>
      <style jsx>{`
        .img
          width ${props.widthImg}px
          height ${props.heightImg}px
        `}</style>
      <div className={styles.imgBlock}>
        <div className={styles.circle}>
          <img src={props.img} className={cx(styles.img, 'img')} />
        </div>
        {props.rectangle && <div className={styles.rectangle} />}
      </div>
      <div className={styles.text}>
        <H3 is='div'>
          <span className={styles.title}>{props.title}</span>
        </H3>
        <div className={styles.info}>{props.text}</div>
        <Link to={props.url} className={styles.link}
          type='secondary'
        >
          {props.link}
        </Link>
      </div>
      <Mobile>
        <Link to={props.url} className={styles.arrowLink}>
          <ArrowRightSm className={styles.arrow} />
        </Link>
      </Mobile>
    </div>
  );
}
Block.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  img: PropTypes.object,
  widthImg: PropTypes.number,
  heightImg: PropTypes.number,
  rectangle: PropTypes.string,
  url: PropTypes.string,
};

export default Block;
