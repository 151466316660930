import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import renameThemeProp from 'site/components/renameThemeProp';

import Triangle from 'site/icons/Triangle';

import styles from './index.styl';

/* eslint-disable react/prop-types */
const DropdownIndicator = (props) => {
  const {
    hasValue,
    selectProps: {
      showDropdownWithValue,
    },
  } = props;

  if (hasValue && !showDropdownWithValue) return null;

  const {
    theme: {
      controls: {
        chip: chipAtoms,
      },
    },
  } = props;

  return (
    <div className={cx(styles.dropdownIndicatorWrapper, showDropdownWithValue && '_showDropdownWithValue')}>
      <style jsx>{`
        .${styles.dropdownIndicatorWrapper}
          background ${chipAtoms.idle.indicatorBackground}
          :global([id*="$"])
            fill ${chipAtoms.idle.indicatorColor}
          :global(._disabled) &
            :global([id*="$"])
              fill ${chipAtoms.disabled.indicatorColor}
          :global(._active) &
            :global([id*="$"])
              fill ${chipAtoms.active.indicatorColor}
          &._showDropdownWithValue
            background ${chipAtoms.hasValue.backgroundColor}
            :global([id*="$"])
              fill ${chipAtoms.hasValue.color}
        `}</style>
      <Triangle {...props.innerProps} />
    </div>
  );
};

DropdownIndicator.propTypes = {
  theme: PropTypes.object,
};

export default renameThemeProp(withTheme(DropdownIndicator));

