import PropTypes from 'prop-types';
import { withBreakpoint } from 'core/components/breakpoint';

import SideColumn from 'core/components/SideColumn';
import { Block, Section } from 'core/components/Grid';

import { SIDE_INDENT } from 'site/constants';

import { Indent } from 'site/components/Wrappers';

function LayoutWithSideIndent({ isMobile, children }) {
  if (isMobile) {
    return children;
  }

  return (
    <Indent left={SIDE_INDENT} right={SIDE_INDENT}>
      <Section>
        <Block>
          {children}
        </Block>
        <SideColumn />
      </Section>
    </Indent>
  );
}

LayoutWithSideIndent.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(LayoutWithSideIndent);
