import { Fragment } from 'react';
import PropTypes from 'prop-types';
import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Modal from 'site/components/Modal';

import styles from './index.styl';

function FormModal(props) {
  const {
    children,
    theme: {
      layout: layoutAtoms,
    },
    isDesktop,
  } = props;

  return (
    <Fragment>
      <Modal isOpen>
        <div className={styles.content} style={{
          ...isDesktop && { maxWidth: layoutAtoms.maxWidth, minWidth: layoutAtoms.minWidth },
        }}
        >
          {children}
        </div>
      </Modal>
      <div style={{ minHeight: 195 }} />
    </Fragment>
  );
}

FormModal.propTypes = {
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(FormModal));
