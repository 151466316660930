import PropTypes from 'prop-types';

import cx from 'classnames';

import withTheme from 'core/components/theme';

import renameThemeProp from 'site/components/renameThemeProp';

import Close from 'site/icons/Close.svg';

import styles from './index.styl';

function ClearIndicator(props) {
  const {
    theme: {
      controls: {
        chip: chipAtoms,
      },
    },
    innerProps, // eslint-disable-line
  } = props;

  return (
    <div
      {...innerProps}
      className={cx(
        'clearIndicator',
        styles.clearIndicator,
      )}
    >
      <style jsx>{`
        .clearIndicator
          background-color ${chipAtoms.hasValue.indicatorBackground}
          :global([id*="$"])
            fill ${chipAtoms.hasValue.indicatorColor}
          &:hover
            background-color ${chipAtoms.hasValueHover.indicatorBackground}
            :global([id*="$"])
              fill ${chipAtoms.hasValueHover.indicatorColor}
      `}</style>
      <Close width={8} height={8} />
    </div>
  );
}

ClearIndicator.propTypes = {
  hasValue: PropTypes.bool,
  theme: PropTypes.object,
};

export default renameThemeProp(withTheme(ClearIndicator));
