import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'core/libs/lodash';

import SearchInput from 'core/components/SearchInput';


function Search({ onSearch, toggleSpinner }) {
  const [searchValue, setSearchValue] = useState('');

  const onSearchChanged = useMemo(() =>
    debounce(value => {
      onSearch(value);
      toggleSpinner(false);
    }, 1000),
  [onSearch, toggleSpinner]
  );

  const onSearchChange = useCallback(e => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);
    toggleSpinner(true);
    onSearchChanged(newSearchValue);
  }, [onSearchChanged, toggleSpinner]);

  return (
    <SearchInput
      placeholder='Поиск по названию'
      size='small'
      onInput={onSearchChange}
      value={searchValue}
    />
  );
}

Search.propTypes = {
  onSearch: PropTypes.func,
  toggleSpinner: PropTypes.func,
};

export default Search;
