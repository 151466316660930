import PropTypes from 'prop-types';

import { incut as coreIncut } from 'core/components/TopicContent/blocks';

import { LimeGradientBg } from 'site/components/Wrappers';

import styles from './index.styl';

export default function Incut(props) {
  const {
    block,
    parentProps,
    ctx,
  } = props;

  return (
    <div className={styles.incut} >
      <LimeGradientBg className={styles.gradient} />
      {coreIncut(block, parentProps, ctx)}
    </div>
  );
}

Incut.propTypes = {
  /** Данные виджета из API */
  block: PropTypes.object,
  /** @ignore */
  parentProps: PropTypes.object,
  /** Данные из контекста топика */
  ctx: PropTypes.object,
};
