import PropTypes from 'prop-types';
import { Fragment } from 'react';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import TopicHeaderTexts from 'core/components/TopicHeaderTexts';
import TopicTopline from 'core/components/TopicTopline';

import SocialShare from 'site/components/SocialShare';
import { Indent, LimeGradientBg } from 'site/components/Wrappers';

import { isLegacyPost } from 'site/utils';

import TopicCoverMedia from './covers';
import Caption from './captions';
import { JOURNAL_URL } from 'site/constants';

function TopicHeader(props) {
  const {
    isMobile,
    content,
    isGameCompare,
  } = props;

  const {
    attributes: {
      link,
      topic_type: topicType,
      published_at: publishedAt,
      is_comments_disabled: isCommentsDisabled,
    },
  } = content;

  const coverStyles = isLegacyPost(publishedAt) ? {
    padding: '0 14px',
    background: 'none',
  } : { padding: '14px 10px 10px' };

  return (
    <Fragment>
      <TopicTopline path={JOURNAL_URL} />
      <Indent bottom={isMobile ? 16 : 20} />

      {topicType !== 'gallery' && (
        <TopicHeaderTexts />
      )}

      {!isGameCompare &&
        <Fragment>
          <Desktop>
            <Indent
              bottom={15}
              left={-10}
              right={-10}
              dataQa='topic-content'
            >
              <LimeGradientBg style={coverStyles}>
                <Indent top={isMobile ? 0 : 5} bottom={15}>
                  <SocialShare topicLink={link} isCommentsDisabled={isCommentsDisabled} />
                </Indent>
                <TopicCoverMedia content={content} />
              </LimeGradientBg>
            </Indent>
          </Desktop>

          <Mobile>
            <TopicCoverMedia content={content} />
            {topicType !== 'gallery' && (
              <Indent top={isMobile ? 10 : 15}>
                <Caption content={content} />
              </Indent>
            )}
            <Indent bottom={20} />
            <SocialShare topicLink={link} isCommentsDisabled={isCommentsDisabled} />
            <Indent bottom={24} />
          </Mobile>
        </Fragment>
      }
    </Fragment>
  );
}

TopicHeader.propTypes = {
  type: PropTypes.string,
  isMobile: PropTypes.bool,
  content: modelPropTypes(topicAttributes).isRequired,
  isGameCompare: PropTypes.bool,
};

export default withBreakpoint(TopicHeader);
