import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import {
  authorFetcher,
  topicsFetcher,
} from 'core/fetchers/authorPage';

import { filterRequiredParams } from 'core/utils/api';

import AuthorPage from 'core/components/AuthorPage';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';
import { withBreakpoint } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import PageLayout from 'site/components/PageLayout';
import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import {
  Native1asCard3,
  Native2asCard3,
} from 'site/components/Ads/desktop';

import Card1, { Card1Type4 } from 'site/cards/Card1';
import Card3 from 'site/cards/Card3';


function Author({ author, topics, isMobile }) {
  const CardForFeed = isMobile ? Card1Type4 : Card3;

  return (
    <PageLayout>
      <AuthorPage
        author={author}
        topics={topics}
        limit={RUBRICS_PAGE_LIMIT}
        card={CardForFeed}
        cardsReplacement={{
          2: replaceCardWithAd(Native1asCard3, !isMobile),
          6: replaceCardWithAd(Native2asCard3, !isMobile),
        }}
      />
    </PageLayout>
  );
}

Author.propTypes = {
  topics: PropTypes.object,
  author: PropTypes.object,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  author: authorFetcher(),
  topics: topicsFetcher({
    limit: RUBRICS_PAGE_LIMIT,
    include: 'image',
    fields: filterRequiredParams([Card1, Card3], 'fields'),
  }),
});

export default withPageHocs(dataProvider)(withBreakpoint(Author));
