import resolveRelationships from 'core/utils/relationships';

const requiredPayloadImports = ['class'];
const relationships = resolveRelationships(requiredPayloadImports, {}, {
  class: {},
});

export default function getReleasesClasses(releases) {
  const releasesClasses = releases.reduce((groups, release) => {
    const {
      class: {
        name,
        sort,
      },
    } = relationships(release);

    if (groups[name]) {
      groups[name].releases.push(release);
    } else {
      groups[name] = {
        releases: [release],
        sort,
      };
    }

    return groups;
  }, {});

  return releasesClasses;
}
