import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';
import {
  buildSearchRequestParams,
  isMotoPath,
} from 'site/utils';


export default resolve({
  model: ({ servicesApi, renderError, match, location }) => {
    let modelsAPI;

    const isLcvPage = Boolean(match.params.lcv);
    const isMoto = isMotoPath(location.pathname);
    const vehiclePrefix = isMoto ? 'moto' : 'car';
    const requestParams = {
      'filter[brand]': match.params.brand,
      'filter[model]': match.params.model,
      include: `${vehiclePrefix}_brand`,
      [`attributes[${vehiclePrefix}_brand]`]: 'base,image',
      [`attributes[${vehiclePrefix}_model]`]: 'base,seo,text',
      [`relations[${vehiclePrefix}_model]`]: 'brand',
      limit: 1,
      ...(isLcvPage && { 'filter[car_type]': 'lcv' }),
    };

    if (isMoto) {
      modelsAPI = servicesApi.getMotoModels;
    } else {
      modelsAPI = servicesApi.getModels;
    }

    return modelsAPI(requestParams)
      .then(modelsData => denormalizeData(modelsData)[0])
      .then(rejectEmptyResult)
      .catch(renderError);
  },

  modelSeo: ({ servicesApi, consoleError, match, location }) => {
    const isMoto = isMotoPath(location.pathname);

    const seoAPI = isMoto ? servicesApi.getMotoModelSeo : servicesApi.getCarModelSeo;

    return seoAPI({
      'filter[brand]': match.params.brand,
      'filter[model]': match.params.model,
    })
      .then(denormalizeData)
      .catch(consoleError('modelSeo', {}));
  },

  releases: ({ servicesApi, consoleError, match, location }) => {
    let releasesAPI;

    const isMoto = isMotoPath(location.pathname);
    const vehiclePrefix = isMoto ? 'moto' : 'car';
    const isChoiceOfGenerations = `${isMoto ? 'year' : 'generation'}`;
    const requestParams = {
      [`attributes[${vehiclePrefix}_release]`]: 'base,image,stats',
      [`relations[${vehiclePrefix}_release]`]: isChoiceOfGenerations,
      'include': `${vehiclePrefix}_generation,${vehiclePrefix}_year`,
      'filter[brand]': match.params.brand,
      'filter[model]': match.params.model,
      'filter[usages]': 'catalog,archive',
      ...buildSearchRequestParams(location.search),
    };

    if (isMoto) {
      releasesAPI = servicesApi.getMotoReleases;
    } else {
      releasesAPI = servicesApi.getReleases;
    }

    return releasesAPI({
      ...requestParams,
    })
      .then(denormalizeData)
      .catch(consoleError('releases', []));
  },

  generations: ({ servicesApi, consoleError, match, location }) => {
    if (isMotoPath(location.pathname)) return [];

    return servicesApi
      .getGenerations({
        'filter[brand]': match.params.brand,
        'filter[model]': match.params.model,
        sort: '-manual',
      })
      .then(denormalizeData)
      .catch(consoleError('generations', []));
  },

  years: ({ servicesApi, consoleError, match, location }) => {
    if (!isMotoPath(location.pathname)) return [];

    return servicesApi
      .getMotoYears({
        'filter[brand]': match.params.brand,
      })
      .then(denormalizeData)
      .catch(consoleError('years', []));
  },
});
