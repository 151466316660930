import PropTypes from 'prop-types';
import Select from 'react-select';

import * as skin from './skin'; // eslint-disable-line import/no-namespace

const DEFAULT_MENU_WIDTH = 290;

function Chip(props) {
  const {
    menuWidth,
    ...otherProps
  } = props;

  const customStyles = {
    ...(otherProps.hideTitleFromMenu && {
      menuList: (innerStyles) => ({
        ...innerStyles,
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: 4,
      }),
    }),
  };

  return (
    <Select
      {...otherProps}
      isSearchable={false}
      styles={customStyles}
      components={{ ...skin }}
      hideSelectedOptions={false}
      tabSelectsValue={false}
      width={menuWidth || DEFAULT_MENU_WIDTH}
      blurInputOnSelect={!otherProps.isMulti}
    />
  );
}

Chip.propTypes = {
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  loadingMessage: PropTypes.func,

  /** Скрывает заголовок фильтра в dropdown. */
  hideTitleFromMenu: PropTypes.bool,

  /** Ширина выпадающего меню. */
  menuWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Свойство включает/отключает мультиселект.
   * По умолчанию включено.
   */
  isMulti: PropTypes.bool,

  /**
   * Свойство включает/отключает сброс по крестику.
   * По умолчанию включено.
   */
  isClearable: PropTypes.bool,

  /**
   * Можно задать максимальную ширину содержимого,
   * при котором оставшийся текс уйдет в text-overflow: ellipsis.
   * Для всего контрола, добавится нативный аттрибут title={selectValue} с полным текстом.
   */
  overflowSelectValue: PropTypes.number,

  /**
   * Свойство включает/отключает иконку стрлочку, даже когда выбранно значение.
   * По умолчанию выключено.
   */
  showDropdownWithValue: PropTypes.bool,
  dataQa: PropTypes.string,
};

Chip.defaultProps = {
  loadingMessage: () => 'Загрузка...',
  noOptionsMessage: () => 'Ничего не найдено :(',
  closeMenuOnSelect: false,
  isMulti: true,
  isClearable: true,
  showDropdownWithValue: false,
};

export default Chip;
