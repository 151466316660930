import { useEffect, useState, useCallback, useContext } from 'react';

import { hasModificationInStorage } from './';

import { CompareContext } from 'site/components/CompareProvider';

/**
 * Хук-хелпер для манипулирования состоянием выбранных модификациях в localStorage и compareContext.
 *
 * Следит за изменением uudis в compareContext и переключает состояние checked.
 * С помощью метода toggleChecked можно обновить store и переключить состояние checked.
 *
 * Используется в компонентах:
 * site/components/ButtonCompare/index.js
 */
export function useCompareCheck(id, type) {
  const { carUuids, motoUuids, updateUuids } = useContext(CompareContext);
  const typeVehicleUuids = type === 'moto_release' ? motoUuids : carUuids;
  const [checked, toggle] = useState(false);

  const toggleChecked = useCallback(() => {
    updateUuids(id);
    toggle(!checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toggle(hasModificationInStorage(id, type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeVehicleUuids]);

  return [checked, toggleChecked];
}
