
import PropTypes from 'prop-types';

import { aboutFetcher } from 'core/fetchers/about';

import resolve from 'core/resolver/resolve';

import AboutCore from 'core/components/About';

import withPageHocs from 'core/components/withPageHocs';


function AboutPage({ content }) {
  return <AboutCore content={content} />;
}

AboutPage.propTypes = {
  content: PropTypes.object,
};

const dataProvider = resolve('content', aboutFetcher());

export default withPageHocs(dataProvider)(AboutPage);
