import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { compose } from 'core/libs/recompose';
import skip from 'core/resolver/skip';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Dropdown from 'core/components/Dropdown';
import Button from 'core/components/Button';

import withTheme from 'core/components/theme';

import Triangle from 'site/icons/Triangle';
import Close from 'site/icons/Close';

import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';

import InputRange from 'site/formControls/InputRange';

import styles from './index.styl';

const PREPOSITION_MIN = 'с';
const PREPOSITION_MAX = 'по';
const APPLY_BTN_ACTION = 'apply';
const SKIP_BTN_ACTION = 'skip';
const DEFAULT_DROPDOWN_PROPS = {
  contentBorderRadius: '5px',
  contentMargin: '5px 0 0',
};


class ChipRange extends PureComponent {
  static propTypes = {
    /** Атрибут `name` инпута. */
    name: PropTypes.string.isRequired,

    /** Текст, который будет отображаться, когда выбраны дефолтные значения. */
    placeholder: PropTypes.string.isRequired,

    /** Текст поля в дропдауне */
    title: PropTypes.string.isRequired,

    /**
     * Параметры компонента Dropdown.
     * Будут смерджены с объектом из ChipRange,
     * содержащим стандартные параметры.
     */
    dropdownProps: PropTypes.object,

    /** Минимальное значение диапазона. */
    min: PropTypes.number.isRequired,

    /** Максимальное значение диапазона. */
    max: PropTypes.number.isRequired,

    /** Установленные значения. */
    value: PropTypes.exact({
      min: PropTypes.number,
      max: PropTypes.number,
    }).isRequired,

    /** Единица измерения значений. */
    unit: PropTypes.string,

    /**
     * Этот обработчик будет вызван при нажатии "Применить".
     * Ему передаётся параметр с текущими значениями:
     * @param {Object} value - объект со значениями
     * @param {number} value.min - минимальное
     * @param {number} value.max - максимальное
     */
    onChange: PropTypes.func,

    /** Делает поле неактивным. */
    isDisabled: PropTypes.bool,

    /** @ignore */
    theme: PropTypes.object,

    /** @ignore */
    isMobile: PropTypes.bool,

    /** @ignore */
    dataQa: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => null,
    dropdownProps: DEFAULT_DROPDOWN_PROPS,
  };

  static getDerivedStateFromProps({ value }, { prevValue }) {
    if (value !== prevValue) {
      return {
        innerValue: value,
        prevValue: value,
      };
    }
    return null;
  }

  state = {
    innerValue: this.props.value,
    prevValue: this.props.value,
  };

  /**
   * Используем это поле для того, чтобы определить,
   * по какому действию закрылся дропдаун.
   */
  lastAction = null;

  updateLastAction = actionName => {
    this.lastAction = actionName;
  };

  handleDropdownClose = () => {
    switch (this.lastAction) {
      case APPLY_BTN_ACTION:
        this.props.onChange(this.state.innerValue, { name: this.props.name });
        break;

      case SKIP_BTN_ACTION:
        this.props.onChange({ min: this.props.min, max: this.props.max }, { name: this.props.name });
        break;

      default:
        this.setState({ innerValue: this.props.value });
    }
    this.updateLastAction(null);
  };

  handleApplyButtonClick = () => {
    this.updateLastAction(APPLY_BTN_ACTION);
    this.closeDropdown();
  };

  closeDropdown = () => {
    this.dropdown && this.dropdown.close();
  };

  setInnerValue = newInnerValue => this.setState({ innerValue: newInnerValue });

  setDropdownInstance = instance => {
    this.dropdown = instance;
  };

  get anchor() {
    const {
      min,
      max,
      value,
      placeholder,
      theme: {
        controls: {
          chip: chipAtoms,
        },
      },
    } = this.props;

    const {
      min: innerMin,
      max: innerMax,
    } = this.state.innerValue;

    const isLowestMinValue = !value || value.min === min;
    const isHighestMaxValue = !value || value.max === max;
    const isDefaultRange = isLowestMinValue && isHighestMaxValue;
    const innerValuesAreDefault = innerMin === min && innerMax === max;
    const anchorText =
      (isLowestMinValue ? '' : `${PREPOSITION_MIN} ${value.min}`)
      + (isHighestMaxValue ? '' : ` ${PREPOSITION_MAX} ${value.max}`)
      + (isDefaultRange ? placeholder : '');

    const Icon = isDefaultRange ? Triangle : Close;

    return (
      <div className={cx(
        styles.anchorWrapper,
        !isDefaultRange && '_hasValue'
      )}
      >
        <style jsx>{`
          .${styles.anchorWrapper}
            color ${chipAtoms.idle.color}
            background-color ${chipAtoms.idle.backgroundColor}
            border-color ${chipAtoms.idle.borderColor}
            &:hover
              color ${chipAtoms.hover.color}
              background-color ${chipAtoms.hover.backgroundColor}
              border-color ${chipAtoms.hover.borderColor}
            &._hasValue
              color ${chipAtoms.hasValue.color}
              background-color ${chipAtoms.hasValue.backgroundColor}
              border-color ${chipAtoms.hasValue.borderColor}
            &._hasValue:hover
              color ${chipAtoms.hasValueHover.color}
              background-color ${chipAtoms.hasValueHover.backgroundColor}
              border-color ${chipAtoms.hasValueHover.borderColor}
          .${styles.icon}
            background ${chipAtoms.idle.indicatorBackground}
            :global([id*="$"])
              fill ${chipAtoms.idle.indicatorColor}
            &:hover
              background ${chipAtoms.hover.indicatorBackground}
              :global([id*="$"])
                fill ${chipAtoms.hover.indicatorColor}
            ._hasValue &
              background ${chipAtoms.hasValue.indicatorBackground}
              :global([id*="$"])
                fill ${chipAtoms.hasValue.indicatorColor}
            ._hasValue &:hover
              background ${chipAtoms.hasValueHover.indicatorBackground}
              :global([id*="$"])
                fill ${chipAtoms.hasValueHover.indicatorColor}
        `}</style>
        <div className={styles.anchorText}>
          {anchorText}
        </div>
        <div
          className={cx(
            styles.icon,
            isDefaultRange && styles._fixPosition
          )}
          onClick={e => {
            if (innerValuesAreDefault) return;

            e.stopPropagation();
            this.updateLastAction(SKIP_BTN_ACTION);
            this.closeDropdown();
          }}
        >
          <Icon width={8} height={8} />
        </div>
      </div>
    );
  }

  render() {
    const {
      name,
      min,
      max,
      unit,
      isDisabled,
      dropdownProps,
      isMobile,
      title,
      dataQa,
    } = this.props;

    const { innerValue } = this.state;
    const mergedDropdownProps = {
      ...DEFAULT_DROPDOWN_PROPS,
      ...dropdownProps,
    };

    return (
      <ThemeSwitchWithColor tone='light'>
        <Dropdown
          dataQa={dataQa}
          {...mergedDropdownProps}
          {...isMobile && { contentPadding: '9px' }}
          className={cx(
            styles.dropdown,
            isDisabled && styles._disabled,
          )}
          getInstance={this.setDropdownInstance}
          showIcon={false}
          anchor={this.anchor}
          onClose={this.handleDropdownClose}
        >
          <div className={styles.inputsContainer} data-qa='dropdown-inputs-container'>
            <InputRange
              title={title}
              input={{
                name,
                value: innerValue,
                onChange: this.setInnerValue,
              }}
              min={min}
              max={max}
              prepositionMin={PREPOSITION_MIN}
              prepositionMax={PREPOSITION_MAX}
              unit={unit}
              formatNumberType={2}
            />
            <div className={styles.buttonsWrapper}>
              <Button
                onClick={this.closeDropdown}
                type='ghostActive1'
              >
                <span className={styles.buttonText}>Отменить</span>
              </Button>
              <Button onClick={this.handleApplyButtonClick}>
                <span className={styles.buttonText}>Применить</span>
              </Button>
            </div>
          </div>
        </Dropdown>
      </ThemeSwitchWithColor>
    );
  }
}

const ChipRangeWithHOCs = compose(
  skip,
  withTheme,
  withBreakpoint
)(ChipRange);

ChipRangeWithHOCs.displayName = 'ChipRange';

export default ChipRangeWithHOCs;
export { ChipRange as StorybookComponent };
