import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';

import withTheme from 'core/components/theme';

import styles from './comparison.styl';

const listOfValues = [
  { slug: 'engine', title: 'Тип двигателя' },
  { slug: 'power', title: 'Мощность', suffix: 'л.с.' },
  { slug: 'fuel_consumption_mixed', title: 'Расход топлива', suffix: 'л/100 км' },
  { slug: 'transmission', title: 'Коробка передач' },
  { slug: 'trunk_volume', title: 'Объем багажника', suffix: 'л' },
  { slug: 'drive', title: 'Привод' },
  { slug: 'acceleration', title: 'Время разгона 0 - 100 км/ч', suffix: 'c' },
  { slug: 'clearance', title: 'Дорожный просвет', suffix: 'мм' },
];

const listOfMotoValues = [
  { slug: 'volume', title: 'Рабочий объем', suffix: 'см³' },
  { slug: 'power', title: 'Мощность', suffix: 'л.с.' },
  { slug: 'cooling', title: 'Система охлаждения' },
  { slug: 'drive', title: 'Главная передача' },
  { slug: 'engine', title: 'Конфигурация двигателя' },
  { slug: 'cylinders', title: 'Количество цилиндров' },
  { slug: 'takts', title: 'Число тактов' },
];

function getValues(data, isMoto) {
  const features = isMoto ? listOfMotoValues : listOfValues;
  return features.reduce((obj, { slug }) => {
    if (slug === 'power' && !isMoto) {
      obj[slug] = get(data, slug);
    } else {
      obj[slug] = get(data, ['specifications_primary', slug, 'value']);
    }

    return obj;
  }, {});
}

function Comparison({ content, theme }) {
  const [
    { attributes: attributes1, type },
    { attributes: attributes2 },
  ] = content.items;
  const isMoto = type === 'moto_release';
  const values1 = getValues(attributes1, isMoto);
  const values2 = getValues(attributes2, isMoto);
  const features = isMoto ? listOfMotoValues : listOfValues;

  return (
    <div className={styles.comparison}>
      <style jsx>{`
        .${styles.item}
          font 14px/16px ${theme.fonts.text}
          color ${theme.colors.primary500}

          &:before
            border-bottom 1px dashed ${theme.colors.divider}

          span
            background ${theme.colors.content}
      `}</style>
      {features.map(({ slug, title, suffix = '' }) => {
        return (
          <div key={slug} className={styles.item}>
            <span>{values1[slug] ? `${values1[slug]}  ${suffix}` : 'н.д.'}</span>
            <span>{title}</span>
            <span>{values2[slug] ? `${values2[slug]}  ${suffix}` : 'н.д.'}</span>
          </div>
        );
      })}
    </div>
  );
}

Comparison.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(Comparison);
