import { Component } from 'react';
import PropTypes from 'prop-types';

import { get } from 'core/libs/lodash';
import queryString from 'core/libs/query-string';

import { createPath } from 'core/utils/url-helper';

import {
  buildPathnameFromValues,
  buildSearchParamsFromValues,
  resolveDependenciesFields,
  getFiltersCount,
} from 'site/utils/forms';

import {
  excludeObjectValues,
} from 'site/utils';

import { FULL_FILTER_HASH } from 'site/constants';


export default class BaseForm extends Component {
  componentDidUpdate(prevProps) {
    // при переходе по ссылке нужно снова взять текущие значения из location
    if (prevProps.location.key !== this.props.location.key
      && !get(this.props, 'location.state.formNavigation')
    ) {
      this.setState(this.getFormStateFromLocation({ ...this.state })); // eslint-disable-line
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escapeHandler);
  }

  handleControlChange = (value, context) => {
    const name = context.name;
    let lcv = {};

    if (name === 'model' && value?.attributes?.is_lcv) {
      lcv = { isLcv: true };
    }

    const updatedFields = {
      [name]: value,
      ...lcv,
      ...resolveDependenciesFields(name, this.dependencies),
    };

    this.setState(updatedFields, this.handleFormChange);
  };

  getLocationFromState = state => {
    const formState = state || this.state;

    const pathname = buildPathnameFromValues(
      formState,
      this.pathnameFields,
      this.basePath,
      this.emptyPath,
      { geo: 'attributes.url' }
    );

    const filteredSearchValues = excludeObjectValues(formState, this.pathnameFields.concat(this.excludedFields));
    const search = queryString.stringify(buildSearchParamsFromValues(filteredSearchValues, this.ranges));

    return { pathname, search };
  };

  navigateToNewUrl = (e, forcePushHistory) => {
    const newLocation = this.getLocationFromState();

    /**
     * Сравниваем путь, который был до открытия полного фильтра с тем,
     * который будет после применения. Если они одинаковы, то просто
     * закрываем форму без хистори пуш.
     * Это нужно для того, чтобы со страницы релиза открыть форму и при
     * закрытии вернуться на ту же страницу релиза, а не на страницу
     * поколения, т.к релизов у нас в форме нет.
     *
     * TODO: Все это похоже на плохой костыль из-за того,что у нас нет
     * релизов в форме, а форма в релизах есть. Нужно продумать добавление
     * контрола выбора релизов и эти костыли убрать
     */
    if (!forcePushHistory && this.isFullFormOpened && this.stateToReset) {
      const prevLocation = this.getLocationFromState(this.stateToReset);

      if (createPath(prevLocation) === createPath(newLocation)) {
        this.toggleFullForm();
        return;
      }
    }

    this.props.history.push(
      newLocation,
      {
        infinity: true, // передаем это значение для того, чтобы не перерендеривалась реклама
        formNavigation: true, // это устанавливаем чтобы отличить навигацию в форме от навигации по ссылкам
      });
  };

  toggleFullForm = () => {
    const {
      location,
      history,
    } = this.props;

    if (!this.isFullFormOpened) {
      this.stateToReset = { ...this.state };
      history.replace({ ...location, hash: FULL_FILTER_HASH });
      document.addEventListener('keydown', this.escapeHandler);
    } else {
      history.replace({ ...location, hash: '' });
      document.removeEventListener('keydown', this.escapeHandler);
    }
  };

  handleFormChange = () => {
    if (!this.isFullFormOpened) {
      this.navigateToNewUrl();
    }
  };

  resetAllForm = () => {
    this.props.history.push({
      pathname: this.emptyPath || this.basePath,
      hash: FULL_FILTER_HASH,
    });
  };

  escapeHandler = event => {
    if (event.key === 'Escape') {
      this.navigateToNewUrl();
    }
  };

  get allFiltersCount() {
    return getFiltersCount(this.state, this.ranges);
  }

  get fullFiltersCount() {
    return getFiltersCount(this.state, this.ranges, this.fullFilterFields);
  }

  get isFullFormOpened() {
    return this.props.location.hash === FULL_FILTER_HASH;
  }
}

BaseForm.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};
