import cx from 'classnames';
import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import resolveRelationships from 'core/utils/relationships';

import relationshipsPropTypes from 'site/utils/prop-types/relationships';
import modelPropTypes, {
  carNoveltyAttributes,
  carReleaseAttributes,
} from 'site/utils/prop-types/model';
import getThumbnail from 'site/utils/get-thumbnail';

import cardServiceHOC from 'site/components/cardService';

import styles from './index.styl';

const requiredPayloadImports = ['release'];

const relationships = resolveRelationships(requiredPayloadImports, {}, {});

function CardServiceNewRelease3(props) {
  const {
    theme,
    content: {
      attributes: {
        name,
      },
    },
    content,
  } = props;

  if (!content) return null;

  const {
    release: {
      url,
      short_name: shortName,
      image,
    },
  } = relationships(content);

  const cover = getThumbnail(image);

  return (
    <div className='cardServiceNewRelease3'>
      <style jsx>{`
        .name
          color ${theme.colors.primary}
          font 500 16px/20px ${theme.fonts.text}
          transition color ${theme.animations.hover}

        .description
          color ${theme.colors.primary500}
          font 14px/16px ${theme.fonts.text}
          transition color ${theme.animations.hover}

        .cardServiceNewRelease3
          border-bottom 1px solid ${theme.colors.primary100}
          &:hover
            .name
              color ${theme.colors.active1}
      `}</style>
      <Link
        to={url}
        type='secondary'
        className={styles.link}
      >
        <div className={cx('content', styles.content)}>
          <div className={cx(styles.name, 'name')}>
            {shortName}
          </div>
          <div className={cx(styles.description, 'description')}>
            <MarkdownWrapper children={name} inline />
          </div>
        </div>
        {cover &&
          <div className={styles.image}>
            <Image src={cover} width={110} />
          </div>
        }
      </Link>
    </div>
  );
}

CardServiceNewRelease3.propTypes = {
  /** Модель данных для карточки */
  content: modelPropTypes(
    carNoveltyAttributes,
    relationshipsPropTypes({
      release: modelPropTypes(carReleaseAttributes),
    })
  ),
  /** @ignore */
  theme: PropTypes.object,
};

const CardWithHOCs = cardServiceHOC(CardServiceNewRelease3);
CardWithHOCs.displayName = 'CardServiceNewRelease3';

export default CardWithHOCs;
export { CardServiceNewRelease3 as StorybookComponent };
