import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from 'core/components/Button';

import styles from './index.styl';

export function ViewToggleButton({ clickHandler, text, hidden }) {
  return (
    <div className={cx(styles.viewToggle, hidden && styles._hidden)}>
      <Button onClick={clickHandler}>
        <span className={styles.buttonContent}>{text}</span>
      </Button>
    </div>
  );
}

ViewToggleButton.propTypes = {
  text: PropTypes.string,
  clickHandler: PropTypes.func,
  hidden: PropTypes.bool,
};

export default function ViewToggle(props) {
  const [readyToRender, setReadyToRender] = useState(false);
  useEffect(() => {
    setReadyToRender(true);
  }, []);

  return process.env.BROWSER_RUNTIME && readyToRender
    ? createPortal((
      <ViewToggleButton {...props} />
    ), document.body)
    : null;
}
