import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import { topicDateFormat } from 'core/utils/dates';

import Link from 'core/components/Link';
import Lightning from 'core/components/GameCompare/Lightning';

import styles from './index.styl';


const requiredPayloadFields = [
  'link',
  'headline',
  'listHeadline',
  'published_at',
];

const requiredPayloadImports = [
  'content',
];
const relationships = resolveRelationships(requiredPayloadImports, {}, {
  content: {},
});


function CompareCard(props) {
  const {
    content,
    theme: {
      colors,
      fonts,
    },
    isMobile,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    listHeadline,
    published_at: publishedAt,
  } = content.attributes;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  if (!widgets || widgets?.length === 0) return null;

  const {
    first_announce: firstAnnounce,
  } = widgets[0]?.attributes || {};

  return (
    <div className={styles.card}>
      <style jsx>{`
        .${styles.body}
          background ${colors.primary}

        .${styles.headline}
          color ${colors.content}
          font 900 24px/35px ${fonts.text}
          :global(.mobile) &
            font 700 18px/22px ${fonts.text}
          
        .${styles.announce}
          color ${colors.content}
          font 300 18px/26px ${fonts.text}
          :global(.mobile) &
            font 300 14px/17px ${fonts.text}

        .${styles.date}
          color ${colors.hint}
          font 14px/20px ${fonts.text}
      `}</style>
      <Link
        to={link}
        type='primary'
        className={styles.wrapper}
      >
        <Lightning
          imageMaxWidth={isMobile ? 546 : 392}
          height={265}
          widget={widgets[0]}
          progressType={1}
          progress={0}
          lightningColor={colors.content}
        />
        <div className={styles.body}>
          {(listHeadline || headline) && (
            <div className={styles.headline}>
              {listHeadline || headline}
            </div>
          )}
          {firstAnnounce && (
            <div className={styles.announce}>
              {firstAnnounce}
            </div>
          )}
          <div className={styles.date}>{topicDateFormat(publishedAt)}</div>
        </div>
      </Link>
    </div>
  );
}

CompareCard.propTypes = {
  /** Данные для карточки, соответствующий модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
};

const Card = skip(withTheme(withBreakpoint(CompareCard)));

Card.displayName = 'CompareCard';
Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export default Card;

export { CompareCard as StorybookComponent };
