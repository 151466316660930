import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Route, Switch, Redirect } from 'core/libs/router';

import SecondaryMenu from 'site/components/SecondaryMenu';
import PageLayoutService from 'site/components/PageLayoutService';

import withGeo from 'site/components/GeoContext/withGeo';

import Offers from 'site/pages/offers';

import OffersForm from 'site/forms/Offers';


function OffersLayout({ geoSlug }) {
  return (
    <Fragment>
      <SecondaryMenu section='inventory' />
      <OffersForm />
      <PageLayoutService bgContentList>
        <Switch>
          <Route
            exact
            path='/inventory'
            render={params => <Redirect to={params.match.path + '/' + geoSlug} />}
          />
          <Route
            exact
            path='/inventory/:geo?/:brand?/:model?/:generation?'
            component={Offers}
          />
        </Switch>
      </PageLayoutService>
    </Fragment>
  );
}

OffersLayout.propTypes = {
  geoSlug: PropTypes.string,
};

export default withGeo(OffersLayout);
