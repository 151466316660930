import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { Desktop, Mobile } from 'core/components/breakpoint';

import Page from 'core/components/Page';
import H2 from 'core/components/H2';

import PageLayout from 'site/components/PageLayout';

import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing1 } from 'site/components/Ads/mobile';
import { Indent, NegativeMobile } from 'site/components/Wrappers';

import Exam from './components/Exam';
import Blocks from './components/Blocks';
import Tickets from './components/Tickets';

import { VERTICAL_INDENT_MOBILE } from 'site/constants';

function Pdd({ isMobile }) {
  const currentYear = new Date().getFullYear();
  return (
    <Page
      title={`ПДД онлайн - бесплатный онлайн экзамен ПДД ${currentYear} по билетам ГИБДД, актуальные билеты ПДД, режим тренировки`}
    >
      <PageLayout>
        <H2 is='h1'>Онлайн-билеты ПДД России, {currentYear}</H2>
        <Indent bottom={isMobile ? VERTICAL_INDENT_MOBILE : 24} />
        <NegativeMobile>
          <Exam />
        </NegativeMobile>
        <Blocks />
        <Tickets />
        <Indent bottom={41} />
        <Desktop>
          <SuperFooter />
        </Desktop>
        <Mobile>
          <Listing1 />
        </Mobile>
      </PageLayout>
    </Page>
  );
}

Pdd.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Pdd);
