import PropTypes from 'prop-types';
import cx from 'classnames';

import themePropTypes from 'core/utils/prop-types/theme';
import withTheme from 'core/components/theme';

function LimeGradientBg(props) {
  const {
    theme,
    children,
    className,
    ...otherProps
  } = props;

  return (
    <div className={cx('gradient', className)} {...otherProps}>
      <style jsx>{`
        .gradient
          background linear-gradient(360deg,
            ${theme.colors.lime1} 10%,
            ${theme.colors.lime2} 50%,
            ${theme.colors.lime3} 100%
          )
      `}</style>
      {children}
    </div>
  );
}

LimeGradientBg.propTypes = {
  className: PropTypes.string,
  theme: themePropTypes(`{
    colors: {
      lime1,
      lime2,
      lime3,
    }
  }`),
};

export default withTheme(LimeGradientBg);
