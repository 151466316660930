import { useContext, useEffect, useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import { ConfiguratorContext } from 'site/components/ConfiguratorProvider';

import Option from 'site/components/Option';
import { Indent } from 'site/components/Wrappers';

import styles from './index.styl';


function InstalledOptions({ modificationId, theme }) {
  const configurations = useContext(ConfiguratorContext);
  const { getConfiguration } = configurations;
  const {
    options,
    packages,
    groups,
    subgroups,
    configuration: {
      installed_options: installedOptions,
    },
  } = configurations[modificationId];

  /**
   * Сортируем и фильтруем опции по группам и подгруппам.
   */
  const [groupsFormatted, buildGroupsFormatted] = useState({});
  /**
   * Отрезаем пакеты опций в отдельную группу.
   */
  const [installedPackages, setInstalledPackages] = useState({});

  useEffect(() => {
    if (!(installedOptions && groups && subgroups)) return;

    const slicedPackages = installedOptions.filter(({ is_package: isPackage }) => isPackage);
    setInstalledPackages(slicedPackages);

    const formatted = installedOptions.reduce((acc, option) => {
      const groupId = Object.keys(groups).find(groupKey => {
        return groups[groupKey].options.find(id => id === option.id);
      });
      const subgroupId = !groupId && Object.keys(subgroups).find(groupKey => {
        return subgroups[groupKey].options.find(id => id === option.id);
      });

      const { name: groupName } = groups[groupId] || {};
      const { name: subgroupName, is_hidden: isHidden } = subgroups[subgroupId] || {};

      if (groupName) {
        if (acc[groupName]) {
          acc[groupName].push(option);
        } else {
          acc[groupName] = [option];
        }
      }

      /**
       * Добавляем служебные подгруппы is_hidden в родительские группы.
       * Например: "CD-проигрыватель", "Поддержка MP3", "MMI Navigation Plus"
       * в группу "Аудио- и информационно-развлекательные системы"
       */
      if (subgroupName && isHidden) {
        const specialGroupId = Object.keys(groups).find(groupKey => {
          return groups[groupKey].subgroups.find(id => id === +subgroupId);
        });
        const { name: specialGroupName } = groups[specialGroupId] || {};

        if (acc[specialGroupName]) {
          acc[specialGroupName].push(option);
        } else {
          acc[specialGroupName] = [option];
        }
      }

      if (subgroupName && !isHidden) {
        if (acc[subgroupName]) {
          acc[subgroupName].push(option);
        } else {
          acc[subgroupName] = [option];
        }
      }
      return acc;
    }, {});
    buildGroupsFormatted(formatted);
  }, [installedOptions, groups, subgroups]);

  const resetConfiguration = useCallback(() => {
    getConfiguration(modificationId, {
      is_clear_install_option: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className={styles.scroll}>
        <style jsx>{`
          .${styles.rest}
            color ${theme.colors.blue1}
        `}</style>
        <div className={styles.scrollBody}>
          {Object.keys(groupsFormatted).map(groupName => {
            const groupOptions = groupsFormatted[groupName];

            return (
              <div className={styles.group} key={groupName}>
                <div className={styles.title}>{groupName}</div>
                {groupOptions.map(({ is_summarize_dependencies_price: isSummarizeDependenciesPrice, id }) => (
                  <Option
                    key={id}
                    type={isSummarizeDependenciesPrice ? 3 : 5}
                    option={options[id]}
                    modificationId={modificationId}
                  />
                ))}
              </div>
            );
          })}
          {installedPackages.length > 0 && (
            <div className={styles.group}>
              <div className={styles.title}>Пакеты</div>
              {installedPackages.map(({ is_summarize_dependencies_price: isSummarizeDependenciesPrice, id }) => (
                <Option
                  key={id}
                  type={isSummarizeDependenciesPrice ? 4 : 6}
                  option={packages[id]}
                  modificationId={modificationId}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <Indent top={10} />
      <div className={styles.footer}>
        <div
          className={styles.reset}
          onClick={resetConfiguration}
        >СБРОСИТЬ ВСЕ</div>
      </div>
    </Fragment>
  );
}

InstalledOptions.propTypes = {
  theme: themePropTypes(`{
    colors: {
      blue1,
    },
  }`),
  modificationId: PropTypes.string,
};

export default withTheme(InstalledOptions);
