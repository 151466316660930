import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import mapTypes from './types';


function Divider(props) {
  const {
    theme,
    type,
    top,
    bottom,
    left,
    right,
    className,
  } = props;


  const {
    borderType,
    color,
  } = mapTypes[type];

  return (
    <Fragment>
      <style jsx>{`
        .divider
          width ${(left || right) ? 'auto' : '100%'}
          border-bottom 1px ${borderType} ${color(theme)}
      `}</style>
      {
        <div
          className={cx('divider', className)}
          style={{
            ...top && { marginTop: `${top}px` },
            ...bottom && { marginBottom: `${bottom}px` },
            ...left && { marginLeft: `${left}px` },
            ...right && { marginRight: `${right}px` },
          }}
        >
          {' '}
        </div>
      }
    </Fragment>
  );
}

Divider.defaultProps = {
  type: 1,
};

Divider.propTypes = {
  /** Отступ сверху */
  top: PropTypes.number,
  /** Отступ снизу */
  bottom: PropTypes.number,
  /** Отступ слева */
  left: PropTypes.number,
  /** Отступ справа */
  right: PropTypes.number,
  /** Тип разделителя */
  type: PropTypes.oneOf([1, 2, 3, 4, 5]),
  /** @ignore */
  className: PropTypes.string,
  /** @ignore */
  theme: themePropTypes(`
    {
      colors: {
        divider,
      },
    }
  `),
};

const DividerWithHOCs = withTheme(Divider);
DividerWithHOCs.displayName = 'Divider';

export default DividerWithHOCs;
export { Divider as StorybookComponent };
