import { Fragment } from 'react';

import Drum from 'core/components/Drum';

export default function SmartDrum(props) {
  const {
    title,
    content,
    headerComponent: HeaderComponent,
    card: Card,
    itemWidth,
  } = props;

  if (content.length > 1) {
    return <Drum {...props} />;
  }

  return (
    <Fragment>
      <HeaderComponent>{title}</HeaderComponent>
      <div style={{ maxWidth: itemWidth, marginTop: 20 }}>
        <Card content={content[0]} />
      </div>
    </Fragment>
  );
}

SmartDrum.propTypes = Drum.propTypes;
