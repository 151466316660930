import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { rawContentFetcher } from 'core/fetchers/searchPage';

import SearchPage from 'core/components/SearchPage';
import ColumnLayout from 'core/components/ColumnLayout';

import withPageHocs from 'core/components/withPageHocs';
import { withBreakpoint } from 'core/components/breakpoint';

import { PageIndent } from 'core/components/Wrappers';
import InnerSideColumn from 'site/components/InnerSideColumn';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpec,
} from 'site/components/Ads/mobile';

import filters from './filters';

import { Card1Type4 } from 'site/cards/Card1';
import Card3 from 'site/cards/Card3';

/**
 * Результаты поиска на стейджах не будут соответствовать действительности,
 * потому что апишка поиска работает только с продовыми данными.
 * Для стейджей будут выдаваться рандомный список топиков.
 */
function Search({ rawContent, isMobile }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn showBoesque={false} />}>
        <SearchPage
          rawContent={rawContent}
          filters={filters}
          ad1={SuperFooter}
          ad2={Context}
          ad1Mobile={Listing1}
          ad2Mobile={ListingSpec}
          card={isMobile ? Card1Type4 : Card3}
          interitemSpacing={isMobile ? 0 : 30}
          dataQa='search-page'
        />
      </ColumnLayout>
    </PageIndent>
  );
}

Search.propTypes = {
  rawContent: PropTypes.array,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  rawContent: props => rawContentFetcher({ card: props.isMobile ? Card1Type4 : Card3 })(props),
});

export default withBreakpoint(withPageHocs(dataProvider)(Search));
