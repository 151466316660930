export default {
  1: {
    size: 'sm',
    headlineSize: '500 14px/16px',
    withDashed: true,
  },
  2: {
    size: 'md',
    headlineSize: '500 16px/20px',
    withComparison: true,
  },
  3: {
    size: 'lg',
    headlineSize: '500 16px/20px',
    withComparison: true,
  },
};
