import PropTypes from 'prop-types';

import InputRange from 'site/components/InputRange';


function InputRangeAdapter(props) {
  const {
    input,
    meta, // eslint-disable-line no-unused-vars
    ...otherProps
  } = props;

  return (
    <InputRange
      {...input}
      {...otherProps}
    />
  );
}

InputRangeAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

export default InputRangeAdapter;
