import React, { Suspense } from 'react';
import { SimpleLoader } from 'core/components/Loader';
import withoutSSR from 'core/components/withoutSSR';

const CommonContent = React.lazy(() => import('./CommonContent'));

export default withoutSSR(function(props) {
  return (
    <Suspense fallback={<SimpleLoader />}>
      <CommonContent {...props} />
    </Suspense>
  );
});
