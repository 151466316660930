import PropTypes from 'prop-types';
import cx from 'classnames';

import color from 'core/libs/color';
import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import { capitalize } from 'core/utils/string-helper';

import styles from './radioCloud.styl';

function RadioCloud(props) {
  const {
    label,
    theme: {
      controls: {
        radioCloud,
      },
      colors: {
        primary,
      },
      animations: {
        hover: animationsHover,
      },
    },
    name,
    ...otherInputProps
  } = props;

  return (
    <label className={cx('radioCloud', styles.radioCloud)}>
      <style jsx>{`
        .content
          color ${radioCloud.idle.color}
          background ${radioCloud.idle.background}
          transition all ${animationsHover}

        .input
          &:hover + .content
            color ${radioCloud.hover.color}
            background ${radioCloud.hover.background}

          &:hover:checked + .content
            color ${radioCloud.hoverChecked.color}
            background ${radioCloud.hoverChecked.background}

          &:checked + .content
            color ${radioCloud.checked.color}
            background ${radioCloud.checked.background}

          &:focus + .content
            color ${radioCloud.focus.color}
            background ${radioCloud.focus.background}
            box-shadow 0 0 4px ${color(primary).rgb()}

          &:focus:checked + .content
            color ${radioCloud.focusChecked.color}
            background ${radioCloud.focusChecked.background}
            box-shadow 0 0 4px ${color(primary).rgb()}

          &:disabled + .content
            color ${radioCloud.disabled.color}
            background ${radioCloud.disabled.background}

          &:disabled:checked + .content
            color ${radioCloud.disabledChecked.color}
            background ${radioCloud.disabledChecked.background}
      `}</style>
      <input
        name={name}
        {...otherInputProps}
        type='radio'
        className={cx('input', styles.input)}
      />
      <div className={cx('content', styles.content)}>{capitalize(label)}</div>
    </label>
  );
}

const radioCloudContract = `{
  color,
  background,
}`;

RadioCloud.propTypes = {
  /**
   * Имя радио-группы
   */
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  /* @ignore */
  theme: themePropTypes(`{
    controls: {
      radioCloud: {
        idle: ${radioCloudContract},
        hover: ${radioCloudContract},
        hoverChecked: ${radioCloudContract},
        checked: ${radioCloudContract},
        focus: ${radioCloudContract},
        focusChecked: ${radioCloudContract},
        disabled: ${radioCloudContract},
        disabledChecked: ${radioCloudContract},
      }
    },
  `),
};

export default withTheme(RadioCloud);
