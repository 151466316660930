import { denormalizeData } from 'core/utils/api';


export default (customParams = {}) => (componentProps) => {
  const props = { ...componentProps, ...customParams };

  const {
    servicesApi,
    consoleError,
    isMoto,
  } = props;

  let brandAPI;

  if (isMoto) {
    brandAPI = servicesApi
      .getMotoBrands({
        is_popular: true,
        'attributes[moto_brand]': ['base', 'image'],
      });
  } else {
    brandAPI = servicesApi
      .getBrands({
        is_popular: true,
        'attributes[car_brand]': ['base', 'image'],
      });
  }

  return brandAPI
    .then(denormalizeData)
    .catch(consoleError('brands', []));
};
