import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import H3 from 'core/components/H3';
import Drum from 'core/components/Drum';
import Feed from 'core/components/Feed';

import { Indent } from 'site/components/Wrappers';

import CardServiceReview1 from 'site/cardsService/CardServiceReview1';
import { CardServiceReview2Type1 } from 'site/cardsService/CardServiceReview2';
import CardServiceReview3 from 'site/cardsService/CardServiceReview3';

function MoreReviews({ isMobile, reviews }) {
  if (!reviews || reviews.length === 0) return null;

  const feedProps = {
    grid: true,
    title: 'Другие отзывы',
    headerComponent: H3,
    content: reviews,
  };

  if (isMobile) {
    return (
      <Drum
        itemWidth='80%'
        itemWidthMobile='100%'
        card={CardServiceReview1}
        {...feedProps}
      />
    );
  }

  if (reviews.length === 1) {
    return (
      <Fragment>
        <H3>Другие отзывы</H3>
        <Indent bottom={10} />
        <CardServiceReview2Type1 content={reviews[0]} />
      </Fragment>
    );
  }

  if (reviews.length === 3) {
    return (
      <Feed
        {...feedProps}
        interitemSpacing={15}
        card={CardServiceReview1}
      />
    );
  }

  return (
    <Feed
      columns={2}
      interitemSpacing={15}
      card={CardServiceReview3}
      {...feedProps}
    />
  );
}

MoreReviews.propTypes = {
  isMobile: PropTypes.bool,
  reviews: PropTypes.array,
};

export default withBreakpoint(MoreReviews);
