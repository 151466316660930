import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import Socializator from 'core/components/Socializator';

import ButtonWithIcon from 'core/components/ButtonWithIcon';
import ButtonTextWrap from 'site/components/Wrappers/ButtonTextWrap';

import { replaceLastCharacter } from 'site/utils';

import Comments from 'site/icons/Comments';

import styles from './index.styl'; // eslint-disable-line

export default function SocialShare(props) {
  const {
    topicLink,
    isCommentsDisabled,
  } = props;

  return (
    <div className={styles.socialShare}>
      {!isCommentsDisabled && (
        <Link
          className={styles.box}
          type='blank'
          to={`${replaceLastCharacter(topicLink, '/')}/comments`}
        >
          <ButtonWithIcon
            icon={Comments}
            type='secondary'
          >
            <ButtonTextWrap>Обсудить</ButtonTextWrap>
          </ButtonWithIcon>
        </Link>
      )}
      <Socializator />
    </div>
  );
}

SocialShare.propTypes = {
  topicLink: PropTypes.string,
  isCommentsDisabled: PropTypes.bool,
};
