import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';

import Page from 'core/components/Page';
import withPageHocs from 'core/components/withPageHocs';

import ReleaseBoroda from 'site/components/ReleaseBoroda';
import { ReleaseInnerPages } from 'site/components/Wrappers';
import PhotoAndVideo from 'site/components/PhotoAndVideo';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';
import modelPropTypes, { carReleaseAttributes, seoAttributes } from 'site/utils/prop-types/model';

import { releaseCommonProviders } from 'site/pages/release/releaseDataProvider';


function ReleasePhoto(props) {
  const {
    seo,
    release,
    isTopicsByBrandExist,
  } = props;

  return (
    <Page title={seo.title} description={seo.description}>
      <ReleaseInnerPages release={release} isTopicsByBrandExist={isTopicsByBrandExist}>
        <PhotoAndVideo release={release} />
        <ReleaseBoroda release={release} />
      </ReleaseInnerPages>
    </Page>
  );
}

ReleasePhoto.propTypes = {
  release: modelPropTypes(carReleaseAttributes),
  isTopicsByBrandExist: PropTypes.bool,
  seo: seoAttributes,
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
    }),
  }).isRequired,
};

const dataProvider = resolve({
  release: ({ servicesApi, renderError, match }) => {
    const {
      params: {
        brand,
        model,
        generation,
        body,
      },
    } = match;

    return (
      servicesApi
        .getReleases({
          'filter[brand]': brand,
          'filter[model]': model,
          'filter[generation]': generation,
          'filter[body]': body,
          include: 'car_brand,car_feature',
          limit: 1,
          'relations[car_release]': 'brand,features',
          'attributes[car_release]': 'base,image,media,seo,extended',
        })
        .then(releases => denormalizeData(releases)[0])
        .then(rejectEmptyResult)
        .catch(renderError)
    );
  },

  seo: ({ servicesApi, consoleError, match: { params } }) => {
    return (
      servicesApi
        .getReleasesPhotoSeo({
          'filter[brand]': params.brand,
          'filter[model]': params.model,
          'filter[generation]': params.generation,
          'filter[submodel]': params.body,
        })
        .then(denormalizeData)
        .catch(consoleError('seo', {}))
    );
  },
  ...releaseCommonProviders,
});

export default withPageHocs(dataProvider)(ReleasePhoto);
