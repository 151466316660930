import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import Feed from 'core/components/Feed';

import { Indent } from 'site/components/Wrappers';
import Divider from 'site/components/Divider';
import TitleUpperCase from 'site/components/TitleUpperCase';

import CardModification1WithPrice from 'site/cardsService/CardServiceModification1/cards/CardModification1WithPrice';
import CardModification2WithPrice from 'site/cardsService/CardServiceModification2/cards/CardModification2WithPrice';

import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import { getReleasesClasses, getSortedClassTitles } from './utils';


function BrandReleases(props) {
  const {
    releases,
    isMobile,
    desktopCard: DesktopCard,
    mobileCard: MobileCard,
  } = props;

  const releasesClasses = getReleasesClasses(releases);

  const sortedClassTitles = getSortedClassTitles(releasesClasses);

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Fragment>
      <Mobile>
        <Divider />
      </Mobile>
      {sortedClassTitles.map(classTitle => (
        <Indent bottom={vertical} key={classTitle}>
          <TitleUpperCase title={classTitle} />
          <Feed
            content={releasesClasses[classTitle].releases}
            card={isMobile ? MobileCard : DesktopCard}
          />
        </Indent>
      ))}
    </Fragment>
  );
}

BrandReleases.defaultProps = {
  desktopCard: CardModification1WithPrice,
  mobileCard: CardModification2WithPrice,
};

BrandReleases.propTypes = {
  isMobile: PropTypes.bool,
  releases: PropTypes.arrayOf(modelPropTypes(carReleaseAttributes)).isRequired,
  desktopCard: PropTypes.func,
  mobileCard: PropTypes.func,
};

export default withBreakpoint(BrandReleases);
