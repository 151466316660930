import PropTypes from 'prop-types';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import H3 from 'core/components/H3';
import { Section, Block } from 'core/components/Grid';

import Divider from 'site/components/Divider';
import Checkboxes from 'site/components/Checkboxes';
import { Indent } from 'site/components/Wrappers';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

/**
 * Выбор опций конфигуратора.
 */
function Options({ filteredOptions, optionsValue, onChange, isMobile }) {
  const {
    security,
    exterior,
    climate,
    heating,
    electricDrive,
    electronics,
    multimedia,
    interiorTrim,
  } = filteredOptions;

  const verticalIndent = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const divider = (
    <Desktop>
      <Divider
        type={4}
        top={verticalIndent}
        bottom={verticalIndent}
      />
    </Desktop>
  );

  const checkboxesProps = {
    onChange,
    inputName: 'options',
    checkboxesState: optionsValue,
    isVerticalView: true,
  };

  return (
    <div className='options'>
      <H3>Функции и оборудование</H3>
      <Indent bottom={30} />
      <Section>
        <Block mobile={12} desktop={4}>
          <Checkboxes
            title={security?.title}
            options={security?.options.slice(0, 4)}
            {...checkboxesProps}
          />
          <Mobile>
            <Divider
              type={4}
              top={verticalIndent}
              bottom={verticalIndent}
            />
          </Mobile>
        </Block>
        <Block mobile={12} desktop={4}>
          <Desktop><Indent top={50} /></Desktop>
          <Checkboxes
            options={security?.options.slice(4)}
            {...checkboxesProps}
          />
          <Mobile><Indent bottom={VERTICAL_INDENT} /></Mobile>
        </Block>
      </Section>
      {divider}
      <Section>
        {[exterior, climate, heating].map((group, i) => (
          <Block
            mobile={12}
            desktop={4}
            key={i}
          >
            <Checkboxes
              title={group?.title}
              options={group?.options}
              {...checkboxesProps}
            />
            <Mobile><Indent bottom={VERTICAL_INDENT} /></Mobile>
          </Block>
        ))}
      </Section>
      {divider}
      <Section>
        <Block mobile={12} desktop={4}>
          <Checkboxes
            title={electricDrive?.title}
            options={electricDrive?.options}
            {...checkboxesProps}
          />
          <Indent bottom={isMobile ? 30 : 50} />
          <Checkboxes
            title={interiorTrim?.title}
            options={interiorTrim?.options}
            {...checkboxesProps}
          />
          <Mobile><Indent bottom={VERTICAL_INDENT} /></Mobile>
        </Block>
        {[electronics, multimedia].map((group, i) => (
          <Block
            mobile={12}
            desktop={4}
            key={i}
          >
            <Checkboxes
              title={group?.title}
              options={group?.options}
              {...checkboxesProps}
            />
            <Mobile><Indent bottom={VERTICAL_INDENT} /></Mobile>
          </Block>
        ))}
      </Section>
    </div>
  );
}

Options.propTypes = {
  /**
   * Список опций для рендера.
   */
  filteredOptions: PropTypes.object,
  /**
   * Выбранные опции
   */
  optionsValue: PropTypes.array,
  /**
   * Обработчик контролов в форме
   */
  onChange: PropTypes.func.isRequired,
  /** @ignore */
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Options);
