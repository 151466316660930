import PropTypes from 'prop-types';
import { useContext } from 'react';

import { withBreakpoint } from 'core/components/breakpoint';

import Switch from 'site/components/Switch';

import { ComparePageContext } from 'site/pages/compare/ComparePageProvider';

/**
 * Компонент тумблер "Все параметры/Только различающиеся"
 */
function Switcher({ isMobile }) {
  const { toggleDifferent, differentState } = useContext(ComparePageContext);

  return (
    <Switch
      labelBefore='Все параметры'
      labelAfter={isMobile ? 'Различающиеся' : 'Только различающиеся'}
      highlightText
      input={{
        checked: differentState,
        onChange: toggleDifferent,
      }}
    />
  );
}

Switcher.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Switcher);
