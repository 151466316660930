import { denormalizeData } from 'core/utils/api';


const COMPARISON_LIMIT = 4;

export default (customParams = {}) => (componentProps) => {
  const props = { ...componentProps, ...customParams };

  const {
    servicesApi,
    consoleError,
    id,
    bestChoice,
  } = props;

  let method;
  let params;

  const { slug: editorsChoice, type: vehicleType } = bestChoice?.attributes || {};

  if (vehicleType === 'moto') {
    method = servicesApi.getMotoCompareTop;
    params = {
      'attributes[moto_release]': 'base,image,extended,',
      limit: COMPARISON_LIMIT,
    };
  } else {
    method = servicesApi.getCompareTop;
    params = {
      'attributes[car_release]': 'base,image',
      'attributes[car_modification]': 'base,extended',
      'relations[car_modification]': 'release',
      modification_uuid: id,
      include: 'car_release',
      limit: COMPARISON_LIMIT,
    };
  }

  if (editorsChoice) {
    params['filter[editors_choice]'] = editorsChoice;
  }

  return method(params)
    .then(({ data, included }) => (
      /**
       * Т. к. ответе приходят пары наиболее частых сравнений модификаци,
       * используем denormalizeData для каждой модификации отдельно.
       * Здесь же создаём сущность, которую можно передавать в Feed.
       */
      data.map((pair, index) => ({
        id: index,
        items: pair.map(comparison => denormalizeData({ data: comparison, included })),
      }))
    ))
    .catch(consoleError('comparisons', []));
};
