import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { Mobile, Desktop } from 'core/components/breakpoint';

import themePropTypes from 'core/utils/prop-types/theme';

import { Indent } from 'site/components/Wrappers';
import Divider from 'site/components/Divider';

import { VERTICAL_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';

function TitleUpperCase({ theme, title }) {
  if (!title) return null;

  return (
    <div className={styles.headline}>
      <style jsx>{`
        .${styles.headline}
          color ${theme.colors.primary}
          font bold 16px/20px ${theme.fonts.text}

          :global(.mobile) &
            color ${theme.colors.primary400}
            font bold 12px/16px ${theme.fonts.text}
      `}</style>
      <Desktop>
        {title}
      </Desktop>
      <Mobile>
        <Indent top={VERTICAL_INDENT_MOBILE}>
          {title}
          <Divider top={VERTICAL_INDENT_MOBILE} />
        </Indent>
      </Mobile>
    </div>
  );
}

TitleUpperCase.propTypes = {
  /** Текст заголовка */
  title: PropTypes.string,
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      primary400,
    },
  }`),
};

const TitleUpperCaseWithHOCs = withTheme(TitleUpperCase);
TitleUpperCaseWithHOCs.displayName = 'TitleUpperCase';

export default TitleUpperCaseWithHOCs;
export { TitleUpperCase as StorybookComponent };
