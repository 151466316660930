import PropTypes from 'prop-types';

import modelPropTypes, { geoAttributes } from 'site/utils/prop-types/model';

import InputDropdown from 'site/components/InputDropdown';

import { generateSelectOptions } from 'site/utils/forms';

function GeoSelect({ geoSlug, geos, onChange }) {
  const geoOptions = generateSelectOptions(geos, { value: 'attributes.url' });
  const defaultValue = geoOptions.find(option => option.value === geoSlug);

  return (
    <InputDropdown
      isMulti={false}
      onChange={onChange}
      name='geo'
      label='Город или регион'
      placeholder='Введите название'
      options={geoOptions}
      defaultValue={defaultValue}
    />
  );
}

GeoSelect.propTypes = {
  /** Обработчик выбора региона */
  onChange: PropTypes.func,
  /** Коллекция гео-сущностей, описываемых моделью `geoAttributes` */
  geos: PropTypes.arrayOf(modelPropTypes(geoAttributes)).isRequired,
  /** Слаг региона */
  geoSlug: PropTypes.string,
};

export default GeoSelect;
